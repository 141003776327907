/* eslint-disable react/no-array-index-key */
import * as utility from 'lib/core/utility';
import React from 'react';

export const getOptionsProfile = (profiles) => {
  const options = profiles.map((profile) => ({
    label: profile.name,
    value: profile.id,
  }));
  return options;
};
export const DataFilter = (segments, profiles) => {
  const segmentData = segments.map((segment) => ({
    label: segment.name,
    value: {
      id: segment.id,
      others: '',
    },
  }));
  const profileData = profiles.map((profile) => ({
    label: profile.name,
    value: {
      id: profile.id,
      others: '',
    },
  }));
  const statusData = [
    {
      label: 'Ativos',
      value: {
        id: 'true',
      },
    },
    {
      label: 'Inativos',
      value: {
        id: 'false',
      },
    },
  ];
  const dataFilter = [
    {
      label: 'Nome',
      name: 'name',
      type: 'input',
    },
    {
      data: statusData,
      label: 'Status',
      name: 'active',
      type: 'multiselect',
    },
    {
      data: profileData,
      label: 'Perfil',
      type: 'multiselect',
      name: 'profile',
    },
    {
      data: segmentData,
      label: 'Segmento',
      type: 'multiselect',
      name: 'segment',
    },
  ];
  return dataFilter;
};
export const getMultiselectOptionsSegment = (segments) => {
  const options = segments.map((segment) => ({
    label: segment.name,
    value: {
      id: segment.id,
      others: '',
    },
  }));
  return options;
};

export const getDataSend = ({ name, account, profileId, segmentIds }) => {
  const getData = {
    name,
    account,
    profileId,
    segmentIds: segmentIds.map((segment) => segment.value.id),
  };
  return getData;
};

export const getUsersParams = (PageIndex, PageSize) => {
  const filterFields = {
    filterFields: {
      PageIndex,
      PageSize,
    },
  };
  return `${utility.autoListingParamsGet(filterFields)}`;
};

export const getFilterParams = (data) => {
  let ProfileIds = [];
  let SegmentIds = [];
  let Active = null;
  if (data.profile) {
    ProfileIds = data.profile.map((item) => item.value.id);
  }
  if (data.segment) {
    SegmentIds = data.segment.map((item) => item.value.id);
  }
  if (data.active) {
    Active =
      data.active.length === 2
        ? null
        : data.active.map((item) => item.value.id)[0];
  }

  const filterFields = {
    filterFields: {
      UserName: data.name,
      ProfileIds,
      SegmentIds,
      Active,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `${utility.autoListingParamsGet(filterFields)}`;
};

export const getAutoParams = (data) => {
  const filterFields = {
    filterFields: {
      UserName: data.name,
      Active: true,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `${utility.autoListingParamsGet(filterFields)}`;
};

export const formatErrorMessages = (messagesFormat) => {
  return (
    <ul style={{ marginLeft: '20px' }}>
      <p>
        Formalizações atreladas ao usuário impossibilitam a ação de desativação
        da mesma.
      </p>
      <br />
      <p>
        <b>Contrato(s) vinculados(s):</b>
      </p>
      {messagesFormat?.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  );
};

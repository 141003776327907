import { Text, Tokens } from '@vli/locomotive-ds';
import styled from 'styled-components';

export const CustomText = styled(Text)`
  cursor: pointer;
  font: ${Tokens.fonts.textRegularSmall};

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: ${Tokens.colors.accentPrimaryDark};
    }

    svg path {
      fill: ${Tokens.colors.accentPrimaryDark};
    }
  }
`;

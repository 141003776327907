import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const CardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const Container = styled.div`
  margin-top: ${Tokens.spacing.sm};
`;

export const TextStatus = styled.span`
  margin-left: ${Tokens.spacing.xs};
`;

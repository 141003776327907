import React from 'react';
import CellTable from 'components/core/Table/CellTable';

const Columns = [
  {
    Header: 'Fórmula',
    accessor: 'name',
    align: 'start',
    Cell: (props) => <CellTable pointer={false} {...props} />,
  },
  {
    Header: 'Racional',
    accessor: 'translatedFormula',
    align: 'start',
    Cell: (props) => <CellTable pointer={false} {...props} />,
  },
];

export default Columns;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from 'constants/request';

export const { Types: UserTypes, Creators: UserActions } = createActions({
  fetchUsers: ['params', 'filter'],
  fetchUsersSuccess: ['payload', 'filter'],
  fetchUsersFilter: ['params', 'filter'],
  fetchUsersFilterSuccess: ['payload', 'filter'],
  fetchUsersFailure: ['error'],
  updateUser: ['payload'],
  updateUserSuccess: [],
  getUser: ['payload'],
  getUserSuccess: ['payload'],
  getUserFailure: ['error'],
  createUser: ['payload'],
  createUserSuccess: [],
  setDeleteUser: ['payload'],
  deleteUser: ['payload', 'callback'],
  deleteUserSuccess: [],
  userRequestFailure: ['error', 'originalType'],
  listFormalizationsUser: ['payload'],
  listFormalizationsUserSuccess: ['payload'],
  listFormalizationsUserFailure: ['error'],
});

const INITIAL_STATE = {
  data: [],
  user: null,
  userDelete: null,
  openDelete: false,
  filter: {},
  error: null,
  formalizationsUserList: [],
  usersRequests: {
    [UserTypes.FETCH_USERS]: REQUEST_NOT_STARTED,
    [UserTypes.CREATE_USER]: REQUEST_NOT_STARTED,
    [UserTypes.UPDATE_USER]: REQUEST_NOT_STARTED,
    [UserTypes.LIST_FORMALIZATIONS_USER]: REQUEST_NOT_STARTED,
  },
};

const fetchUsers = (state) => ({
  ...INITIAL_STATE,
  filter: state.filter,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.FETCH_USERS]: REQUEST_PENDING,
  },
});

const fetchUsersSuccess = (state, { payload, filter }) => ({
  ...state,
  data: payload,
  user: null,
  filter,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.FETCH_USERS]: REQUEST_RESOLVED,
    [UserTypes.CREATE_USER]: REQUEST_NOT_STARTED,
    [UserTypes.UPDATE_USER]: REQUEST_NOT_STARTED,
  },
});

const fetchUsersFilter = (state) => ({
  ...INITIAL_STATE,
  filter: state.filter,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.FETCH_USERS_FILTER]: REQUEST_PENDING,
  },
});

const fetchUsersFilterSuccess = (state, { payload, filter }) => ({
  ...state,
  data: payload,
  user: null,
  filter,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.FETCH_USERS_FILTER]: REQUEST_RESOLVED,
  },
});

const fetchUsersFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.FETCH_USERS]: REQUEST_REJECTED,
  },
});

const updateUser = (state) => ({
  ...state,
  message: null,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.UPDATE_USER]: REQUEST_PENDING,
  },
});

const updateUserSuccess = (state) => ({
  ...state,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.UPDATE_USER]: REQUEST_RESOLVED,
  },
});

const getUser = (state) => ({
  ...state,
  user: null,
  message: null,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.GET_USER]: REQUEST_PENDING,
  },
});

const getUserSuccess = (state, { payload }) => ({
  ...state,
  user: payload,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.GET_USER]: REQUEST_RESOLVED,
  },
});

const getUserFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.GET_USER]: REQUEST_REJECTED,
  },
});

const setDeleteUser = (state, { payload }) => ({
  ...state,
  userDelete: payload.user,
  openDelete: payload.modalOpen,
});

const deleteUser = (state) => ({
  ...state,
  message: null,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.DELETE_USER]: REQUEST_PENDING,
  },
});

const deleteUserSuccess = (state) => ({
  ...state,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.DELETE_USER]: REQUEST_RESOLVED,
  },
});

const createUser = (state) => ({
  ...state,
  message: null,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.CREATE_USER]: REQUEST_PENDING,
  },
});

const createUserSuccess = (state) => ({
  ...state,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.CREATE_USER]: REQUEST_RESOLVED,
  },
});

const listFormalizationsUser = () => ({
  ...INITIAL_STATE,
  usersRequests: {
    ...INITIAL_STATE.usersRequests,
    [UserTypes.LIST_FORMALIZATIONS_USER]: REQUEST_PENDING,
  },
});

const listFormalizationsUserSuccess = (state, { payload }) => ({
  ...state,
  formalizationsUserList: payload,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.LIST_FORMALIZATIONS_USER]: REQUEST_RESOLVED,
  },
});

const listFormalizationsUserFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  usersRequests: {
    ...state.usersRequests,
    [UserTypes.LIST_FORMALIZATIONS_USER]: REQUEST_REJECTED,
  },
});

const userRequestFailure = (state, { error: { response }, originalType }) => ({
  ...state,
  error: response,
  usersRequests: {
    ...state.trainingRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [UserTypes.FETCH_USERS]: fetchUsers,
  [UserTypes.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [UserTypes.FETCH_USERS_FILTER]: fetchUsersFilter,
  [UserTypes.FETCH_USERS_FILTER_SUCCESS]: fetchUsersFilterSuccess,
  [UserTypes.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [UserTypes.UPDATE_USER]: updateUser,
  [UserTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UserTypes.GET_USER]: getUser,
  [UserTypes.GET_USER_SUCCESS]: getUserSuccess,
  [UserTypes.GET_USER_FAILURE]: getUserFailure,
  [UserTypes.SET_DELETE_USER]: setDeleteUser,
  [UserTypes.DELETE_USER]: deleteUser,
  [UserTypes.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [UserTypes.CREATE_USER]: createUser,
  [UserTypes.CREATE_USER_SUCCESS]: createUserSuccess,
  [UserTypes.USER_REQUEST_FAILURE]: userRequestFailure,
  [UserTypes.LIST_FORMALIZATIONS_USER]: listFormalizationsUser,
  [UserTypes.LIST_FORMALIZATIONS_USER_SUCCESS]: listFormalizationsUserSuccess,
  [UserTypes.LIST_FORMALIZATIONS_USER_FAILURE]: listFormalizationsUserFailure,
});

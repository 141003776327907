import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const TableContainer = styled.div`
  width: 100%;
  padding: ${Tokens.spacing.md} 0px;

  table {
    width: 100%;

    th {
      text-align: left;
    }

    th:last-child {
      text-align: right;
    }
  }
`;

import styled from 'styled-components';

export const HeadLight = styled.div`
  display: block;
  cursor: pointer;
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  margin-left: ${({ marginLeft }) => `${marginLeft}`};
  border-radius: 100%;
  background-color: ${({ color }) => `${color}`};
`;

import React from 'react';
import { Text, ErrorPainel, Button } from '@vli/locomotive-ds/dist';

import { useDispatch } from 'react-redux';
import { AuthActions } from 'store/auth/auth.ducks';

import * as S from './Forbidden.styles';

const Forbidden = () => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(AuthActions.signOut());
  };

  return (
    <S.Wrapper data-testid="content">
      <ErrorPainel>
        <Text>
        Seu usuário não tem permissão para acessar essa página. Por favor, entre em contato com o time Jetsons.
        </Text>
        <S.ForbiddenContainer>
          <S.ForbiddenButtons>
            <Button
              variant="secondary"
              icon={false}
              name="Trocar a conta"
              onClick={logout}
            />
          </S.ForbiddenButtons>
        </S.ForbiddenContainer>
      </ErrorPainel>
    </S.Wrapper>
  );
};
export default Forbidden;

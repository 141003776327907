import React from 'react';
import PropTypes from 'prop-types';

import TableContainer from 'components/core/TableContainer';
import Table from 'components/core/Table';

import Columns from './Columns';

import * as S from './ReportListStyles';

const ReportList = ({ data }) => {
  const itemsData = data
    .reduce((acc, current) => {
      return [...acc, ...current.items];
    }, [])
    .filter((item) => !item.closed);

  return (
    <TableContainer data-testid="report-list-table-test">
      <S.TotalInfo>
        Total de contratos incluídos no sistema:{' '}
        <strong data-testid="report-list-total">{itemsData.length}</strong>{' '}
        contratos
      </S.TotalInfo>

      {itemsData.length && (
        <Table width="100%" columns={Columns} data={itemsData} />
      )}
    </TableContainer>
  );
};

ReportList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReportList;

import React from 'react';
import { useSelector } from 'react-redux';

import DieselOther from './DieselOther';
import DieselSupplierVli from './DieselSupplierVli';
import DieselAccordionBaseList from './DieselAccordionBaseList';
import DieselSupplierPetrobras from './DieselSupplierPetrobras';

const DieselForm = () => {
  const { suppliersList } = useSelector(({ diesel }) => diesel);

  switch (suppliersList[0].key) {
    case 'vlisuppliers':
      return (
        <>
          <DieselAccordionBaseList />
          <DieselSupplierVli />
        </>
      );

    case 'petrobras':
      return <DieselSupplierPetrobras />;

    default:
      return <DieselOther suppliersList={suppliersList} />;
  }
};

DieselForm.propTypes = {};

DieselForm.defaultProps = {};

export default DieselForm;

import * as utility from 'lib/core/utility';
import { ROLES, ACTIONS, canExecuteAction } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';

export const setMonthsDataFromStringToNumber = (data) => {
  const dataFormatted = data.map((month) => {
    const formatted =
      month.value !== null
        ? { ...month, value: parseFloat(month.value.replace(',', '.')) }
        : month;
    return formatted;
  });
  return dataFormatted;
};

export const monthsData = [
  {
    month: 1,
    value: null,
  },
  {
    month: 2,
    value: null,
  },
  {
    month: 3,
    value: null,
  },
  {
    month: 4,
    value: null,
  },
  {
    month: 5,
    value: null,
  },
  {
    month: 6,
    value: null,
  },
  {
    month: 7,
    value: null,
  },
  {
    month: 8,
    value: null,
  },
  {
    month: 9,
    value: null,
  },
  {
    month: 10,
    value: null,
  },
  {
    month: 11,
    value: null,
  },
  {
    month: 12,
    value: null,
  },
];

export const getNewItemDataSend = (
  {
    product,
    trigger,
    placeOriginCargo,
    originCode,
    destination,
    destinationCode,
    contract,
    railwayId,
    railwayInvolved,
    dataBase,
    correctionStartDate,
    correctionEndDate,
    fareType,
    fareValues,
    triggerPercentage,
    cargoOrigin,
    diesel,
  },
  segmentId,
  status, // 0 - RASCUNHO e 1 - VINCULADO
  inCurveData,
) => {
  const getData = {
    codeItem: railwayId,
    codeContract: contract,
    localId: !diesel ? null : diesel,
    status,
    originType: 0, // 0 - MANUAL e 1 - INTEGRACAO
    origin: !cargoOrigin ? null : cargoOrigin,
    originCode: !originCode ? null : originCode,
    destination: !destination ? null : destination,
    destinationCode: !destinationCode ? null : destinationCode,
    railwayInvolved: !railwayInvolved ? null : railwayInvolved,
    segmentId,
    productUnicomMerchandiseTacito: !product ? null : product,
    placeOriginCargo: !placeOriginCargo ? null : placeOriginCargo,
    hasTrigger: trigger !== 0,
    triggerPercentage:
      triggerPercentage === undefined || '' ? null : Number(triggerPercentage),
    dataBase: !dataBase ? null : dataBase,
    correctionStartDate: !correctionStartDate ? null : correctionStartDate,
    correctionEndDate: !correctionEndDate ? null : correctionEndDate,
    fareType, // 0 - FIXA e 1 - CURVA
    fareValues:
      fareType === 1
        ? setMonthsDataFromStringToNumber(inCurveData)
        : [
            {
              month: null,
              value: Number(fareValues.replace('.', '').replace(',', '.')),
            },
          ],
  };
  return getData;
};

export const DataFilter = (contracts, segments, items, baseDiesel) => {
  const contractData = contracts.map((contract) => ({
    label: contract.code,
    value: {
      id: contract.code,
      others: '',
    },
  }));
  const segmentData = segments.map((segment) => ({
    label: segment.name,
    value: {
      id: segment.id,
      others: '',
    },
  }));
  const itemData = items.map((item) => ({
    label: item.name,
    value: {
      id: item.id,
      others: '',
    },
  }));
  const baseDieselData = baseDiesel.map((diesel) => ({
    label: diesel.name,
    value: {
      id: diesel.id,
      others: '',
    },
  }));

  const dataFilter = [
    {
      data: contractData,
      label: 'Contrato',
      name: 'contract',
      type: 'multiselect',
    },
    {
      data: segmentData,
      label: 'Segmento Comercial',
      name: 'segment',
      type: 'multiselect',
    },
    {
      data: itemData,
      label: 'Item ferroviário',
      type: 'multiselect',
      name: 'item',
    },
    {
      data: baseDieselData,
      label: 'Base Diesel',
      type: 'multiselect',
      name: 'diesel',
    },
  ];
  return dataFilter;
};

export const getFilterParams = (data) => {
  const contractIds = data.contract
    ? data.contract.map((contract) => contract.value.id)
    : null;
  const baseDieselIds = data.diesel
    ? data.diesel.map((diesel) => diesel.value.id)
    : null;
  const itemIds = data.item ? data.item.map((item) => item.value.id) : null;
  const segmentIds = data.segment
    ? data.segment.map((segment) => segment.value.id)
    : null;
  const filterFields = {
    filterFields: {
      CodeContract: contractIds,
      LocalId: baseDieselIds,
      CodeItem: itemIds,
      SegmentId: segmentIds,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const setBaseDieselLocalTypeNameOptions = (data) => {
  if (data) {
    // get objects with names
    const duplicateNames = data
      .map((e) => e.name)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => data[obj])
      .map((e) => data[e].name);

    // get only names
    const getDuplicates = data.filter((obj) =>
      duplicateNames.includes(obj.name),
    );

    // get only ids of objects with same names
    const duplicateObjectsId = getDuplicates.map((obj) => obj.id);
    // going to get the objects with same name and change it, to make different names
    const changeName = getDuplicates.map((obj) => {
      return { ...obj, name: `${obj.name} - ${obj.typeLocal}` };
    });

    // get array without objects with same names
    const getArrayWithoutDuplicates = data.filter(
      (obj) => !duplicateObjectsId.includes(obj.id),
    );

    return getArrayWithoutDuplicates
      .concat(changeName)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  return data;
};

export const canEditNewItem = () => {
  const { roles } = GET('roles');

  return canExecuteAction(ROLES.NEW_ITEMS, ACTIONS.UPDATE, roles);
};

export const modeEditRow = (newItems, row, editMode) => {
  return newItems?.map((newItem) => {
    if (row.id === newItem.id) {
      return {
        ...newItem,
        editMode: true,
      };
    }

    return {
      ...newItem,
      editMode: editMode ? false : null,
    };
  });
};

export const resetEditRow = (newItems) => {
  return newItems?.map((newItem) => {
    return {
      ...newItem,
      editMode: false,
    };
  });
};

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const { Types: SegmentTypes, Creators: SegmentActions } = createActions({
  listSegment: ['payload'],
  listSegmentSuccess: ['payload'],
  listSegmentActive: ['payload'],
  listSegmentActiveSuccess: ['payload'],
  getSegment: ['payload'],
  getSegmentSuccess: ['payload'],
  createSegment: ['payload'],
  createSegmentSuccess: ['payload'],
  updateSegment: ['payload'],
  updateSegmentSuccess: ['payload'],
  updateSegmentStatus: ['payload', 'callback'],
  updateSegmentStatusSuccess: ['payload'],
  segmentRequestFailure: ['error', 'originalType'],
  listAllSegments: [],
  listAllSegmentsSuccess: ['payload'],
  listAllSegmentsFailure: ['error'],
});

const INITIAL_STATE = {
  segmentList: [],
  segmentProfileList: [],
  allSegments: [],
  error: null,
  details: null,
  segmentRequests: {
    [SegmentTypes.LIST_SEGMENT]: REQUEST_NOT_STARTED,
    [SegmentTypes.LIST_SEGMENT_ACTIVE]: REQUEST_NOT_STARTED,
  },
};

const listSegment = () => ({
  ...INITIAL_STATE,
  segmentRequests: {
    ...INITIAL_STATE.segmentRequests,
    [SegmentTypes.LIST_SEGMENT]: REQUEST_PENDING,
  },
});

const listSegmentSuccess = (state, { payload }) => ({
  ...state,
  segmentList: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.LIST_SEGMENT]: REQUEST_RESOLVED,
  },
});

const listSegmentActive = () => ({
  ...INITIAL_STATE,
  segmentRequests: {
    ...INITIAL_STATE.segmentRequests,
    [SegmentTypes.LIST_SEGMENT_ACTIVE]: REQUEST_PENDING,
  },
});

const listSegmentActiveSuccess = (state, { payload }) => ({
  ...state,
  segmentProfileList: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.LIST_SEGMENT_ACTIVE]: REQUEST_RESOLVED,
  },
});

const getSegment = (state) => ({
  ...state,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.GET_SEGMENT]: REQUEST_PENDING,
  },
});

const getSegmentSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.GET_SEGMENT]: REQUEST_RESOLVED,
  },
});

const createSegment = (state) => ({
  ...state,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.CREATE_SEGMENT]: REQUEST_PENDING,
  },
});

const createSegmentSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.CREATE_SEGMENT]: REQUEST_RESOLVED,
  },
});

const updateSegment = (state) => ({
  ...state,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.UPDATE_SEGMENT]: REQUEST_PENDING,
  },
});

const updateSegmentSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.UPDATE_SEGMENT]: REQUEST_RESOLVED,
  },
});

const updateSegmentStatus = (state) => ({
  ...state,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.UPDATE_SEGMENT_STATUS]: REQUEST_PENDING,
  },
});

const updateSegmentStatusSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.UPDATE_SEGMENT_STATUS]: REQUEST_RESOLVED,
  },
});

const listAllSegments = () => ({
  ...INITIAL_STATE,
  segmentRequests: {
    ...INITIAL_STATE.segmentRequests,
    [SegmentTypes.LIST_ALL_SEGMENTS]: REQUEST_PENDING,
  },
});

const listAllSegmentsSuccess = (state, { payload }) => ({
  ...state,
  allSegments: payload,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.LIST_ALL_SEGMENTS]: REQUEST_RESOLVED,
  },
});

const listAllSegmentsFailure = (state, { error: { response } }) => ({
  ...state,
  allSegments: [],
  error: response,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.LIST_ALL_SEGMENTS]: REQUEST_REJECTED,
  },
});

const segmentFailure = (state, { error: { response }, originalType }) => ({
  ...state,
  error: response,
  segmentRequests: {
    ...state.segmentRequests,
    [SegmentTypes.LIST_SEGMENT]: REQUEST_REJECTED,
    [SegmentTypes.LIST_SEGMENT_ACTIVE]: REQUEST_REJECTED,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [SegmentTypes.LIST_SEGMENT]: listSegment,
  [SegmentTypes.LIST_SEGMENT_SUCCESS]: listSegmentSuccess,
  [SegmentTypes.LIST_SEGMENT_ACTIVE]: listSegmentActive,
  [SegmentTypes.LIST_SEGMENT_ACTIVE_SUCCESS]: listSegmentActiveSuccess,
  [SegmentTypes.GET_SEGMENT]: getSegment,
  [SegmentTypes.GET_SEGMENT_SUCCESS]: getSegmentSuccess,
  [SegmentTypes.CREATE_SEGMENT]: createSegment,
  [SegmentTypes.CREATE_SEGMENT_SUCCESS]: createSegmentSuccess,
  [SegmentTypes.UPDATE_SEGMENT]: updateSegment,
  [SegmentTypes.UPDATE_SEGMENT_SUCCESS]: updateSegmentSuccess,
  [SegmentTypes.UPDATE_SEGMENT_STATUS]: updateSegmentStatus,
  [SegmentTypes.UPDATE_SEGMENT_STATUS_SUCCESS]: updateSegmentStatusSuccess,
  [SegmentTypes.SEGMENT_REQUEST_FAILURE]: segmentFailure,
  [SegmentTypes.LIST_ALL_SEGMENTS]: listAllSegments,
  [SegmentTypes.LIST_ALL_SEGMENTS_SUCCESS]: listAllSegmentsSuccess,
  [SegmentTypes.LIST_ALL_SEGMENTS_FAILURE]: listAllSegmentsFailure,
});

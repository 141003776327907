import React from 'react';
import { Loading as Spinner } from '@vli/locomotive-ds/dist';

import * as S from './LoadingStyles';

const Loading = () => (
  <S.LoadingContainer>
    <Spinner
      message="Por favor, espere alguns segundos..."
      title="Carregando"
      type="primary"
      fitParent
    />
  </S.LoadingContainer>
);

export default Loading;

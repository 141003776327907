import styled, { css } from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const ContentCell = styled.div`
  /* position: relative; */
  width: 100%;
  overflow: visible;
`;

export const OptionsContainer = styled.div`
  display: block;
`;
export const OptionItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 8px 4px;
  align-items: center;

  p {
    margin-left: 10px;
  }

  :hover {
    p {
      ${({ disabled }) =>
        disabled
          ? `color: ${Tokens.colors.gray300};`
          : `color: ${Tokens.colors.accentPrimaryDark};`}
    }
    svg {
      path {
        fill: ${Tokens.colors.accentPrimaryDark};
      }
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Tokens.colors.gray300};
      pointer-events: none;
    `}
`;

export const IconContent = styled.div`
  margin-right: 6px;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

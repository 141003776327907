import React from 'react';
import PropTypes from 'prop-types';
import { Tokens } from '@vli/locomotive-ds/dist';

import Headlight from 'components/core/headlight';
import Tooltip from 'components/core/Tooltip';

const StatusClient = ({ cell: { row: { original } } }) => (
  <Tooltip title={original.active ? 'Ativo' : 'Inativo'}>
    <Headlight
      data-testid="cell-table"
      color={original.active ? Tokens.colors.green300 : Tokens.colors.red300}
      style={{ cursor: 'pointer' }}
    />
  </Tooltip>
);

StatusClient.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        active: PropTypes.bool,
      }),
    }),
  }),
};

export default StatusClient;

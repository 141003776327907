import { useState, useEffect } from 'react';

export const useScroll = ({ element }) => {
  const getElement = element || document.body;
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    getElement.getBoundingClientRect(),
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = () => {
    setBodyOffset(getElement.getBoundingClientRect());
    setScrollY(getElement.scrollTop);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > getElement.scrollTop ? 'down' : 'up');
    setLastScrollTop(getElement.scrollTop);
  };

  useEffect(() => {
    const getElementDiv = element || window;
    getElementDiv.addEventListener('scroll', listener);
    return () => {
      getElementDiv.removeEventListener('scroll', listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
    lastScrollTop,
  };
};

import styled, { keyframes } from 'styled-components';
import { ReactComponent as IcSpinner } from 'assets/icons/spinner.svg';

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    margin-right: 12px;
  }
`;

export const FileButton = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #0074c0;
  margin-right: 20px;
`;

export const FileName = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #919191;
`;

const rotating = keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`;

export const Loading = styled(IcSpinner)`
  margin-left: 8px;
  animation-name: ${rotating};
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

import PropTypes from 'prop-types';

export const EditCellSchema = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  transformedData: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      editMode: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  color: PropTypes.string,
  align: PropTypes.string,
  options: PropTypes.array,
  defaultSelect: PropTypes.object,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
};

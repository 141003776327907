import { Tokens } from '@vli/locomotive-ds';
import styled from 'styled-components';

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;

  svg path {
    fill: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const TooltipTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  z-index: 2;

  padding: ${Tokens.spacing.sm};
  background-color: ${Tokens.colors.gray500};
  border-radius: 8px;
  color: ${Tokens.colors.grayWhite};
`;

import React from 'react';
import { Tokens, Input } from '@vli/locomotive-ds/dist';

import CellTable from 'components/core/Table/CellTable';

import { EditCellSchema } from 'lib/core/tableCellSchemas';

const CellEditInput = (props) => {
  const {
    cell: { value },
    row,
    transformedData,
    align,
    color,
    onChange,
    prefix,
  } = props;
  return row.original.editMode ? (
    <Input
      size="medium"
      data-testid={`cell-table-${value}`}
      style={{ cursor: 'pointer', textAlign: align }}
      color={color}
      onChange={onChange}
      value={value !== undefined && (transformedData(value) || '-')}
      prefix={prefix}
    />
  ) : (
    <CellTable {...props} />
  );
};

CellEditInput.propTypes = EditCellSchema;

CellEditInput.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
  onChange: () => {},
  prefix: '',
};

export default CellEditInput;

export const getMultiselectOptions = (initialOptions, selectedOptions) => {
  const options = initialOptions?.map((initialOption) => ({
    label: initialOption.name,
    value: {
      id: initialOption.id,
      others: '',
    },
    selected: !!selectedOptions.find(
      (selectedOption) => selectedOption === initialOption.id,
    ),
  }));

  return options;
};

export const validateDates = async ({ startDate, endDate }) => {
  let valid = { isValid: true, errors: null };

  if (!!startDate && !!endDate) return valid;

  if (startDate)
    valid = { isValid: false, errors: { endDate: 'Campo requerido' } };

  if (endDate)
    valid = { isValid: false, errors: { startDate: 'Campo requerido' } };

  return valid;
};

import React from 'react';
import PropTypes from 'prop-types';
import * as utility from 'lib/core/utility';
import * as S from './styles';

const Table = ({ data, columns }) => {
  return (
    <S.Table>
      <S.Thead>
        <S.Tr>
          {columns.map((column) => (
            <S.Th key={column}>{column}</S.Th>
          ))}
        </S.Tr>
      </S.Thead>
      <S.TBody>
        {data.map((item) => (
          <S.Tr key={item.name}>
            <S.Td>{item.name}</S.Td>
            {item?.contracted && (
              <S.Td>
                <p>{utility.formatMoney(item.contracted)}</p>
              </S.Td>
            )}
            <S.Td>
              <p>{utility.formatMoney(item.calculated)}</p>
            </S.Td>
          </S.Tr>
        ))}
      </S.TBody>
    </S.Table>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf({
    name: PropTypes.string,
    contracted: PropTypes.number,
    calculated: PropTypes.number,
  }),
  columns: PropTypes.array,
};

Table.defaultProps = {
  data: [],
  columns: [],
};

export default Table;

import React, { useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Flex, Container, Text } from '@vli/locomotive-ds/dist';

import ContractDetail from 'components/contexts/contract/ContractDetail/ContractDetail';
import CardDetail from 'components/contexts/contract/ContractDetail/Card/CardDetail';
import Content from 'components/core/content';
import Notification from 'components/core/Notification';
import Loading from 'components/core/Loading';

import { isPending, isFinished } from 'lib/core/request';
import * as utility from 'lib/core/utility';
import {
  getDateApplication,
  getContractStatus,
  getRailwayItemsLocal,
} from 'lib/contexts/contracts';

import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';
import { MessagesActions } from 'store/messages/messages.ducks';

import useDimensions from 'hooks/useDimensions';

import * as ROUTES from 'constants/urls';

const ContractDetails = () => {
  const dispatch = useDispatch();

  const { contractDetails, contractDetailRequests } = useSelector(
    ({ contractDetail }) => contractDetail,
  );

  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
  ];

  const { dieselWasReajusted } = contractDetails;

  const dimensions = useDimensions();
  const { id, status } = useParams();

  const query = new URLSearchParams(useLocation().search);

  const viewScenario = query.get('ViewScenario');

  const reload = useCallback(() => {
    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario: 'CurrentPrice',
      }),
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (
      dieselWasReajusted.length > 0 &&
      contractDetails.filter.viewScenario === 'NextPrice'
    ) {
      dispatch(
        MessagesActions.openModal({
          titleModal: 'Ops',
          typeModal: 'error',
          callback: reload,
          contentModalText: utility.formatMessageLocals(dieselWasReajusted),
        }),
      );
    }
    if (
      contractDetails.details.status === 1 &&
      contractDetails.filter.viewScenario === 'NextPrice' &&
      isFinished(contractDetailRequests.GET_CONTRACT_DETAIL)
    ) {
      const message = `Ainda não calculamos a data de aplicação do diesel para  ${getDateApplication(
        contractDetails.details.railwayItems,
      )}, pois o índice inflacionário não foi atualizado. Aguarde a atualização pela inflação`;
      dispatch(
        MessagesActions.openModal({
          titleModal: 'Ops',
          typeModal: 'error',
          contentModalText: message,
        }),
      );
    }
  }, [
    dieselWasReajusted,
    contractDetails,
    dispatch,
    reload,
    contractDetailRequests,
  ]);

  useEffect(() => {
    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario:
          status === 'ativo' ? 'CurrentPrice' : 'ClosedRailwayItems',
      }),
    );
  }, [dispatch, id, viewScenario, status]);

  useEffect(() => {
    const railwayLocals = getRailwayItemsLocal(
      contractDetails.details.railwayItems,
    );

    if (
      contractDetails.details.id === id &&
      railwayLocals.length > 0 &&
      contractDetails.filter.viewScenario === 'CurrentPrice'
    ) {
      dispatch(
        MessagesActions.openModal({
          titleModal: 'Ops',
          typeModal: 'error',
          contentModalText: utility.formatMessageLocals(railwayLocals),
        }),
      );
    }
  }, [contractDetails, dispatch, contractDetailRequests, id]);

  const isLoading =
    isPending(contractDetailRequests.GET_CONTRACT_DETAIL) ||
    isPending(contractDetailRequests.EXTEND_CONTRACT) ||
    isPending(contractDetailRequests.APPROVE_PRICE) ||
    isPending(contractDetailRequests.RENEGOTIATE_PRICE) ||
    isPending(contractDetailRequests.CORRECTION_PRICE) ||
    isPending(contractDetailRequests.SEND_IMMEDIATELY) ||
    isPending(contractDetailRequests.GET_PREVIOUS_MONTH) ||
    isPending(contractDetailRequests.SEND_MANUALLY_VALUE);

  return (
    <Content data-testid="content" height={dimensions.height}>
      {isLoading && <Loading />}
      {!isLoading && isFinished(contractDetailRequests.GET_CONTRACT_DETAIL) && (
        <Container>
          <Flex>
            <Header
              bread={breadcrumb}
              tag="header"
              title={`Contrato: ${contractDetails.details.code}`}
            />
          </Flex>
          {getContractStatus(contractDetails) && (
            <Flex>
              <Notification
                data-testid="toast-test"
                title="Contrato Encerrado"
                error
                isOpen
              >
                <Text data-testid="text-test">
                  Você está visualizando um contrato encerrado.
                </Text>
              </Notification>
            </Flex>
          )}
          <Flex maxWidth="500px">
            <CardDetail />
          </Flex>
          <ContractDetail />
        </Container>
      )}
    </Content>
  );
};

export default ContractDetails;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ContractReportTypes,
  Creators: ContractReportActions,
} = createActions({
  listContractReports: ['params'],
  listContractReportsSuccess: ['payload'],
  downloadContractReport: ['params'],
  downloadContractReportSuccess: [],
  createContractReport: ['payload'],
  createContractReportSuccess: ['payload'],
  deleteContractReport: ['payload', 'callback'],
  deleteContractReportSuccess: ['payload'],
  contractReportRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  contractReportsList: [],
  details: null,
  error: null,
  contractReportsRequests: {
    [ContractReportTypes.LIST_CONTRACT_REPORTS]: REQUEST_NOT_STARTED,
  },
};

const listContractReports = () => ({
  ...INITIAL_STATE,
  contractReportsRequests: {
    ...INITIAL_STATE.contractReportsRequests,
    [ContractReportTypes.LIST_CONTRACT_REPORTS]: REQUEST_PENDING,
  },
});

const listContractReportsSuccess = (state, { payload }) => ({
  ...state,
  contractReportsList: payload,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.LIST_CONTRACT_REPORTS]: REQUEST_RESOLVED,
  },
});

const downloadContractReport = (state) => ({
  ...state,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.DOWNLOAD_CONTRACT_REPORT]: REQUEST_PENDING,
  },
});

const downloadContractReportSuccess = (state) => ({
  ...state,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.DOWNLOAD_CONTRACT_REPORT]: REQUEST_RESOLVED,
  },
});

const createContractReport = (state) => ({
  ...state,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.CREATE_CONTRACT_REPORT]: REQUEST_PENDING,
  },
});

const createContractReportSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.CREATE_CONTRACT_REPORT]: REQUEST_RESOLVED,
  },
});

const deleteContractReport = (state) => ({
  ...state,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.DELETE_CONTRACT_REPORT]: REQUEST_PENDING,
  },
});

const deleteContractReportSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.DELETE_CONTRACT_REPORT]: REQUEST_RESOLVED,
  },
});

const contractReportRequestFailure = (
  state,
  { error: { response }, originalType },
) => ({
  ...state,
  error: response,
  contractReportsRequests: {
    ...state.contractReportsRequests,
    [ContractReportTypes.LIST_CONTRACT_REPORTS]: REQUEST_REJECTED,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ContractReportTypes.LIST_CONTRACT_REPORTS]: listContractReports,
  [ContractReportTypes.LIST_CONTRACT_REPORTS_SUCCESS]: listContractReportsSuccess,
  [ContractReportTypes.DOWNLOAD_CONTRACT_REPORT]: downloadContractReport,
  [ContractReportTypes.DOWNLOAD_CONTRACT_REPORT_SUCCESS]: downloadContractReportSuccess,
  [ContractReportTypes.CREATE_CONTRACT_REPORT]: createContractReport,
  [ContractReportTypes.CREATE_CONTRACT_REPORT_SUCCESS]: createContractReportSuccess,
  [ContractReportTypes.DELETE_CONTRACT_REPORT]: deleteContractReport,
  [ContractReportTypes.DELETE_CONTRACT_REPORT_SUCCESS]: deleteContractReportSuccess,
  [ContractReportTypes.CONTRACT_REPORT_REQUEST_FAILURE]: contractReportRequestFailure,
});

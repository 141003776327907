import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const NameGroup = styled.div`
  margin-top: ${Tokens.spacing.md};
`;

export const PermissionGroup = styled.div`
  margin-top: ${Tokens.spacing.sm};
`;

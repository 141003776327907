import React from 'react';
import PropTypes from 'prop-types';
import { Text, Tokens } from '@vli/locomotive-ds/dist';

const CellTable = ({
  cell: { value },
  informative,
  transformedData,
  row,
  onClick,
  align,
  color,
  opacity,
  pointer = true,
}) => {
  const { original } = row;
  const cursor = pointer ? 'pointer' : 'auto';

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };

  return (
    <Text
      size="medium"
      data-testid={`cell-table-${value}`}
      style={{ cursor, textAlign: align, fontWeight: '400' }}
      onClick={handleOnClick}
      color={color}
      opacity={opacity}
    >
      {value !== undefined && (transformedData(value) || '-')} {informative}
    </Text>
  );
};

CellTable.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  color: PropTypes.string,
  align: PropTypes.string,
  opacity: PropTypes.string,
  pointer: PropTypes.bool,
};

CellTable.defaultProps = {
  cell: {
    value: '',
  },
  color: Tokens.colors.grayBlack,
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
  align: 'start',
  opacity: '1',
  pointer: true,
};

export default CellTable;

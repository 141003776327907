import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ContractUploadTypes,
  Creators: ContractUploadActions,
} = createActions({
  importSpreadsheet: ['payload'],
  importSpreadsheetSuccess: [],
  importSpreadsheetFailure: ['error'],
  getAvailableContracts: [],
  getAvailableContractsSuccess: ['payload'],
  getAvailableContractsFailure: ['error'],
  createContract: ['payload'],
  createContractSuccess: [],
  createContractFailure: ['error'],
  getAvailableContractDetail: ['contractCode'],
  getAvailableContractDetailSuccess: ['payload'],
  getAvailableContractDetailFailure: ['error'],
});

const INITIAL_STATE = {
  error: null,
  availableContracts: [],
  selectedContract: null,
  contractUploadRequests: {
    [ContractUploadTypes.IMPORT_SPREADSHEET]: REQUEST_NOT_STARTED,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACTS]: REQUEST_NOT_STARTED,
    [ContractUploadTypes.CREATE_CONTRACT]: REQUEST_NOT_STARTED,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL]: REQUEST_NOT_STARTED,
  },
};

const importSpreadsheet = (state) => ({
  ...state,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.IMPORT_SPREADSHEET]: REQUEST_PENDING,
  },
});

const importSpreadsheetSuccess = (state) => ({
  ...state,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.IMPORT_SPREADSHEET]: REQUEST_RESOLVED,
  },
});

const importSpreadsheetFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.IMPORT_SPREADSHEET]: REQUEST_REJECTED,
  },
});

const getAvailableContracts = (state) => ({
  ...state,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACTS]: REQUEST_PENDING,
  },
});

const getAvailableContractsSuccess = (state, { payload }) => ({
  ...state,
  availableContracts: payload,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACTS]: REQUEST_RESOLVED,
  },
});

const getAvailableContractsFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACTS]: REQUEST_REJECTED,
  },
});

const createContract = (state) => ({
  ...state,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.CREATE_CONTRACT]: REQUEST_PENDING,
  },
});

const createContractSuccess = (state) => ({
  ...state,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.CREATE_CONTRACT]: REQUEST_RESOLVED,
  },
});

const createContractFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.CREATE_CONTRACT]: REQUEST_REJECTED,
  },
});

const getAvailableContractDetail = (state) => ({
  ...state,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL]: REQUEST_PENDING,
  },
});

const getAvailableContractDetailSuccess = (state, { payload }) => ({
  ...state,
  selectedContract: payload,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL]: REQUEST_RESOLVED,
  },
});

const getAvailableContractDetailFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractUploadRequests: {
    ...state.contractUploadRequests,
    [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ContractUploadTypes.IMPORT_SPREADSHEET]: importSpreadsheet,
  [ContractUploadTypes.IMPORT_SPREADSHEET_SUCCESS]: importSpreadsheetSuccess,
  [ContractUploadTypes.IMPORT_SPREADSHEET_FAILURE]: importSpreadsheetFailure,
  [ContractUploadTypes.GET_AVAILABLE_CONTRACTS]: getAvailableContracts,
  [ContractUploadTypes.GET_AVAILABLE_CONTRACTS_SUCCESS]: getAvailableContractsSuccess,
  [ContractUploadTypes.GET_AVAILABLE_CONTRACTS_FAILURE]: getAvailableContractsFailure,
  [ContractUploadTypes.CREATE_CONTRACT]: createContract,
  [ContractUploadTypes.CREATE_CONTRACT_SUCCESS]: createContractSuccess,
  [ContractUploadTypes.CREATE_CONTRACT_FAILURE]: createContractFailure,
  [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL]: getAvailableContractDetail,
  [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL_SUCCESS]: getAvailableContractDetailSuccess,
  [ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL_FAILURE]: getAvailableContractDetailFailure,
});

import React from 'react';
import PropTypes from 'prop-types';
import * as S from './HeadlightStyle';

const Headlight = ({ color, height, width, marginLeft }) => (
  <S.HeadLight
    data-testid="headlight-test"
    color={color}
    height={height}
    marginLeft={marginLeft}
    width={width}
  />
);

Headlight.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  marginLeft: PropTypes.string,
};

Headlight.defaultProps = {
  color: '#ff9900',
  height: '15px',
  width: '15px',
  marginLeft: '10px',
};

export default Headlight;

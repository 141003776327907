import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  CloseIcon,
  Button,
  Text,
  Tokens,
} from '@vli/locomotive-ds/dist';

import * as S from './DieselReportDownload.styles';

const DieselReportDownload = ({
  onConfirm,
  onClose,
  title,
  children,
  errorMessage,
}) => {
  return (
    <S.Container data-testid="report-modal">
      <S.Header>
        <Heading tag="h5">{title}</Heading>
        <S.Close style={{ transform: 'translateY(1.5px)' }} onClick={onClose}>
          <CloseIcon />
        </S.Close>
      </S.Header>
      <S.Content>{children}</S.Content>
      <div>
        <Text color={Tokens.colors.red400}>{errorMessage}</Text>
      </div>
      <S.Footer>
        <Button
          data-testid="test-cancel"
          name="cancelar"
          variant="secondary"
          action={onClose}
        />
        <Button
          data-testid="test-apply"
          name="aplicar"
          variant="primary"
          action={onConfirm}
        />
      </S.Footer>
    </S.Container>
  );
};

DieselReportDownload.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  errorMessage: PropTypes.string,
};

DieselReportDownload.defaultProps = {
  title: '',
  children: '',
  onClose: () => {},
  onConfirm: () => {},
  errorMessage: '',
};
export default DieselReportDownload;

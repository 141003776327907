import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';

import {
  SPREADSHEET_IMPORT,
  GET_AVAILABLE_CONTRACTS,
  CREATE_CONTRACT,
  GET_AVAILABLE_CONTRACTS_DETAIL,
} from 'constants/endpoints';
import { POST, GET } from 'constants/verbs';

import * as utility from 'lib/core/utility';

import { MessagesActions } from 'store/messages/messages.ducks';

import {
  ContractUploadActions,
  ContractUploadTypes,
} from './contractUpload.ducks';

export function* importSpreadsheet({ payload }) {
  try {
    const response = yield api({
      method: POST,
      url: SPREADSHEET_IMPORT,
      data: payload,
    });

    yield put(ContractUploadActions.importSpreadsheetSuccess());

    yield put(
      MessagesActions.openModal({
        titleModal: response.data.data.message,
        typeModal: 'success',
        contentModalText: utility.formatMessages([
          'O ajuste dos preços do itens ferroviários impactados serão calculados em breve.',
        ]),
      }),
    );
  } catch (error) {
    let message = '';

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    if (error?.response?.status === 422) {
      yield put(
        MessagesActions.openModal({
          titleModal: 'Ops',
          typeModal: 'error',
          contentModalText: utility.formatMessages(error.response.data.errors),
        }),
      );
    } else {
      yield put(
        MessagesActions.openModal({
          titleModal: 'Erro ao executar upload',
          typeModal: 'error',
          contentModalText: message,
        }),
      );
    }

    yield put(ContractUploadActions.importSpreadsheetFailure(error));
  }
}

export function* getAvailableContracts() {
  try {
    const response = yield api({
      method: GET,
      url: GET_AVAILABLE_CONTRACTS,
    });

    yield put(
      ContractUploadActions.getAvailableContractsSuccess(response.data.data),
    );
  } catch (error) {
    yield put(ContractUploadActions.getAvailableContractsFailure(error));
  }
}

export function* createContract({ payload }) {
  try {
    yield api({
      method: POST,
      url: CREATE_CONTRACT,
      data: payload,
    });

    yield put(ContractUploadActions.createContractSuccess());

    yield put(
      MessagesActions.open({
        title: 'Contrato criado com sucesso',
        type: 'success',
        contentText: '',
      }),
    );
  } catch (error) {
    let message = '';

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.open({
        title: 'Erro ao criar contrato',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(ContractUploadActions.createContractFailure(error));
  }
}

export function* getAvailableContractDetail({ contractCode }) {
  try {
    const response = yield api({
      method: GET,
      url: `${GET_AVAILABLE_CONTRACTS_DETAIL}?contractCode=${contractCode}`,
    });

    yield put(
      ContractUploadActions.getAvailableContractDetailSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(ContractUploadActions.getAvailableContractDetailFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(ContractUploadTypes.IMPORT_SPREADSHEET, importSpreadsheet);
  yield takeLatest(
    ContractUploadTypes.GET_AVAILABLE_CONTRACTS,
    getAvailableContracts,
  );
  yield takeLatest(ContractUploadTypes.CREATE_CONTRACT, createContract);
  yield takeLatest(
    ContractUploadTypes.GET_AVAILABLE_CONTRACT_DETAIL,
    getAvailableContractDetail,
  );
}

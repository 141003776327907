/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ title, children, place, offset, multiline, id }) => (
  <div data-testid="tooltip-test">
    <span data-tip={title} data-for={id}>
      {children}
    </span>
    <ReactTooltip
      place={place}
      multiline={multiline}
      offset={offset}
      id={id}
      html
    />
  </div>
);

Tooltip.propTypes = {
  /**
   * você pode ter muitos <ReactTooltip /> em uma página, mas eles devem ter ids diferentes
   */
  id: PropTypes.any,
  title: PropTypes.string,
  /**
   * Posição do tooltip sobre o hover
   */
  place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  /**
   * Offset do tool tip
   */
  offset: PropTypes.oneOf({ top: 10, left: 10 }),
  /**
   * Aceita <br>, <br /> para multiplas linhas
   */
  multiline: PropTypes.bool,
};

Tooltip.defaultProps = {
  id: 'tool',
  title: '',
  place: 'top',
  offset: {},
  multiline: false,
};

export default Tooltip;

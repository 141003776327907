import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const Sections = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.md};

  margin-bottom: ${Tokens.spacing.md};
`;

export const CardHeader = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${Tokens.spacing.md};

  position: relative;

  & > h2 {
    font: ${Tokens.fonts.textBoldSmall};
    color: ${Tokens.colors.gray300};
  }

  button {
    margin: 0;
  }

  & > ul {
    top: 32px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { endOfMonth, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import * as utility from 'lib/core/utility';

const ReportChart = ({ data, handleFilter }) => {
  const dateFormatter = (date) => {
    return format(new Date(date), "MMMM 'de' yyyy", {
      locale: ptBR,
    });
  };

  const getChartData = (reportData) => {
    return reportData.map((reportItem) => {
      return {
        name: utility.capitalizeFirstString(dateFormatter(reportItem.period)),
        Incluídos: reportItem.added,
        Encerrados: reportItem.closed,
        ...reportItem,
      };
    });
  };

  const handleLineClick = ({ payload }) => {
    const filterData = {
      StartDate: new Date(payload.period),
      EndDate: endOfMonth(new Date(payload.period)),
    };

    handleFilter(filterData);
  };

  return (
    <div data-testid="report-chart-test">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={getChartData(data)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            dataKey="Incluídos"
            stroke="#38A740"
            strokeWidth={2}
            activeDot={{ onClick: handleLineClick }}
          />
          <Line
            dataKey="Encerrados"
            stroke="#E24445"
            strokeWidth={2}
            activeDot={{ onClick: handleLineClick }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

ReportChart.propTypes = {
  data: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default ReportChart;

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Tooltip } from '@vli/locomotive-ds';

import { CommentActions } from 'store/comments/comments.ducks';
import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import { GET } from 'lib/core/localStorage';
import { FormalizationSteps } from 'lib/contexts/formalizationRequest';

import CommentItem from 'components/core/Comments/CommentItem';
import CommentModal from 'components/core/Comments/CommentModal';

import * as S from './FormalizationCommentsStyle';

const FormalizationComments = ({
  formalizationId,
  formalizationStep,
  hasAttachment,
}) => {
  const dispatch = useDispatch();
  const { comments: requestedComments } = useSelector(
    ({ comments }) => comments,
  );

  const { name } = GET('name');

  const handleOpenCommentModal = () => {
    dispatch(CommentActions.setIsModalOpen({ isOpen: true }));
  };

  useEffect(() => {
    dispatch(CommentActions.commentByFormalizationId(formalizationId));
  }, [formalizationId, dispatch]);

  const lastComment = useMemo(
    () => requestedComments[requestedComments.length - 1],
    [requestedComments],
  );

  const restCommentsLength =
    requestedComments.length > 1 ? requestedComments.length - 1 : 0;

  const isWriteButtonDisabled =
    formalizationStep === FormalizationSteps.LegalRequested;

  const handleDownloadAttachment = () => {
    if (formalizationId) {
      dispatch(FormalizationRequestActions.downloadAttachment(formalizationId));
    }
  };

  return (
    <S.WrapperCard>
      <S.Header>
        <h3 data-testid="formalization-comment">
          Comentários <span>(Opcional)</span>
        </h3>

        {hasAttachment && (
          <button type="button" onClick={handleDownloadAttachment}>
            Download do anexo
          </button>
        )}
      </S.Header>

      <div>
        {lastComment && (
          <div>
            <CommentItem data={lastComment} onlineUser={name} isCollapsible />
          </div>
        )}

        {requestedComments.length > 1 && (
          <S.Footer>
            <S.Tag data-testid="formalization-comment-tag">{`+${restCommentsLength}`}</S.Tag>

            <Button
              variant="tertiary"
              name="Ver comentários"
              action={handleOpenCommentModal}
              data-testid="formalization-comment-view-button"
            />
          </S.Footer>
        )}

        {isWriteButtonDisabled && requestedComments.length < 1 && (
          <Tooltip
            place="top"
            title="Você precisa assumir o atendimento para realizar alguma ação"
          >
            <Button
              variant="secondary"
              name="escrever comentário"
              action={handleOpenCommentModal}
              data-testid="formalization-comment-write-button-disabled"
              isDisabled
            />
          </Tooltip>
        )}

        {!isWriteButtonDisabled && requestedComments.length <= 1 && (
          <Button
            variant="secondary"
            name="escrever comentário"
            action={handleOpenCommentModal}
            data-testid="formalization-comment-write-button"
          />
        )}
      </div>

      <CommentModal formalizationId={formalizationId} />
    </S.WrapperCard>
  );
};

FormalizationComments.propTypes = {
  formalizationId: PropTypes.string.isRequired,
  formalizationStep: PropTypes.number.isRequired,
  hasAttachment: PropTypes.bool.isRequired,
};

export default FormalizationComments;

import React, { useState } from 'react';

import * as S from './styles';

const TooltipTable = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleShowTooltip = () => {
    setIsVisible(true);
  };

  const handleHideTooltip = () => {
    setIsVisible(false);
  };

  return (
    <S.TooltipContainer>
      <S.TooltipTrigger
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
      >
        {children}
      </S.TooltipTrigger>
      {isVisible && <S.TooltipBox>{content}</S.TooltipBox>}
    </S.TooltipContainer>
  );
};

export default TooltipTable;

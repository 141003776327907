import { put, takeLatest } from 'redux-saga/effects';

import {
  NEW_ITEM,
  NEW_ITEM_ORIGIN_DESTINY,
  NEW_ITEM_TARIFF,
  NEW_ITEM_GRAINS,
} from 'constants/endpoints';
import { GET, POST, PUT } from 'constants/verbs';
import messages from 'constants/messages';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import * as utility from 'lib/core/utility';

import api from 'services/api';

import { MessagesActions } from 'store/messages/messages.ducks';

import { NewItemsTypes, NewItemsActions } from './newItems.ducks';

export function* newItems({ payload }) {
  try {
    const params = payload || '';

    const response = yield api({
      method: GET,
      url: `${NEW_ITEM}/${params}`,
    });

    yield put(NewItemsActions.newItemsListSuccess(response.data, payload));
  } catch (error) {
    yield put(NewItemsActions.newItemsListFailure(error));
  }
}

export function* getFilteredItems() {
  try {
    const response = yield api({
      method: GET,
      url: `${NEW_ITEM}/items`,
    });
    yield put(NewItemsActions.getItemsForFilterSuccess(response.data));
  } catch (error) {
    yield put(NewItemsActions.getItemsForFilterFailure(error));
  }
}

export function* createItem({ payload }) {
  try {
    yield api({
      method: POST,
      url: NEW_ITEM,
      data: payload,
    });
    yield put(NewItemsActions.createItemSuccess());

    yield put(
      MessagesActions.open({
        title: messages.messages.newItems[POST].title,
        type: 'success',
        contentText: messages.messages.newItems[POST].message,
      }),
    );
    yield history.push(ROUTES.ITEMS);
  } catch (error) {
    let message = messages.messages.newItems[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.newItems[POST].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(NewItemsActions.createItemFailure(message));
  }
}

export function* linkNewContract({ payload, callback }) {
  try {
    const data = {
      id: payload.id,
      newContractCode: payload.newContractCode,
    };

    yield api({
      method: PUT,
      url: `${NEW_ITEM}/link-new-contract`,
      data,
    });

    yield put(NewItemsActions.linkNewContractSuccess());

    yield put(
      MessagesActions.open({
        title: 'Editado com sucesso',
        type: 'success',
        contentText: `O item ${payload.codeItem} foi vinculado com sucesso!`,
      }),
    );

    yield callback();
  } catch (error) {
    let message = 'Erro ao vincular novo contrato!';

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.open({
        title: 'Erro',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(NewItemsActions.linkNewContractFailure(error));
  }
}

export function* getNewItem({ newItemId }) {
  try {
    const response = yield api({
      method: GET,
      url: `${NEW_ITEM}/${newItemId}`,
    });

    yield put(NewItemsActions.getNewItemSuccess(response.data));
  } catch (error) {
    yield put(NewItemsActions.getNewItemFailure(error));
  }
}

export function* updateItem({ payload }) {
  try {
    yield api({
      method: PUT,
      url: NEW_ITEM,
      data: payload,
    });

    yield put(NewItemsActions.updateItemSuccess());

    yield put(
      MessagesActions.open({
        title: messages.messages.newItems[PUT].title,
        type: 'success',
        contentText: messages.messages.newItems[PUT].message,
      }),
    );

    yield history.push(ROUTES.ITEMS);
  } catch (error) {
    let message = messages.messages.newItems[PUT].errorMessage;

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.newItems[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(NewItemsActions.updateItemFailure(message));
  }
}

export function* listOriginDestiny({ contractId }) {
  try {
    const response = yield api({
      method: GET,
      url: `${NEW_ITEM_ORIGIN_DESTINY}/${contractId}`,
    });

    yield put(NewItemsActions.listOriginDestinySuccess(response.data));
  } catch (error) {
    yield put(NewItemsActions.listOriginDestinyFailure(error));
  }
}

export function* getTariff({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: NEW_ITEM_TARIFF,
      params: payload,
    });

    yield put(NewItemsActions.getTariffSuccess(response.data));
  } catch (error) {
    yield put(NewItemsActions.getTariffFailure(error));
  }
}

export function* createGrainItem({ payload }) {
  try {
    yield api({
      method: POST,
      url: NEW_ITEM_GRAINS,
      data: payload,
    });

    yield put(NewItemsActions.createGrainItemSuccess());

    yield put(
      MessagesActions.open({
        title: messages.messages.newItems[POST].title,
        type: 'success',
        contentText: messages.messages.newItems[POST].message,
      }),
    );

    yield history.push(ROUTES.ITEMS);
  } catch (error) {
    let message = messages.messages.newItems[POST].errorMessage;

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.newItems[POST].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(NewItemsActions.createGrainItemFailure(message));
  }
}

export function* watchSagas() {
  yield takeLatest(NewItemsTypes.NEW_ITEMS_LIST_REQUEST, newItems);
  yield takeLatest(
    NewItemsTypes.GET_ITEMS_FOR_FILTER_REQUEST,
    getFilteredItems,
  );
  yield takeLatest(NewItemsTypes.CREATE_ITEM_REQUEST, createItem);
  yield takeLatest(NewItemsTypes.LINK_NEW_CONTRACT_REQUEST, linkNewContract);
  yield takeLatest(NewItemsTypes.GET_NEW_ITEM_REQUEST, getNewItem);
  yield takeLatest(NewItemsTypes.UPDATE_ITEM_REQUEST, updateItem);
  yield takeLatest(
    NewItemsTypes.LIST_ORIGIN_DESTINY_REQUEST,
    listOriginDestiny,
  );
  yield takeLatest(NewItemsTypes.GET_TARIFF_REQUEST, getTariff);
  yield takeLatest(NewItemsTypes.CREATE_GRAIN_ITEM_REQUEST, createGrainItem);
}

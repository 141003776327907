/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { Tokens } from '@vli/locomotive-ds';
import CONSTANTS from 'constants/constants';
import { messages } from 'constants/messages';
import { ACTIONS, canExecuteAction, ROLES } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';
import React from 'react';

export const capitalizeString = (str) => {
  if (!str) return str;
  const format = str
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return format;
};
export const capitalizeFirstString = (str) => {
  if (!str) return str;
  const format = str
    .toLowerCase()
    .trim()
    .replace(/^\w/, (c) => c.toUpperCase());
  return format;
};
export const joinComma = (str) => {
  if (!str) return str;
  const format = str.join(', ');
  return format;
};

export const formatBooleanToText = (boolean) => {
  return boolean ? 'Sim' : 'Não';
};

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});
export const statusFilter = {
  0: '#C4C4C4',
  1: '#13AA3D',
  2: '#DE2B2B',
};
export const simpleId = () => `_${Math.random().toString(36).substr(2, 9)}`;

export const valstr = (string, type) =>
  !string ? '-' : type === 'percent' ? `${string}%` : string;

export const formatDate = (date, format = 'dd/mm/yyyy') => {
  const dateObject = new Date(date);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();

  return !date
    ? '-'
    : format.replace('dd', day).replace('mm', month).replace('yyyy', year);
};

export const autoListingParamsGet = (opt) => {
  const filterFields = [];
  if (opt && opt.filterFields) {
    Object.keys(opt.filterFields).forEach((field) => {
      const localValue = opt.filterFields[field];
      if (
        opt.filterFields[field] !== null &&
        opt.filterFields[field] !== undefined
      )
        if (Array.isArray(localValue))
          localValue.map((value) =>
            filterFields.push(`${field}=${encodeURIComponent(value)}`),
          );
        else if (localValue)
          filterFields.push(`${field}=${encodeURIComponent(localValue)}`);
    });
  }

  if (opt && opt.params) {
    Object.keys(opt.params).forEach((fields) => {
      if (opt.params[fields] !== null && opt.params[fields] !== undefined)
        Object.keys(opt.params[fields]).forEach((field) => {
          if (opt.params[fields][field] !== null)
            filterFields.push(`${field}=${opt.params[fields][field]}`);
        });
    });
  }
  return filterFields.join('&');
};

export const getProp = (object, keys, defaultVal) => {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
};

export const formatMoney = (value) =>
  value || value === 0
    ? `R$ ${value
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`
    : '-';
export const formatMoneyGraph = (value) =>
  value ? `R$ ${value.toString().split('.')[0]}` : '-';
export const formatArray = (value) =>
  value.length > 0 ? capitalizeFirstString(value.join(', ')) : value;
export const randomColor = () =>
  `#${(0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)}`;

export const findObject = (objects, attribute, value) => {
  const found = objects.find((x) => x[attribute] === value);
  return found;
};
export const findIndexObject = (objects, attribute, value) => {
  if (objects) {
    const index = objects.findIndex((x) => x[attribute] === value);
    return index;
  }
  return -1;
};
export const existObject = (objects, attribute, value) => {
  if (!objects) {
    return false;
  }
  const found = objects.find((x) => x[attribute] === value);
  return !!found;
};
export const deleteObject = (objects, attribute, value) => {
  objects = objects.filter((x) => x[attribute] !== value);
  return objects;
};

export const hasDuplicates = (array, attribute) => {
  const uniqueValues = new Set(array?.map((v) => v[attribute]));

  if (uniqueValues.size < array?.length) {
    return true;
  }
  return false;
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export const getLocalsDiesel = (railwayItems) => {
  const items = railwayItems.filter((item) => !item.dieselWasReajusted);
  const locals = items
    .map((item) => item.local)
    .filter((v, i, a) => a.indexOf(v) === i);
  return locals;
};
export const formatMessages = (messagesFormat) => {
  return (
    <ul style={{ marginLeft: '20px' }}>
      {messagesFormat?.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  );
};
export const formatMessageLocals = (dieselWasReajusted) => {
  const messagesFormat = [
    `${messages.diesels.hasDieselWasReajusted[0]}`,
    ...dieselWasReajusted,
  ];
  return (
    <>
      {messagesFormat.map((message) => (
        <p>{message}</p>
      ))}
    </>
  );
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteAllCookie() {
  const cookies = document.cookie.split(';');
  const expire = new Date();
  for (let i = 0; i < cookies.length; i++) {
    // const cookie = cookies[i].split('=')[0];
    expire.setDate(expire.getDate() - 1);
    document.cookie = `asd=; expires=${expire}`;
  }
}

export function getReportType(type) {
  switch (type) {
    case 'inflation':
      return 'Database';
    default:
      return 'Diesel';
  }
}

export function getHeadlight() {
  const enumHeadlight = {
    '#949494': 'Dados pendentes',
    '#E25051': 'Valores divergentes',
    '#38A740': 'Valores em linha',
  };
  return enumHeadlight;
}

export function downloadFile(data, filename) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export function getPositionTooltip(rows, position) {
  if (position < rows.length - 3) {
    return 'bottom';
  }
  return 'top';
}
export function getPositionDrop(rows, position) {
  if (position === rows.length - 1) {
    return 'up';
  }
  return 'down';
}

export const transformInfoLength = (
  totalTo,
  pluEntity,
  singEntity,
  totalFrom,
  pluFromEntity,
  singFromEntity,
) => (
  <>
    de <b>{totalTo}</b> {totalTo > 1 ? pluEntity : singEntity}
    {totalFrom > 0 && (
      <>
        <span style={{ marginLeft: '3px' }}>
          de <b>{totalFrom}</b>
        </span>
        <span style={{ marginLeft: '3px' }}>
          {totalFrom > 1 ? pluFromEntity : singFromEntity}
        </span>
      </>
    )}
  </>
);

export const transformInfoLengthContracts = (
  pluEntity,
  singEntity,
  totalFrom,
  totalClients,
  pluFromEntity,
  singFromEntity,
) => (
  <>
    <b>{totalFrom}</b> {totalFrom > 1 ? pluEntity : singEntity} de{' '}
    <b>{totalClients}</b> {totalClients > 1 ? pluFromEntity : singFromEntity}
  </>
);

export const formatParamsActionAll = (fields, railsItens) => {
  const getIds = actionsAttributes(railsItens).map(
    (railsItem) => railsItem.history.id,
  );
  const data = {
    idHistorys: getIds,
    justification: fields.justification,
  };
  return data;
};

export const formatParamsAction = (railsItens, justification) => {
  let getId = [];

  if (railsItens.length > 1) {
    getId = actionsAttributesAll(railsItens).map(
      (railsItem) => railsItem.history.id,
    );
  } else {
    getId = actionsAttributes(railsItens).map(
      (railsItem) => railsItem.history.id,
    );
  }

  const data = {
    idHistorys: getId,
    justification,
  };

  return data;
};

export const formatParamsRenegociateAction = (railsItens, justification) => {
  const data = {
    historys: railsItens,
    justification,
  };
  return data;
};

export const actionsAttributesAll = (railsItens) =>
  railsItens.filter(
    (railItem) =>
      railItem.history.status === enumStatus.WaitingForApproval ||
      railItem.history.status === enumStatus.RequestExecuted ||
      railItem.history.status === enumStatus.Approved,
  );

export const actionsAttributes = (railsItens) =>
  railsItens.filter(
    (railItem) =>
      railItem.history.status === enumStatus.WaitingForApproval ||
      railItem.history.status === enumStatus.RequestExecuted ||
      railItem.history.status === enumStatus.Approved,
  );

export const enumStatus = {
  Undefined: 0,
  WaitingForApproval: 1,
  Approved: 2,
  Disapproved: 3,
  RequestExecuted: 7,
};

export const IndiceType = {
  DIESEL: '1',
  INFLATION: '2',
};

export const truncateString = (str, num) => {
  let format = '';
  if (!str) {
    return str;
  }
  if (Array.isArray(str)) {
    format = str.join(' ');
  } else {
    format = str;
  }
  if (format?.length <= num) {
    return format;
  }
  return `${format.slice(0, num)}...`;
};

// Has return true to active and return false to desactive
export const validateAction = (rows, segment, details) => {
  const getApproves = [];
  rows.forEach((row) => {
    getApproves.push(validateEdit(row, segment, details));
  });
  const returnValue = getApproves.filter((i) => i === true);

  return returnValue.length > 1;
};

// Retorna true para habilitar e false para desabilitar

export const validateImmediateSend = (item) => {
  const { roles } = GET('roles');

  const isRequestExecutedStatus =
    item.history?.status === enumStatus.RequestExecuted;

  if (
    canExecuteAction(ROLES.CONTRACT, ACTIONS.IMMEDIATE_SEND, roles) &&
    isRequestExecutedStatus
  ) {
    return true;
  }

  return false;
};

export const validateEdit = (
  item,
  segmentId,
  details,
  isMassAction = true,
  isZeroTariff = false,
) => {
  const { segments } = GET('segments');
  const { userId } = GET('userId');
  const { roles } = GET('roles');

  const now = new Date();
  const endPeriod = new Date(details.endPeriod);

  if (endPeriod < now) {
    return false;
  }

  if (isZeroTariff && item.history?.status === enumStatus.WaitingForApproval) {
    return true;
  }

  if (
    item.history?.canFixPrice &&
    item.history?.status === enumStatus.Approved
  ) {
    return true;
  }

  if (validateStatus(isMassAction, item)) {
    return false;
  }

  const hasSegment = segments?.filter((s) => s.id === segmentId).length > 0;
  if (hasSegment) {
    if (
      canExecuteAction(
        ROLES.CONTRACT,
        ACTIONS.APPROVE_INAPPROVE_PRICE_SEGMENT,
        roles,
      )
    ) {
      return true;
    }

    if (
      userId === details.accountManagerUserId &&
      canExecuteAction(
        ROLES.CONTRACT,
        ACTIONS.APPROVE_INAPPROVE_PRICE_SELF,
        roles,
      )
    ) {
      return true;
    }
    return false;
  }
  return false;
};

const validateStatus = (isMassAction, item) => {
  if (isMassAction)
    return (
      item.history?.status !== enumStatus.WaitingForApproval ||
      item.status !== 'Ativo'
    );

  return (
    (item.history?.status !== enumStatus.WaitingForApproval &&
      item.history?.status !== enumStatus.RequestExecuted) ||
    item.status !== 'Ativo'
  );
};

export const isAdminIndices = () => {
  const { roles } = GET('roles');
  if (canExecuteAction(ROLES.INFLACIONATY_INDICES, ACTIONS.CREATE, roles)) {
    return true;
  }
  return false;
};

export const getEnvironment = () => {
  if (CONSTANTS.API.includes('dev')) {
    return {
      environment: 'dev',
      color: Tokens.colors.gray500,
      title: 'Desenvolvimento',
    };
  }
  if (CONSTANTS.API.includes('hml')) {
    return {
      environment: 'hom',
      color: Tokens.colors.accentPrimaryDark,
      title: 'Homologação',
    };
  }
  return {
    environment: 'prd',
    color: Tokens.colors.grayWhite,
    title: '',
  };
};

export const getNumberTruncate = (number) => {
  if (window.innerWidth > Tokens.media.breakpoints.lg) {
    return number * 3;
  }
  return number;
};

export const getMonth = {
  1: 'JAN',
  2: 'FEV',
  3: 'MAR',
  4: 'ABR',
  5: 'MAI',
  6: 'JUN',
  7: 'JUL',
  8: 'AGO',
  9: 'SET',
  10: 'OUT',
  11: 'NOV',
  12: 'DEZ',
};

export const getStatusApplication = {
  Healthy: true,
  Unhealthy: false,
};

export const getStatusName = {
  Healthy: 'Disponível',
  Unhealthy: 'Indisponível',
};

export const optionsTrigger = [
  {
    label: 'Sim',
    value: 1,
  },
  {
    label: 'Não',
    value: 0,
  },
];

export const optionsMonths = [
  {
    label: 'Janeiro',
    value: 1,
  },
  {
    label: 'Fevereiro',
    value: 2,
  },
  {
    label: 'Março',
    value: 3,
  },
  {
    label: 'Abril',
    value: 4,
  },
  {
    label: 'Maio',
    value: 5,
  },
  {
    label: 'Junho',
    value: 6,
  },
  {
    label: 'Julho',
    value: 7,
  },
  {
    label: 'Agosto',
    value: 8,
  },
  {
    label: 'Setembro',
    value: 9,
  },
  {
    label: 'Outubro',
    value: 10,
  },
  {
    label: 'Novembro',
    value: 11,
  },
  {
    label: 'Dezembro',
    value: 12,
  },
];

export const optionsContractedFare = [
  {
    label: 'Em curva',
    value: 1,
  },
  {
    label: 'Fixa',
    value: 0,
  },
];

export const getOptions = (list) => {
  const options = list.map((option) => ({
    label: option.name,
    value: option.id,
  }));
  return options;
};

export const addZero = (i) => {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
};

export const formatHour = (date) => {
  if (date) {
    const dateObject = new Date(date);
    const hour = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const fullHour = `${hour}:${addZero(minutes)}h`;
    return fullHour;
  }
  return date;
};

export const railwayItemStatus = {
  ativo: 0,
  inativo: 1,
  encerrado: 2,
};

import React from 'react';
import { Button, Col, Input, Row } from '@vli/locomotive-ds/dist';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Modal from 'components/core/modal';
import { RailwayItemsHistoryActions } from 'store/railwayItemsHistory/railwayItemsHistory.ducks';
import { RailwayItemActions } from 'store/railwayItem/railwayItem.ducks';
import { railwayItemStatus } from 'lib/core/utility';
import { SpacingTop } from 'components/core/Spacing';

const RailwayItemEditModal = () => {
  const { editRailwayItem } = useSelector(({ railwayItem }) => railwayItem);

  const { railwayItems } = useSelector(
    ({ railwayItemsHistory }) => railwayItemsHistory,
  );

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    origin: Yup.string()
      .max(128, 'A justificativa deve ter maxima de 128 caracteres')
      .required('A justificativa é um campo requerido'),
    originCode: Yup.string()
      .max(20, 'O código estação origem deve ter máximo de 20 caracteres')
      .required('O código estação origem é um campo requerido'),
    destination: Yup.string()
      .max(128, 'A justificativa deve ter maxima de 128 caracteres')
      .required('A justificativa é um campo requerido'),
    destinationCode: Yup.string()
      .max(20, 'O código estação destino deve ter máximo de 20 caracteres')
      .required('O código estação destino é um campo requerido'),
    productUnicomMerchandiseTacito: Yup.string()
      .max(500, 'A justificativa deve ter maxima de 500 caracteres')
      .required('A justificativa é um campo requerido'),
  });

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      origin: editRailwayItem.origin,
      originCode: editRailwayItem.originCode,
      destination: editRailwayItem.destination,
      destinationCode: editRailwayItem.destinationCode,
      productUnicomMerchandiseTacito:
        editRailwayItem.productUnicomMerchandiseTacito,
    },
  });

  const closeModal = () => {
    dispatch(
      RailwayItemActions.setEditModalOpen({
        modalOpen: false,
      }),
    );
  };

  const callback = () => {
    const params = {
      code: railwayItems.code,
      status: railwayItemStatus[railwayItems.status.toLowerCase()],
    };

    dispatch(
      RailwayItemsHistoryActions.listRailwayItemsHistory({
        ...params,
      }),
    );
  };

  const submit = (data) => {
    const {
      origin,
      originCode,
      destination,
      destinationCode,
      productUnicomMerchandiseTacito,
    } = data;

    const payload = {
      id: editRailwayItem.id,
      origin,
      originCode,
      destination,
      destinationCode,
      productUnicomMerchandiseTacito,
    };

    dispatch(RailwayItemActions.update(payload, callback));

    closeModal();
  };

  const actions = () => (
    <>
      <Button variant="secondary" name="CANCELAR" onClick={closeModal} />

      <Button variant="primary" name="SALVAR" onClick={handleSubmit(submit)} />
    </>
  );

  return (
    <Modal
      className="modal"
      title="Editar informações de item ferroviário"
      onClose={closeModal}
      actions={actions}
    >
      <form>
        <Row>
          <Col>
            <Controller
              name="origin"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <Input
                  label="Origem"
                  required
                  value={value}
                  marginTop={10}
                  width={290}
                  heigth={126}
                  onChange={onChange}
                  statusMessage={errors.origin?.message}
                  status={errors.origin && 'error'}
                />
              )}
            />
          </Col>

          <Col>
            <Controller
              name="originCode"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <Input
                  label="Código estação origem"
                  required
                  value={value}
                  marginTop={10}
                  width={140}
                  heigth={126}
                  onChange={onChange}
                  statusMessage={errors.originCode?.message}
                  status={errors.originCode && 'error'}
                  data-testid="origin-code-test"
                />
              )}
            />
          </Col>
        </Row>

        <SpacingTop sm />

        <Row>
          <SpacingTop xs />
          <Col>
            <Controller
              name="destination"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <Input
                  label="Destino"
                  required
                  value={value}
                  marginTop={10}
                  width={290}
                  heigth={126}
                  onChange={onChange}
                  statusMessage={errors.destination?.message}
                  status={errors.destination && 'error'}
                />
              )}
            />
          </Col>

          <Col>
            <Controller
              name="destinationCode"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <Input
                  label="Código estação destino"
                  required
                  value={value}
                  marginTop={10}
                  width={140}
                  heigth={126}
                  onChange={onChange}
                  statusMessage={errors.destinationCode?.message}
                  status={errors.destinationCode && 'error'}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <SpacingTop xs />
          <Col>
            <Controller
              name="productUnicomMerchandiseTacito"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <Input
                  label="Produto"
                  required
                  value={value}
                  marginTop={10}
                  width={440}
                  heigth={126}
                  onChange={onChange}
                  statusMessage={errors.productUnicomMerchandiseTacito?.message}
                  status={errors.productUnicomMerchandiseTacito && 'error'}
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

RailwayItemEditModal.defaultProps = {};

RailwayItemEditModal.propTypes = {};

export default RailwayItemEditModal;

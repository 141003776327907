import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: RequestsListTypes,
  Creators: RequestsListActions,
} = createActions({
  requestsListRequest: ['params', 'rawFilter'],
  requestsListSuccess: ['payload'],
  requestsListFailure: ['error'],
});

const INITIAL_STATE = {
  requestsListItems: [],
  filter: { filterData: {} },
  error: null,
  requestsListRequests: {
    [RequestsListTypes.REQUESTS_LIST_REQUEST]: REQUEST_NOT_STARTED,
  },
};

const requestsListRequest = (_, { rawFilter }) => ({
  ...INITIAL_STATE,
  filter: { ...rawFilter },
  requestsListRequests: {
    ...INITIAL_STATE.requestsListRequests,
    [RequestsListTypes.REQUESTS_LIST_REQUEST]: REQUEST_PENDING,
  },
});

const requestsListSuccess = (state, { payload }) => ({
  ...state,
  requestsListItems: payload.data,
  requestsListRequests: {
    ...state.RequestsListRequests,
    [RequestsListTypes.REQUESTS_LIST_REQUEST]: REQUEST_RESOLVED,
  },
});

const requestsListFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  requestsListRequests: {
    ...state.requestsListRequests,
    [RequestsListTypes.REQUESTS_LIST_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [RequestsListTypes.REQUESTS_LIST_REQUEST]: requestsListRequest,
  [RequestsListTypes.REQUESTS_LIST_SUCCESS]: requestsListSuccess,
  [RequestsListTypes.REQUESTS_LIST_FAILURE]: requestsListFailure,
});

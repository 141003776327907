import * as utility from 'lib/core/utility';
import * as Yup from 'yup';

export const createSuppliersOptions = (suppliersList) => {
  const suppliers = [];
  const VliSupplier = suppliersList.filter((obj) => obj.isVliSupplier);
  let refinary = [];
  let station = [];
  VliSupplier.forEach((supplier) => {
    supplier.types.forEach((obj) => {
      if (obj.name === 'Posto') {
        station = [...station, ...obj.locals];
      }
      if (obj.name === 'Refinaria') {
        refinary = [...refinary, ...obj.locals];
      }
    });
  });

  const noVliSupplier = suppliersList.filter((obj) => !obj.isVliSupplier);
  const optionsRefinary = refinary
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    .map((supplier) => {
      return {
        key: utility.simpleId(),
        label: supplier.name,
        value: { id: supplier.id },
      };
    });
  const optionsStation = station
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    .map((supplier) => {
      return {
        key: utility.simpleId(),
        label: supplier.name,
        value: { id: supplier.id },
      };
    });
  suppliers.push({
    key: utility.simpleId(),
    label: 'Base Supridora / Refinaria',
    name: `suppliers_refinary`,
    type: 'custom-multiselect',
    data: optionsRefinary,
  });
  suppliers.push({
    key: utility.simpleId(),
    label: 'Posto',
    name: `suppliers_station`,
    type: 'custom-multiselect',
    data: optionsStation,
  });
  noVliSupplier.forEach((supplier) => {
    let supplierType = '';
    let options = [];
    const suppliersOptions = [];

    supplier.types.forEach((type) => {
      supplierType = type.name;
      options = [];

      type.locals
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .forEach((local) => {
          options.push({
            key: utility.simpleId(),
            label: local.name,
            value: { id: local.id },
          });
        });

      suppliersOptions.push({
        key: utility.simpleId(),
        label: supplierType,
        data: options,
      });
    });

    suppliers.push({
      key: utility.simpleId(),
      label: supplier.name,
      name: `suppliers_${supplier.name}`,
      type: 'multiselectgroup',
      data: suppliersOptions,
    });
  });

  return suppliers;
};
export const createInflationaryOptions = (inflationary) => {
  const inflationaryData = inflationary?.map((data) => ({
    label: data.name,
    data: data.id,
  }));
  return inflationaryData;
};
export const setFilterDefault = (inflationary) => {
  const inflationaryData = createInflationaryOptions(inflationary);
  let filter;
  inflationaryData.forEach((item) => {
    filter = { ...filter, [`inflationary_${item.data}`]: item.data };
  });
  return filter;
};
export const DataFilter = (suppliers, inflationary) => {
  const inflationaryData = createInflationaryOptions(inflationary);
  const supplierData = createSuppliersOptions(suppliers);
  const dataFilter = [
    ...supplierData,
    {
      label: 'Data inicial',
      name: 'startPeriod',
      type: 'date',
    },
    {
      label: 'Data final',
      name: 'endPeriod',
      type: 'date',
    },
    {
      label: 'Índices Inflacionários',
      name: 'inflationary',
      type: 'checkbox',
      data: inflationaryData,
    },
    {
      label: 'Todas as Bases Inativas/Ativas',
      name: 'WithDeleted',
      type: 'switch',
      data: [
        {
          label: '',
          data: true,
        },
      ],
    },
  ];
  return dataFilter;
};
export const getFilterParams = (data) => {
  const newData = Object.entries(data);
  let ids = [];
  newData.forEach((dataItem) => {
    if (
      dataItem[0] !== 'startPeriod' &&
      dataItem[0] !== 'endPeriod' &&
      dataItem[0] !== 'WithDeleted'
    ) {
      if (Array.isArray(dataItem[1])) {
        ids = [...ids, dataItem[1].map((item) => item.value.id)];
      } else {
        ids = [...ids, dataItem[1]];
      }
    }
  });
  const startPeriodData = data.startPeriod
    ? utility.formatDate(data.startPeriod, 'yyyy-mm-dd')
    : null;
  const endPeriodData = data.endPeriod
    ? utility.formatDate(data.endPeriod, 'yyyy-mm-dd')
    : null;
  const filterFields = {
    filterFields: {
      startPeriod: startPeriodData,
      endPeriod: endPeriodData,
      ids: ids.flat(),
    },
  };
  return filterFields;
};
export const getSuppliersParams = (data) => {
  const filterFields = {
    filterFields: {
      ids: data,
    },
  };
  return `${utility.autoListingParamsGet(filterFields)}`;
};
export const validateFilter = async ({ startPeriod, endPeriod }) => {
  const validationSchema = Yup.object().shape({
    startPeriod: Yup.date()
      .when('endPeriod', {
        is: (endPeriodData) => !endPeriodData || endPeriodData.length === 0,
        then: Yup.date(),
        otherwise: Yup.date().required('A data inicial deve ser preenchida'),
      })
      .nullable(),
    endPeriod: Yup.date()
      .min(
        Yup.ref('startPeriod'),
        'A data final não pode ser inferior a data inicial.',
      )
      .nullable(),
  });
  const valid = validationSchema
    .validate({
      startPeriod,
      endPeriod,
    })
    .then(() => ({ isValid: true, errors: null }))
    .catch((err) => ({ isValid: false, errors: { [err.path]: err.message } }));
  return valid;
};

export const validateEdit = async ({ percentage }) => {
  const validationSchema = Yup.object().shape({
    percentage: Yup.string().required('O campo é requerido!'),
  });
  const valid = validationSchema
    .validate({
      percentage,
    })
    .then(() => ({ isValid: true, errors: null, status: 'success' }))
    .catch((err) => ({
      isValid: false,
      errors: { [err.path]: err.message },
      status: 'error',
    }));
  return valid;
};

export const getFieldsEdit = (item, percentage) => {
  let fields;
  if (item.order === utility.IndiceType.INFLATION) {
    fields = {
      Percentage: parseFloat(percentage.replaceAll('.', '').replace(',', '.')),
      InflationReadjustmentId: item.percentageId,
    };
  } else {
    fields = {
      Percentage: parseFloat(percentage.replaceAll('.', '').replace(',', '.')),
      DieselReadjustmentId: item.percentageId,
    };
  }
  return fields;
};

export const validateLocalId = (data) => {
  const values = [];
  Object.keys(data.localId).forEach((macroBase) => {
    Object.keys(data.localId[macroBase]).forEach((local) => {
      if (
        data.localId[macroBase][local] &&
        data.localId[macroBase][local].trim() !== ''
      ) {
        values.push(true);
      }
    });
  });
  return values;
};

export const getFormData = (data) => {
  const formData = new FormData();
  if (data.localId) {
    Object.keys(data.localId).forEach((macroBase, index) => {
      formData.append(`Suppliers[${index}].SupplierId`, macroBase);
      if (data.file && data.file[macroBase].station) {
        formData.append(
          `Suppliers[${index}].Station`,
          data.file[macroBase].station,
        );
      }
      if (data.file && data.file[macroBase].refinary) {
        formData.append(
          `Suppliers[${index}].Refinary`,
          data.file[macroBase].refinary,
        );
      }
      formData.append(
        `Suppliers[${index}].StartPeriod`,
        utility.formatDate(
          new Date(data.date[macroBase].startDate),
          'yyyy-mm-dd',
        ),
      );
      formData.append(
        `Suppliers[${index}].EndPeriod`,
        utility.formatDate(
          new Date(data.date[macroBase].endDate),
          'yyyy-mm-dd',
        ),
      );
      let idxDiesel = 0;
      Object.keys(data.localId[macroBase]).forEach((local) => {
        if (
          data.localId[macroBase][local] &&
          data.localId[macroBase][local].trim() !== ''
        ) {
          formData.append(
            `Suppliers[${index}].Diesels[${idxDiesel}].LocalId`,
            local,
          );
          formData.append(
            `Suppliers[${index}].Diesels[${idxDiesel}].Percentage`,
            data.localId[macroBase][local],
          );
          idxDiesel += 1;
        }
      });
    });
  }
  return formData;
};
export const getFormDataVli = (data) => {
  const formData = new FormData();
  if (data.file) {
    Object.keys(data.file).forEach((macroBase, index) => {
      formData.append(`Suppliers[${index}].SupplierId`, macroBase);
      if (data.file && data.file[macroBase].station) {
        formData.append(
          `Suppliers[${index}].Station`,
          data.file[macroBase].station,
        );
      }
      if (data.file && data.file[macroBase].refinary) {
        formData.append(
          `Suppliers[${index}].Refinary`,
          data.file[macroBase].refinary,
        );
      }
    });
  }
  formData.append(
    'DieselDate',
    utility.formatDate(new Date(data.DieselDate), 'yyyy-mm-dd'),
  );
  formData.append('DieselExcel', data.DieselExcel);
  return formData;
};
export const getLetterName = (data, indice) => {
  const names = data.map(
    (month) =>
      month.indexVariations.filter((idx) => idx.name === indice.name)[0]
        .dieselLetter,
  );
  return names.filter((e) => e);
};
export const getLetterIds = (data, indice) => {
  const ids = data.map((month) =>
    month.indexVariations
      .filter((idx) => idx.name === indice.name)[0]
      .dieselLetters?.map((letter) => letter.id),
  );

  return ids.filter((e) => e).flat();
};

export const isItemTypeInflation = (item) => {
  return item.type === 'Inflação';
};

export const enableDownload = (item, letterIds) =>
  item.type !== 'Inflação' && letterIds.length > 0;
export const enableEdit = (item) =>
  item.isLastReadjustment && utility.isAdminIndices();
export const enableAccumulatedInflation = (item) => isItemTypeInflation(item);

export const getEnableOption = (item, letterIds) =>
  enableEdit(item) ||
  enableDownload(item, letterIds) ||
  enableAccumulatedInflation(item);

export const groupMacrobase = (suppliers) => {
  const auxGroup = suppliers.reduce((r, a) => {
    r[a.isVliSupplier] = r[a.isVliSupplier] || [];
    r[a.isVliSupplier].push(a);
    return r;
  }, Object.create(null));
  const anotherBase = auxGroup.false;
  const supplierVli = {
    id: utility.simpleId(),
    ids: auxGroup.true.map((id) => id.id),
    name: 'Fornecedor VLI',
    suppliers: auxGroup.true,
    isVliSupplier: true,
  };
  const group = [supplierVli, ...anotherBase];
  return group;
};

export const getSuppliersFormat = (suppliers) => {
  const newResponse = suppliers.map((obj) => {
    const group = utility.groupBy(
      obj.locals.sort((a, b) => (a.typeLocal > b.typeLocal ? 1 : -1)),
      'typeLocal',
    );
    const types = [];
    Object.keys(group).forEach((category) => {
      const locals = [];
      let name = '';
      let localId = '';
      group[category].forEach((memb) => {
        locals.push(memb);
        name = memb.typeLocal;
        localId = memb.typeLocalId;
      });
      types.push({
        id: localId,
        name,
        locals,
      });
    });
    return {
      ...obj,
      types,
    };
  });

  return newResponse;
};

export const getTitleTooltipRefinaria = () => {
  return `<p><center>Seguir nomenclatura:</center></p>
  <p>"Refinaria_Distribuidora_Mes_Ano (MM/AAAA)"</p>`;
};
export const getTitleTooltipPosto = () => {
  return `<p><center>Seguir nomenclatura:</center></p>
  <p>"“Posto_Distribuidora_Mes_Ano (MM/AAAA)”"</p>`;
};

export const SupplierHasLetterToUpload = (supplier) => {
  return supplier.hasRefinary || supplier.hasStation;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/entry.webpack';

import { simpleId } from 'lib/core/utility';

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess(props) {
    setNumPages(props.numPages);
  }

  return (
    <Document
      file={file}
      onLoadSuccess={(props) => onDocumentLoadSuccess(props)}
    >
      {Array.from(new Array(numPages), (item, index) => (
        <Page key={`pdf-page-${simpleId()}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};

PDFViewer.propTypes = {
  file: PropTypes.object.isRequired,
};

export default PDFViewer;

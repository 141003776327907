import styled from 'styled-components';
import {
  Tokens,
  Button,
  Accordion,
  Row,
  Flex,
  Card,
  Text,
} from '@vli/locomotive-ds/dist';

export const DeleteButton = styled(Button)`
  height: ${Tokens.spacing.lg};
  width: ${Tokens.spacing.lg};
  background: ${Tokens.colors.red300};
  margin-top: 24px;

  svg {
    height: 20px;
  }
`;

export const AddButton = styled(Button)`
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: auto;

  svg {
    width: 20px;
  }
`;

export const RowContainer = styled(Row)`
  padding: ${Tokens.spacing.sm};
  align-items: center;
  > div {
    margin-right: ${Tokens.spacing.sm};
  }
`;

export const ExpandedAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: ${Tokens.spacing.sm};
`;

export const CardContainer = styled(Card)`
  padding: ${Tokens.spacing.sm} ${Tokens.spacing.sm} ${Tokens.spacing.md};
  margin-bottom: ${Tokens.spacing.md};
`;

export const Label = styled(Text)`
  color: ${Tokens.colors.gray400};
  margin-bottom: 6px;
`;

export const TextValue = styled(Text)`
  font-size: ${Tokens.spacing.sm};
`;

export const FlexContainer = styled(Flex)`
  align-items: center;

  > div {
    margin-right: ${Tokens.spacing.sm};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: ${Tokens.spacing.sm};
`;

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const SidebarContent = styled.div`
  #menu-container {
    ul {
      overflow-x: auto;
      max-height: 75vh !important;
      height: 100%;

      ::-webkit-scrollbar {
        width: 4px;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;

        &:hover {
          width: 10px;
          cursor: pointer;
          background: ${Tokens.colors.gray500};
        }
      }

      ::-webkit-scrollbar-thumb {
        background: ${Tokens.colors.gray500};
      }

      li {
        min-height: 40px;
      }
      li.open-sub-item {
        position: unset;
        .tooltip-wrapper {
          align-items: center;
        }
        div {
          span {
            button.title-item {
              margin-top: 10px;
            }
          }
        }

        button.title-item > svg.sub-item-arrow {
          right: 10px;
        }
        button.title-item > p {
          color: #0074c0;
        }
        button.title-item > svg {
          path {
            fill: #0074c0;
          }
          g {
            path {
              fill: #0074c0;
            }
          }
        }
        top: 7px;
      }
      .sub-list > li {
        align-items: center;
        padding-left: 8px;

        div {
          p {
            flex-direction: inherit;
            align-items: center;
          }
        }
      }
      .type-dark {
        background: transparent !important;
      }
    }
    #menu-container.collapse {
      ul {
        overflow-x: unset;
      }
    }
    ${({ enviroment, color }) =>
      enviroment !== 'prd' &&
      `background-color: ${color};
      ul {
        background-color: transparent;
        li{
          background-color: ${color};
          p {
            color: #fff;
          }
          svg {
            path {
              fill: #fff;
            }
             g {
               path {
                 fill: #fff;
               }
             }
          }
        }
        li.active{
          background-color: #fff;
          p {
            color: #0074C0;
          }
          svg {
            path {
              fill: #0074C0;
            }
            g {
              path {
                fill:#0074C0;
              }
            }
         }
         .sub-items{
          .sub-list > li {
            background-color: #fff;
          }
        }
        }
        li.menu-danger{
          background-color: transparent;
        }

      }
      #menu-header {
        background-color: ${color};
        span{
          color: #fff;
        }
        svg{
          path {
            fill: #fff;
          }
        }
      }
      #hide-menu{
        svg{
          path {
            fill: #fff;
          }
        }
        span{
          color: #fff;
        }
      }
    `}
  }
`;

export const TutorialLink = styled.a`
  cursor: pointer;
  text-decoration: none;

  position: absolute;
  bottom: 56px;

  display: flex;
  align-items: center;
  gap: 8px;

  padding: 8px 12px;

  & > svg {
    width: 18px;
    height: 18px;

    & > path {
      transform: translate(1px, 1px);

      &:first-child {
        stroke: #fff;
        fill: transparent;
        stroke-width: 1px;
        ${(props) =>
          props.environment === 'prd' &&
          `
        stroke: #666;
      `}
      }

      &:last-child {
        ${(props) =>
          props.environment === 'prd' &&
          `
        fill: #666;
      `}
      }
    }
  }

  span {
    color: ${Tokens.colors.grayWhite};
    font: ${Tokens.fonts.textRegularMedium};

    ${(props) =>
      props.environment === 'prd' &&
      `
        color: #666;
      `}
  }
`;

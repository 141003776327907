import CONSTANTS from 'constants/constants';

export const getPVProposalLink = (proposalCode) =>
  `${CONSTANTS.PV_DIGITAL_URL}proposta-solucao-logistica/formulario/${proposalCode}/resumo`;

export const getPVProposalListLink = () =>
  `${CONSTANTS.PV_DIGITAL_URL}proposta-solucao-logistica`;

export const getNetlexDocLink = (id) =>
  `${CONSTANTS.NETLEX_URL}app/dashboard/document/view/${id}/overview`;

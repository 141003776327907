import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import * as ROUTES from 'constants/urls';

import Contract from 'pages/contract';
import Forbidden from 'pages/forbidden';
import ContractDetails from 'pages/contract/contractDetails';
import RailwayItemsHistory from 'pages/railwayItemsHistory';
import ReadjustmentDiesel from 'pages/MacroeconomicIndices/ReajustmentDiesel';
import ReadjustmentInflation from 'pages/MacroeconomicIndices/ReajustmentInflation';
import ContractUpload from 'pages/ContractUpload';
import StatusApplication from 'pages/status';
import Login from 'pages/login';
import Indices from 'pages/Indices';
import ProfileList from 'pages/profile/ProfileList';
import UserList from 'pages/user/UserList';
import CreateProfile from 'pages/profile/CreateProfile';
import EditProfile from 'pages/profile/EditProfile';
import CreateUser from 'pages/user/CreateUser';
import EditUser from 'pages/user/EditUser';
import Local from 'pages/local';
import NewItems from 'pages/newItems';
import CreateNewItem from 'pages/newItems/CreateNewItem/CreateNewItem';
import ResendItemsList from 'pages/resendItems';
import EmailNotificationsManagerList from 'pages/notifications/manager/EmailNotificationsManagerList/EmailNotificationsManagerList';
import EmailNotificationManagerCreate from 'pages/notifications/manager/EmailNotificationManagerCreate/EmailNotificationManagerCreate';
import EmailNotificationsClientList from 'pages/notifications/client/EmailNotificationsClientList/EmailNotificationsClientList';
import EmailNotificationClientCreate from 'pages/notifications/client/EmailNotificationClientCreate/EmailNotificationClientCreate';
import ReportContractItemsList from 'pages/reportContractItems/ReportContractItemsList/ReportContractItemsList';
import ReportContractItemsEdit from 'pages/reportContractItems/ReportContractItemsEdit/ReportContractItemsEdit';
import SegmentsList from 'pages/segments/SegmentsList/SegmentsList';
import CreateSegment from 'pages/segments/CreateSegment/CreateSegment';
import EditSegment from 'pages/segments/EditSegment/EditSegment';
import ContractReport from 'pages/contractReport';
import SuppliersList from 'pages/suppliers/SuppliersList';
import CreateSupplier from 'pages/suppliers/CreateSupplier';
import UpdateSupplier from 'pages/suppliers/UpdateSupplier';
import ReactivateItems from 'pages/reactivateItems';
import Models from 'pages/models/';
import Formulas from 'pages/formulas/Formulas';
import FormalizationRequest from 'pages/formalization/FormalizationRequest';
import RequestsList from 'pages/requestsList/RequestsList';
import ClientsList from 'pages/clients/ClientList';
import CommercialNegotiations from 'pages/CommercialNegotiations';
import SystemParameters from 'pages/SystemParameters';
import ContractsControl from 'pages/ContractsControl';

import { ACTIONS, ROLES, canExecuteAction } from 'lib/contexts/permissions';
import { isAdminProfile } from 'lib/contexts/systemParameters';

import PrivateRoute from './PrivateRoute';

const Dashboard = (roles) => (
  <Switch>
    <Route exact path={ROUTES.LOGIN} component={Login} />

    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.HOME}
      component={Contract}
      canAccess={canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.CONTRACTS}
      component={Contract}
      canAccess={canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.MACROECONOMIC_INDICES_HISTORY}
      component={Indices}
      canAccess={canExecuteAction(ROLES.INDICES, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.CONTRACTS}/:id/:status?`}
      component={ContractDetails}
      canAccess={canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.RAILWAY_ITEMS_HISTORY}/:code/:status?`}
      component={RailwayItemsHistory}
      canAccess={canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.REAJUSTMENT_DIESEL_INDICES}`}
      component={ReadjustmentDiesel}
      canAccess={
        canExecuteAction(ROLES.ANP, ACTIONS.CREATE, roles) ||
        canExecuteAction(ROLES.VLI_SUPPLIER, ACTIONS.CREATE, roles) ||
        canExecuteAction(ROLES.PETROBRAS, ACTIONS.CREATE, roles)
      }
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.REAJUSTMENT_INFLATION_INDICES}`}
      component={ReadjustmentInflation}
      canAccess={canExecuteAction(ROLES.INDICES, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.SPREADSHEET_IMPORT}
      component={ContractUpload}
      canAccess={canExecuteAction(ROLES.CONTRACT_UPLOAD, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.STATUS}
      component={StatusApplication}
      canAccess={canExecuteAction(ROLES.HEALTH_CHECK, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.PROFILE_LIST}`}
      component={ProfileList}
      canAccess={canExecuteAction(ROLES.PERMISSION, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.NEW_PROFILE}`}
      component={CreateProfile}
      canAccess={canExecuteAction(ROLES.PERMISSION, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.EDIT_PROFILE(':id')}`}
      component={EditProfile}
      canAccess={canExecuteAction(ROLES.PERMISSION, ACTIONS.UPDATE, roles)}
    />

    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.USER_LIST}`}
      component={UserList}
      canAccess={canExecuteAction(ROLES.USER, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EDIT_USER(':id')}
      component={EditUser}
      canAccess={canExecuteAction(ROLES.USER, ACTIONS.UPDATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.NEW_USER}`}
      component={CreateUser}
      canAccess={canExecuteAction(ROLES.USER, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={`${ROUTES.LOCAL}`}
      component={Local}
      canAccess={canExecuteAction(ROLES.VLI_SUPPLIER, ACTIONS.MANAGER, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.ITEMS}
      component={NewItems}
      canAccess={canExecuteAction(ROLES.NEW_ITEMS, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.NEW_ITEM}
      component={CreateNewItem}
      canAccess={canExecuteAction(ROLES.NEW_ITEMS, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.NEW_ITEM_DRAFT(':id')}
      component={CreateNewItem}
      canAccess={canExecuteAction(ROLES.NEW_ITEMS, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.RESEND_ITEMS}
      component={ResendItemsList}
      canAccess={canExecuteAction(ROLES.RESEND_ITEMS, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EMAIL_NOTIFICATIONS_MANAGER_LIST}
      component={EmailNotificationsManagerList}
      canAccess={canExecuteAction(
        ROLES.NOTIFICATION_MANAGER,
        ACTIONS.MANAGER_NOTIFICATION_LOCAL_DIESEL,
        roles,
      )}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EMAIL_NOTIFICATIONS_MANAGER_CREATE(':id')}
      component={EmailNotificationManagerCreate}
      canAccess={canExecuteAction(
        ROLES.NOTIFICATION_MANAGER,
        ACTIONS.MANAGER_NOTIFICATION_LOCAL_DIESEL,
        roles,
      )}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EMAIL_NOTIFICATIONS_CLIENT_LIST}
      component={EmailNotificationsClientList}
      canAccess={
        canExecuteAction(
          ROLES.NOTIFICATION_CLIENT,
          ACTIONS.USER_SEGMENT,
          roles,
        ) ||
        canExecuteAction(
          ROLES.NOTIFICATION_CLIENT,
          ACTIONS.USER_CONTRACT,
          roles,
        )
      }
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EMAIL_NOTIFICATIONS_CLIENT_CREATE(':id')}
      component={EmailNotificationClientCreate}
      canAccess={
        canExecuteAction(
          ROLES.NOTIFICATION_CLIENT,
          ACTIONS.USER_SEGMENT,
          roles,
        ) ||
        canExecuteAction(
          ROLES.NOTIFICATION_CLIENT,
          ACTIONS.USER_CONTRACT,
          roles,
        )
      }
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.REPORT_CONTRACT_ITEMS}
      component={ReportContractItemsList}
      canAccess={
        canExecuteAction(ROLES.REPORT, ACTIONS.USER_SEGMENT, roles) ||
        canExecuteAction(ROLES.REPORT, ACTIONS.USER_CONTRACT, roles)
      }
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.REPORT_CONTRACT_ITEMS_UPDATE(':id')}
      component={ReportContractItemsEdit}
      canAccess={
        canExecuteAction(ROLES.REPORT, ACTIONS.USER_SEGMENT, roles) ||
        canExecuteAction(ROLES.REPORT, ACTIONS.USER_CONTRACT, roles)
      }
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.SEGMENT_LIST}
      component={SegmentsList}
      canAccess={canExecuteAction(ROLES.SEGMENT, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.NEW_SEGMENT}
      component={CreateSegment}
      canAccess={canExecuteAction(ROLES.SEGMENT, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EDIT_SEGMENT(':id')}
      component={EditSegment}
      canAccess={canExecuteAction(ROLES.SEGMENT, ACTIONS.UPDATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.CONTRACT_REPORT}
      component={ContractReport}
      canAccess={canExecuteAction(ROLES.CONTRACT_REPORT, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.SUPPLIER_LIST}
      component={SuppliersList}
      canAccess={canExecuteAction(ROLES.SUPPLIER, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.NEW_SUPPLIER}
      component={CreateSupplier}
      canAccess={canExecuteAction(ROLES.SUPPLIER, ACTIONS.CREATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.EDIT_SUPPLIER(':id')}
      component={UpdateSupplier}
      canAccess={canExecuteAction(ROLES.SUPPLIER, ACTIONS.UPDATE, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path="/modelos"
      component={Models}
      canAccess={
        canExecuteAction(ROLES.MODELS, ACTIONS.UPLOAD, roles) &&
        canExecuteAction(ROLES.MODELS, ACTIONS.DOWNLOAD, roles)
      }
    />
    <PrivateRoute
      exact
      roles={roles}
      path="/formulas"
      component={Formulas}
      canAccess={canExecuteAction(ROLES.FORMULAS, ACTIONS.READ, roles)}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.REACTIVATE_ITEMS}
      component={ReactivateItems}
      canAccess={canExecuteAction(
        ROLES.REACTIVATE_ITEMS,
        ACTIONS.UPDATE,
        roles,
      )}
    />

    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.FORMALIZATION_REQUEST(':action', ':formalizationId')}
      component={FormalizationRequest}
      canAccess
    />

    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.REQUESTS_LIST}
      component={RequestsList}
      canAccess
    />

    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.CLIENT_LIST}
      component={ClientsList}
      canAccess={canExecuteAction(ROLES.CLIENT, ACTIONS.READ, roles)}
    />

    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.COMMERCIAL_NEGOTIATIONS}
      component={CommercialNegotiations}
      canAccess={canExecuteAction(
        ROLES.COMMERCIAL_NEGOTIATIONS,
        ACTIONS.MANAGE_SERVICE,
        roles,
      )}
    />

    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.SYSTEM_PARAMETERS}
      component={SystemParameters}
      canAccess={isAdminProfile()}
    />
    <PrivateRoute
      exact
      roles={roles}
      path={ROUTES.CONTRACTS_CONTROL}
      component={ContractsControl}
      canAccess={canExecuteAction(ROLES.CONTRACT, ACTIONS.READ, roles)}
    />

    <Route exact path={ROUTES.FORBIDDEN} component={Forbidden} />

    <Redirect from="*" to={ROUTES.HOME} />
  </Switch>
);

export default Dashboard;

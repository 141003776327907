import styled from 'styled-components';

import css from '@styled-system/css';
import { variant } from 'styled-system';
import { Tokens as theme } from '@vli/locomotive-ds';

export const CalendarStyle = styled('div')(
  css({
    position: 'relative',
    '& > div > span > div.react-date-picker__calendar, div.react-daterange-picker__calendar': {
      width: '264px',
      zIndex: '5',
    },
    'button, input': {
      outline: 'none',
    },

    // Date Picker DAY buttons behavior
    '& > .react-date-picker': {
      '& > span > .react-date-picker__calendar > .react-calendar > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days': {
        'button.react-calendar__tile--active': {
          background: theme.colors.accentPrimaryLight,
          color: theme.colors.accentPrimaryDark,
          borderRadius: '4px',
        },
        'button.react-calendar__tile--now': {
          background: '#FFFFFF',
          color: theme.colors.accentPrimaryDark,
          fontWeight: 'bold',
          position: 'relative',
          '::before': {
            content: "'HOJE'",
            position: 'absolute',
            top: '-1px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '8px',
          },
        },
      },
    },

    // DateRange Picker DAY buttons behavior
    '& > .react-daterange-picker': {
      width: '100%',
      '& > span > .react-daterange-picker__calendar > .react-calendar > .react-calendar__viewContainer > .react-calendar__month-view > div > div > .react-calendar__month-view__days': {
        'button.react-calendar__tile--now': {
          background: '#FFFFFF',
          color: theme.colors.accentPrimaryDark,
          fontWeight: 'bold',
          position: 'relative',
          '::before': {
            content: "'HOJE'",
            position: 'absolute',
            top: '-1px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '8px',
          },
        },
        'button.react-calendar__tile--rangeEnd': {
          borderRadius: '0 4px 4px 0',
          background: theme.colors.accentPrimaryDark,
          color: theme.colors.grayWhite,
        },
        'button.react-calendar__tile--rangeStart': {
          borderRadius: '4px 0 0 4px',
          background: theme.colors.accentPrimaryDark,
          color: theme.colors.grayWhite,
        },
        'button.react-calendar__tile--rangeStart.react-calendar__tile--active, button.react-calendar__tile--rangeEnd.react-calendar__tile--active': {
          background: theme.colors.accentPrimaryDark,
          color: theme.colors.grayWhite,
        },
        'button.react-calendar__tile--active': {
          background: theme.colors.accentPrimaryLight,
          color: theme.colors.accentPrimaryDark,
        },
      },
    },

    '& > div > div.react-date-picker__wrapper, div.react-daterange-picker__wrapper': {
      border: `1px solid ${theme.colors.gray200}`,
      background: theme.colors.grayWhite,
      borderRadius: '4px',
      padding: '6px',
      font: theme.fonts.textRegularMedium,
      minWidth: '140px',
      paddingRight: '0',
      height: '40px',
      '& > span.react-daterange-picker__range-divider': {
        display: 'none',
      },
      '& > button.react-date-picker__clear-button, button.react-daterange-picker__clear-button': {
        display: 'none',
      },
      '& > button.react-date-picker__calendar-button': {
        padding: '0',
        paddingRight: '8px',
      },
      '& > div.react-date-picker__inputGroup': {
        '& > input': {
          color: theme.colors.gray400,
        },
        '& > input::placeholder': {
          color: theme.colors.gray200,
        },
        '.react-date-picker__inputGroup__leadingZero': {
          paddingBottom: '1px',
        },
      },
      '& :first-child.react-daterange-picker__inputGroup': {
        '::before': {
          content: "'De '",
          color: theme.colors.gray400,
          fontWeight: '500',
        },
      },
      '& > div.react-daterange-picker__inputGroup': {
        flexGrow: '0',
        fontWeight: '700',
        '::before': {
          content: "'até '",
          color: theme.colors.gray400,
          fontWeight: '500',
        },
        '& > input': {
          color: theme.colors.gray400,
        },
        '& > span': {
          color: theme.colors.gray400,
        },
        '~ .react-daterange-picker__calendar-button': {
          position: 'absolute',
          right: '3px',
        },
        '.react-daterange-picker__inputGroup__leadingZero': {
          paddingBottom: '1px',
        },
      },
    },
    '& > div > span > div > div.react-calendar': {
      border: 'none',
      boxShadow:
        '0px 2px 3px rgba(0, 0, 0, 0.12), 0px -1px 2px rgba(0, 0, 0, 0.08)',
      borderRadius: '4px',
      '& > div.react-calendar__navigation': {
        borderBottom: `2px solid ${theme.colors.gray100}`,
        marginBottom: '0',
        padding: '8px',
        '& > button.react-calendar__navigation__arrow': {
          color: theme.colors.accentPrimaryDark,
        },
        '& > button.react-calendar__navigation__label > span': {
          font: theme.fonts.textRegularMedium,
          color: theme.colors.grayBlack,
          fontWeight: '700',
          textTransform: 'capitalize',
        },
        '& > button:hover': {
          background: theme.colors.gray100,
          borderRadius: '4px',
        },
        '& > button.react-calendar__navigation__prev2-button': {
          display: 'none',
        },
        '& > button.react-calendar__navigation__next2-button': {
          display: 'none',
        },
        '& > button.react-calendar__navigation__prev-button': {
          background: theme.colors.gray100,
          marginRight: '8px',
          borderRadius: '4px',
        },
        '& > button.react-calendar__navigation__next-button': {
          background: theme.colors.gray100,
          marginLeft: '8px',
          borderRadius: '4px',
        },
      },
      '& > div.react-calendar__viewContainer': {
        '& > div.react-calendar__year-view': {
          padding: '7px',
          paddingTop: '0',
          '& > div.react-calendar__year-view__months': {
            '& > button': {
              background: theme.colors.gray100,
              padding: '1em 0em',
              borderRadius: '4px',
              boxShadow: 'inset 0px 0px 0px 2px #FFFFFF',
              textTransform: 'capitalize',
              '&.react-calendar__tile--active': {
                color: theme.colors.accentPrimaryDark,
                background: theme.colors.accentPrimaryLight,
              },
            },
          },
        },
        '& > div.react-calendar__decade-view, div.react-calendar__century-view': {
          '& > div.react-calendar__decade-view__years, div.react-calendar__century-view__decades': {
            padding: '7px',
            paddingTop: '0',
            '& > button': {
              background: theme.colors.gray100,
              padding: '1em 0em',
              borderRadius: '4px',
              boxShadow: 'inset 0px 0px 0px 2px #FFFFFF',
              textTransform: 'capitalize',
              '&.react-calendar__tile--now': {
                color: theme.colors.accentPrimaryDark,
                background: theme.colors.accentPrimaryLight,
              },
              '&.react-calendar__tile--active': {
                background: theme.colors.accentPrimaryLight,
                borderRadius: '4px',
                color: theme.colors.accentPrimaryDark,
              },
            },
          },
        },
        '& > div.react-calendar__month-view': {
          padding: '8px',
          paddingTop: '0',
        },
        '& > div.react-calendar__month-view > div > div': {
          '& > div.react-calendar__month-view__weekdays': {
            '& > div': {
              textTransform: 'capitalize',
              '& > abbr': {
                textDecoration: 'none',
              },
            },
          },
          '& > div.react-calendar__month-view__days': {
            '& > button.react-calendar__month-view__days__day--weekend': {
              color: theme.colors.grayBlack,
            },
            '& > button.react-calendar__month-view__days__day': {
              color: theme.colors.gray400,
            },
            '& > button.react-calendar__month-view__days__day--neighboringMonth': {
              color: theme.colors.gray200,
              font: theme.fonts.textRegularMedium,
            },
            '& > button:hover': {
              background: theme.colors.gray100,
              borderRadius: '4px',
            },
          },
        },
      },
    },
    '& > select.react-date-picker__inputGroup__input, select.react-date-picker__inputGroup__month': {
      color: theme.colors.gray400,
      textIndent: '1px',
      textOverflow: '',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '& option': {
        display: 'none',
      },
    },
  }),
  variant({
    variants: {
      range: {
        '& > .react-daterange-picker': {
          '& > div > div.react-date-picker__wrapper, div.react-daterange-picker__wrapper': {
            minWidth: '264px',
          },
        },
      },
    },
  }),
  ({ width }) =>
    width &&
    css({
      '& div.react-date-picker': {
        width,
      },
    }),
  ({ spanStyle }) =>
    spanStyle
      ? css({
          '& > div > div.react-date-picker__wrapper, div.react-daterange-picker__wrapper': {
            '& > div.react-date-picker__inputGroup': {
              '& > span': {
                color: theme.colors.gray400,
              },
            },
          },
        })
      : css({
          '& > div > div.react-date-picker__wrapper, div.react-daterange-picker__wrapper': {
            '& > div.react-date-picker__inputGroup': {
              '& > span': {
                color: theme.colors.gray200,
              },
            },
          },
        }),
);

export const Label = styled('label')(
  css({
    color: theme.colors.gray400,
    font: theme.fonts.textRegularSmall,
    display: 'block',
    marginBottom: '4px',
    transitionProperty: 'color',
    transitionDuration: '.5s',
    transitionTimingFunction: 'cubic-bezier(.5, 0, 0, 1)',
    span: {
      color: theme.colors.gray200,
    },
  }),
  ({ disabled }) =>
    disabled &&
    css({
      color: theme.colors.gray300,
      pointerEvents: 'none',
    }),
);

import React from 'react';
import CellWithActions from 'components/contexts/profile/profileList/Table/CellWithActions';
import CellTable from 'components/core/Table/CellTable';
import * as utility from 'lib/core/utility';

export const Columns = [
  {
    Header: 'Perfil',
    accessor: 'name',
    align: 'start',
    width: 400,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeFirstString}
        align="start"
      />
    ),
  },
  {
    Header: 'Usuários vinculados',
    accessor: 'totalUsers',
    align: 'center',
    width: 20,
    Cell: (props) => <CellTable {...props} align="center" />,
  },
  {
    Header: 'Ações',
    accessor: 'acoes',
    align: 'end',
    width: 10,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Loading, Button, Checkbox } from '@vli/locomotive-ds/dist';

import { ContractActions } from 'store/contract/contract.ducks';

import { isPending } from 'lib/core/request';
import { GET } from 'lib/core/localStorage';

import * as S from './RecipientsModaStyles';

const RecipientsModal = ({ isOpen, onClose, contractDetails, onConfirm }) => {
  const dispatch = useDispatch();
  const { userId } = GET('userId');

  const { recipients, contractRequests } = useSelector(
    ({ contract }) => contract,
  );

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (isOpen) {
      dispatch(ContractActions.listContractRecipientsByUserId(userId));
    }
  }, [dispatch, isOpen, userId]);

  const isLoading = isPending(
    contractRequests.LIST_CONTRACT_RECIPIENTS_BY_USER_ID,
  );

  const handleCheckboxChange = (optionId) => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== optionId));
    } else {
      setSelectedOptions([...selectedOptions, optionId]);
    }
  };

  const handleCancel = () => {
    setSelectedOptions([]);
    onClose();
  };

  const handleImportRecipients = () => {
    const recipientsSelectedList = recipients.filter((recipient) =>
      selectedOptions.includes(recipient.contractId),
    );

    const recipientsList = recipientsSelectedList
      .map((item) => {
        return item.recipients;
      })
      .flat();

    setSelectedOptions([]);
    onConfirm(recipientsList);
  };

  return (
    <S.ModalContainer
      title="Vincular e-mails de outros contratos"
      isOpen={isOpen}
      onClose={onClose}
      width="650px"
    >
      <S.Scroll>
        {isLoading && (
          <Loading fitParent message="Por favor, espere alguns segundos..." />
        )}

        <S.Wrapper>
          <S.Info>
            Selecione abaixo os contratos onde você deseja que os e-mails sejam
            importados para o contrato {contractDetails.contractCode} do cliente{' '}
            {contractDetails.customerName}.
          </S.Info>

          <S.Table>
            <S.TableHead>
              <S.TableCell width={5} />
              <S.TableCell width={40}>Cliente</S.TableCell>
              <S.TableCell width={25}>Contrato</S.TableCell>
              <S.TableCell width={30}>Destinatários vinculados</S.TableCell>
            </S.TableHead>

            <S.TableBody>
              {recipients?.map((contractRecipient) => (
                <S.TableItem
                  key={`contract-recipients-option-${contractRecipient.contractId}`}
                  htmlFor={`contract-${contractRecipient.contractId}`}
                  isSelected={false}
                  data-testid={`checkbox-recipient-${contractRecipient.contractId}`}
                >
                  <div>
                    <Checkbox
                      name="recipients"
                      label=""
                      type="checkbox"
                      id={`contract-${contractRecipient.contractId}`}
                      value={contractRecipient.contractId}
                      checked={selectedOptions.includes(
                        contractRecipient.contractId,
                      )}
                      onChange={() =>
                        handleCheckboxChange(contractRecipient.contractId)
                      }
                      labelStyle={{ padding: 0, margin: 0, display: 'none' }}
                    />
                  </div>
                  <span>{contractRecipient.custumerName}</span>
                  <span>{contractRecipient.contractCode}</span>
                  <small>{contractRecipient.recipients.length}</small>
                </S.TableItem>
              ))}
            </S.TableBody>
          </S.Table>

          <S.Actions>
            <Button
              variant="secondary"
              name="cancelar"
              type="button"
              onClick={handleCancel}
            />

            <Button
              variant="primary"
              name="importar"
              type="button"
              onClick={handleImportRecipients}
              isDisabled={selectedOptions.length < 1}
            />
          </S.Actions>
        </S.Wrapper>
      </S.Scroll>
    </S.ModalContainer>
  );
};

RecipientsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contractDetails: PropTypes.object.isRequired,
};

export default RecipientsModal;

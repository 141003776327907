import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import messages from 'constants/messages';

export const { Types: IndicesTypes, Creators: IndicesActions } = createActions({
  listSuppliers: ['payload'],
  getAllSuppliers: ['payload'],
  listSuppliersSuccess: ['payload'],
  listInflationary: ['payload'],
  listInflationarySuccess: ['payload'],
  dieselReadjustmentStart: ['readjustment', 'isVliSupplier', 'key', 'callback'],
  dieselReadjustmentSuccess: [],
  inflationaryReadjustmentStart: ['readjustment', 'callback'],
  inflationaryReadjustmentSuccess: [],
  suppliersRequestFailure: ['error'],
  inflationaryRequestFailure: ['error'],
  dieselReadjustmentRequestFailure: ['error'],
  inflationaryReadjustmentRequestFailure: ['error'],
});

const INITIAL_STATE = {
  suppliersList: [],
  inflationaryList: [],
  error: null,
  indicesRequests: {
    [IndicesTypes.LIST_SUPPLIERS]: REQUEST_NOT_STARTED,
  },
};

const suppliers = () => ({
  ...INITIAL_STATE,
  indicesRequests: {
    ...INITIAL_STATE.indicesRequests,
    [IndicesTypes.LIST_SUPPLIERS]: REQUEST_PENDING,
  },
});
const getAllSuppliers = (state) => ({
  ...state,
  suppliersList: [],
  indicesRequests: {
    ...INITIAL_STATE.indicesRequests,
    [IndicesTypes.LIST_SUPPLIERS]: REQUEST_PENDING,
  },
});
const inflationary = () => ({
  ...INITIAL_STATE,
  indicesRequests: {
    ...INITIAL_STATE.indicesRequests,
    [IndicesTypes.LIST_INFLATIONARY]: REQUEST_PENDING,
  },
});

const suppliersSuccess = (state, { payload }) => ({
  ...state,
  suppliersList: payload,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.LIST_SUPPLIERS]: REQUEST_RESOLVED,
  },
});
const inflationarySuccess = (state, { payload }) => ({
  ...state,
  inflationaryList: payload,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.LIST_INFLATIONARY]: REQUEST_RESOLVED,
  },
});
const suppliersFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.LIST_SUPPLIERS]: REQUEST_REJECTED,
  },
});
const inflationaryFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.LIST_INFLATIONARY]: REQUEST_REJECTED,
  },
});
const dieselReadjustmentStart = (state) => ({
  ...state,
  message: null,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.DIESEL_READJUSTMENT_START]: REQUEST_PENDING,
  },
});
const dieselReadjustmentSuccess = (state) => ({
  ...state,
  message: messages.messages.diesels.success,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.DIESEL_READJUSTMENT_START]: REQUEST_RESOLVED,
  },
});
const dieselReadjustmentFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.DIESEL_READJUSTMENT_START]: REQUEST_REJECTED,
  },
});
const inflationaryReadjustmentStart = (state) => ({
  ...state,
  message: null,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.INFLATIONARY_READJUSTMENT_START]: REQUEST_PENDING,
  },
});
const inflationaryReadjustmentSuccess = (state) => ({
  ...state,
  message: messages.messages.inflation.success,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.INFLATIONARY_READJUSTMENT_START]: REQUEST_RESOLVED,
  },
});
const inflationaryReadjustmentFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  indicesRequests: {
    ...state.indicesRequests,
    [IndicesTypes.INFLATIONARY_READJUSTMENT_START]: REQUEST_REJECTED,
  },
});
export default createReducer(INITIAL_STATE, {
  [IndicesTypes.LIST_SUPPLIERS]: suppliers,
  [IndicesTypes.GET_ALL_SUPPLIERS]: getAllSuppliers,
  [IndicesTypes.LIST_SUPPLIERS_SUCCESS]: suppliersSuccess,
  [IndicesTypes.LIST_INFLATIONARY]: inflationary,
  [IndicesTypes.LIST_INFLATIONARY_SUCCESS]: inflationarySuccess,
  [IndicesTypes.DIESEL_READJUSTMENT_START]: dieselReadjustmentStart,
  [IndicesTypes.DIESEL_READJUSTMENT_SUCCESS]: dieselReadjustmentSuccess,
  [IndicesTypes.DIESEL_READJUSTMENT_REQUEST_FAILURE]: dieselReadjustmentFailure,
  [IndicesTypes.INFLATIONARY_READJUSTMENT_START]: inflationaryReadjustmentStart,
  [IndicesTypes.INFLATIONARY_READJUSTMENT_SUCCESS]: inflationaryReadjustmentSuccess,
  [IndicesTypes.INFLATIONARY_READJUSTMENT_REQUEST_FAILURE]: inflationaryReadjustmentFailure,
  [IndicesTypes.SUPPLIERS_REQUEST_FAILURE]: suppliersFailure,
  [IndicesTypes.INFLATIONARY_REQUEST_FAILURE]: inflationaryFailure,
});

import { put, takeLatest } from 'redux-saga/effects';
import { FORMALIZATION } from 'constants/endpoints';
import api from 'services/api';
import { GET } from 'constants/verbs';
import { formatDataFromRequest } from 'lib/contexts/requestsList';
import { RequestsListActions, RequestsListTypes } from './requestsList.ducks';

export function* getFormalization({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${FORMALIZATION}${params || ''}`,
    });
    const formattedData = formatDataFromRequest(response);
    yield put(RequestsListActions.requestsListSuccess({ data: formattedData }));
  } catch (error) {
    yield put(RequestsListActions.requestsListFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(RequestsListTypes.REQUESTS_LIST_REQUEST, getFormalization);
}

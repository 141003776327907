import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { MsalProvider } from '@azure/msal-react';
import { Tokens } from '@vli/locomotive-ds/dist';

import { store } from 'store';
import Route from 'constants/routes';

import GlobalStyle from 'components/structure/GlobalStyle';
import MessageToast from 'components/core/MessageToast';
import MessageModal from 'components/core/MessageModal';

import { AuthModal } from 'components/core/AuthModal';

const App = ({ pca }) => (
  <Provider store={store}>
    <GlobalStyle />
    <ThemeProvider theme={Tokens}>
      <MsalProvider instance={pca}>
        <Route />
        <AuthModal />
      </MsalProvider>
    </ThemeProvider>
    <MessageToast />
    <MessageModal />
  </Provider>
);

export default App;

import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { RESOURCE_USER } from 'constants/endpoints';
import * as utility from 'lib/core/utility';
import { formatErrorMessages } from 'lib/contexts/user';
import { GET, PATCH, PUT, POST } from 'constants/verbs';
import * as ROUTES from 'constants/urls';
import { MessagesActions } from 'store/messages/messages.ducks';
import { history } from 'constants/routes';
import messages from 'constants/messages';
import { UserActions, UserTypes } from './user.ducks';

export function* getUsers({ params, filter }) {
  try {
    const {
      data: { data: users },
    } = yield api({
      method: GET,
      url: `${RESOURCE_USER}?${params}`,
    });

    yield put(UserActions.fetchUsersSuccess(users, filter));
  } catch (err) {
    yield put(UserActions.fetchUsersFailure(err));
  }
}

export function* getFilterUsers({ params, filter, type }) {
  try {
    const {
      data: { data: users },
    } = yield api({
      method: GET,
      url: `${RESOURCE_USER}/filter?${params}`,
    });

    yield put(UserActions.fetchUsersFilterSuccess(users, filter));
  } catch (err) {
    yield put(UserActions.userRequestFailure(err, type));
  }
}

export function* getUser({ payload }) {
  try {
    const {
      data: { data: users },
    } = yield api({
      method: GET,
      url: `${RESOURCE_USER}/${payload}`,
    });
    yield put(UserActions.getUserSuccess(users));
  } catch (err) {
    yield put(UserActions.getUserFailure(err));
  }
}

export function* updateUser({ payload, type }) {
  try {
    yield api({
      method: PUT,
      url: RESOURCE_USER,
      data: payload,
    });

    yield put(UserActions.updateUserSuccess());
    yield put(
      MessagesActions.open({
        title: messages.messages.users[PUT].title,
        type: 'success',
        contentText: messages.messages.users[PUT].message,
      }),
    );
    yield history.push(ROUTES.USER_LIST);
  } catch (error) {
    let message = messages.messages.users[PUT].errorMessage;
    if (error.response?.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.users[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(UserActions.userRequestFailure(message, type));
  }
}

export function* deleteUser({ payload, callback, type }) {
  try {
    yield api({
      method: PATCH,
      url: `${RESOURCE_USER}/${payload}`,
    });
    yield put(UserActions.deleteUserSuccess());
    yield put(
      MessagesActions.open({
        title: messages.messages.users[PATCH].title,
        type: 'success',
        contentText: messages.messages.users[PATCH].message,
      }),
    );
    yield callback();
  } catch (error) {
    let message = messages.messages.users[PATCH].errorMessage;
    if (error.response?.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.users[PATCH].errorTitle,
        typeModal: 'error',
        contentModalText: formatErrorMessages(message),
      }),
    );

    yield put(UserActions.userRequestFailure(message, type));
  }
}

export function* createUser({ payload, type }) {
  try {
    yield api({
      method: POST,
      url: RESOURCE_USER,
      data: payload,
    });
    yield put(UserActions.createUserSuccess());
    yield put(
      MessagesActions.open({
        title: messages.messages.users[POST].title,
        type: 'success',
        contentText: messages.messages.users[POST].message,
      }),
    );
    yield history.push(ROUTES.USER_LIST);
  } catch (error) {
    let message = messages.messages.users[POST].errorMessage;
    if (error.response?.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.users[POST].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(UserActions.userRequestFailure(message, type));
  }
}

export function* listFormalizationsUser() {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_USER}/formalizations`,
    });

    yield put(UserActions.listFormalizationsUserSuccess(response.data.data));
  } catch (error) {
    yield put(UserActions.listFormalizationsUserFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(UserTypes.FETCH_USERS, getUsers);
  yield takeLatest(UserTypes.FETCH_USERS_FILTER, getFilterUsers);
  yield takeLatest(UserTypes.UPDATE_USER, updateUser);
  yield takeLatest(UserTypes.CREATE_USER, createUser);
  yield takeLatest(UserTypes.GET_USER, getUser);
  yield takeLatest(UserTypes.DELETE_USER, deleteUser);
  yield takeLatest(UserTypes.LIST_FORMALIZATIONS_USER, listFormalizationsUser);
}

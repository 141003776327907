import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { createActions, createReducer } from 'reduxsauce';

export const {
  Types: ReportContractItemsTypes,
  Creators: ReportContractItemsActions,
} = createActions({
  listReportContractItems: ['params'],
  listReportContractItemsFilter: ['params', 'rawFilter'],
  listReportContractItemsSuccess: ['payload'],
  getReportContractItems: ['payload'],
  getReportContractItemsSuccess: ['payload'],
  updateReportContractItems: ['payload', 'callback'],
  updateReportContractItemsSuccess: [''],
  updateReportServices: ['payload', 'callback'],
  updateReportServicesSuccess: ['payload'],
  updateReportPiscofins: ['payload', 'callback'],
  updateReportPiscofinsSuccess: ['payload'],
  updateReportHistoric: ['contractId', 'callback'],
  updateReportHistoricSuccess: [],
  reportContractItemsRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  reportContractItemsList: [],
  params: null,
  filter: null,
  details: null,
  error: null,
  reportContractItemsRequests: {
    [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS]: REQUEST_NOT_STARTED,
  },
};

const listReportContractItems = (state) => ({
  ...INITIAL_STATE,
  filter: state.filter,
  reportContractItemsRequests: {
    ...INITIAL_STATE.reportContractItemsRequests,
    [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS]: REQUEST_PENDING,
  },
});

const listReportContractItemsFilter = (state, { params, rawFilter }) => ({
  ...state,
  ...INITIAL_STATE,
  params,
  filter: rawFilter,
  reportContractItemsRequests: {
    ...INITIAL_STATE.reportContractItemsRequests,
    [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS]: REQUEST_RESOLVED,
  },
});

const listReportContractItemsSuccess = (state, { payload }) => ({
  ...state,
  reportContractItemsList: payload,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS]: REQUEST_RESOLVED,
  },
});

const getReportContractItems = () => ({
  ...INITIAL_STATE,
  reportContractItemsRequests: {
    ...INITIAL_STATE.reportContractItemsRequests,
    [ReportContractItemsTypes.GET_REPORT_CONTRACT_ITEMS]: REQUEST_PENDING,
  },
});

const getReportContractItemsSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.GET_REPORT_CONTRACT_ITEMS]: REQUEST_RESOLVED,
  },
});

const updateReportContractItems = (state) => ({
  ...state,
  reportContractItemsRequests: {
    ...INITIAL_STATE.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_CONTRACT_ITEMS]: REQUEST_PENDING,
  },
});

const updateReportContractItemsSuccess = (state) => ({
  ...state,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_CONTRACT_ITEMS]: REQUEST_RESOLVED,
  },
});

const updateReportServices = (state) => ({
  ...state,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_SERVICES]: REQUEST_PENDING,
  },
});

const updateReportServicesSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_SERVICES]: REQUEST_RESOLVED,
  },
});

const updateReportPiscofins = (state) => ({
  ...state,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_PISCOFINS]: REQUEST_PENDING,
  },
});

const updateReportPiscofinsSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_PISCOFINS]: REQUEST_RESOLVED,
  },
});

const updateReportHistoric = (state) => ({
  ...state,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_HISTORIC]: REQUEST_PENDING,
  },
});

const updateReportHistoricSuccess = (state) => ({
  ...state,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [ReportContractItemsTypes.UPDATE_REPORT_HISTORIC]: REQUEST_RESOLVED,
  },
});

const reportContractItemsRequestFailure = (
  state,
  { error: { response }, originalType },
) => ({
  ...state,
  error: response,
  reportContractItemsRequests: {
    ...state.reportContractItemsRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS]: listReportContractItems,
  [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS_FILTER]: listReportContractItemsFilter,
  [ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS_SUCCESS]: listReportContractItemsSuccess,
  [ReportContractItemsTypes.GET_REPORT_CONTRACT_ITEMS]: getReportContractItems,
  [ReportContractItemsTypes.GET_REPORT_CONTRACT_ITEMS_SUCCESS]: getReportContractItemsSuccess,
  [ReportContractItemsTypes.UPDATE_REPORT_CONTRACT_ITEMS]: updateReportContractItems,
  [ReportContractItemsTypes.UPDATE_REPORT_CONTRACT_ITEMS_SUCCESS]: updateReportContractItemsSuccess,
  [ReportContractItemsTypes.UPDATE_REPORT_SERVICES]: updateReportServices,
  [ReportContractItemsTypes.UPDATE_REPORT_SERVICES_SUCCESS]: updateReportServicesSuccess,
  [ReportContractItemsTypes.UPDATE_REPORT_PISCOFINS]: updateReportPiscofins,
  [ReportContractItemsTypes.UPDATE_REPORT_PISCOFINS_SUCCESS]: updateReportPiscofinsSuccess,
  [ReportContractItemsTypes.UPDATE_REPORT_HISTORIC]: updateReportHistoric,
  [ReportContractItemsTypes.UPDATE_REPORT_HISTORIC_SUCCESS]: updateReportHistoricSuccess,
  [ReportContractItemsTypes.REPORT_CONTRACT_ITEMS_REQUEST_FAILURE]: reportContractItemsRequestFailure,
});

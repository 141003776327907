import { put, takeLatest } from 'redux-saga/effects';
import { HEALTH } from 'constants/endpoints';
import api from 'services/api';
import { GET } from 'constants/verbs';
import { StatusTypes, StatusActions } from './status.ducks';

export function* listStatus() {
  try {
    const response = yield api({
      method: GET,
      url: HEALTH,
    });
    yield put(StatusActions.listStatusSuccess(response.data));
  } catch (error) {
    yield put(StatusActions.statusRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(StatusTypes.LIST_STATUS, listStatus);
}

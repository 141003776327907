import { put, takeLatest } from 'redux-saga/effects';
import { ACTIVE_CONTRACTS } from 'constants/endpoints';
import api from 'services/api';
import { GET } from 'constants/verbs';
import {
  ActiveContractTypes,
  ActiveContractActions,
} from './activeContracts.ducks';

export function* activeContract({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: `${ACTIVE_CONTRACTS}${payload ?? ''}`,
    });

    yield put(ActiveContractActions.activeContractSuccess(response.data.data));
  } catch (error) {
    yield put(ActiveContractActions.activeContractFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(ActiveContractTypes.ACTIVE_CONTRACT, activeContract);
}

import React from 'react';
import PropTypes from 'prop-types';

import * as S from './FormalizationItem.styles';

const FormalizationItem = ({ title, content }) => {
  return (
    <div>
      <S.Title>{title}</S.Title>
      <S.Content>{content}</S.Content>
    </div>
  );
};

FormalizationItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

FormalizationItem.defaultProps = {
  title: '',
};

export default FormalizationItem;

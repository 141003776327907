import { createActions, createReducer } from 'reduxsauce';
import { getLocalsDiesel } from 'lib/core/utility';
import { setPriceActionItem } from 'lib/contexts/contracts';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ContractDetailTypes,
  Creators: ContractDetailActions,
} = createActions({
  getContractDetail: ['payload'],
  getContractDetailSuccess: ['payload'],
  getContractDetailFailure: ['error'],
  setPriceAction: ['payload'],
  approvePrice: ['data', 'callback'],
  approvePriceSuccess: [],
  approvePriceFailure: ['error'],
  renegotiatePrice: ['data', 'callback'],
  renegotiatePriceSuccess: [],
  renegotiatePriceFailure: ['error'],
  extendContract: ['payload', 'callback'],
  extendContractSuccess: [],
  extendContractFailure: ['error'],
  correctionPrice: ['data', 'callback'],
  correctionPriceSuccess: [],
  correctionPriceFailure: ['error'],
  sendImmediately: ['data', 'callback'],
  sendImmediatelySuccess: [],
  sendImmediatelyFailure: ['error'],
  getPreviousMonth: ['data', 'callback'],
  getPreviousMonthSuccess: [],
  getPreviousMonthFailure: ['error'],
  sendManuallyValue: ['data', 'callback'],
  sendManuallyValueSuccess: [],
  sendManuallyValueFailure: ['error'],
});

const INITIAL_STATE = {
  contractDetails: {
    details: {
      railwayItems: [],
    },
    filter: {
      viewScenario: 'CurrentPrice',
      id: '',
    },
    error: [],
    dieselWasReajusted: [],
    segment: '',
    segmentId: null,
  },
  priceActions: {
    action: null,
    modal: false,
    id: null,
    item: {},
    items: [],
  },
  data: {},
  contractDetailRequests: {
    [ContractDetailTypes.GET_CONTRACT_DETAIL]: REQUEST_NOT_STARTED,
    [ContractDetailTypes.APPROVE_PRICE]: REQUEST_NOT_STARTED,
    [ContractDetailTypes.RENEGOTIATE_PRICE]: REQUEST_NOT_STARTED,
    [ContractDetailTypes.EXTEND_CONTRACT]: REQUEST_NOT_STARTED,
    [ContractDetailTypes.CORRECTION_PRICE]: REQUEST_NOT_STARTED,
    [ContractDetailTypes.SEND_IMMEDIATELY]: REQUEST_NOT_STARTED,
  },
};

const getContractDetail = (state, { payload }) => ({
  ...INITIAL_STATE,
  contractDetails: {
    ...state.contractDetails,
    filter: {
      id: payload.id,
      viewScenario: payload.viewScenario,
    },
  },
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.GET_CONTRACT_DETAIL]: REQUEST_PENDING,
  },
});

const getContractDetailSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  contractDetails: {
    ...state.contractDetails,
    details: payload,
    error: [],
    dieselWasReajusted: getLocalsDiesel(payload.railwayItems),
    segment: payload.segment,
    segmentId: payload.segmentId,
  },
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.GET_CONTRACT_DETAIL]: REQUEST_RESOLVED,
  },
});

const getContractDetailFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.GET_CONTRACT_DETAIL]: REQUEST_REJECTED,
  },
});

const setPriceAction = (state, { payload }) => ({
  ...state,
  priceActions: {
    ...state.priceActions,
    action: payload.action,
    modal: payload.modal,
    id: payload.id,
    item: payload.item,
    items: payload?.groupedItems || setPriceActionItem(state, payload),
  },
});

const approvePrice = (state) => ({
  ...state,

  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.APPROVE_PRICE]: REQUEST_PENDING,
  },
});

const approvePriceSuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.APPROVE_PRICE]: REQUEST_RESOLVED,
  },
});

const approvePriceFailure = (state, { error }) => ({
  ...state,
  error,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.APPROVE_PRICE]: REQUEST_REJECTED,
  },
});

const renegotiatePrice = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.RENEGOTIATE_PRICE]: REQUEST_PENDING,
  },
});

const renegotiatePriceSuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.RENEGOTIATE_PRICE]: REQUEST_RESOLVED,
  },
});

const renegotiatePriceFailure = (state, { error }) => ({
  ...state,
  error,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.RENEGOTIATE_PRICE]: REQUEST_REJECTED,
  },
});

const extendContract = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.EXTEND_CONTRACT]: REQUEST_PENDING,
  },
});

const extendContractSuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.EXTEND_CONTRACT]: REQUEST_RESOLVED,
  },
});

const extendContractFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.EXTEND_CONTRACT]: REQUEST_REJECTED,
  },
});

const correctionPrice = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.CORRECTION_PRICE]: REQUEST_PENDING,
  },
});

const correctionPriceSuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.CORRECTION_PRICE]: REQUEST_RESOLVED,
  },
});

const correctionPriceFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.CORRECTION_PRICE]: REQUEST_REJECTED,
  },
});

const sendImmediately = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.SEND_IMMEDIATELY]: REQUEST_PENDING,
  },
});

const sendImmediatelySuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.SEND_IMMEDIATELY]: REQUEST_RESOLVED,
  },
});

const sendImmediatelyFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.SEND_IMMEDIATELY]: REQUEST_REJECTED,
  },
});

const getPreviousMonth = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.GET_PREVIOUS_MONTH]: REQUEST_PENDING,
  },
});

const getPreviousMonthSuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.GET_PREVIOUS_MONTH]: REQUEST_RESOLVED,
  },
});

const getPreviousMonthFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.GET_PREVIOUS_MONTH]: REQUEST_REJECTED,
  },
});

const sendManuallyValue = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.SEND_MANUALLY_VALUE]: REQUEST_PENDING,
  },
});

const sendManuallyValueSuccess = (state) => ({
  ...state,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.SEND_MANUALLY_VALUE]: REQUEST_RESOLVED,
  },
});

const sendManuallyValueFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractDetailRequests: {
    ...state.contractDetailRequests,
    [ContractDetailTypes.SEND_MANUALLY_VALUE]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ContractDetailTypes.GET_CONTRACT_DETAIL]: getContractDetail,
  [ContractDetailTypes.GET_CONTRACT_DETAIL_SUCCESS]: getContractDetailSuccess,
  [ContractDetailTypes.GET_CONTRACT_DETAIL_FAILURE]: getContractDetailFailure,
  [ContractDetailTypes.SET_PRICE_ACTION]: setPriceAction,
  [ContractDetailTypes.APPROVE_PRICE]: approvePrice,
  [ContractDetailTypes.APPROVE_PRICE_SUCCESS]: approvePriceSuccess,
  [ContractDetailTypes.APPROVE_PRICE_FAILURE]: approvePriceFailure,
  [ContractDetailTypes.RENEGOTIATE_PRICE]: renegotiatePrice,
  [ContractDetailTypes.RENEGOTIATE_PRICE_SUCCESS]: renegotiatePriceSuccess,
  [ContractDetailTypes.RENEGOTIATE_PRICE_FAILURE]: renegotiatePriceFailure,
  [ContractDetailTypes.EXTEND_CONTRACT]: extendContract,
  [ContractDetailTypes.EXTEND_CONTRACT_SUCCESS]: extendContractSuccess,
  [ContractDetailTypes.EXTEND_CONTRACT_FAILURE]: extendContractFailure,
  [ContractDetailTypes.CORRECTION_PRICE]: correctionPrice,
  [ContractDetailTypes.CORRECTION_PRICE_SUCCESS]: correctionPriceSuccess,
  [ContractDetailTypes.CORRECTION_PRICE_FAILURE]: correctionPriceFailure,
  [ContractDetailTypes.SEND_IMMEDIATELY]: sendImmediately,
  [ContractDetailTypes.SEND_IMMEDIATELY_SUCCESS]: sendImmediatelySuccess,
  [ContractDetailTypes.SEND_IMMEDIATELY_FAILURE]: sendImmediatelyFailure,
  [ContractDetailTypes.GET_PREVIOUS_MONTH]: getPreviousMonth,
  [ContractDetailTypes.GET_PREVIOUS_MONTH_SUCCESS]: getPreviousMonthSuccess,
  [ContractDetailTypes.GET_PREVIOUS_MONTH_FAILURE]: getPreviousMonthFailure,
  [ContractDetailTypes.SEND_MANUALLY_VALUE]: sendManuallyValue,
  [ContractDetailTypes.SEND_MANUALLY_VALUE_SUCCESS]: sendManuallyValueSuccess,
  [ContractDetailTypes.SEND_MANUALLY_VALUE_FAILURE]: sendManuallyValueFailure,
});

import * as utility from 'lib/core/utility';

export const getFilterParams = (data) => {
  const filterFields = {
    filterFields: {
      PageIndex: data?.pageIndex,
      PageSize: data?.pageSize,
    },
  };

  return `${utility.autoListingParamsGet(filterFields)}`;
};

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from 'constants/request';

export const {
  Types: CompensationTypes,
  Creators: CompensationActions,
} = createActions({
  compensationById: ['formalizationId'],
  compensationByIdSuccess: ['payload'],
  compensationByIdFailure: ['error'],
});

const INITIAL_STATE = {
  compensationData: {},
  error: null,
  compensationRequest: {
    [CompensationTypes.COMPENSATION_BY_ID]: REQUEST_NOT_STARTED,
  },
};

const compensationById = () => ({
  ...INITIAL_STATE,
  compensationRequest: {
    ...INITIAL_STATE.compensationRequest,
    [CompensationTypes.COMPENSATION_BY_ID]: REQUEST_PENDING,
  },
});

const compensationByIdSuccess = (state, { payload }) => ({
  ...state,
  compensationData: payload.data,
  compensationRequest: {
    ...state.compensationRequest,
    [CompensationTypes.COMPENSATION_BY_ID]: REQUEST_RESOLVED,
  },
});

const compensationByIdFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  compensationRequest: {
    ...state.compensationRequest,
    [CompensationTypes.COMPENSATION_BY_ID]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [CompensationTypes.COMPENSATION_BY_ID]: compensationById,
  [CompensationTypes.COMPENSATION_BY_ID_SUCCESS]: compensationByIdSuccess,
  [CompensationTypes.COMPENSATION_BY_ID_FAILURE]: compensationByIdFailure,
});

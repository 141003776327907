import styled from 'styled-components';
import { Text, Tokens } from '@vli/locomotive-ds';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};
`;

export const FormFields = styled.div`
  display: flex;
  align-items: center;
  gap: ${Tokens.spacing.lg};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.xs};
`;

export const ErrorText = styled(Text)`
  color: ${Tokens.colors.red400};
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination, useExpanded } from 'react-table';

import { Footer } from '@vli/locomotive-ds/dist';

import * as S from './Table.styles';

const Table = ({
  columns: userColumns,
  data,
  footer,
  classes,
  footerAttrs,
  isSubComponent,
  isEditMode,
  pageCount,
  onPageChange,
  onPerPageChange,
  totalItems,
  renderRowSubComponent,
  pageIndex,
  customList,
  handleColumnToSort,
  isServerSort,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    setPageSize,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns: userColumns,
      data,
      manualSortBy: isServerSort,
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  const onColumnHeaderClick = (column) => {
    handleColumnToSort(column.id);
  };

  return (
    <>
      <S.Table data-testid="TableTest" {...getTableProps()} className={classes}>
        <S.Thead>
          {headerGroups.map((headerGroup) => (
            <S.Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <S.Th
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      style: {
                        width: column.width,
                        textAlign: column.align,
                      },
                    },
                  ])}
                  {...(isServerSort && {
                    onClick: () => onColumnHeaderClick(column),
                  })}
                >
                  <S.IconContainer align={column.align}>
                    <span>{column.render('Header')}</span>
                  </S.IconContainer>
                </S.Th>
              ))}
            </S.Tr>
          ))}
        </S.Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                {isEditMode ? (
                  <S.Tr
                    {...row.getRowProps()}
                    className={row.original.editMode ? 'editMode' : 'opacity'}
                  >
                    {row.cells.map((cell) => (
                      <S.Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </S.Td>
                    ))}
                  </S.Tr>
                ) : (
                  <S.Tr
                    {...row.getRowProps()}
                    className={row.depth === 1 && 'expanded'}
                  >
                    {row.cells.map((cell) => (
                      <S.Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </S.Td>
                    ))}
                  </S.Tr>
                )}
                {row.isExpanded && isSubComponent && (
                  <S.Tr>
                    <S.Td padding="0px" colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </S.Td>
                  </S.Tr>
                )}
              </>
            );
          })}
        </tbody>
      </S.Table>
      {footer && (
        <Footer
          onPageChange={(newPage) => {
            gotoPage(newPage);
            onPageChange(newPage);
          }}
          type="unnumbered"
          totalPages={pageCount}
          currentPage={pageIndex}
          customList={customList}
          totalItems={totalItems}
          onChangePageSize={(option) => {
            setPageSize(option.value);
            onPerPageChange(option.value);
          }}
          style={{ marginTop: '1em' }}
          {...footerAttrs}
        />
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onUpdateTable: PropTypes.func,
  tableAttrs: PropTypes.object,
  renderRowSubComponent: PropTypes.func,
  onPageChange: PropTypes.func,
  onPerPageChange: PropTypes.func,
  isSubComponent: PropTypes.bool,
  isEditMode: PropTypes.bool,
  footerAttrs: PropTypes.object,
  sortBy: PropTypes.arrayOf({
    id: PropTypes.string,
    desc: PropTypes.bool,
  }),
  width: PropTypes.string,
  height: PropTypes.string,
  classes: PropTypes.string,
  footer: PropTypes.bool,
  pageCount: PropTypes.number,
  totalItems: PropTypes.number,
  pageIndex: PropTypes.number,
  customList: PropTypes.array,
  handleColumnToSort: PropTypes.func,
  isServerSort: PropTypes.bool,
};

Table.defaultProps = {
  onUpdateTable: () => {},
  columns: [],
  data: [],
  tableAttrs: {},
  footerAttrs: {},
  sortBy: [],
  width: '1136px',
  height: '560px',
  classes: '',
  isSubComponent: false,
  isEditMode: false,
  pageIndex: 0,
  renderRowSubComponent: () => {},
  onPageChange: () => {},
  onPerPageChange: () => {},
  footer: false,
  pageCount: 1,
  totalItems: 0,
  customList: [],
  handleColumnToSort: () => {},
  isServerSort: false,
};

export default Table;

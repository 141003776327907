const messages = {
  login: {
    404: 'Login não encontrado.',
    403: 'Você não está autorizado para acessar o sistema.',
    401: 'Você não está autorizado para acessar o sistema.',
    200: 'Login realizado com sucesso.',
    400: 'Sua sessão foi expirada, por favor entre novamente.',
  },
  diesels: {
    error: ['Erro ao salvar os reajustes. Por favor, tente novamente.'],
    success: ['Reajuste do diesel realizado!'],
    hasDieselWasReajusted: [
      'A variação do diesel ainda não está disponível para cálculo da próxima aplicação na(s) seguinte(s) base(s): ',
    ],
  },
  inflation: {
    error: ['Erro ao salvar os reajustes. Por favor, tente novamente.'],
    success: [
      'O ajuste dos preços do itens ferroviários impactados serão calculados em breve.',
    ],
  },
  users: {
    POST: {
      title: 'Usuário cadastrado com sucesso',
      message: `Enviamos e-mail informando o usuário sobre seu novo acesso.`,
      errorTitle: 'Erro ao criar usuário',
      errorMessage: ['Erro ao criar usuário'],
    },
    PUT: {
      title: 'Usuário editado com sucesso',
      message: `Edição salva com sucesso.`,
      errorTitle: 'Erro ao editar usuário',
      errorMessage: ['Erro ao editar usuário'],
    },
    PATCH: {
      title: 'Usuário inativado/reativado com sucesso',
      message: 'Inativação/Reativação realizada com sucesso',
      errorTitle: 'Erro ao inativar/reativar usuário',
      errorMessage: ['Erro ao inativar/reativar usuário'],
    },
  },
  profiles: {
    POST: {
      title: 'Perfil cadastrado com sucesso',
      message: `Perfil cadastro com sucesso.`,
      errorTitle: 'Erro ao criar perfil',
      errorMessage: ['Erro ao criar perfil'],
    },
    PUT: {
      title: 'Perfil editado com sucesso',
      message: `As edições referente ao foram salvas com sucesso.`,
      errorTitle: 'Erro ao editar perfil',
      errorMessage: ['Erro ao editar perfil'],
    },
    DELETE: {
      title: 'Perfil excluído com sucesso',
      message: 'Exclusão realizada com sucesso',
      errorTitle: 'Erro ao excluir o perfil',
      errorMessage: ['Erro ao excluir o perfil'],
    },
  },
  local: {
    PUT: {
      title: 'Editado com sucesso',
      message: `A base de diesel foi editada com sucesso!`,
      errorTitle: 'Erro ao editar base de diesel',
      errorMessage: ['Erro ao editar base de diesel'],
    },
    PATCH: {
      title: 'Status alterado com sucesso',
      message: `O Status da base de diesel foi alterado com sucesso!`,
      errorTitle: 'Erro ao alterar status da base de diesel',
      errorMessage: ['Erro ao alterar status da base de diesel'],
    },
    DELETE: {
      title: 'Base de diesel desativada com sucesso',
      message: 'Exclusão realizada com sucesso',
      errorTitle: 'Erro ao excluir o base de diesel',
      errorMessage: ['Erro ao excluir o base de diesel'],
    },
  },
  newItems: {
    POST: {
      title: 'Sucesso',
      message: `O item ferroviário foi cadastrado com sucesso!`,
      errorTitle: 'Erro',
      errorMessage: ['Erro ao cadastrar item ferroviário'],
    },
    PUT: {
      title: 'Sucesso',
      message: `O item ferroviário foi atualizado com sucesso!`,
      errorTitle: 'Erro',
      errorMessage: ['Erro ao atualizar item ferroviário'],
    },
  },
  emailNotification: {
    POST: {
      title: 'Dados salvo com sucesso',
      message: `Destinatários incluídos com sucesso!`,
      errorTitle: 'Erro',
      errorMessage: ['Erro ao cadastrar destinatários'],
    },
  },
  reportContractItems: {
    PUT: {
      title: 'Dados salvos com sucesso',
      message: 'Itens no relatório selecionados com sucesso!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao atualizar seleção de itens do contrato'],
    },
    PATCH: {
      title: 'Dados atualizados com sucesso',
      message: 'Exibição de quebra de serviço atualizado com sucesso!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao atualizar exibição de quebra de serviço'],
    },
  },
  reportContractItemsPiscofins: {
    PATCH: {
      title: 'Dados atualizados com sucesso',
      message: 'Exibição de tarifa Piscofins atualizado com sucesso!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao atualizar exibição de tarifa Piscofins'],
    },
  },
  segment: {
    POST: {
      title: 'Dados salvos com sucesso',
      message: 'O segmento foi cadastrado com sucesso!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao cadastrar segmento'],
    },
    PUT: {
      title: 'Editado com sucesso',
      message: 'O segmento foi editado com sucesso!',
      errorTitle: 'Erro ao editar segmento',
      errorMessage: ['Erro ao editar segmento'],
    },
    PATCH: {
      title: 'Status alterado com sucesso',
      message: 'O Status do segmento foi alterado com sucesso!',
      errorTitle: 'Erro ao alterar status do segmento',
      errorMessage: ['Erro ao alterar status do segmento'],
    },
  },
  contractReport: {
    POST: {
      title: 'Dados enviados com sucesso',
      message: 'O relatório estará disponível em breve!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao gerar relatório'],
    },
    DELETE: {
      title: 'Relatório deletado com sucesso',
      message: 'Exclusão realizada com sucesso!',
      errorTitle: 'Erro ao excluir o relatório',
      errorMessage: ['Erro ao excluir o relatório'],
    },
  },
  supplier: {
    POST: {
      title: 'Dados salvos com sucesso',
      message: 'O fornecedor foi cadastrado com sucesso!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao cadastrar fornecedor'],
    },
    PUT: {
      title: 'Editado com sucesso',
      message: 'O fornecedor foi editado com sucesso!',
      errorTitle: 'Erro ao editar fornecedor',
      errorMessage: ['Erro ao editar fornecedor'],
    },
    PATCH: {
      title: 'Status alterado com sucesso',
      message: 'O Status do fornecedor foi alterado com sucesso!',
      errorTitle: 'Erro ao alterar status do fornecedor',
      errorMessage: ['Erro ao alterar status do fornecedor'],
    },
  },
  railwayItem: {
    PUT: {
      title: 'Editado com sucesso',
      message: 'O item ferroviário foi editado com sucesso!',
      errorTitle: 'Erro ao editar item ferroviário',
      errorMessage: ['Erro ao editar item ferroviário'],
    },
  },
  resendItems: {
    POST: {
      title: 'Itens enviados com sucesso',
      message: 'Os itens ferroviários foram enviados com sucesso!',
      errorTitle: 'Erro ao enviar itens ferroviários',
      errorMessage: ['Erro ao enviar itens ferroviários'],
    },
  },
  models: {
    GET: {
      title: 'Planilha baixada',
      message: 'Download realizado com sucesso!',
      errorTitle: 'Erro',
      errorMessage: ['Erro ao fazer download da planilha'],
    },
    POST: {
      title: 'Planilha carregada',
      message: 'Upload realizado com sucesso!',
      errorTitle: 'Arquivo não suportado',
      errorMessage: [
        'O arquivo selecionado não é compatível, faça novamente o envio da planilha. Formatos suportados: .XLSX ou .XLS.',
      ],
    },
  },
};

module.exports = {
  messages,
};

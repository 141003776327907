import * as utility from 'lib/core/utility';
import * as constants from 'constants/constants';

export const getFilterParams = (data) => {
  const filterFields = {
    filterFields: {
      CustomerName: data.clientName,
      ContractCode: data.contractName,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};
export const getFilterManagerParams = (data) => {
  const filterFields = {
    filterFields: {
      localName: data.localName,
      typeLocal: data.typeLocal,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const optionsTypeLocal = [
  {
    label: 'Selecione',
    value: '',
  },
  {
    label: 'Posto',
    value: constants.default.STATION_ID,
  },
  {
    label: 'Refinaria',
    value: constants.default.REFINARY_ID,
  },
];

import styled from 'styled-components';
import { Tokens, Modal } from '@vli/locomotive-ds';

export const ModalContainer = styled(Modal)`
  width: 100%;
`;

export const Title = styled.h2`
  font: ${Tokens.fonts.textRegularMedium};
  margin-bottom: ${Tokens.spacing.xs};
`;

export const Text = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
`;

export const Box = styled.div`
  min-width: 500px;
  padding: ${Tokens.spacing.sm} 0;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${Tokens.spacing.sm};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    font: ${Tokens.fonts.textRegularSmall};
    font-weight: 400;
    color: ${Tokens.colors.gray400};
  }

  small {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.gray500};
  }
`;

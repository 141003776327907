import styled from 'styled-components';
import { Tokens, Modal } from '@vli/locomotive-ds';

export const ModalContainer = styled(Modal)`
  width: 100%;

  #divider {
    display: none;
    margin: 0;
  }
`;

export const Scroll = styled.div`
  width: 100%;
  height: 480px;

  position: relative;

  margin-top: ${Tokens.spacing.sm};

  max-height: 480px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${Tokens.colors.accentPrimaryLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 ${Tokens.spacing.sm} 0 0;
`;

export const DateContainer = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  padding: ${Tokens.spacing.md} 0;
  border-top: 1px solid ${Tokens.colors.gray200};
`;

export const CompensationContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};

  padding: ${Tokens.spacing.md} 0;
  border-top: 1px solid ${Tokens.colors.gray200};
`;

export const FlowContainer = styled.section`
  width: 100%;

  padding: ${Tokens.spacing.md} 0;
  border-top: 1px solid ${Tokens.colors.gray200};
`;

export const GridFlow = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const Row = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 80%;
`;

export const FlowByModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  padding-right: ${(props) => (props.hasDivider ? '40px' : 0)};

  & + div {
    padding-left: 40px;
    border-left: 1px solid ${Tokens.colors.gray200};
  }
`;

export const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.xs};

  p {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.gray400};
    font-weight: 400;
  }
`;

export const DataLine = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  gap: ${Tokens.spacing.sm};
`;

export const DataLineItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.gray400};
    font-weight: 400;
  }

  small {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.grayBlack};
    font-weight: 400;

    padding: 0 ${Tokens.spacing.sm};
  }
`;

export const FlowCard = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  padding: ${Tokens.spacing.sm};
  border: 1px solid ${Tokens.colors.accentPrimaryLight};
  border-radius: 8px;

  background-color: #f5f7fa;
`;

export const FlowCardItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.gray300};
    font-weight: 400;
  }

  svg {
    width: 10px;
    height: 10px;

    path {
      transform: scale(0.5);
    }
  }

  small {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.gray500};
    font-weight: 400;
  }
`;

export const Title = styled.h2`
  font: ${Tokens.fonts.textRegularLarge};
  color: ${Tokens.colors.accentPrimaryDark};
`;

export const FlowCardGroup = styled.div`
  border: 1px solid ${Tokens.colors.accentPrimaryLight};
  border-radius: 8px;
`;

export const FlowCardGroupHeader = styled.div`
  background-color: #f5f7fa;

  padding: ${Tokens.spacing.sm};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h2 {
    font: ${Tokens.fonts.headingRegularH6};
    color: ${Tokens.colors.gray500};
  }
`;

export const FlowCardGroupContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};

  padding: ${Tokens.spacing.sm};

  span {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.gray400};
    font-weight: 400;
  }
`;

export const FlowInfo = styled.div`
  display: flex;
  gap: ${Tokens.spacing.xs};
  align-items: center;

  svg {
    path:first-of-type {
      fill: ${Tokens.colors.yellow300};
    }
  }
`;

export const FlowCardGroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Tokens.spacing.xs};
`;

export const FlowTag = styled.div`
  padding: ${Tokens.spacing.xs};
  border-radius: 8px;

  background-color: ${Tokens.colors.blue100};
  color: ${Tokens.colors.accentPrimaryDark};

  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
`;

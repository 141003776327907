import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, Card } from '@vli/locomotive-ds/dist';

import Loading from 'components/core/Loading';
import TableContainer from 'components/core/TableContainer';
import Table from 'components/core/Table';

import { isPending, isFinished } from 'lib/core/request';

import { StatusActions } from 'store/status/status.ducks';

import Columns from './Columns';

const StatusList = () => {
  const dispatch = useDispatch();
  const { statusRequests, statusList } = useSelector(({ status }) => status);
  useEffect(() => {
    dispatch(StatusActions.listStatus());
  }, [dispatch]);
  return (
    <TableContainer>
      {isPending(statusRequests.LIST_STATUS) && <Loading />}
      {statusList.length ? (
        <Table
          data-testid="statuslist-test"
          width="100%"
          footer={false}
          columns={Columns}
          data={statusList}
        />
      ) : (
        isFinished(statusRequests.LIST_STATUS) && (
          <Card>
            <EmptyState condensed={false} title="Nenhum status encontrado." />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default StatusList;

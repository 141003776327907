import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Text, Popover, Icon } from '@vli/locomotive-ds';

import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

import { hasPermissionToDownload } from 'lib/contexts/commercialNegotiations';

import { CommercialNegotiationsActions } from 'store/commercialNegotiations/commercialNegotiations.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();

  const handleDownloadAttachment = () => {
    if (row.original.commercialNegociationId) {
      const commercialNegotiationIds = row.original.commercialNegociationId;
      const payload = {
        commercialNegociationIds: [commercialNegotiationIds],
      };

      dispatch(
        CommercialNegotiationsActions.commercialNegotiationsDownload(payload),
      );
    }
  };

  const Options = (
    <S.OptionsContainer>
      {hasPermissionToDownload() && (
        <S.OptionItem
          data-testid="test-option-download"
          onClick={handleDownloadAttachment}
        >
          <Icon src={DownloadIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Download de anexo
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="cell-with-action-option-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      commercialNegociationId: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

CellWithActions.defaultProps = {
  row: {
    index: 0,
    original: {
      commercialNegociationId: '',
    },
  },
};

export default CellWithActions;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: RailwayItemsHistoryTypes,
  Creators: RailwayItemsHistoryActions,
} = createActions({
  listRailwayItemsHistory: ['params'],
  listRailwayItemsHistorySuccess: ['payload'],
  getCurvedFare: ['params'],
  getCurvedFareSuccess: ['payload'],
  getCurvedFareEmpty: [],
  closeModalCurved: [],
  railwayItemsHistoryRequestFailure: ['error'],
  closeRailwayItem: ['payload', 'callback'],
  closeRailwayItemSuccess: [],
  closeRailwayItemFailure: ['error'],
});

const INITIAL_STATE = {
  railwayItems: {
    historyList: [],
  },
  modalData: {
    curvedFares: [],
  },
  modalOpen: false,
  error: null,
  railwayItemsHistoryRequests: {
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_NOT_STARTED,
  },
};

const listRailwayItemsHistory = () => ({
  ...INITIAL_STATE,
  railwayItemsHistoryRequests: {
    ...INITIAL_STATE.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_PENDING,
  },
});

const listRailwayItemsHistorySuccess = (state, { payload }) => ({
  ...state,
  railwayItems: payload,
  railwayItemsHistoryRequests: {
    ...state.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_RESOLVED,
  },
});

const getCurvedFare = (state) => ({
  ...state,
  modalData: {
    curvedFares: [],
  },
  modalOpen: false,
  railwayItemsHistoryRequests: {
    ...INITIAL_STATE.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_PENDING,
  },
});

const getCurvedFareSuccess = (state, { payload }) => ({
  ...state,
  modalData: payload,
  modalOpen: true,
  railwayItemsHistoryRequests: {
    ...state.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_RESOLVED,
  },
});

const getCurvedFareEmpty = (state) => ({
  ...state,
  modalData: {
    curvedFares: [],
  },
  modalOpen: false,
  railwayItemsHistoryRequests: {
    ...state.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_RESOLVED,
  },
});

const railwayItemsHistoryFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  railwayItemsHistoryRequests: {
    ...state.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: REQUEST_REJECTED,
  },
});

const closeModalCurved = (state) => ({
  ...state,
  modalData: {
    curvedFares: [],
  },
  modalOpen: false,
});

const closeRailwayItem = () => ({
  ...INITIAL_STATE,
  railwayItemsHistoryRequests: {
    ...INITIAL_STATE.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM]: REQUEST_PENDING,
  },
});

const closeRailwayItemSuccess = (state) => ({
  ...state,
  railwayItemsHistoryRequests: {
    ...state.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM]: REQUEST_RESOLVED,
  },
});

const closeRailwayItemFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  railwayItemsHistoryRequests: {
    ...state.railwayItemsHistoryRequests,
    [RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY]: listRailwayItemsHistory,
  [RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY_SUCCESS]: listRailwayItemsHistorySuccess,
  [RailwayItemsHistoryTypes.GET_CURVED_FARE]: getCurvedFare,
  [RailwayItemsHistoryTypes.GET_CURVED_FARE_SUCCESS]: getCurvedFareSuccess,
  [RailwayItemsHistoryTypes.GET_CURVED_FARE_EMPTY]: getCurvedFareEmpty,
  [RailwayItemsHistoryTypes.CLOSE_MODAL_CURVED]: closeModalCurved,
  [RailwayItemsHistoryTypes.RAILWAY_ITEMS_HISTORY_REQUEST_FAILURE]: railwayItemsHistoryFailure,
  [RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM]: closeRailwayItem,
  [RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM_SUCCESS]: closeRailwayItemSuccess,
  [RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM_FAILURE]: closeRailwayItemFailure,
});

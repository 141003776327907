import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const ContainerTable = styled.div`
  position: relative;
`;

export const ContainerTableStick = styled.div`
  header {
    position: sticky;
    top: 60px;
    background-color: ${Tokens.colors.gray100};
  }
  table {
    thead,
    th {
      position: sticky;
      top: 130px;
    }
  }
`;

export const MainContainer = styled.div`
  min-height: 1000px;
`;

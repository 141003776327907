import React from 'react';
import { Header, Flex, Container } from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';
import ClientsTable from 'components/contexts/clients/ClientTable';

import useDimensions from 'hooks/useDimensions';

const breadcrumb = [
  {
    text: 'Clientes',
    href: null,
  },
];

const ClientsList = () => {
  const dimensions = useDimensions();

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Cliente" />
        </Flex>

        <ClientsTable />
      </Container>
    </Content>
  );
};

export default ClientsList;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Header,
  Flex,
  Container,
  Text,
  Button,
  Tabs,
} from '@vli/locomotive-ds/dist';

import { CONTRACTS } from 'constants/urls';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import DashboardHistory from 'components/contexts/railwayItemHistory/DashboardHistory';
import Content from 'components/core/content';
import RailwayItemsHistoryList from 'components/contexts/railwayItemHistory/RailwayItemHistoryList';
import Notification from 'components/core/Notification';
import ModalTariff from 'components/contexts/railwayItemHistory/ModalTariff';
import Loading from 'components/core/Loading';
import CloseItemModal from 'components/contexts/railwayItemHistory/CloseItemModal';

import { RailwayItemsHistoryActions } from 'store/railwayItemsHistory/railwayItemsHistory.ducks';

import { isPending, isFinished, isReject } from 'lib/core/request';
import { railwayItemStatus } from 'lib/core/utility';
import { getContractStatus } from 'lib/contexts/contracts';

import useDimensions from 'hooks/useDimensions';
import useQuery from 'hooks/useQuery';

import * as S from './RailwayItemsHistory.styles';

const RailwayItemsHistory = () => {
  const { railwayItems, railwayItemsHistoryRequests } = useSelector(
    ({ railwayItemsHistory }) => railwayItemsHistory,
  );

  const dimensions = useDimensions();
  const params = useParams();
  const dispatch = useDispatch();
  const query = useQuery();

  const tabs = query.get('groupedItems');

  const urlParam = useMemo(() => {
    const isContractClosed = getContractStatus({
      details: { endPeriod: railwayItems.endPeriodContract },
    });

    return isContractClosed ? 'encerrado' : 'ativo';
  }, [railwayItems]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${CONTRACTS}`,
    },
    {
      text: `${railwayItems.codeContract}`,
      href: `#${CONTRACTS}/${railwayItems.idContract}/${urlParam}`,
    },
  ];

  useEffect(() => {
    dispatch(
      RailwayItemsHistoryActions.listRailwayItemsHistory({
        ...params,
        status: railwayItemStatus[params.status],
      }),
    );
  }, [dispatch, params]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isRejected = isReject(railwayItemsHistoryRequests.CLOSE_RAILWAY_ITEM);

  useEffect(() => {
    if (isRejected) {
      dispatch(
        RailwayItemsHistoryActions.listRailwayItemsHistory({
          ...params,
          status: railwayItemStatus[params.status],
        }),
      );
    }
  }, [isRejected, dispatch, params]);

  return (
    <Content id="content" data-testid="content" height={dimensions.height}>
      {isPending(railwayItemsHistoryRequests.LIST_RAILWAY_ITEMS_HISTORY) && (
        <Loading />
      )}

      {isFinished(railwayItemsHistoryRequests.LIST_RAILWAY_ITEMS_HISTORY) && (
        <Container>
          <Flex justifyContent="space-between" alignItems="center">
            <Header
              bread={breadcrumb}
              tag="header"
              title={`Item Ferroviário: ${params.code}`}
            />

            {railwayItems.status !== 'Encerrado' && (
              <Button
                variant="secondary"
                name="Encerrar item"
                action={handleOpenModal}
              />
            )}
          </Flex>

          {railwayItems.status === 'Encerrado' && (
            <Flex>
              <Notification
                data-testid="toast-test"
                title="Item Encerrado"
                error
                isOpen
              >
                <Text data-testid="text-test">
                  Você está visualizando um item encerrado.
                </Text>
              </Notification>
            </Flex>
          )}

          {railwayItems.status === 'Inativo' && (
            <Flex>
              <Notification
                data-testid="toast-test"
                title="Item inativo"
                error
                isOpen
              >
                <Text data-testid="text-test">
                  Você está visualizando um item inativo.
                </Text>
              </Notification>
            </Flex>
          )}

          <DashboardHistory
            breadcrumb={breadcrumb}
            railwayItems={railwayItems}
          />

          <S.ContainerTable>
            <S.ContainerTableStick>
              <Header tag="header" title="Histórico do item" />

              {tabs ? (
                <Tabs
                  position="start"
                  onChange={(code) => {
                    if (code) {
                      if (code !== params.code) {
                        history.push(
                          ROUTES.RAILWAY_ITEMS_HISTORY_PARAMS(
                            code,
                            params.status,
                            tabs,
                          ),
                        );
                      }
                    }
                  }}
                >
                  {tabs.split(',').map((tab) => (
                    <li key={tab} label={tab} active={tab === params.code}>
                      <RailwayItemsHistoryList />
                    </li>
                  ))}
                </Tabs>
              ) : (
                <RailwayItemsHistoryList />
              )}
            </S.ContainerTableStick>
          </S.ContainerTable>

          <ModalTariff />

          {isModalOpen && (
            <CloseItemModal isOpen={isModalOpen} onClose={handleCloseModal} />
          )}
        </Container>
      )}
    </Content>
  );
};

export default RailwayItemsHistory;

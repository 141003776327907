import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_SUPPLIER, GET_SUPPLIERS } from 'constants/endpoints';
import api from 'services/api';
import { GET } from 'constants/verbs';
import { getSuppliersFormat } from 'lib/contexts/indices';
import { DieselTypes, DieselActions } from './diesel.ducks';

export function* listMacrobase() {
  try {
    const response = yield api({
      method: GET,
      url: RESOURCE_SUPPLIER,
    });
    yield put(DieselActions.listMacrobaseSuccess(response.data.data));
  } catch (error) {
    yield put(DieselActions.dieselRequestFailure(error));
  }
}
export function* getSuppliers({ params, isSupplierVli }) {
  try {
    const response = yield api({
      method: GET,
      url: GET_SUPPLIERS(params),
    });
    const suppliers = getSuppliersFormat(response.data.data);
    yield put(DieselActions.getSuppliersSuccess(suppliers, isSupplierVli));
  } catch (error) {
    yield put(DieselActions.dieselRequestFailure(error));
  }
}
export function* watchSagas() {
  yield takeLatest(DieselTypes.LIST_MACROBASE, listMacrobase);
  yield takeLatest(DieselTypes.GET_SUPPLIERS, getSuppliers);
}

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ResendItemsActions } from 'store/resendItems/resendItems.ducks';
import { Button, Text, Popover } from '@vli/locomotive-ds/dist';
import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';
import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    const payload = { IdHistorys: [row.original.historyId] };
    dispatch(ResendItemsActions.resendItemRequest(payload));
  };
  const Options = (
    <S.OptionsContainer>
      <S.OptionItem data-testid="test-negociation" onClick={handleOnClick}>
        <Text size="large">Reenvio</Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      historyId: PropTypes.shape({
        lastExecutedAction: PropTypes.any,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;

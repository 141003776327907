import { createActions, createReducer } from 'reduxsauce';

import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import messages from 'constants/messages';

export const {
  Types: IndicesHistoryTypes,
  Creators: IndicesHistoryActions,
} = createActions({
  listIndicesHistoryWithInflation: [],
  listIndicesHistory: ['params'],
  downloadLetter: ['params'],
  downloadLetterSuccess: [],
  dieselReadjustmentEditStart: ['readjustment', 'callback'],
  dieselReadjustmentEditSuccess: [],
  inflationaryReadjustmentEditStart: ['readjustment', 'callback'],
  inflationaryReadjustmentEditSuccess: [],
  listIndicesHistorySuccess: ['payload'],
  indicesHistoryRequestFailure: ['error'],
  downloadReport: ['payload'],
  downloadReportSuccess: [],
  downloadReportFailure: ['error'],
  setFilterData: ['payload'],
});

const INITIAL_STATE = {
  indicesHistoryList: {
    chart: { monthlyValues: [] },
    list: { names: [], months: [] },
  },
  filter: null,
  params: '',
  message: null,
  error: null,
  indicesHistoryRequests: {
    [IndicesHistoryTypes.LIST_INDICES_HISTORY]: REQUEST_NOT_STARTED,
  },
};

const listIndicesHistory = () => ({
  ...INITIAL_STATE,
  indicesHistoryRequests: {
    ...INITIAL_STATE.indicesHistoryRequests,
    [IndicesHistoryTypes.LIST_INDICES_HISTORY]: REQUEST_PENDING,
  },
});

const listIndicesHistorySuccess = (state, { payload }) => ({
  ...state,
  indicesHistoryList: payload.response,
  params: payload.params,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.LIST_INDICES_HISTORY]: REQUEST_RESOLVED,
  },
});

const downloadLetter = (state) => ({
  ...state,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DOWNLOAD_LETTER]: REQUEST_PENDING,
  },
});

const downloadLetterSuccess = (state) => ({
  ...state,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DOWNLOAD_LETTER]: REQUEST_RESOLVED,
  },
});

const dieselReadjustmentEditStart = (state) => ({
  ...state,
  message: null,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DIESEL_READJUSTMENT_EDIT_START]: REQUEST_PENDING,
  },
});

const dieselReadjustmentEditSuccess = (state) => ({
  ...state,
  message: messages.messages.diesels.success,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DIESEL_READJUSTMENT_EDIT_START]: REQUEST_RESOLVED,
  },
});

const inflationaryReadjustmentEditStart = (state) => ({
  ...state,
  message: null,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.INFLATIONARY_READJUSTMENT_EDIT_START]: REQUEST_PENDING,
  },
});

const inflationaryReadjustmentEditSuccess = (state) => ({
  ...state,
  message: messages.messages.inflation.success,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.INFLATIONARY_READJUSTMENT_EDIT_START]: REQUEST_RESOLVED,
  },
});

const indicesHistoryFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.LIST_INDICES_HISTORY]: REQUEST_REJECTED,
    [IndicesHistoryTypes.DIESEL_READJUSTMENT_EDIT_START]: REQUEST_REJECTED,
  },
});

const setFilterData = (state, { payload }) => ({
  ...state,
  filter: payload,
});

const downloadReport = (state) => ({
  ...state,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DOWNLOAD_REPORT]: REQUEST_PENDING,
  },
});

const downloadReportSuccess = (state) => ({
  ...state,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DOWNLOAD_REPORT]: REQUEST_RESOLVED,
  },
});

const downloadReportFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  indicesHistoryRequests: {
    ...state.indicesHistoryRequests,
    [IndicesHistoryTypes.DOWNLOAD_REPORT]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [IndicesHistoryTypes.LIST_INDICES_HISTORY_WITH_INFLATION]: listIndicesHistory,
  [IndicesHistoryTypes.LIST_INDICES_HISTORY]: listIndicesHistory,
  [IndicesHistoryTypes.LIST_INDICES_HISTORY_SUCCESS]: listIndicesHistorySuccess,
  [IndicesHistoryTypes.INDICES_HISTORY_REQUEST_FAILURE]: indicesHistoryFailure,
  [IndicesHistoryTypes.DIESEL_READJUSTMENT_EDIT_START]: dieselReadjustmentEditStart,
  [IndicesHistoryTypes.DIESEL_READJUSTMENT_EDIT_SUCCESS]: dieselReadjustmentEditSuccess,
  [IndicesHistoryTypes.INFLATIONARY_READJUSTMENT_EDIT_START]: inflationaryReadjustmentEditStart,
  [IndicesHistoryTypes.INFLATIONARY_READJUSTMENT_EDIT_SUCCESS]: inflationaryReadjustmentEditSuccess,
  [IndicesHistoryTypes.DOWNLOAD_LETTER]: downloadLetter,
  [IndicesHistoryTypes.DOWNLOAD_LETTER_SUCCESS]: downloadLetterSuccess,
  [IndicesHistoryTypes.DOWNLOAD_REPORT]: downloadReport,
  [IndicesHistoryTypes.DOWNLOAD_REPORT_SUCCESS]: downloadReportSuccess,
  [IndicesHistoryTypes.DOWNLOAD_REPORT_FAILURE]: downloadReportFailure,
  [IndicesHistoryTypes.SET_FILTER_DATA]: setFilterData,
});

/* eslint-disable react/prop-types */
import React from 'react';
import CellTable from 'components/core/Table/CellTable';
import * as utility from 'lib/core/utility';
import CellWithCheckbox from '../CellWithCheckbox/CellWithCheckbox';

const Columns = (railwayItems, handleChangeRowSelection) => [
  {
    Header: 'Vincular',
    accessor: 'selected',
    align: 'center',
    width: 50,
    Cell: (props) => {
      // eslint-disable-next-line react/destructuring-assignment
      const line = parseInt(props?.row?.id, 10);

      return (
        <CellWithCheckbox
          line={line}
          checked={railwayItems[line]?.selected ?? false}
          handleChangeRowSelection={handleChangeRowSelection}
        />
      );
    },
  },
  {
    Header: 'Item',
    align: 'start',
    accessor: 'railwayItemCode',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Origem',
    align: 'start',
    accessor: 'origin',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Destino',
    align: 'start',
    accessor: 'destination',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Produto',
    align: 'start',
    accessor: 'product',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Tarifa Base',
    align: 'start',
    accessor: 'contractValue',
    width: 50,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatMoney} />
    ),
  },
];

export default Columns;

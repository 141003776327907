import React from 'react';
import * as utility from 'lib/core/utility';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const Chart = ({
  data,
  labels,
  height,
  tickFormatter,
  formatter,
  fontSize,
}) => (
  <ResponsiveContainer width="100%" height={height} data-testid="chart-test">
    <LineChart data={data} margin={{ right: 38, top: 10 }}>
      <XAxis dataKey="name" fontSize={fontSize} axisLine={false} />
      <YAxis fontSize={fontSize} tickFormatter={tickFormatter} />
      <ReferenceLine y={0} label="" stroke="gray" strokeWidth={3} />
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <Tooltip active formatter={formatter} offset={3} />
      <Legend iconType="circle" iconSize={10} align="right" />
      {labels.map((key) => {
        const color = utility.randomColor();
        return (
          <Line
            type="monotone"
            dataKey={key}
            stroke={color}
            fill={color}
            key={color}
          />
        );
      })}
    </LineChart>
  </ResponsiveContainer>
);
Chart.defaultProps = {
  fontSize: 10,
  height: 300,
  data: [],
  labels: [],
  tickFormatter: () => {},
  formatter: () => {},
};
Chart.propTypes = {
  fontSize: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  labels: PropTypes.array,
  tickFormatter: PropTypes.func,
  formatter: PropTypes.func,
};
export default Chart;

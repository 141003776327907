import React from 'react';
import { getStatusApplication, getStatusName } from 'lib/core/utility';
import PropTypes from 'prop-types';
import * as S from './CellStatus.styles';

const CellStatus = ({ cell: { value } }) => (
  <S.Content data-testid="cellstatus">
    <S.BallStatus status={getStatusApplication[value]} />
    <S.TextStatus>{getStatusName[value]}</S.TextStatus>
  </S.Content>
);

CellStatus.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }),
};

CellStatus.defaultProps = {
  cell: {
    value: '',
  },
};

export default CellStatus;

import { put, takeLatest } from 'redux-saga/effects';
import {
  FORMALIZATION,
  FORMALIZATION_SEND,
  FORMALIZATION_RETURN_TO_PLAINTIFF,
  FORMALIZE,
  REVIEW,
  EXPECTED_DATE,
  FORMALIZATION_DRAFT_TO_REQUESTED,
  ACCEPT_SERVICE,
  TRANSFER_SERVICE,
  CANCEL_FORMALIZATION,
  GROUPED_SALES,
  APPROVE_REQUEST,
  DISAPPROVE_REQUEST,
  UPLOAD_ATTACHMENT,
  DOWNLOAD_ATTACHMENT,
  DOWNLOAD_SUMMARY,
  SERVICES,
} from 'constants/endpoints';
import { GET, PUT, PATCH } from 'constants/verbs';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import api from 'services/api';

import {
  formatDataFromRequest,
  FormalizationStatus,
} from 'lib/contexts/formalizationRequest';
import { handleIdPattern } from 'lib/core/idPattern';
import * as utility from 'lib/core/utility';

import { MessagesActions } from 'store/messages/messages.ducks';
import {
  FormalizationRequestActions,
  FormalizationRequestTypes,
} from './formalizationRequest.ducks';

export function* formalizationByIdRequest({ formalizationId }) {
  try {
    const response = yield api({
      method: GET,
      url: `${FORMALIZATION}/${formalizationId}`,
    });

    const formattedData = formatDataFromRequest(response);

    yield put(
      FormalizationRequestActions.formalizationByIdRequestSuccess({
        data: formattedData,
      }),
    );
  } catch (error) {
    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* formalizationEditRequest({ payload }) {
  try {
    yield api({
      method: PUT,
      url: FORMALIZATION,
      data: payload,
    });

    const formattedId = handleIdPattern(payload.id, 'S');

    yield put(FormalizationRequestActions.formalizationEditRequestSuccess());

    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));

    yield put(FormalizationRequestActions.formalizationByIdRequest(payload.id));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao editar formalização',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* formalizationSendRequest({ payload }) {
  try {
    yield api({
      method: PUT,
      url: FORMALIZATION_SEND,
      data: payload,
    });

    const formattedId = handleIdPattern(payload.id, 'S');

    yield put(FormalizationRequestActions.formalizationSendRequestSuccess());

    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));
    yield put(FormalizationRequestActions.formalizationByIdRequest(payload.id));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao enviar solicitação',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* formalizationReturnToPlaintiffRequest({ formalizationId }) {
  try {
    yield api({
      method: PATCH,
      url: FORMALIZATION_RETURN_TO_PLAINTIFF(formalizationId),
    });

    const formattedId = handleIdPattern(formalizationId, 'S');

    yield put(
      FormalizationRequestActions.formalizationReturnToPlaintiffRequestSuccess(),
    );

    yield put(
      FormalizationRequestActions.formalizationByIdRequest(formalizationId),
    );
    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao enviar devolver para demandante',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* sendForFormalizationRequest({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: FORMALIZE,
      data: payload,
    });

    const formattedId = handleIdPattern(payload.id, 'S');

    yield put(FormalizationRequestActions.sendForFormalizationRequestSuccess());

    yield put(FormalizationRequestActions.formalizationByIdRequest(payload.id));

    yield callback();

    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao enviar formalização',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* reviewRequest({ formalizationId, expectedDate }) {
  try {
    yield api({
      method: PUT,
      url: REVIEW,
      data: {
        id: formalizationId,
        expectedDate,
      },
    });

    const formattedId = handleIdPattern(formalizationId, 'S');

    yield put(FormalizationRequestActions.reviewRequestSuccess());

    yield put(
      FormalizationRequestActions.formalizationByIdRequest(formalizationId),
    );
    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao enviar formalização',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* expectedDateRequest({ formalizationId }) {
  try {
    const { data } = yield api({
      method: GET,
      url: EXPECTED_DATE(formalizationId),
    });
    yield put(
      FormalizationRequestActions.expectedDateRequestSuccess(
        data.data.expectedDate,
      ),
    );
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao enviar formalização',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* draftToRequestedFormalizationRequest({
  formalizationId,
  callback,
}) {
  try {
    const { data } = yield api({
      method: PATCH,
      url: FORMALIZATION_DRAFT_TO_REQUESTED(formalizationId),
    });

    const formattedId = handleIdPattern(formalizationId, 'S');

    yield put(
      FormalizationRequestActions.draftToRequestedFormalizationRequestSuccess(),
    );

    yield callback(data.data.actualStep);

    yield put(
      FormalizationRequestActions.formalizationByIdRequest(formalizationId),
    );

    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao enviar solicitação',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* acceptService({ formalizationId, expectedDate }) {
  try {
    yield api({
      method: PUT,
      url: ACCEPT_SERVICE,
      data: {
        id: formalizationId,
        expectedDate,
      },
    });

    const formattedId = handleIdPattern(formalizationId, 'S');

    yield put(FormalizationRequestActions.acceptServiceSuccess());

    yield put(
      FormalizationRequestActions.formalizationByIdRequest(formalizationId),
    );

    yield history.push(ROUTES.FORMALIZATION_REQUEST('visualizar', formattedId));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao aceitar atendimento',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* transferService({ formalizationId, userId }) {
  try {
    yield api({
      method: PUT,
      url: TRANSFER_SERVICE,
      data: {
        formalizationId,
        userId,
      },
    });

    yield put(FormalizationRequestActions.transferServiceSuccess());

    yield put(
      FormalizationRequestActions.formalizationByIdRequest(formalizationId),
    );
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao transferir atendimento',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* cancelFormalization({ formalizationId }) {
  try {
    yield api({
      method: PATCH,
      url: `${CANCEL_FORMALIZATION}/${formalizationId}`,
    });

    yield put(FormalizationRequestActions.cancelFormalizationSuccess());

    yield put(
      FormalizationRequestActions.formalizationByIdRequest(formalizationId),
    );
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao cancelar solicitação',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* groupedSalesBySaleId({ saleId }) {
  try {
    const response = yield api({
      method: GET,
      url: GROUPED_SALES(saleId),
    });

    yield put(
      FormalizationRequestActions.groupedSalesBySaleIdSuccess({
        data: response.data.data,
      }),
    );
  } catch (error) {
    yield put(FormalizationRequestActions.groupedSalesBySaleIdFailure(error));
  }
}

export function* approveRequest({ formalizationId }) {
  try {
    yield api({
      method: PATCH,
      url: APPROVE_REQUEST(formalizationId),
    });

    yield put(FormalizationRequestActions.approveRequestSuccess());

    yield history.push(ROUTES.REQUESTS_LIST);
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao aprovar solicitação',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* disapproveRequest({ payload }) {
  try {
    yield api({
      method: PUT,
      url: DISAPPROVE_REQUEST,
      data: payload,
    });

    yield put(FormalizationRequestActions.disapproveRequestSuccess());

    yield history.push(ROUTES.REQUESTS_LIST);
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao reprovar solicitação',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(FormalizationRequestActions.formalizationRequestFailure(error));
  }
}

export function* uploadAttachment({ payload }) {
  try {
    yield api({
      method: PUT,
      url: UPLOAD_ATTACHMENT,
      data: payload,
    });

    yield put(FormalizationRequestActions.uploadAttachmentSuccess());

    yield put(
      MessagesActions.open({
        title: 'Upload feito com sucesso',
        type: 'success',
        contentText: 'O upload do arquivo foi concluído com sucesso',
      }),
    );

    const id = payload.get('FormalizationId');

    yield put(FormalizationRequestActions.formalizationByIdRequest(id));
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.open({
        title: 'Falha ao fazer upload',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(FormalizationRequestActions.uploadAttachmentFailure(error));
  }
}

export function* downloadAttachment({ formalizationId }) {
  try {
    const response = yield api({
      method: GET,
      url: DOWNLOAD_ATTACHMENT(formalizationId),
      responseType: 'arraybuffer',
    });

    yield put(FormalizationRequestActions.downloadAttachmentSuccess());

    utility.downloadFile(
      response.data,
      `AnexoSolicitacao${formalizationId}.zip`,
    );

    yield put(
      MessagesActions.open({
        title: 'Download feito com sucesso',
        type: 'success',
        contentText: 'O download do arquivo foi concluído com sucesso',
      }),
    );
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.open({
        title: 'Falha ao fazer download',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(FormalizationRequestActions.downloadAttachmentFailure(error));
  }
}

export function* downloadSummary({ formalizationId, statusId }) {
  try {
    const response = yield api({
      method: GET,
      url: DOWNLOAD_SUMMARY(formalizationId),
      responseType: 'arraybuffer',
    });

    yield put(FormalizationRequestActions.downloadSummarySuccess());

    utility.downloadFile(
      response.data,
      `RelatorioSolicitacao${formalizationId}.zip`,
    );

    if (statusId === FormalizationStatus.InProgress) {
      yield put(
        MessagesActions.open({
          title: 'Download feito com sucesso',
          type: 'warning',
          contentText: 'A formalização não está concluída.',
        }),
      );
    } else {
      yield put(
        MessagesActions.open({
          title: 'Download feito com sucesso',
          type: 'success',
          contentText: 'O download do relatório foi concluído com sucesso',
        }),
      );
    }
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.open({
        title: 'Falha ao fazer download',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(FormalizationRequestActions.downloadSummaryFailure(error));
  }
}

export function* servicesBySaleId({ saleId }) {
  try {
    const response = yield api({
      method: GET,
      url: SERVICES(saleId),
    });

    const allServices = response.data.data.validityPrice.map((validityItem) => {
      return validityItem.price.map((priceItem) => {
        return {
          period: validityItem.beginningValidity,
          microRegion: priceItem.services[0].microregion,
          serviceValues: priceItem.services.map((serviceItem) => ({
            name: serviceItem.serviceDescription,
            value: serviceItem.value,
          })),
          serviceTotalValue: priceItem.services.reduce((acc, current) => {
            return acc + current.value;
          }, 0),
        };
      });
    });

    const groupedServices = allServices.reduce((acc, current) => {
      return acc.concat(current);
    }, []);

    yield put(
      FormalizationRequestActions.servicesBySaleIdSuccess({
        data: {
          groupedServices,
          ...response.data.data,
        },
      }),
    );
  } catch (error) {
    yield put(FormalizationRequestActions.servicesBySaleIdFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(
    FormalizationRequestTypes.FORMALIZATION_BY_ID_REQUEST,
    formalizationByIdRequest,
  );
  yield takeLatest(
    FormalizationRequestTypes.FORMALIZATION_EDIT_REQUEST,
    formalizationEditRequest,
  );
  yield takeLatest(
    FormalizationRequestTypes.FORMALIZATION_SEND_REQUEST,
    formalizationSendRequest,
  );
  yield takeLatest(
    FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST,
    formalizationReturnToPlaintiffRequest,
  );
  yield takeLatest(
    FormalizationRequestTypes.SEND_FOR_FORMALIZATION_REQUEST,
    sendForFormalizationRequest,
  );
  yield takeLatest(FormalizationRequestTypes.REVIEW_REQUEST, reviewRequest);
  yield takeLatest(
    FormalizationRequestTypes.EXPECTED_DATE_REQUEST,
    expectedDateRequest,
  );
  yield takeLatest(
    FormalizationRequestTypes.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST,
    draftToRequestedFormalizationRequest,
  );
  yield takeLatest(FormalizationRequestTypes.ACCEPT_SERVICE, acceptService);
  yield takeLatest(FormalizationRequestTypes.TRANSFER_SERVICE, transferService);
  yield takeLatest(
    FormalizationRequestTypes.CANCEL_FORMALIZATION,
    cancelFormalization,
  );
  yield takeLatest(
    FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID,
    groupedSalesBySaleId,
  );
  yield takeLatest(FormalizationRequestTypes.APPROVE_REQUEST, approveRequest);
  yield takeLatest(
    FormalizationRequestTypes.DISAPPROVE_REQUEST,
    disapproveRequest,
  );
  yield takeLatest(
    FormalizationRequestTypes.UPLOAD_ATTACHMENT,
    uploadAttachment,
  );
  yield takeLatest(
    FormalizationRequestTypes.DOWNLOAD_ATTACHMENT,
    downloadAttachment,
  );
  yield takeLatest(FormalizationRequestTypes.DOWNLOAD_SUMMARY, downloadSummary);
  yield takeLatest(
    FormalizationRequestTypes.SERVICES_BY_SALE_ID,
    servicesBySaleId,
  );
}

import styled, { css } from 'styled-components';
import { Tokens, Card } from '@vli/locomotive-ds';

export const AccordionContainer = styled(Card)`
  padding: ${Tokens.spacing.sm};

  ${(props) => {
    switch (props.variant) {
      case 'success':
        return css`
          border: 1px solid ${Tokens.colors.green300};
        `;
      case 'error':
        return css`
          border: 1px solid ${Tokens.colors.red300};
        `;
      case 'none':
        return css`
          border: 1px solid ${Tokens.colors.gray600};
        `;
      default:
        return css`
          border: none;
        `;
    }
  }}
`;

export const AccordionHeader = styled.div`
  width: 100%;
  height: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: all 0.15s ease-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  & > div {
    width: 100%;

    display: flex;
    align-items: center;
    gap: ${Tokens.spacing.sm};
  }

  & > svg {
    transition: all 0.15s ease-out;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'none')};

    path {
      stroke: ${Tokens.colors.accentPrimaryDark};
    }
  }
`;

export const AccordionTitle = styled.span`
  font: ${Tokens.fonts.headingBoldH6};
  color: ${Tokens.colors.gray500};
`;

export const AccordionContent = styled.div`
  width: 100%;
  height: 0;

  overflow: hidden;
  opacity: 0.5;

  transition: all 0.3s ease-out;

  ${(props) =>
    props.isOpen &&
    css`
      height: auto;
      overflow: visible;
      opacity: 1;
      padding-top: ${Tokens.spacing.sm};
    `}
`;

import { Tokens } from '@vli/locomotive-ds';
import styled, { css } from 'styled-components';

export const InputGroup = styled.div`
  position: relative;
  width: auto;
  box-sizing: content-box;

  width: ${(props) => props.width || '100%'};

  & > input {
    box-sizing: content-box;
  }

  & > label {
    margin-bottom: 4px;

    span {
      font-weight: 400;
      color: ${Tokens.colors.gray400};
    }
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;

  background-color: white;
  color: ${Tokens.colors.gray500};

  font: ${Tokens.fonts.textRegularMedium};

  position: relative;
  z-index: unset;

  width: 100%;
  height: 40px;

  border: 1px solid ${Tokens.colors.gray200};
  border-radius: 4px;
  padding: 0px;

  overflow: hidden;
  outline: none;

  transition-property: color, background-color, border-color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-delay: 0s, 0s, 0s, 0.1s;

  will-change: border;

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Tokens.colors.gray100};
      color: ${Tokens.colors.gray300};
      border-color: ${Tokens.colors.gray200};
      pointer-events: 'none';
    `}

  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${Tokens.colors.accentPrimaryDark};
    `}
`;

export const Field = styled.input`
  background-color: white;
  color: ${Tokens.colors.gray500};

  font: ${Tokens.fonts.textRegularMedium};

  width: 100%;
  height: 40px;

  border: none;
  border-radius: 4px;
  padding: 0px 10px 0px 10px;

  overflow: hidden;
  outline: none;

  transition-property: color, background-color, border-color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  transition-delay: 0s, 0s, 0s, 0.1s;

  will-change: border;

  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  visibility: hidden;

  &::placeholder {
    color: ${Tokens.colors.gray200};

    transition-property: opacity transform;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

    z-index: 2;
  }

  &:disabled {
    background-color: ${Tokens.colors.gray100};
    color: ${Tokens.colors.gray300};
    border-color: ${Tokens.colors.gray200};
    pointer-events: 'none';
  }

  ${(props) =>
    props.focus &&
    css`
      border: none;

      &::placeholder: {
        opacity: 0,
        transform: translateX(5px)
      }
    `}

  &[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

export const FileGroup = styled.div`
  background: transparent;
  color: ${Tokens.colors.gray500};

  font: ${Tokens.fonts.textRegularMedium};

  width: 100%;
  height: 40px;

  position: absolute;
  left: 0;
  top: 20px;

  label {
    cursor: pointer;
    
    position: relative;
    z-index: 3;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    width: 100%;
    height: 100%;
    
    padding: 0 10px 0 0;

    span {
      z-index: 4;
      
      font: ${Tokens.fonts.textRegularMedium};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      
      display: block;
      
      width: calc(100% - 40px);
      
      padding-left: 12px;
      
      transition-property: color;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    }

    svg {
      flex-shrink: 0;

      path {
        transition-property: fill;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      }
    }
  }

  ${(props) =>
    props.focus &&
    css`
      border: none;

      &::placeholder: {
        opacity: 0,
        transform: translateX(5px)
      }
    `}

  ${(props) =>
    !props.fileTitle &&
    css`
      label {
        span {
          color: ${Tokens.colors.gray200};
        }
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Tokens.colors.gray100};
      color: ${Tokens.colors.gray300};
      border-color: ${Tokens.colors.gray200};
      pointer-events: 'none';

      label {
        span {
          color: ${Tokens.colors.gray300};
        }

        svg path {
          fill: ${Tokens.colors.gray300};
        }
      }
    `}
`;

export const StatusText = styled.div`
  position: relative;
  z-index: 1;

  font: ${Tokens.fonts.textRegularSmall};
  line-height: 1.4;
  text-align: right;

  display: block;

  margin-top: 5px;

  color: ${Tokens.colors.red400};
`;

export const SelectedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  span,
  small {
    font: ${Tokens.fonts.textRegularSmall};
    font-weight: 400;
    color: ${Tokens.colors.gray400};
  }

  small {
    color: ${Tokens.colors.gray300};
  }
`;

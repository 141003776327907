import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const { Types: CommentTypes, Creators: CommentActions } = createActions({
  commentCreate: ['payload'],
  commentCreateSuccess: ['payload'],
  commentCreateFailure: ['error'],
  commentByFormalizationId: ['formalizationId'],
  commentByFormalizationIdSuccess: ['payload'],
  commentByFormalizationIdFailure: ['error'],
  setIsModalOpen: ['payload'],
});

const INITIAL_STATE = {
  comments: [],
  error: null,
  isModalOpen: false,
  commentsRequest: {
    [CommentTypes.COMMENT_CREATE]: REQUEST_NOT_STARTED,
    [CommentTypes.COMMENT_BY_FORMALIZATION_ID]: REQUEST_NOT_STARTED,
  },
};

const commentCreate = (state) => ({
  ...state,
  commentsRequest: {
    ...state.commentsRequest,
    [CommentTypes.COMMENT_CREATE]: REQUEST_PENDING,
  },
});

const commentCreateSuccess = (state) => ({
  ...state,
  commentsRequest: {
    ...state.commentsRequest,
    [CommentTypes.COMMENT_CREATE]: REQUEST_RESOLVED,
  },
});

const commentCreateFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  commentsRequest: {
    ...state.commentsRequest,
    [CommentTypes.COMMENT_CREATE]: REQUEST_REJECTED,
  },
});

const commentByFormalizationId = (state) => ({
  ...state,
  commentsRequest: {
    ...state.commentsRequest,
    [CommentTypes.COMMENT_BY_FORMALIZATION_ID]: REQUEST_PENDING,
  },
});

const commentByFormalizationIdSuccess = (state, { payload }) => ({
  ...state,
  comments: payload,
  commentsRequest: {
    ...state.commentsRequest,
    [CommentTypes.COMMENT_BY_FORMALIZATION_ID]: REQUEST_RESOLVED,
  },
});

const commentByFormalizationIdFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  commentsRequest: {
    ...state.commentsRequest,
    [CommentTypes.COMMENT_BY_FORMALIZATION_ID]: REQUEST_REJECTED,
  },
});

const setIsModalOpen = (state, { payload }) => ({
  ...state,
  isModalOpen: payload.isOpen,
});

export default createReducer(INITIAL_STATE, {
  [CommentTypes.COMMENT_CREATE]: commentCreate,
  [CommentTypes.COMMENT_CREATE_SUCCESS]: commentCreateSuccess,
  [CommentTypes.COMMENT_CREATE_FAILURE]: commentCreateFailure,
  [CommentTypes.COMMENT_BY_FORMALIZATION_ID]: commentByFormalizationId,
  [CommentTypes.COMMENT_BY_FORMALIZATION_ID_SUCCESS]: commentByFormalizationIdSuccess,
  [CommentTypes.COMMENT_BY_FORMALIZATION_ID_FAILURE]: commentByFormalizationIdFailure,
  [CommentTypes.SET_IS_MODAL_OPEN]: setIsModalOpen,
});

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const Container = styled.div`
  width: 100%;
  margin: ${Tokens.spacing.sm} 0;

  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};
`;

import React from 'react';
import { Container, Header } from '@vli/locomotive-ds/dist';
import Content from 'components/core/content';
import useDimensions from 'hooks/useDimensions';
import Form from 'components/contexts/contractReport/Form';
import ContractReportList from 'components/contexts/contractReport/ContractReportList';

const breadcrumb = [
  {
    text: 'Gestão contratual',
    href: `#`,
  },
];

const ContractReport = () => {
  const dimensions = useDimensions();

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Header bread={breadcrumb} tag="header" title="Gestão contratual" />
        <Form />
        <ContractReportList />
      </Container>
    </Content>
  );
};

export default ContractReport;

import * as utility from 'lib/core/utility';

export const DataFilter = () => {
  const dataFilter = [
    {
      label: 'Vigência Inicial',
      name: 'StartDate',
      type: 'date',
    },
    {
      label: 'Vigência Final',
      name: 'EndDate',
      type: 'date',
    },
  ];

  return dataFilter;
};

export const getFilterParams = (data) => {
  const StartDate = data?.StartDate
    ? utility.formatDate(data.StartDate, 'yyyy-mm-dd')
    : null;
  const EndDate = data?.EndDate
    ? utility.formatDate(data.EndDate, 'yyyy-mm-dd')
    : null;

  const filterFields = {
    filterFields: {
      StartDate,
      EndDate,
    },
  };

  return `?${utility.autoListingParamsGet(filterFields)}`;
};

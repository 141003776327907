import React from 'react';
import CellTable from 'components/core/Table/CellTable';
import * as utility from 'lib/core/utility';
import CellWithActions from './CellWithActions';
import StatusSegment from './StatusSegment';

export const Columns = [
  {
    Header: 'Status',
    accessor: 'active',
    align: 'start',
    width: 50,
    Cell: (props) => <StatusSegment {...props} />,
  },
  {
    Header: 'Segmentos',
    accessor: 'name',
    align: 'start',
    width: 400,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeFirstString}
        align="start"
      />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'acoes',
    align: 'end',
    width: 10,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

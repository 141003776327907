import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_EMAIL_NOTIFICATION_CLIENT } from 'constants/endpoints';
import api from 'services/api';
import { GET, POST } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import * as ROUTES from 'constants/urls';
import { history } from 'constants/routes';
import messages from 'constants/messages';
import {
  EmailNotificationClientTypes,
  EmailNotificationClientActions,
} from './emailNotificationClient.ducks';

export function* listEmailNotificationClient({ params, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_EMAIL_NOTIFICATION_CLIENT}/paged/${params}`,
    });
    yield put(
      EmailNotificationClientActions.listEmailNotificationClientSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      EmailNotificationClientActions.emailNotificationClientRequestFailure(
        error,
        type,
      ),
    );
  }
}
export function* getEmailNotificationClient({ payload, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_EMAIL_NOTIFICATION_CLIENT}/details/${payload}`,
    });
    yield put(
      EmailNotificationClientActions.getEmailNotificationClientSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      EmailNotificationClientActions.emailNotificationClientRequestFailure(
        error,
        type,
      ),
    );
  }
}

export function* createEmailNotificationClient({ payload, type }) {
  try {
    const response = yield api({
      method: POST,
      url: `${RESOURCE_EMAIL_NOTIFICATION_CLIENT}`,
      data: payload,
    });
    yield put(
      EmailNotificationClientActions.createEmailNotificationClientSuccess(
        response.data.data,
      ),
    );
    yield put(
      MessagesActions.open({
        title: messages.messages.emailNotification[POST].title,
        type: 'success',
        contentText: messages.messages.emailNotification[POST].message,
      }),
    );
    yield history.push(ROUTES.EMAIL_NOTIFICATIONS_CLIENT_LIST);
  } catch (error) {
    let message = messages.messages.emailNotification[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.emailNotification[POST].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );
    yield put(
      EmailNotificationClientActions.emailNotificationClientRequestFailure(
        error,
        type,
      ),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT,
    listEmailNotificationClient,
  );
  yield takeLatest(
    EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT_FILTER,
    listEmailNotificationClient,
  );
  yield takeLatest(
    EmailNotificationClientTypes.GET_EMAIL_NOTIFICATION_CLIENT,
    getEmailNotificationClient,
  );
  yield takeLatest(
    EmailNotificationClientTypes.CREATE_EMAIL_NOTIFICATION_CLIENT,
    createEmailNotificationClient,
  );
}

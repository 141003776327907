import { put, takeLatest } from 'redux-saga/effects';

import api from 'services/api';

import { COMMENT } from 'constants/endpoints';
import { GET, POST } from 'constants/verbs';

import { MessagesActions } from 'store/messages/messages.ducks';
import { CommentActions, CommentTypes } from './comments.ducks';

export function* commentCreate({ payload }) {
  try {
    yield api({
      method: POST,
      url: COMMENT,
      data: payload,
    });

    yield put(CommentActions.commentCreateSuccess());
    yield put(CommentActions.commentByFormalizationId(payload.formalizationId));

    yield put(
      MessagesActions.open({
        title: 'Comentário adicionado',
        type: 'success',
        contentText: `O comentário foi adicionado com sucesso!`,
      }),
    );
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.open({
        title: 'Erro ao adicionar comentário',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(CommentActions.commentCreateFailure(error));
  }
}

export function* commentByFormalizationId({ formalizationId }) {
  try {
    const response = yield api({
      method: GET,
      url: `${COMMENT}/${formalizationId}`,
    });

    yield put(
      CommentActions.commentByFormalizationIdSuccess(response.data.data),
    );
  } catch (error) {
    yield put(CommentActions.commentByFormalizationIdFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(CommentTypes.COMMENT_CREATE, commentCreate);
  yield takeLatest(
    CommentTypes.COMMENT_BY_FORMALIZATION_ID,
    commentByFormalizationId,
  );
}

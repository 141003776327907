import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_EMAIL_NOTIFICATION_MANAGER } from 'constants/endpoints';
import api from 'services/api';
import { GET, POST } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import * as ROUTES from 'constants/urls';
import { history } from 'constants/routes';
import messages from 'constants/messages';
import {
  EmailNotificationManagerTypes,
  EmailNotificationManagerActions,
} from './emailNotificationManager.ducks';

export function* listEmailNotificationManager({ params, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_EMAIL_NOTIFICATION_MANAGER}${params}`,
    });
    yield put(
      EmailNotificationManagerActions.listEmailNotificationManagerSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      EmailNotificationManagerActions.emailNotificationManagerRequestFailure(
        error,
        type,
      ),
    );
  }
}
export function* getEmailNotificationManager({ payload, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_EMAIL_NOTIFICATION_MANAGER}/details/${payload}`,
    });
    yield put(
      EmailNotificationManagerActions.getEmailNotificationManagerSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      EmailNotificationManagerActions.emailNotificationManagerRequestFailure(
        error,
        type,
      ),
    );
  }
}

export function* createEmailNotificationManager({ payload, type }) {
  try {
    const response = yield api({
      method: POST,
      url: `${RESOURCE_EMAIL_NOTIFICATION_MANAGER}`,
      data: payload,
    });
    yield put(
      EmailNotificationManagerActions.createEmailNotificationManagerSuccess(
        response.data.data,
      ),
    );
    yield put(
      MessagesActions.open({
        title: messages.messages.emailNotification[POST].title,
        type: 'success',
        contentText: messages.messages.emailNotification[POST].message,
      }),
    );
    yield history.push(ROUTES.EMAIL_NOTIFICATIONS_MANAGER_LIST);
  } catch (error) {
    let message = messages.messages.emailNotification[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.emailNotification[POST].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );
    yield put(
      EmailNotificationManagerActions.emailNotificationManagerRequestFailure(
        error,
        type,
      ),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER,
    listEmailNotificationManager,
  );
  yield takeLatest(
    EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER_FILTER,
    listEmailNotificationManager,
  );
  yield takeLatest(
    EmailNotificationManagerTypes.GET_EMAIL_NOTIFICATION_MANAGER,
    getEmailNotificationManager,
  );
  yield takeLatest(
    EmailNotificationManagerTypes.CREATE_EMAIL_NOTIFICATION_MANAGER,
    createEmailNotificationManager,
  );
}

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';
import bgImage from 'assets/images/bg-login.jpg';

export const ForbiddenBackground = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background 0.4s linear;
  -webkit-transition: background 0.4s linear;
`;
export const ForbiddenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ForbiddenCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 60%;
  text-align: center;
  background: #336eae;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 55, 120, 0.29);
`;
export const ForbiddenLogo = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;

  span {
    display: block;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    margin-bottom: 1rem;
  }

  svg {
    width: 30%;
    height: auto;
    margin-left: 50px;
  }
`;

export const ForbiddenMessage = styled.div`
  margin-bottom: 2rem;
  color: ${Tokens.colors.grayWhite};
  font-size: 0.8rem;
`;
export const ForbiddenButtons = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  button {
    margin: 5px !important;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: none;
  }

  * {
    margin-bottom: 0;
  }
`;

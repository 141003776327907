import { Tokens } from '@vli/locomotive-ds/dist';
import CellTable from 'components/core/Table/CellTable';
import ExpanderCell from 'components/core/Table/ExpanderCell';
import * as utility from 'lib/core/utility';
import React from 'react';
import CellCalculatedValue from '../CellCalculatedValue';
import CellContractedValue from '../CellContractedValue';
import CellExecutedValue from '../CellExecutedValue';
import CellServices from '../CellServices';
import CellStatus from '../CellStatus';

const Columns = (clickRow) => [
  {
    Header: 'Vigência inicial do preço',
    accessor: 'startDate',
    align: 'left',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Vigência final do preço',
    accessor: 'endDate',
    align: 'left',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Data da aplicação',
    accessor: 'applicationDate',
    align: 'left',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: ' Valor contratado',
    accessor: 'contractValue',
    align: 'left',
    Cell: (props) => (
      <CellContractedValue
        {...props}
        transformedData={utility.formatMoney}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Flink',
    accessor: 'executedValue',
    align: 'left',
    Cell: (props) => (
      <CellExecutedValue
        {...props}
        transformedData={utility.formatMoney}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Calculado',
    accessor: 'calculatedValue',
    align: 'left',
    Cell: (props) => (
      <CellCalculatedValue
        {...props}
        transformedData={utility.formatMoney}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Status flink',
    accessor: 'statusText',
    align: 'left',
    Cell: (props) => (
      <CellStatus
        {...props}
        color={Tokens.colors.accentPrimaryDark}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Abertura por serviço',
    align: 'left',
    Cell: (props) => <CellServices {...props} onClick={clickRow} />,
  },
  {
    Header: () => null,
    id: 'expander',
    align: 'left',
    width: '50px',
    Cell: (props) => <ExpanderCell {...props} />,
  },
];

export default Columns;

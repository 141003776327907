import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Header,
  Flex,
  Row,
  Col,
  Button,
  Card,
  Input,
} from '@vli/locomotive-ds/dist';
import PropTypes from 'prop-types';
import Multiselect from 'components/form/multiselect';
import InputSelect from 'components/form/InputSelect';
import { useForm, Controller } from 'react-hook-form';
import {
  getOptionsProfile,
  getMultiselectOptionsSegment,
} from 'lib/contexts/user';
import * as S from './Form.styles';

const Form = ({
  breadcrumb,
  profiles,
  segments,
  onSubmit,
  editUser,
  method,
}) => {
  const [selectedItems, setSelectedItems] = useState({});
  const {
    handleSubmit,
    errors,
    setValue,
    control,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: '',
      account: '',
      profileId: '',
      segmentIds: '',
    },
  });

  const addItem = (name, value) => {
    setSelectedItems({
      ...selectedItems,
      [name]: value,
    });
  };

  const getData = useCallback(
    (data, name) => {
      const normalize = data.map((newData) => ({
        ...newData,
        selected:
          selectedItems[name]?.filter((f) => f.value.id === newData.value.id)
            .length > 0,
      }));
      return normalize;
    },
    [selectedItems],
  );

  const removeItem = (value) => {
    setSelectedItems((olders) => olders.filter((item) => item !== value));
  };

  const handleItem = (name, value, isSelected) => {
    if (isSelected) removeItem(value);
    else addItem(name, value);
    if (value.length === 0) setValue(name, '');
    else setValue(name, value);
  };

  useEffect(() => {
    if (method === 'edit' && editUser) {
      const { name, account, profileId, segments: editSegments } = editUser;
      setValue('name', name);
      setValue('account', account);
      setValue('profileId', profileId);
      setSelectedItems({
        segmentIds: getMultiselectOptionsSegment(editSegments),
      });
      if (editSegments.length) {
        setValue('segmentIds', getMultiselectOptionsSegment(editSegments));
      }
    }
  }, [editUser, method, setValue]);

  const items = useMemo(() => {
    return getData(getMultiselectOptionsSegment(segments), 'segmentIds');
  }, [segments, getData]);

  const segmentIds = watch('segmentIds');

  useEffect(() => {
    clearErrors('segmentIds');
  }, [segmentIds, clearErrors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
      <Flex justifyContent="space-between" alignItems="center">
        <Header bread={breadcrumb} tag="header" title="Usuário" />
        <div>
          <Button name="Salvar" type="submit" data-testid="save-button-test" />
        </div>
      </Flex>
      <Card>
        <Row>
          <Col desktop={8}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              render={({ onChange, value }) => (
                <Input
                  data-testid="input-name-test"
                  value={value}
                  onChange={onChange}
                  placeholder="Nome completo sem abreviação"
                  label="Nome do usuário"
                  type="text"
                  maxLength="50"
                  required
                  status={errors.name && 'error'}
                  statusMessage={errors.name && 'Campo requerido'}
                />
              )}
            />
          </Col>
          <Col desktop={4}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="account"
              render={({ onChange, value }) => (
                <Input
                  data-testid="input-account-test"
                  value={value}
                  onChange={onChange}
                  placeholder="Insira a matrícula VLI"
                  label="Matrícula"
                  type="text"
                  maxLength="8"
                  required
                  styles={{ marginRight: '10px' }}
                  status={errors.account && 'error'}
                  statusMessage={errors.account && 'Campo requerido'}
                />
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col desktop={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="segmentIds"
              render={() => (
                <S.SpacingMultselect>
                  <Multiselect
                    width="100%"
                    data-testid="multiselect-test-segment"
                    required
                    name="segmentIds"
                    keyValue="id"
                    placeholder="Insira os segmentos"
                    label="Segmento"
                    selected={selectedItems.segmentIds}
                    listItems={items}
                    sections={false}
                    onChange={(value) => handleItem('segmentIds', value)}
                    status={errors.segmentIds && 'error'}
                    statusMessage={errors.segmentIds && 'Campo requerido'}
                  />
                </S.SpacingMultselect>
              )}
            />
          </Col>

          <Col desktop={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="profileId"
              render={({ onChange, value }) => {
                return (
                  <S.Spacing>
                    <InputSelect
                      value={value}
                      options={getOptionsProfile(profiles)}
                      onChange={onChange}
                      placeholder="Selecione o perfil de acesso"
                      label="Perfil"
                      type="text"
                      maxLength="8"
                      required
                      styles={{ marginRight: '10px' }}
                      status={errors.profileId && 'error'}
                      statusMessage={errors.profileId && 'Campo requerido'}
                    />
                  </S.Spacing>
                );
              }}
            />
          </Col>
        </Row>
      </Card>
    </form>
  );
};
Form.propTypes = {
  editUser: PropTypes.object,
  breadcrumb: PropTypes.array,
  profiles: PropTypes.array,
  segments: PropTypes.array,
  onSubmit: PropTypes.func,
  method: PropTypes.string,
};

Form.defaultProps = {
  editUser: null,
  breadcrumb: [{ href: '#' }],
  profiles: [],
  segments: [],
  method: 'create',
  onSubmit: () => {},
};

export default Form;

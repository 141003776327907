import keys from 'constants/keys';

import jwt from 'jsonwebtoken';

export const SET = (name, value) => {
  localStorage.setItem(name, jwt.sign(value, keys.hashKey));
};

export const GET = (name) => {
  const token = localStorage.getItem(name);
  if (token) return jwt.verify(token, keys.hashKey);
  return {};
};

export const REMOVE = (name) => localStorage.removeItem(name);

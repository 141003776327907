import React from 'react';
import CellStatus from 'components/core/Table/CellStatus';
import CellTable from 'components/core/Table/CellTable/CellTable';
import CellEditMultiSelect from 'components/core/Table/CellEditMultiSelect/CellEditMultiSelect';

import CellWithActions from 'components/contexts/local/CellWithActions';

export const Columns = ({ options, onChangeSupplier }) => [
  {
    Header: 'Status',
    accessor: 'active',
    align: 'left',
    width: 20,
    Cell: (props) => <CellStatus {...props} />,
  },
  {
    Header: 'Base de diesel',
    accessor: 'name',
    align: 'left',
    width: 400,
    Cell: (props) => <CellTable {...props} align="left" />,
  },
  {
    Header: 'Vínculo',
    accessor: 'suppliers',
    align: 'left',
    width: 200,
    Cell: (props) => (
      <CellEditMultiSelect
        {...props}
        align="left"
        onChange={onChangeSupplier}
        options={options}
        transformedData={(data) => {
          return data?.map((o) => o.name).join(', ');
        }}
      />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'acoes',
    align: 'end',
    width: 10,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

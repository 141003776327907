import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ContractsControlTypes,
  Creators: ContractsControlActions,
} = createActions({
  getContractsControlReport: ['params'],
  getContractsControlReportSuccess: ['payload'],
  getContractsControlReportFailure: ['error'],
  downloadContractsControlReport: ['params'],
  downloadContractsControlReportSuccess: [],
  downloadContractsControlReportFailure: ['error'],
});

const INITIAL_STATE = {
  error: null,
  reportData: [],
  filter: {},
  contractsControlRequests: {
    [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT]: REQUEST_NOT_STARTED,
  },
};

const getContractsControlReport = (state) => ({
  ...state,
  contractsControlRequests: {
    ...state.contractsControlRequests,
    [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT]: REQUEST_PENDING,
  },
});

const getContractsControlReportSuccess = (state, { payload }) => ({
  ...state,
  reportData: payload,
  contractsControlRequests: {
    ...state.contractsControlRequests,
    [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT]: REQUEST_RESOLVED,
  },
});

const getContractsControlReportFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  contractsControlRequests: {
    ...state.contractsControlRequests,
    [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT]: REQUEST_REJECTED,
  },
});

const downloadContractsControlReport = (state) => ({
  ...state,
  contractsControlRequests: {
    ...state.contractsControlRequests,
    [ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT]: REQUEST_PENDING,
  },
});

const downloadContractsControlReportSuccess = (state) => ({
  ...state,
  contractsControlRequests: {
    ...state.contractsControlRequests,
    [ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT]: REQUEST_RESOLVED,
  },
});

const downloadContractsControlReportFailure = (
  state,
  { error: { response } },
) => ({
  ...state,
  error: response,
  contractsControlRequests: {
    ...state.contractsControlRequests,
    [ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT]: getContractsControlReport,
  [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT_SUCCESS]: getContractsControlReportSuccess,
  [ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT_FAILURE]: getContractsControlReportFailure,
  [ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT]: downloadContractsControlReport,
  [ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT_SUCCESS]: downloadContractsControlReportSuccess,
  [ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT_FAILURE]: downloadContractsControlReportFailure,
});

import React from 'react';
import PropTypes from 'prop-types';

import { Modal as Modalcustom } from '@vli/locomotive-ds/dist';

const Modal = ({ open, children, title, actions, width, onClose }) => (
  <Modalcustom
    data-testid="modal-test"
    isOpen={open}
    title={title}
    width={width}
    actions={actions()}
    onClose={onClose}
  >
    {children}
  </Modalcustom>
);
Modal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  width: PropTypes.string,
  actions: PropTypes.func,
};
Modal.defaultProps = {
  open: true,
  title: '',
  width: '480px',
  onClose: () => {},
  actions: () => {},
};
export default Modal;

import { useEffect } from 'react';
import { Tokens } from '@vli/locomotive-ds';
import useDimensions from './useDimensions';

const useCollapseResize = () => {
  const dimensions = useDimensions();
  const wrapper = document.getElementById('menu-wrapper');
  const container = document.getElementById('menu-container');
  const header = document.getElementById('menu-header');

  useEffect(() => {
    const collapse = () => {
      if (wrapper && container && header) {
        wrapper.classList.remove('expanded');
        container.classList.remove('expanded');
        header.classList.remove('expanded');
        wrapper.classList.add('collapse');
        container.classList.add('collapse');
        header.classList.add('collapse');
      }
    };
    const expanded = () => {
      if (wrapper && container && header) {
        wrapper.classList.remove('collapse');
        container.classList.remove('collapse');
        header.classList.remove('collapse');
        wrapper.classList.add('expanded');
        container.classList.add('expanded');
        header.classList.add('expanded');
      }
    };
    if (dimensions.width >= Tokens.media.breakpoints.md) {
      expanded();
    } else {
      collapse();
    }
  }, [container, dimensions, header, wrapper]);
};

export default useCollapseResize;

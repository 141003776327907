import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/core/Table';
import { Heading } from '@vli/locomotive-ds/dist';
import AdditionsColumns from '../AdditionChevron/AdditionsColumns';
import * as S from './AdditionsSublist.styles';

const ContractAdditionsList = ({ data }) => {
  return (
    <S.Wrapper>
      <Heading tag="h4">Aditivos agregados</Heading>
      <Table
        data-testid="contractlist-additions-test"
        width="100%"
        columns={AdditionsColumns}
        data={data}
        sortBy={[{ id: 'contractStartPeriod', desc: false }]}
      />
    </S.Wrapper>
  );
};

ContractAdditionsList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ContractAdditionsList;

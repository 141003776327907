import styled from 'styled-components';

export const Cell = styled.div`
  height: 29px;
  display: flex;
  justify-content: end;
  align-items: center;
  transition-timing-function: ease-out;
  padding: 2px;
`;

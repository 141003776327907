/* eslint-disable no-dupe-keys */
import styled from 'styled-components';
import css from '@styled-system/css';
import { Tokens } from '@vli/locomotive-ds/dist';

export const Table = styled.table`
  width: 100%;
  background: ${Tokens.colors.grayWhite};
  border: 1px solid ${Tokens.colors.gray200};
  border-radius: 4px;
  border-spacing: 0px;
  .internal {
    tr {
      td {
        border-bottom: 1px solid ${Tokens.colors.gray200};
      }
    }

    tr:last-child {
      td {
        border-bottom: 0px;
      }
    }
  }
`;
export const Thead = styled('thead')(
  css({
    minHeight: '45px',
    width: 'auto',
    borderTop: `1px solid ${Tokens.colors.gray200}`,
  }),
);
export const Th = styled('th')(
  css({
    alignItems: 'center',
    margin: 0,
    borderBottom: `1px solid ${Tokens.colors.gray200}`,
    padding: '10px',
    font: Tokens.fonts.textRegularSmall,
    fontSize: '12px',
    lineHeight: '16px',
    background: Tokens.colors.grayWhite,
    color: Tokens.colors.gray400,
    ':last-child': {
      borderRight: 0,
    },
  }),
);
export const Td = styled('td')(
  css({
    margin: 0,
    height: '45px',
    background: Tokens.colors.grayWhite,
    borderBottom: `1px solid ${Tokens.colors.gray200}`,
    font: Tokens.fonts.textRegularMedium,
    lineHeight: '20px',
    color: Tokens.colors.grayBlack,
  }),
  ({ padding }) => (padding ? `padding: ${padding};` : 'padding: 4px 8px;'),
);

export const Tr = styled('tr')(
  css({
    '&.expanded': {
      td: {
        backgroundColor: Tokens.colors.gray100,
        ':first-child': {
          div: {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        },
      },
    },
    '&.editMode': {
      backgroundColor: Tokens.colors.accentPrimaryLight,
      td: {
        backgroundColor: Tokens.colors.accentPrimaryLight,
      },
    },
    '&.opacity': {
      td: {
        opacity: 0.5,
        ':last-child': {
          opacity: 1,
        },
      },
    },
    ':last-child': {
      td: {
        borderBottom: 0,
      },
    },
  }),
);

export const IconContainer = styled('div')(
  css({
    display: 'inline-flex',
    alignItems: 'center',
  }),
  ({ align }) =>
    align ? `justify-content: flex-${align};` : 'justify-content: center;',
);

import useQuery from 'hooks/useQuery';
import { history } from 'constants/routes';

const useSearchParams = () => {
  const searchParams = useQuery();

  const setSearchParams = (searchName, searchValue) => {
    searchParams.set(searchName, String(searchValue));
    history.push({ search: searchParams.toString() });
  };

  return [searchParams, setSearchParams];
};

export default useSearchParams;

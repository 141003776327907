import { put, takeLatest } from 'redux-saga/effects';
import {
  RESOURCE_REACTIVATEITEMS,
  RESOURCE_REACTIVATEITEM,
} from 'constants/endpoints';
import api from 'services/api';
import { GET, PUT } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import {
  ReactivateItemsActions,
  ReactivateItemsTypes,
} from './reactivateItems.ducks';

export function* reactivateItems({ filter, filterData }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_REACTIVATEITEMS}?${filter}`,
    });

    yield put(
      ReactivateItemsActions.reactivateItemsListSuccess(
        response.data,
        filterData,
      ),
    );
  } catch (error) {
    yield put(ReactivateItemsActions.reactivateItemsListFailure(error));
  }
}

export function* reactivateItemRequest({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_REACTIVATEITEM}`,
      data: payload,
    });

    yield put(ReactivateItemsActions.reactivateItemSuccess());

    yield put(
      MessagesActions.open({
        title: 'Reativado com sucesso',
        type: 'success',
        contentText: `Seleção de itens reativada com sucesso!`,
      }),
    );

    if (callback) callback();
  } catch (error) {
    let messages = 'Erro ao reativar item!';
    if (error.response.data?.errors) {
      messages = error.response.data.errors;
    }
    yield put(
      MessagesActions.open({
        title: 'Erro',
        type: 'error',
        contentText: messages,
      }),
    );
    yield put(ReactivateItemsActions.reactivateItemFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(
    ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_REQUEST,
    reactivateItems,
  );
  yield takeLatest(
    ReactivateItemsTypes.REACTIVATE_ITEM_REQUEST,
    reactivateItemRequest,
  );
}

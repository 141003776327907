import styled from 'styled-components';
import { Tokens as theme } from '@vli/locomotive-ds';
import TableContainer from 'components/core/TableContainer';

export const Title = styled.span`
  font: ${theme.fonts.textBoldLarge};
`;

export const Container = styled(TableContainer)`
  margin-top: ${theme.spacing.md};
`;

import { formatVolume } from 'lib/core/formatting';
import { REQUEST_PENDING } from 'constants/request';
import { setHours } from 'date-fns';

export const FormalizationSteps = {
  Draft: 1,
  UnderReview: 2,
  SentToFormalization: 3,
  ReturnedToPlaintiff: 4,
  LegalRequested: 7,
  PricingRequested: 8,
};

export const FormalizationStatus = {
  InProgress: 1,
  Canceled: 2,
  Concluded: 3,
};

export const CompensationTypes = {
  ALL_THE_FLOWS: 1,
  BY_A_FLOW: 2,
  BY_FLOW_GROUP: 3,
};

export const DefaultDieselFormulasIds = [15];
export const DefaultInflationFormulasIds = [11, 16];

export const formalizationFormDefaultValues = {
  parametrics: {
    startDate: null,
    endDate: null,
    typeFormalization: 0,
    instrumentReference: '',
    loadingDate: null,
    baseDate: null,
    applicationDateDay: 0,
    firstReadjustment: '',
  },
  indices: {
    diesel: null,
    railwayIGPM: null,
    railwayIPCA: null,
    harborIGPM: null,
    harborIPCA: null,
  },
  formulas: {
    dieselFormulaRailway: null,
    inflationFormulaRailway: null,
    inflationFormulaHarbor: null,
  },
};

export const getDataFromFormEdit = (data, formalizationId) => {
  const {
    startDate,
    endDate,
    typeFormalization,
    instrumentReference,
    loadingDate,
    baseDate,
    applicationDateDay,
    firstReadjustment,
    diesel,
    railwayIGPM,
    railwayIPCA,
    harborIGPM,
    harborIPCA,
    dieselFormulaRailway,
    inflationFormulaRailway,
    inflationFormulaHarbor,
  } = data;

  return {
    id: formalizationId,
    startDate: setHours(startDate, 0),
    endDate: setHours(endDate, 0),
    loadingDate: setHours(loadingDate, 0),
    instrumentType: typeFormalization,
    instrumentReference,
    priceParametric: {
      database: baseDate,
      applicationDateDay,
      firstReadjustmentDate: new Date(firstReadjustment),
    },
    indexes: {
      diesel,
      railwayIGPM,
      railwayIPCA,
      harborIGPM,
      harborIPCA,
    },
    formulas: {
      dieselFormulaRailway,
      inflationFormulaRailway,
      inflationFormulaHarbor,
    },
  };
};

export const getDataToSendRequest = (formalizationData) => {
  const payload = {
    id: formalizationData?.formalization?.id,
  };
  return payload;
};

export const formatDataFromRequest = (response) => {
  return {
    ...response.data.data,
    proposalItems: response.data.data.proposalItems.map((item) => {
      return {
        ...item,
        volumeFormatted: formatVolume(item.volume),
      };
    }),
  };
};

export const getFormalizationStepByStatus = ({ step, status, statusId }) => {
  return statusId === FormalizationStatus.InProgress ? step : status;
};

export const setPageLoading = (requests) => {
  return Object.values(requests).includes(REQUEST_PENDING);
};

// Validação de formulário

export const validateValues = {
  startDate: {
    value: null,
    type: 'parametrics',
  },
  endDate: {
    value: null,
    type: 'parametrics',
  },
  typeFormalization: {
    value: 0,
    type: 'parametrics',
  },
  instrumentReference: {
    value: '',
    type: 'parametrics',
  },
  loadingDate: {
    value: null,
    type: 'parametrics',
  },
  baseDate: {
    value: null,
    type: 'parametrics',
  },
  applicationDateDay: {
    value: 0,
    type: 'parametrics',
  },
  firstReadjustment: {
    value: '',
    type: 'parametrics',
  },
  diesel: {
    value: null,
    type: 'indices',
  },
  dieselFormulaRailway: {
    value: null,
    type: 'formulas',
  },
  inflationFormulaRailway: {
    value: null,
    type: 'formulas',
  },
  inflationFormulaHarbor: {
    value: null,
    type: 'formulas',
  },
};

export const validateAdditiveValues = {
  startDate: {
    value: null,
    type: 'parametrics',
  },
  endDate: {
    value: null,
    type: 'parametrics',
  },
  typeFormalization: {
    value: 0,
    type: 'parametrics',
  },
  instrumentReference: {
    value: '',
    type: 'parametrics',
  },
  loadingDate: {
    value: null,
    type: 'parametrics',
  },
};

export const validateRequiredFormValues = (formValues, isAdditiveFill) => {
  const values = isAdditiveFill ? validateAdditiveValues : validateValues;
  const formFields = Object.keys(values);
  const formDefaultValues = Object.values(values);
  let validatedFields = { errors: null };

  formFields.forEach((field, index) => {
    if (formValues[field] === formDefaultValues[index].value) {
      const formSection = formDefaultValues[index].type;

      validatedFields = {
        ...validatedFields,
        errors: {
          ...validatedFields.errors,
          [formSection]: {
            ...validatedFields.errors?.[formSection],
            [field]: 'Campo requerido',
          },
        },
      };
    }
  });

  return validatedFields;
};

export const validateIndicesValues = (formValues, isAdditiveFill) => {
  let errors = null;

  if (isAdditiveFill) {
    return { indices: errors };
  }

  const railwaySum =
    formValues?.diesel + formValues?.railwayIGPM + formValues?.railwayIPCA ||
    null;

  const harborSum = formValues?.harborIGPM + formValues?.harborIPCA || null;

  if (railwaySum) {
    const validatedRailwayIndices =
      railwaySum !== 100
        ? 'A soma dos percentuais de diesel e inflação deve ser igual a 100%'
        : null;

    if (validatedRailwayIndices) {
      errors = {
        ...errors,
        railway: validatedRailwayIndices,
      };
    }
  }

  if (harborSum) {
    const validateHarborIndices =
      harborSum !== 100
        ? 'A soma dos percentuais de inflação deve ser igual a 100%'
        : null;

    if (validateHarborIndices) {
      errors = {
        ...errors,
        harbor: validateHarborIndices,
      };
    }
  }

  return { indices: errors };
};

// Check Standard Company Values

export const checkDieselValue = (dieselValue) => {
  if (dieselValue && dieselValue !== 30) {
    return true;
  }

  return false;
};

export const checkDieselFormulaRailwaySelected = (selectedFormula) => {
  if (selectedFormula && !DefaultDieselFormulasIds.includes(selectedFormula)) {
    return true;
  }

  return false;
};

export const checkInflationFormulaRailwaySelected = (selectedFormula) => {
  if (
    selectedFormula &&
    !DefaultInflationFormulasIds.includes(selectedFormula)
  ) {
    return true;
  }

  return false;
};

export const checkInflationFormulaHarborSelected = (selectedFormula) => {
  if (
    selectedFormula &&
    !DefaultInflationFormulasIds.includes(selectedFormula)
  ) {
    return true;
  }

  return false;
};

export const checksForNonStandardData = ({ formulas, indices }) => {
  let hasNonStandardData = false;

  const dieselValue = checkDieselValue(indices.diesel);
  const dieselFormulaRailwaySelected = checkDieselFormulaRailwaySelected(
    +formulas.dieselFormulaRailway,
  );
  const inflationFormulaRailwaySelected = checkInflationFormulaRailwaySelected(
    +formulas.inflationFormulaRailway,
  );
  const inflationFormulaHarborSelected = checkInflationFormulaHarborSelected(
    +formulas.inflationFormulaHarbor,
  );

  if (
    dieselValue ||
    dieselFormulaRailwaySelected ||
    inflationFormulaRailwaySelected ||
    inflationFormulaHarborSelected
  ) {
    hasNonStandardData = true;
  }

  return {
    hasNonStandardData,
    fields: {
      diesel: dieselValue ? indices.diesel : null,
      dieselFormulaRailway: dieselFormulaRailwaySelected
        ? +formulas.dieselFormulaRailway
        : null,
      inflationFormulaRailway: inflationFormulaRailwaySelected
        ? +formulas.inflationFormulaRailway
        : null,
      inflationFormulaHarbor: inflationFormulaHarborSelected
        ? +formulas.inflationFormulaHarbor
        : null,
    },
  };
};

export const displayLocation = (location) => {
  return location ? ` - ${location}` : '';
};

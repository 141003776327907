import styled from 'styled-components';
import { Tokens as theme, Accordion } from '@vli/locomotive-ds/dist';
import css from '@styled-system/css';

export const CardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const ContainerForm = styled.div`
  margin-top: ${theme.spacing.xs};
`;
export const ContainerButton = styled.div`
  margin-top: auto;
`;
export const GroupTitle = styled('span')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray400,
    height: '32px',
  }),
);
export const Label = styled('span')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: theme.fonts.textRegularSmall,
    color: theme.colors.gray400,
    marginBottom: '5px',
    div: {
      cursor: 'pointer',
      marginLeft: '4px',
    },
  }),
);
export const Group = styled('ul')(
  css({
    position: 'relative',
    userSelect: 'none',
    listStyle: 'none inside none',
    margin: '0 0 16px 0',
    padding: 0,
    display: 'flex',
    paddingRight: 4,
  }),
);

export const MessageErrors = styled.div`
  position: absolute;
`;

export const ContainerDate = styled.div`
  width: 255px;

  .react-date-picker__wrapper {
    min-width: 130px !important;
  }
`;

export const Separate = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.lg};
`;

export const TitleSupplier = styled.div`
  margin-top: ${theme.spacing.lg};
`;

export const AccordionContainer = styled(Accordion)`
  margin-top: ${theme.spacing.lg};
  width: 100%;
`;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const { Types: ModelsTypes, Creators: ModelsActions } = createActions({
  templatesRequest: ['payload'],
  templatesSuccess: ['payload'],
  templatesFailure: ['error'],
  templateUploadRequest: ['payload', 'templateType', 'callback'],
  templateUploadSuccess: ['payload'],
  templateUploadFailure: ['error'],
  templateDownloadRequest: ['payload'],
  templateDownloadSuccess: ['payload'],
  templateDownloadFailure: ['error'],
});

const INITIAL_STATE = {
  templatesList: [],
  error: null,
  templateLoading: null,
  templatesRequests: {
    [ModelsTypes.TEMPLATES_REQUEST]: REQUEST_NOT_STARTED,
    [ModelsTypes.TEMPLATE_UPLOAD_REQUEST]: REQUEST_NOT_STARTED,
    [ModelsTypes.TEMPLATE_DOWNLOAD_REQUEST]: REQUEST_NOT_STARTED,
  },
};

const templatesRequest = (state) => ({
  ...state,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATES_REQUEST]: REQUEST_PENDING,
  },
});

const templatesSuccess = (state, { payload }) => ({
  ...state,
  templatesList: payload.data,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATES_REQUEST]: REQUEST_RESOLVED,
  },
});

const templatesFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATES_REQUEST]: REQUEST_REJECTED,
  },
});

const templateUploadRequest = (state, { templateType }) => ({
  ...state,
  error: null,
  templateLoading: templateType,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATE_UPLOAD_REQUEST]: REQUEST_PENDING,
  },
});

const templateUploadSuccess = (state) => ({
  ...state,
  templateLoading: null,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATE_UPLOAD_REQUEST]: REQUEST_RESOLVED,
  },
});

const templateUploadFailure = (state, { error: { response } }) => ({
  ...state,
  templateLoading: null,
  error: response,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATE_UPLOAD_REQUEST]: REQUEST_REJECTED,
  },
});

const templateDownloadRequest = (state) => ({
  ...state,
  error: null,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATE_DOWNLOAD_REQUEST]: REQUEST_PENDING,
  },
});

const templateDownloadSuccess = (state) => ({
  ...state,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATE_DOWNLOAD_REQUEST]: REQUEST_RESOLVED,
  },
});

const templateDownloadFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  templatesRequests: {
    ...state.templatesRequests,
    [ModelsTypes.TEMPLATE_DOWNLOAD_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ModelsTypes.TEMPLATES_REQUEST]: templatesRequest,
  [ModelsTypes.TEMPLATES_SUCCESS]: templatesSuccess,
  [ModelsTypes.TEMPLATES_FAILURE]: templatesFailure,
  [ModelsTypes.TEMPLATE_UPLOAD_REQUEST]: templateUploadRequest,
  [ModelsTypes.TEMPLATE_UPLOAD_SUCCESS]: templateUploadSuccess,
  [ModelsTypes.TEMPLATE_UPLOAD_FAILURE]: templateUploadFailure,
  [ModelsTypes.TEMPLATE_DOWNLOAD_REQUEST]: templateDownloadRequest,
  [ModelsTypes.TEMPLATE_DOWNLOAD_SUCCESS]: templateDownloadSuccess,
  [ModelsTypes.TEMPLATE_DOWNLOAD_FAILURE]: templateDownloadFailure,
});

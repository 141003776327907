import { Card } from '@vli/locomotive-ds';
import React from 'react';
import { useSelector } from 'react-redux';

import ProposalItem from './Item';

import * as S from './FormalizationItemsCardStyles';

const FormalizationItemsCard = () => {
  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const items = formalizationData?.proposalItems?.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const checkIfHasServices = (saleId) => {
    const sale = formalizationData?.openningPriceService.find(
      (op) => op.saleId === saleId,
    );

    return sale?.isOpenningPriceService;
  };

  return (
    <Card data-testid="formalization-request-items-card-test">
      <S.Title>Itens solicitação de formalização</S.Title>

      <S.ItemsList>
        {items?.map((item) => (
          <ProposalItem
            key={`${item.origin}${item.destination}${item.product}${item.id}`}
            proposalItem={item}
            hasServicesToDisplay={checkIfHasServices(item.saleId)}
          />
        ))}
      </S.ItemsList>
    </Card>
  );
};

export default FormalizationItemsCard;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    cursor: pointer;
  }
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Loading } from '@vli/locomotive-ds/dist';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import { isPending } from 'lib/core/request';
import { capitalizeFirstString } from 'lib/core/utility';

import InfoItem from './InfoItem';
import SalesDataSection from './Sections/SalesData';
import VolumeSection from './Sections/Volume';
import PriceSection from './Sections/Price';

import * as S from './PriceAndVolumeModalStyles';

const PriceAndVolumeModal = ({ isOpen, onClose, saleId }) => {
  const dispatch = useDispatch();

  const { groupedSales, salesRequest } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(FormalizationRequestActions.groupedSalesBySaleId(saleId));
    }
  }, [saleId, dispatch, isOpen]);

  const isLoading = isPending(salesRequest.GROUPED_SALES_BY_SALE_ID);

  const formatDate = (date) => {
    if (date) {
      return capitalizeFirstString(
        format(new Date(date), "MMMM 'de' yyyy", { locale: ptBR }),
      );
    }

    return '-';
  };

  const period = `${formatDate(groupedSales?.beginningValidity)} - 
  ${formatDate(groupedSales?.endValidity)}`;

  return (
    <S.ModalContainer
      title="Detalhes de volume e preço por período"
      isOpen={isOpen}
      onClose={onClose}
      width="1150px"
    >
      <S.Scroll>
        {isLoading && (
          <Loading fitParent message="Por favor, espere alguns segundos..." />
        )}

        {groupedSales && (
          <S.Wrapper>
            <SalesDataSection
              modalName={groupedSales.modalName}
              subSegment={groupedSales.subSegment}
              segment={groupedSales.segment}
              originName={groupedSales.originName}
              destinyName={groupedSales.destinyName}
              customerName={groupedSales.customerName}
              productName={groupedSales.productName}
            />

            <S.Section data-testid="period-section-test">
              <S.Title>Período</S.Title>

              <InfoItem title="Data de início e término" content={period} />
            </S.Section>

            <VolumeSection
              volumeData={groupedSales.validityVolumeViewModel}
              period={period}
            />

            <PriceSection
              priceData={groupedSales.validityPriceViewModel}
              period={period}
            />
          </S.Wrapper>
        )}
      </S.Scroll>
    </S.ModalContainer>
  );
};

PriceAndVolumeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  saleId: PropTypes.number.isRequired,
};

export default PriceAndVolumeModal;

import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Timeline.styles';

const Timeline = ({ title, isActive, name, date, hour }) => {
  return (
    <S.Container data-testid="timeline">
      <S.Title isActive={isActive}>{title}</S.Title>
      <S.PointTimeLineContainer>
        <S.Point isActive={isActive} />
        <S.HorizontalLine isActive={isActive} />
      </S.PointTimeLineContainer>
      <S.TextInfo isActive={isActive}>{name}</S.TextInfo>
      <S.TextInfo isActive={isActive}>
        {date} - {hour}
      </S.TextInfo>
    </S.Container>
  );
};

Timeline.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  hour: PropTypes.string,
};
Timeline.defaultProps = {
  isActive: false,
  title: '',
  name: '',
  date: '',
  hour: '',
};

export default Timeline;

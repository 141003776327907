import { ReportsIcon, Tokens } from '@vli/locomotive-ds/dist';
import Tooltip from 'components/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import TooltipInfo from './TooltipInfo';

import * as S from './CellServicesStyles';

const CellServices = ({ row, onClick }) => {
  const { original } = row;

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
  };

  const newTooltip = original?.adjustesPriceServiceTooltip;

  if (!newTooltip || newTooltip.values.length === 0) {
    return <>-</>;
  }

  return (
    <Tooltip
      title={ReactDOMServer.renderToString(
        <TooltipInfo data={original.adjustesPriceServiceTooltip} />,
      )}
      id={row.id}
      place="top"
    >
      <S.CustomText
        data-testid={`cell-table-services-${row.id}`}
        onClick={handleOnClick}
      >
        <div>
          <span>Ver abertura</span>
          <ReportsIcon />
        </div>
      </S.CustomText>
    </Tooltip>
  );
};

CellServices.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onClick: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      historyOrigin: PropTypes.number,
      tooltip: PropTypes.arrayOf(PropTypes.object),
      flagFarol: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

CellServices.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    id: 0,
    original: {
      id: 1,
      flagFarol: Tokens.colors.grayBlack,
    },
  },
  onClick: () => {},
};

export default CellServices;

import { put, takeLatest } from 'redux-saga/effects';

import {
  INDICES_HISTORY,
  DOWNLOAD_LETTER,
  GET_INFLATIONARY_INDICES,
  DIESEL_READJUSTMENT_UPDATE,
  SAVE_INFLATIONARY_INDICES,
  INDICES_HISTORY_REPORT,
} from 'constants/endpoints';
import messages from 'constants/messages';
import { GET, PUT, POST } from 'constants/verbs';

import api from 'services/api';

import * as utility from 'lib/core/utility';
import { setFilterDefault, getFilterParams } from 'lib/contexts/indices';

import { MessagesActions } from 'store/messages/messages.ducks';

import { IndicesActions } from '../indices/indices.ducks';
import {
  IndicesHistoryTypes,
  IndicesHistoryActions,
} from './indicesHistory.ducks';

export function* listIndicesHistoryWithInflation() {
  try {
    const indices = yield api({
      method: GET,
      url: GET_INFLATIONARY_INDICES,
    });
    const ids = [];
    indices.data.data.forEach((indice) => {
      ids.push(indice.id);
    });
    const values = { ids };
    const response = yield api({
      method: POST,
      url: INDICES_HISTORY,
      data: values,
    });

    const params = getFilterParams(setFilterDefault(indices.data.data));

    yield put(IndicesActions.listInflationarySuccess(indices.data.data));
    yield put(
      IndicesHistoryActions.listIndicesHistorySuccess({
        response: response.data.data,
        params,
      }),
    );
  } catch (error) {
    yield put(IndicesHistoryActions.indicesHistoryRequestFailure(error));
  }
}

export function* listIndicesHistory({ params }) {
  try {
    const response = yield api({
      method: POST,
      url: INDICES_HISTORY,
      data: params.filterFields,
    });

    yield put(
      IndicesHistoryActions.listIndicesHistorySuccess({
        response: response.data.data,
        params,
      }),
    );
  } catch (error) {
    yield put(IndicesHistoryActions.indicesHistoryRequestFailure(error));
  }
}

export function* dieselReadjustmentEdit({ readjustment, callback }) {
  try {
    yield api({
      method: PUT,
      url: DIESEL_READJUSTMENT_UPDATE,
      data: readjustment,
    });
    yield put(
      MessagesActions.open({
        title: 'Diesel Atualizado',
        type: 'success',
        contentText: messages.messages.diesels.success,
      }),
    );
    yield put(IndicesHistoryActions.dieselReadjustmentEditSuccess());
    yield callback();
  } catch (error) {
    let message = messages.messages.diesels.error;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: 'Índice não atualizado',
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );
    yield put(IndicesHistoryActions.indicesHistoryRequestFailure(message));
  }
}

export function* inflationaryIndicesReadjustmentEdit({
  readjustment,
  callback,
}) {
  try {
    yield api({
      method: PUT,
      url: SAVE_INFLATIONARY_INDICES,
      data: readjustment,
    });
    yield put(
      MessagesActions.open({
        title: 'Índice Atualizado',
        type: 'success',
        contentText: messages.messages.inflation.success,
      }),
    );
    yield put(IndicesHistoryActions.inflationaryReadjustmentEditSuccess());
    yield callback();
  } catch (error) {
    let message = messages.messages.inflation.error;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.open({
        title: 'Índice não atualizado',
        type: 'error',
        contentText: message,
      }),
    );
    yield put(IndicesHistoryActions.indicesHistoryRequestFailure(message));
  }
}

export function* downloadLetter({ params }) {
  const { data, type, filename } = params;
  try {
    const response = yield api({
      method: POST,
      url: `${DOWNLOAD_LETTER}/${type}`,
      responseType: 'arraybuffer',
      data,
    });
    yield utility.downloadFile(response.data, filename);
    yield put(IndicesHistoryActions.downloadLetterSuccess());
  } catch (error) {
    let message = 'Falha ao baixar a carta';
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.open({
        title: 'Falha no download',
        type: 'error',
        contentText: message,
      }),
    );
    yield put(IndicesHistoryActions.indicesHistoryRequestFailure(error));
  }
}

export function* downloadReport({ payload }) {
  try {
    yield api({
      method: POST,
      url: INDICES_HISTORY_REPORT,
      data: payload,
    });

    yield put(IndicesHistoryActions.downloadReportSuccess());

    yield put(
      MessagesActions.open({
        title: 'Dados enviados com sucesso',
        type: 'success',
        contentText: 'O relatório será enviado via email!',
      }),
    );
  } catch (error) {
    let message = 'Não foi possível enviar o relatório';

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.open({
        title: 'Falha ao enviar dados',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(IndicesHistoryActions.downloadReportFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(
    IndicesHistoryTypes.LIST_INDICES_HISTORY_WITH_INFLATION,
    listIndicesHistoryWithInflation,
  );
  yield takeLatest(
    IndicesHistoryTypes.LIST_INDICES_HISTORY,
    listIndicesHistory,
  );
  yield takeLatest(
    IndicesHistoryTypes.DIESEL_READJUSTMENT_EDIT_START,
    dieselReadjustmentEdit,
  );
  yield takeLatest(
    IndicesHistoryTypes.INFLATIONARY_READJUSTMENT_EDIT_START,
    inflationaryIndicesReadjustmentEdit,
  );
  yield takeLatest(IndicesHistoryTypes.DOWNLOAD_LETTER, downloadLetter);
  yield takeLatest(IndicesHistoryTypes.DOWNLOAD_REPORT, downloadReport);
}

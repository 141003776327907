import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import * as S from './AdditionChevron.styles';

const AdditionChevron = ({ isInverted, onClick }) => {
  return (
    <S.Button
      data-testid="addition-chevron-test"
      type="button"
      onClick={onClick}
      isInverted={isInverted}
    >
      <ChevronDownIcon />
    </S.Button>
  );
};

AdditionChevron.propTypes = {
  isInverted: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AdditionChevron;

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { capitalizeFirstString, simpleId } from 'lib/core/utility';
import { formatVolume } from 'lib/core/formatting';
import { displayLocation } from 'lib/contexts/formalizationRequest';

import InfoItem from '../InfoItem';
import VolumeBox from '../VolumeBox';

import * as S from '../PriceAndVolumeModalStyles';

const Volume = ({ volumeData, period }) => {
  const formatDate = (date, pattern = 'MMMM yyyy') => {
    if (date) {
      return capitalizeFirstString(
        format(new Date(date), pattern, { locale: ptBR }),
      );
    }

    return '-';
  };

  const separatedDataByVolumeTerms = volumeData?.reduce((result, item) => {
    const term = item.openingsVolumeTerms;
    if (!result[term]) {
      result[term] = [];
    }
    result[term].push(item);
    return result;
  }, {});

  return (
    <S.Section data-testid="volume-section-test">
      <S.Title>Volume</S.Title>

      {separatedDataByVolumeTerms?.Mensal && (
        <S.LineWrapper>
          <span>Volume informado Mensal</span>
          <S.ColumnWrapper>
            {separatedDataByVolumeTerms.Mensal.map((volume) => (
              <S.VolumeContainer key={`volume-mensal-${simpleId()}`}>
                <small>{formatDate(volume.beginningValidity)}</small>

                {volume.volumeViewModel.map((item) => (
                  <VolumeBox
                    key={`volume-mensal-destination-${simpleId()}`}
                    item={item}
                  />
                ))}
              </S.VolumeContainer>
            ))}
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}

      {separatedDataByVolumeTerms?.['Período Total'] && (
        <S.LineWrapper>
          <span>Volume informado Período Total</span>
          <S.ColumnWrapper>
            <S.VolumeContainer key={`volume-total-${simpleId()}`}>
              <small>{period}</small>

              {separatedDataByVolumeTerms[
                'Período Total'
              ][0].volumeViewModel.map((item) => (
                <VolumeBox
                  key={`volume-total-destination-${simpleId()}`}
                  item={item}
                />
              ))}
            </S.VolumeContainer>
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}

      {separatedDataByVolumeTerms?.Anual && (
        <S.LineWrapper>
          <span>Volume informado Anual</span>
          <S.ColumnWrapper>
            {separatedDataByVolumeTerms.Anual.map((volume) => (
              <S.VolumeContainer key={`volume-anual-${simpleId()}`}>
                <small>{formatDate(volume.beginningValidity, 'yyyy')}</small>

                {volume.volumeViewModel.map((item) => (
                  <S.Box key={`volume-anual-destination-${simpleId()}`}>
                    {item.state && item.county ? (
                      <small>
                        {item.state}
                        {displayLocation(item.county)}
                      </small>
                    ) : (
                      <small>
                        {item.state}
                        {displayLocation(item.mesoregion)}
                        {displayLocation(item.microregion)}
                      </small>
                    )}

                    <InfoItem
                      title="Volume"
                      content={formatVolume(item.volumeValue)}
                    />
                  </S.Box>
                ))}
              </S.VolumeContainer>
            ))}
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}
    </S.Section>
  );
};

Volume.propTypes = {
  volumeData: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired,
};

export default Volume;

import { put, takeLatest } from 'redux-saga/effects';
import {
  SUPPLIERS,
  GET_INFLATIONARY_INDICES,
  DIESEL_READJUSTMENT,
  DIESEL_READJUSTMENT_VLI,
  SAVE_INFLATIONARY_INDICES,
} from 'constants/endpoints';
import * as utility from 'lib/core/utility';
import api from 'services/api';
import { GET, POST } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import messages from 'constants/messages';
import { getSuppliersFormat } from 'lib/contexts/indices';
import { IndicesTypes, IndicesActions } from './indices.ducks';

export function* listSuppliers({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: SUPPLIERS(utility.autoListingParamsGet(payload)),
    });
    yield put(
      IndicesActions.listSuppliersSuccess(
        getSuppliersFormat(response.data.data),
      ),
    );
  } catch (error) {
    yield put(IndicesActions.suppliersRequestFailure(error));
  }
}

export function* listIndices() {
  try {
    const response = yield api({
      method: GET,
      url: GET_INFLATIONARY_INDICES,
    });
    yield put(IndicesActions.listInflationarySuccess(response.data.data));
  } catch (error) {
    yield put(IndicesActions.inflationaryRequestFailure(error));
  }
}
export function* dieselReadjustment({
  readjustment,
  isVliSupplier,
  key,
  callback,
}) {
  try {
    const response = yield api({
      method: POST,
      url: isVliSupplier
        ? DIESEL_READJUSTMENT_VLI
        : `${DIESEL_READJUSTMENT}/${key}`,
      data: readjustment,
    });
    const title =
      response.data.data.length > 0 ? 'Diesel Atualizado' : 'Carta Atualizada';
    yield put(
      MessagesActions.open({
        title,
        type: 'success',
        contentText: messages.messages.diesels.success,
      }),
    );
    yield put(IndicesActions.dieselReadjustmentSuccess());
    callback();
  } catch (error) {
    let message = messages.messages.diesels.error;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: 'Índice não atualizado',
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );
    yield put(IndicesActions.dieselReadjustmentRequestFailure(message));
  }
}

export function* inflationaryReadjustment({ readjustment, callback }) {
  try {
    yield api({
      method: POST,
      url: SAVE_INFLATIONARY_INDICES,
      data: readjustment,
    });
    yield put(
      MessagesActions.open({
        title: 'Reajuste de índice inflacionário realizado!',
        type: 'success',
        contentText: messages.messages.inflation.success,
      }),
    );
    yield put(IndicesActions.inflationaryReadjustmentSuccess());
    callback();
  } catch (error) {
    let message = messages.messages.inflation.error;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: 'Reajuste de índice inflacionário não realizado!',
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(IndicesActions.inflationaryReadjustmentRequestFailure(message));
    callback();
  }
}
export function* watchSagas() {
  yield takeLatest(IndicesTypes.LIST_SUPPLIERS, listSuppliers);
  yield takeLatest(IndicesTypes.GET_ALL_SUPPLIERS, listSuppliers);
  yield takeLatest(IndicesTypes.LIST_INFLATIONARY, listIndices);
  yield takeLatest(IndicesTypes.DIESEL_READJUSTMENT_START, dieselReadjustment);
  yield takeLatest(
    IndicesTypes.INFLATIONARY_READJUSTMENT_START,
    inflationaryReadjustment,
  );
}

import * as utility from 'lib/core/utility';
import { GET } from 'lib/core/localStorage';

import { ROLES, ACTIONS, canExecuteAction } from 'lib/contexts/permissions';

export const getFilterParams = (data) => {
  const startPeriodData = data.startPeriod
    ? utility.formatDate(data.startPeriod, 'yyyy-mm-dd')
    : null;
  const endPeriodData = data.endPeriod
    ? utility.formatDate(data.endPeriod, 'yyyy-mm-dd')
    : null;
  const contractIds = data.contract
    ? data.contract.map((c) => c.value.id)
    : null;
  const items = data.item ? data.item.map((i) => i.value.id) : null;

  const filterFields = {
    filterFields: {
      startPeriod: startPeriodData,
      endPeriod: endPeriodData,
      ContractsIds: contractIds,
      Items: items,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };

  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const hasPermissionToDownload = () => {
  const { roles } = GET('roles');

  return canExecuteAction(
    ROLES.COMMERCIAL_NEGOTIATIONS,
    ACTIONS.MANAGE_SERVICE,
    roles,
  );
};

export const DataFilter = (contracts, items) => {
  const contractData = contracts?.map((contract) => ({
    label: contract.code,
    value: {
      id: contract.id,
      others: '',
    },
  }));

  const itemData = items?.map((item) => ({
    label: item,
    value: {
      id: item,
      others: '',
    },
  }));

  const dataFilter = [
    {
      data: contractData,
      label: 'Contrato',
      name: 'contract',
      type: 'multiselect',
    },
    {
      data: itemData,
      label: 'Item ferroviário',
      name: 'item',
      type: 'multiselect',
    },
    {
      label: 'Data inicial',
      name: 'startPeriod',
      type: 'date',
    },
    {
      label: 'Data final',
      name: 'endPeriod',
      type: 'date',
    },
  ];
  return dataFilter;
};

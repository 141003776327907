import * as Yup from 'yup';

export const emailFormSchema = Yup.object().shape({
  notification: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
    }),
  ),
});

import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_FORMULA } from 'constants/endpoints';
import api from 'services/api';
import { GET } from 'constants/verbs';
import { FormulasActions, FormulasTypes } from './formulas.ducks';

export function* getFormulas() {
  try {
    const response = yield api({
      method: GET,
      url: RESOURCE_FORMULA,
    });

    yield put(FormulasActions.formulasItemsSuccess(response.data));
  } catch (error) {
    yield put(FormulasActions.formulasItemsFailure(error));
  }
}

export function* getApprovedFormulas() {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_FORMULA}`,
    });

    yield put(FormulasActions.approvedFormulasItemsSuccess(response.data));
  } catch (error) {
    yield put(FormulasActions.approvedFormulasItemsFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(FormulasTypes.FORMULAS_ITEMS_REQUEST, getFormulas);
  yield takeLatest(
    FormulasTypes.APPROVED_FORMULAS_ITEMS_REQUEST,
    getApprovedFormulas,
  );
}

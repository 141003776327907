import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ActiveContractTypes,
  Creators: ActiveContractActions,
} = createActions({
  activeContract: ['payload'],
  activeContractSuccess: ['payload'],
  activeContractFailure: ['error'],
});

const INITIAL_STATE = {
  activeContractList: [],
  error: null,
  activeContractRequests: {
    [ActiveContractTypes.ACTIVE_CONTRACT]: REQUEST_NOT_STARTED,
  },
};

const activeContract = () => ({
  ...INITIAL_STATE,
  activeContractRequests: {
    ...INITIAL_STATE.activeContractRequests,
    [ActiveContractTypes.ACTIVE_CONTRACT]: REQUEST_PENDING,
  },
});

const activeContractSuccess = (state, { payload }) => ({
  ...state,
  activeContractList: payload,
  activeContractRequests: {
    ...state.activeContractRequests,
    [ActiveContractTypes.ACTIVE_CONTRACT]: REQUEST_RESOLVED,
  },
});
const activeContractFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  activeContractRequests: {
    ...state.activeContractRequests,
    [ActiveContractTypes.ACTIVE_CONTRACT]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ActiveContractTypes.ACTIVE_CONTRACT]: activeContract,
  [ActiveContractTypes.ACTIVE_CONTRACT_SUCCESS]: activeContractSuccess,
  [ActiveContractTypes.ACTIVE_CONTRACT_FAILURE]: activeContractFailure,
});

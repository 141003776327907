import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: AccountManagerTypes,
  Creators: AccountManagerActions,
} = createActions({
  listAccountManager: ['params'],
  listAccountManagerSuccess: ['payload'],
  accountManagerRequestFailure: ['error'],
});

const INITIAL_STATE = {
  accountManagerList: [],
  error: null,
  accountManagerRequests: {
    [AccountManagerTypes.LIST_ACCOUNT_MANAGER]: REQUEST_NOT_STARTED,
  },
};

const listAccountManager = () => ({
  ...INITIAL_STATE,
  accountManagerRequests: {
    ...INITIAL_STATE.accountManagerRequests,
    [AccountManagerTypes.LIST_ACCOUNT_MANAGER]: REQUEST_PENDING,
  },
});

const listAccountManagerSuccess = (state, { payload }) => ({
  ...state,
  accountManagerList: payload,
  accountManagerRequests: {
    ...state.accountManagerRequests,
    [AccountManagerTypes.LIST_ACCOUNT_MANAGER]: REQUEST_RESOLVED,
  },
});
const accountManagerFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  accountManagerRequests: {
    ...state.accountManagerRequests,
    [AccountManagerTypes.LIST_ACCOUNT_MANAGER]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [AccountManagerTypes.LIST_ACCOUNT_MANAGER]: listAccountManager,
  [AccountManagerTypes.LIST_ACCOUNT_MANAGER_SUCCESS]: listAccountManagerSuccess,
  [AccountManagerTypes.ACCOUNT_MANAGER_REQUEST_FAILURE]: accountManagerFailure,
});

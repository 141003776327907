import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: RailwayItemTypes,
  Creators: RailwayItemActions,
} = createActions({
  setEditModalOpen: ['payload'],
  update: ['payload', 'callback'],
  updateSuccess: [''],
  railwayItemRequestFailure: ['error'],
});

const INITIAL_STATE = {
  isEditModalOpen: false,
  editRailwayItem: null,
  error: null,
  railwayItemsRequests: {
    [RailwayItemTypes.UPDATE]: REQUEST_NOT_STARTED,
  },
};

const setEditModalOpen = (state, { payload }) => ({
  ...state,
  isEditModalOpen: payload.modalOpen,
  editRailwayItem: payload?.row,
});

const update = (state) => ({
  ...state,
  railwayItemsRequests: {
    ...state.railwayItemsRequests,
    [RailwayItemTypes.UPDATE]: REQUEST_PENDING,
  },
});

const updateSuccess = (state) => ({
  ...state,
  railwayItemsRequests: {
    ...state.railwayItemsRequests,
    [RailwayItemTypes.UPDATE]: REQUEST_RESOLVED,
  },
});

const railwayItemRequestFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  railwayItemsRequests: {
    ...state.railwayItemsRequests,
    [RailwayItemTypes.UPDATE]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [RailwayItemTypes.SET_EDIT_MODAL_OPEN]: setEditModalOpen,
  [RailwayItemTypes.UPDATE]: update,
  [RailwayItemTypes.UPDATE_SUCCESS]: updateSuccess,
  [RailwayItemTypes.RAILWAY_ITEM_REQUEST_FAILURE]: railwayItemRequestFailure,
});

import React from 'react';
import PropTypes from 'prop-types';

import { Multiselect as Select } from '@vli/locomotive-ds/dist';
import { Container, MessageError } from './MultiselectStyle';

const Multiselect = (props) => {
  const { status, statusMessage } = props;

  return (
    <Container data-testid="multiselect-test">
      <Select {...props} />
      {status === 'error' && <MessageError>{statusMessage}</MessageError>}
    </Container>
  );
};

Multiselect.propTypes = {
  status: PropTypes.string,
  statusMessage: PropTypes.string,
};

Multiselect.defaultProps = {
  status: '',
  statusMessage: '',
};

export default Multiselect;

import React from 'react';
import PropTypes from 'prop-types';

import InfoItem from '../InfoItem';

import * as S from '../PriceAndVolumeModalStyles';

const SalesData = ({
  modalName,
  subSegment,
  segment,
  originName,
  destinyName,
  customerName,
  productName,
}) => {
  return (
    <S.CardContainer data-testid="sales-section-test">
      <S.Title>Informações de venda</S.Title>

      <S.CardWrapper>
        {modalName && <InfoItem title="Modal" content={modalName} />}
        {subSegment && <InfoItem title="Subsegmento" content={subSegment} />}
        {segment && <InfoItem title="Segmento N1" content={segment} />}
        {originName && <InfoItem title="Origem" content={originName} />}
        {destinyName && <InfoItem title="Destino" content={destinyName} />}
        {customerName && <InfoItem title="Cliente" content={customerName} />}
        {productName && <InfoItem title="Produto" content={productName} />}
      </S.CardWrapper>
    </S.CardContainer>
  );
};

SalesData.propTypes = {
  modalName: PropTypes.string.isRequired,
  subSegment: PropTypes.string.isRequired,
  segment: PropTypes.string.isRequired,
  originName: PropTypes.string.isRequired,
  destinyName: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};

export default SalesData;

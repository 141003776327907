import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/core/modal';
import { Text } from '@vli/locomotive-ds';
import { Table, Th, Tr, Td, Thead } from 'components/core/Table/Table.styles';
import * as utility from 'lib/core/utility';
import * as S from '../IndicesHistoryTable/IndicesHistoryTable.styles';

const IndicesHistoryAccumulateModal = ({
  list,
  indiceHistoryName,
  onClose,
}) => {
  const [indice, setIndice] = useState([]);
  const [simpleAccumulate, setSimpleAccumulate] = useState('');
  const [compoundAccumulate, setCompoundAccumulate] = useState('');

  const setSimpleTotalAccumulate = (array) => {
    const simple = array
      ?.map((n) => Number(n.replace(',', '.')))
      .reduce((a, b) => a + b, 0)
      .toFixed(3)
      .toString()
      .replace('.', ',');
    setSimpleAccumulate(simple);
  };

  const setCompoundTotalAccumulate = (array) => {
    const compound =
      array
        ?.map((n) => Number(n.replace(',', '.')))
        .map((x) => 1 + x * 0.01)
        .reduce((a, b) => a * b, 1) *
        100 -
      100;

    const numToString = compound.toFixed(3).toString().replace('.', ',');
    setCompoundAccumulate(numToString);
  };

  const setIndiceAccumulateMonthList = () => {
    const monthlyList = list.monthValues
      ?.map((diesel) =>
        diesel.indexVariations.filter(
          (variationName) => variationName.name === indiceHistoryName,
        ),
      )
      .map((p) => p[0].percentage);
    setIndice(monthlyList);
    setSimpleTotalAccumulate(monthlyList);
    setCompoundTotalAccumulate(monthlyList);
  };
  useEffect(() => {
    setIndiceAccumulateMonthList();
    // eslint-disable-next-line
  }, [indiceHistoryName]);

  return (
    <Modal
      width="100%"
      title="Acúmulo do diesel referente ao período filtrado"
      onClose={onClose}
    >
      <S.TableContainer data-testid="indices-history-accumulate-modal-table-test">
        <Table>
          <Thead>
            <Tr>
              <Th>Índices</Th>
              {list.months?.map((monthName) => (
                <Th key={monthName}>{monthName}</Th>
              ))}
              <Th>Acúmulo simples</Th>
              <Th>Acúmulo composto</Th>
            </Tr>
          </Thead>
          <tbody>
            <Tr>
              <Td>
                {' '}
                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {indiceHistoryName}
                </Text>
              </Td>
              {indice?.map((month) => {
                return (
                  <>
                    <Td>
                      <span data-tip="">
                        <Text
                          size="medium"
                          style={{
                            fontWeight: '400',
                          }}
                        >
                          {utility.valstr(month, 'percent')}
                        </Text>
                      </span>
                    </Td>
                  </>
                );
              })}
              <Td style={{ textAlign: 'center' }}>
                {' '}
                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {simpleAccumulate}%
                </Text>
              </Td>
              <Td style={{ textAlign: 'center' }}>
                {' '}
                <Text
                  size="medium"
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {compoundAccumulate}%
                </Text>
              </Td>
            </Tr>
          </tbody>
        </Table>
      </S.TableContainer>
    </Modal>
  );
};

IndicesHistoryAccumulateModal.propTypes = {
  list: PropTypes.array,
  indiceHistoryName: PropTypes.string,
  onClose: PropTypes.func,
};

IndicesHistoryAccumulateModal.defaultProps = {
  list: [],
  indiceHistoryName: '',
  onClose: () => {},
};

export default IndicesHistoryAccumulateModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Container, Flex, Header } from '@vli/locomotive-ds';

import useDimensions from 'hooks/useDimensions';
import useQuery from 'hooks/useQuery';

import * as ROUTES from 'constants/urls';
import { history } from 'constants/routes';

import Content from 'components/core/content';

import { CommercialNegotiationsActions } from 'store/commercialNegotiations/commercialNegotiations.ducks';

import NegotiationsList from 'components/contexts/commercialNegotiations/NegotiationsList';

const CommercialNegotiations = () => {
  const dispatch = useDispatch();
  const dimensions = useDimensions();
  const query = useQuery();

  const { data } = useSelector(
    ({ commercialNegotiations }) => commercialNegotiations,
  );

  const breadcrumb = [
    {
      text: 'Negociações Comerciais',
      href: `#${ROUTES.COMMERCIAL_NEGOTIATIONS}`,
    },
  ];

  const handleAttachmentDownload = () => {
    if (data) {
      const commercialNegotiationIds = data.items.map(
        (item) => item.commercialNegociationId,
      );

      const payload = {
        commercialNegociationIds: commercialNegotiationIds,
      };

      dispatch(
        CommercialNegotiationsActions.commercialNegotiationsDownload(payload),
      );
    }
  };

  useEffect(() => {
    const commercialNegotiationIds = query.getAll('id');

    if (commercialNegotiationIds.length > 0) {
      const payload = {
        commercialNegociationIds: commercialNegotiationIds,
      };

      dispatch(
        CommercialNegotiationsActions.commercialNegotiationsDownload(payload),
      );

      history.push(ROUTES.COMMERCIAL_NEGOTIATIONS);
    }
  }, [query, dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header
            bread={breadcrumb}
            tag="header"
            title="Negociações Comerciais"
          />

          <Button name="Download de anexos" action={handleAttachmentDownload} />
        </Flex>

        <NegotiationsList />
      </Container>
    </Content>
  );
};

export default CommercialNegotiations;

import styled from 'styled-components';
import { Accordion, Tokens } from '@vli/locomotive-ds/dist';

export const ContainerTab = styled.div`
  width: 100%;
  label {
    span {
      font-weight: 400;
    }
  }
`;

export const ExpandedAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: ${Tokens.spacing.sm};
`;

export const FormGroup = styled.div`
  margin-left: ${Tokens.spacing.sm};
  margin-right: ${Tokens.spacing.sm};
`;

export const RowAction = styled.div`
  border-bottom: 0.5px solid ${Tokens.colors.gray200};
  margin: 0px -16px;
  padding: 12px 16px;
`;

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const BallStatus = styled.div`
  background-color: ${(props) =>
    props.status ? Tokens.colors.green300 : Tokens.colors.red300};
  height: 15px;
  width: 15px;
  border-radius: 50%;
`;

export const TextStatus = styled.span`
  margin-left: ${Tokens.spacing.xs};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Text, Popover, Tokens } from '@vli/locomotive-ds/dist';
import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';
import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';
import { SupplierActions } from 'store/supplier/supplier.ducks';
import { GET } from 'lib/core/localStorage';
import { ACTIONS, ROLES, canExecuteAction } from 'lib/contexts/permissions';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();
  const supplier = row.original;

  const { roles } = GET('roles');

  const canUpdateSupplier = canExecuteAction(
    ROLES.SUPPLIER,
    ACTIONS.UPDATE,
    roles,
  );
  const canChangeSupplierStatus = canExecuteAction(
    ROLES.SUPPLIER,
    ACTIONS.INACTIVE,
    roles,
  );

  const handleEditSupplier = () => {
    history.push(ROUTES.EDIT_SUPPLIER(supplier.id));
  };

  const handleChangeSupplierStatus = () => {
    const callback = () => dispatch(SupplierActions.listVliSuppliers());

    dispatch(SupplierActions.updateSupplierStatus(supplier.id, callback));
  };

  const Options = (
    <S.OptionsContainer>
      {canUpdateSupplier && (
        <S.OptionItem data-testid="test-edit" onClick={handleEditSupplier}>
          <Text size="medium" style={{ fontWeight: '400' }}>
            Editar fornecedor
          </Text>
        </S.OptionItem>
      )}

      {canChangeSupplierStatus && (
        <S.OptionItem
          data-testid="test-decline"
          color={Tokens.colors.gray300}
          onClick={handleChangeSupplierStatus}
        >
          <Text
            size="medium"
            style={{ fontWeight: '400' }}
            color={Tokens.colors.grayBlack}
          >
            {!supplier.deleteAt ? 'Desativar fornecedor' : 'Ativar fornecedor'}
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
          isDisabled={!canUpdateSupplier && !canChangeSupplierStatus}
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      deleteAt: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;

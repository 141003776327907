import { GET } from 'lib/core/localStorage';

export const isAdminProfile = () => {
  const { profile } = GET('profile');
  const adminProfileName = 'Administrador';

  return adminProfileName === profile;
};

export const PARAMETERS_TYPE = {
  TIME_INTERVAL: 1,
  SELECT: 2,
  NUMBER: 3,
};

export const separateByCategory = (parameters) => {
  const parametersByCategory = parameters?.reduce((acumulador, item) => {
    const { categoryName } = item;

    if (!acumulador[categoryName]) {
      acumulador[categoryName] = [];
    }

    acumulador[categoryName].push(item);

    return acumulador;
  }, {});

  return Object.entries(parametersByCategory);
};

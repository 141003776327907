import React from 'react';

const FilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5052 4H3.51589C3.30701 4 3.19024 4.24097 3.31966 4.40491L10.4462 13.4319C10.481 13.476 10.5 13.5306 10.5 13.5868V19.6209C10.5 19.8003 10.6835 19.9214 10.8484 19.8507L13.8484 18.5649C13.9404 18.5255 14 18.4352 14 18.3352V13.5822C14 13.5288 14.017 13.4768 14.0487 13.4339L20.7065 4.3983C20.8281 4.23323 20.7103 4 20.5052 4Z"
        stroke="#336EAE"
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default FilterIcon;

const masks = {
  digits(value) {
    return value.replace(/\D/g, '');
  },
  decimal(value) {
    return value
      .replace(/[^0-9|^,|^-]/g, '')
      .replace(/(-)(-+)/, '$1')
      .replace(/^(-?\d+,?\d{0,2}).*$/, '$1');
  },
  currency(value) {
    // eslint-disable-next-line radix
    let formmatedValue = parseInt(value.replace(/[\D]+/g, '')).toString();
    formmatedValue = formmatedValue.replace(/([0-9]{2})$/g, ',$1');

    if (formmatedValue.length > 6) {
      formmatedValue = formmatedValue.replace(
        /([0-9]{3}),([0-9]{2}$)/g,
        '.$1,$2',
      );
    }

    return formmatedValue === 'NaN' ? '' : formmatedValue;
  },
};

const getMask = (key) => masks[key] || ((value) => value);

const handleInputMask = (event, mask) => {
  const { target } = event;

  if (mask) {
    target.value = getMask(mask)(target.value);
  }
};

export default handleInputMask;

import styled from 'styled-components';

export const Container = styled.div`
  div:last-child {
    ul {
      li {
        label {
          label {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

export const MessageError = styled.div`
  position: relative;
  font: 500 12px/16px Manrope, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  line-height: 1.4;
  display: block;
  text-align: right;
  margin-top: 5px;
  z-index: 1;
  color: #a32626;
`;

import styled from 'styled-components';
import { Modal } from '@vli/locomotive-ds';

export const ModalContainer = styled(Modal)`
  width: 100%;

  #divider {
    display: none;
    margin: 0;
  }
`;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { differenceInYears } from 'date-fns';

import { FormalizationSteps } from 'lib/contexts/formalizationRequest';

import { HARBOR_FIELDS, RAILWAY_FIELDS } from '../../constants';

import * as S from './SectionsStyled';

const Indices = ({ disableFields, sectionErrors, showDieselField }) => {
  const { control, watch, errors, setValue } = useFormContext();

  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [modalOptions, setModalOptions] = useState([]);

  const { stepId } = formalizationData?.formalization ?? {
    stepId: 1,
  };

  const startDate = watch('parametrics.startDate');
  const endDate = watch('parametrics.endDate');

  const isFieldRequired = stepId !== FormalizationSteps.Draft;
  const dateDiff =
    differenceInYears(new Date(endDate), new Date(startDate)) >= 1;

  const identifyModals = useCallback(() => {
    const modals = formalizationData?.formalization?.modals;
    const optionsIndices = [];

    if (dateDiff) {
      if (modals.includes(1)) {
        optionsIndices.push({
          id: 1,
          title: 'Ferrovia',
          fields: RAILWAY_FIELDS,
        });
      }

      if (modals.includes(2)) {
        optionsIndices.push({
          id: 2,
          title: 'Porto',
          fields: HARBOR_FIELDS,
        });
      }
    }

    const sortedIndices = [...optionsIndices].sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }

      return 0;
    });

    return sortedIndices;
  }, [formalizationData, dateDiff]);

  useEffect(() => {
    if (formalizationData?.formalization) {
      const formalizationModals = identifyModals();
      setModalOptions(formalizationModals);
    }
  }, [identifyModals, formalizationData]);

  useEffect(() => {
    if (modalOptions.length > 0) {
      const dataIndices = formalizationData.formalization?.indexes;

      setValue('indices.railwayIGPM', dataIndices?.railwayIGPM || 0);
      setValue('indices.railwayIPCA', dataIndices?.railwayIPCA || 0);
      setValue('indices.harborIGPM', dataIndices?.harborIGPM || 0);
      setValue('indices.harborIPCA', dataIndices?.harborIPCA || 0);
    }
  }, [setValue, formalizationData, modalOptions]);

  useEffect(() => {
    if (showDieselField) {
      const dataIndices = formalizationData?.formalization?.indexes;

      if (dataIndices) {
        setValue('indices.diesel', dataIndices.diesel || 30);
      }
    }
  }, [showDieselField, formalizationData, setValue]);

  const transformToNumber = (value) => Number(value);

  const handleOnDieselChange = (value) => {
    const valueAsNumber = transformToNumber(value);

    return valueAsNumber;
  };

  return (
    <S.FormColumn data-testid="form-section-indices-test">
      {showDieselField && (
        <S.FormGrid>
          <S.InputContainer>
            <Controller
              name="indices.diesel"
              control={control}
              rules={{ required: isFieldRequired }}
              render={({ ...field }) => (
                <S.DefaultInput
                  {...field}
                  onChange={(e) => field.onChange(handleOnDieselChange(e))}
                  label="Diesel - %"
                  placeholder="Digite"
                  type="number"
                  suffix="%"
                  disabled={disableFields}
                  required
                />
              )}
            />

            {errors?.indices?.diesel && (
              <S.ErrorMessage>{errors.indices.diesel.message}</S.ErrorMessage>
            )}
          </S.InputContainer>
        </S.FormGrid>
      )}

      {[...modalOptions].sort().map((modal) => (
        <S.FieldContainer key={modal.id}>
          <span>{modal.title}</span>

          <S.FormGrid>
            {modal.fields.map((modalField) => (
              <S.InputContainer key={modalField.name}>
                <Controller
                  name={modalField.name}
                  control={control}
                  render={({ ...field }) => (
                    <S.DefaultInput
                      {...field}
                      onChange={(e) => field.onChange(transformToNumber(e))}
                      label={modalField.label}
                      placeholder="Digite"
                      type="number"
                      suffix="%"
                      disabled={disableFields}
                    />
                  )}
                />
              </S.InputContainer>
            ))}
          </S.FormGrid>

          {sectionErrors?.railway && modal.id === 1 && (
            <S.ErrorMessage>{sectionErrors.railway}</S.ErrorMessage>
          )}

          {sectionErrors?.harbor && modal.id === 2 && (
            <S.ErrorMessage>{sectionErrors.harbor}</S.ErrorMessage>
          )}
        </S.FieldContainer>
      ))}
    </S.FormColumn>
  );
};

Indices.propTypes = {
  disableFields: PropTypes.bool.isRequired,
  sectionErrors: PropTypes.objectOf(PropTypes.any),
  showDieselField: PropTypes.bool.isRequired,
};

Indices.defaultProps = {
  sectionErrors: null,
};

export default Indices;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const { Types: AuthTypes, Creators: AuthActions } = createActions({
  signIn: ['shouldReloadWindow', 'isFirstLogin'],
  signOut: [],
  signInSuccess: ['payload'],
  authRequestFailure: ['error', 'originalType'],
  openAuthModal: ['httpMethod'],
  closeAuthModal: [],
});

const INITIAL_STATE = {
  error: null,
  roles: [],
  authRequests: {
    [AuthTypes.SIGN_IN]: REQUEST_NOT_STARTED,
  },
  authModalOpen: false,
  httpMethods: [],
};

const signIn = () => ({
  ...INITIAL_STATE,
  authRequests: {
    ...INITIAL_STATE.authRequests,
    [AuthTypes.SIGN_IN]: REQUEST_PENDING,
  },
});

const signOut = () => ({
  ...INITIAL_STATE,
});

const openAuthModal = (state, { httpMethod }) => ({
  ...state,
  authModalOpen: true,
  httpMethods: [...state.httpMethods, httpMethod],
  authRequests: {
    ...state.authRequests,
  },
});

const closeAuthModal = (state) => ({
  ...state,
  authModalOpen: false,
  httpMethods: INITIAL_STATE.httpMethods,
  authRequests: {
    ...state.authRequests,
  },
});

const signInSuccess = (state, { payload }) => ({
  ...state,
  roles: payload.roles ? payload.roles : [],
  authRequests: {
    ...state.authRequests,
    [AuthTypes.SIGN_IN]: REQUEST_RESOLVED,
  },
});

const authRequestFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  status: response?.status,
  authRequests: {
    ...state.authRequests,
    [AuthTypes.SIGN_IN]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [AuthTypes.SIGN_IN]: signIn,
  [AuthTypes.SIGN_IN_SUCCESS]: signInSuccess,
  [AuthTypes.AUTH_REQUEST_FAILURE]: authRequestFailure,
  [AuthTypes.SIGN_OUT]: signOut,
  [AuthTypes.OPEN_AUTH_MODAL]: openAuthModal,
  [AuthTypes.CLOSE_AUTH_MODAL]: closeAuthModal,
});

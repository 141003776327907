import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from 'constants/request';

export const {
  Types: CommercialNegotiationsTypes,
  Creators: CommercialNegotiationsActions,
} = createActions({
  commercialNegotiationsList: ['params', 'rawFilter'],
  commercialNegotiationsListSuccess: ['payload'],
  commercialNegotiationsListFailure: ['error'],
  commercialNegotiationsRailwayItem: [],
  commercialNegotiationsRailwayItemSuccess: ['payload'],
  commercialNegotiationsRailwayItemFailure: ['error'],
  commercialNegotiationsDownload: ['payload'],
  commercialNegotiationsDownloadSuccess: ['payload'],
  commercialNegotiationsDownloadFailure: ['error'],
});

const INITIAL_STATE = {
  data: {},
  railwayItems: [],
  download: null,
  error: null,
  filter: {},
  commercialNegotiationsRequest: {
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST]: REQUEST_NOT_STARTED,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM]: REQUEST_NOT_STARTED,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD]: REQUEST_NOT_STARTED,
  },
};

const commercialNegotiationsList = (state) => ({
  ...state,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST]: REQUEST_PENDING,
  },
});

const commercialNegotiationsListSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST]: REQUEST_RESOLVED,
  },
});

const commercialNegotiationsListFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST]: REQUEST_REJECTED,
  },
});

const commercialNegotiationsRailwayItem = (state) => ({
  ...state,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM]: REQUEST_PENDING,
  },
});

const commercialNegotiationsRailwayItemSuccess = (state, { payload }) => ({
  ...state,
  railwayItems: payload,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM]: REQUEST_RESOLVED,
  },
});

const commercialNegotiationsRailwayItemFailure = (
  state,
  { error: { response } },
) => ({
  ...state,
  error: response,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM]: REQUEST_REJECTED,
  },
});

const commercialNegotiationsDownload = (state) => ({
  ...state,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD]: REQUEST_PENDING,
  },
});

const commercialNegotiationsDownloadSuccess = (state, { payload }) => ({
  ...state,
  download: payload,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD]: REQUEST_RESOLVED,
  },
});

const commercialNegotiationsDownloadFailure = (
  state,
  { error: { response } },
) => ({
  ...state,
  error: response,
  commercialNegotiationsRequest: {
    ...state.commercialNegotiationsRequest,
    [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST]: commercialNegotiationsList,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST_SUCCESS]: commercialNegotiationsListSuccess,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST_FAILURE]: commercialNegotiationsListFailure,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM]: commercialNegotiationsRailwayItem,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM_SUCCESS]: commercialNegotiationsRailwayItemSuccess,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM_FAILURE]: commercialNegotiationsRailwayItemFailure,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD]: commercialNegotiationsDownload,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD_SUCCESS]: commercialNegotiationsDownloadSuccess,
  [CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD_FAILURE]: commercialNegotiationsDownloadFailure,
});

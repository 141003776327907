import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@vli/locomotive-ds/dist';

import Modal from 'components/core/modal';

import { getDataToSendRequest } from 'lib/contexts/formalizationRequest';
import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import { formatDate } from 'lib/core/utility';

const FlowDate = ({ onClose, proposalCode, modalType }) => {
  const { formalizationData, expectedDate } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const dispatch = useDispatch();

  const handleSendFormalization = () => {
    const payload = getDataToSendRequest(formalizationData);
    dispatch(FormalizationRequestActions.formalizationSendRequest(payload));
    
    if(onClose) onClose();
  };

  const handleReview = () => {
    dispatch(
      FormalizationRequestActions.reviewRequest(
        formalizationData?.formalization?.id,
        expectedDate,
      ),
    );
    
    if(onClose) onClose();
  };

  const handleAcceptService = () => {
    dispatch(
      FormalizationRequestActions.acceptService(
        formalizationData?.formalization?.id,
        expectedDate,
      ),
    );
    
    if(onClose) onClose();
  };


  const handleSubmit = () => {
    switch (modalType) {
      case 'send':
        handleSendFormalization();
        break;
      case 'accept':
        handleAcceptService();
        break;
    
      default:
        handleReview();
        break;
    }
  };

  const setModalActions = () => (
    <Button name="Enviar" onClick={handleSubmit} type="submit" />
  );

  const formattedExpectDate = formatDate(expectedDate)

  return (
    <Modal
      width="100%"
      title="Data prevista de atendimento"
      onClose={onClose}
      actions={setModalActions}
    >
      A data prevista para revisão da proposta <b>{proposalCode}</b> é{' '}
      <b>{formattedExpectDate}</b>
    </Modal>
  );
};

FlowDate.propTypes = {
  onClose: PropTypes.func.isRequired,
  proposalCode: PropTypes.number.isRequired,
  modalType: PropTypes.string.isRequired,
};

export default FlowDate;

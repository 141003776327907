import React from 'react';
import PropTypes from 'prop-types';

const TooltipInfo = ({ data }) => (
  <div>
    <b>Parâmetros para cálculo </b>
    {data.values.map((v) => (
      <p key={`_a${v.key}`}>
        {v.key}: {v.value}
      </p>
    ))}
    {data.formulaWithValues !== '' && data.formulaWithValues !== null && (
      <>
        <b>Memória de cálculo </b>
        <p>{data.formulaWithValues}</p>
      </>
    )}
  </div>
);

TooltipInfo.defaultProps = {
  data: [],
};

TooltipInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default TooltipInfo;

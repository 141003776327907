import styled from 'styled-components';
import { Tokens, Modal, Card } from '@vli/locomotive-ds';

export const ModalContainer = styled(Modal)`
  width: 100%;
`;

export const Scroll = styled.div`
  width: 100%;
  height: 480px;

  position: relative;

  margin-top: ${Tokens.spacing.sm};

  max-height: 480px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${Tokens.colors.accentPrimaryLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${Tokens.colors.accentPrimaryDark};
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Table = styled.div`
  width: 100%;
  max-width: 1120px;
  overflow-x: auto;
  padding-bottom: ${Tokens.spacing.md};

  border: 1px solid ${Tokens.colors.gray100};
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    height: 5px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${Tokens.colors.accentPrimaryLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${Tokens.colors.accentPrimaryDark};
    cursor: pointer;
  }
`;

export const TableLine = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(${(props) => props.columnsNumber}, 180px);
`;

export const TableColumn = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${Tokens.colors.gray100};
  border-right: 1px solid ${Tokens.colors.gray100};
  padding: ${Tokens.spacing.xs};

  span {
    font: ${Tokens.fonts.textBoldMedium};
    color: ${Tokens.colors.gray500};
  }

  small {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.gray500};
  }
`;

export const LineWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  & > span {
    font: ${Tokens.fonts.textRegularMedium};
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const Box = styled.div`
  & + & {
    border-top: 1px solid ${Tokens.colors.gray200};
    padding-top: 8px;
  }

  & > small {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

// SalesData

export const CardContainer = styled(Card)`
  width: 100%;
  padding: ${Tokens.spacing.sm};
`;

export const CardWrapper = styled(ColumnWrapper)`
  margin-top: ${Tokens.spacing.md};
`;

// Price

export const PriceContainer = styled(LineWrapper)`
  width: auto;

  gap: 12px;

  & > small {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

// Volume

export const VolumeContainer = styled(PriceContainer)``;

import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_CONTRACT_REPORT } from 'constants/endpoints';
import api from 'services/api';
import { GET, POST, DELETE } from 'constants/verbs';
import * as utility from 'lib/core/utility';
import messages from 'constants/messages';
import { MessagesActions } from 'store/messages/messages.ducks';
import {
  ContractReportActions,
  ContractReportTypes,
} from './contractReport.ducks';

export function* listContractReports({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_CONTRACT_REPORT}/${params}`,
    });

    yield put(
      ContractReportActions.listContractReportsSuccess(response.data.data),
    );
  } catch (error) {
    yield put(ContractReportActions.contractReportRequestFailure(error));
  }
}

export function* downloadContractReport({ params, type }) {
  const { id, fileName } = params;

  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_CONTRACT_REPORT}/download/${id}`,
      responseType: 'arraybuffer',
    });

    yield put(
      ContractReportActions.downloadContractReportSuccess(response.data.data),
    );

    utility.downloadFile(response.data, `${fileName}.xlsx`);
  } catch (error) {
    yield put(ContractReportActions.contractReportRequestFailure(error, type));
  }
}

export function* createContractReport({ payload, type }) {
  try {
    const response = yield api({
      method: POST,
      url: `${RESOURCE_CONTRACT_REPORT}`,
      data: payload,
    });

    yield put(
      ContractReportActions.createContractReportSuccess(response.data.data),
    );

    yield put(
      MessagesActions.open({
        title: messages.messages.contractReport[POST].title,
        type: 'success',
        contentText: messages.messages.contractReport[POST].message,
      }),
    );
  } catch (error) {
    let message = messages.messages.contractReport[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.contractReport[POST].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(ContractReportActions.contractReportRequestFailure(error, type));
  }
}

export function* deleteContractReport({ payload, callback, type }) {
  try {
    const response = yield api({
      method: DELETE,
      url: `${RESOURCE_CONTRACT_REPORT}/${payload}`,
    });

    yield put(
      ContractReportActions.deleteContractReportSuccess(response.data.data),
    );

    yield put(
      MessagesActions.open({
        title: messages.messages.contractReport[DELETE].title,
        type: 'success',
        contentText: messages.messages.contractReport[DELETE].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message = messages.messages.contractReport[DELETE].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.contractReport[DELETE].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(ContractReportActions.contractReportRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(
    ContractReportTypes.LIST_CONTRACT_REPORTS,
    listContractReports,
  );
  yield takeLatest(
    ContractReportTypes.DOWNLOAD_CONTRACT_REPORT,
    downloadContractReport,
  );
  yield takeLatest(
    ContractReportTypes.CREATE_CONTRACT_REPORT,
    createContractReport,
  );
  yield takeLatest(
    ContractReportTypes.DELETE_CONTRACT_REPORT,
    deleteContractReport,
  );
}

import styled from 'styled-components';
import { Tokens, Accordion, InputDate } from '@vli/locomotive-ds';

export const Spacing = styled.div`
  margin-top: ${Tokens.spacing.sm};
`;
export const SpacingMultselect = styled.div`
  margin-top: ${Tokens.spacing.sm};
  div {
    div {
      margin-bottom: 4px;
    }
  }
  ul li label label {
    width: 400px !important;
  }
`;

export const ExpandedAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: ${Tokens.spacing.sm};
`;

export const InputDateItem = styled(InputDate)`
  .react-date-picker__wrapper {
    width: 100%;
    min-width: 100% !important;

    svg {
      display: none;
    }
  }
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@vli/locomotive-ds/dist';

import * as S from './styles';

const Search = ({ onChangeContract, onChangeClient }) => {
  const [clientName, setClient] = useState('');
  const [contractName, setContract] = useState('');

  const handleChangeContract = (value) => {
    setContract(value);
    onChangeContract({
      clientName,
      contractName: value,
    });
  };
  const handleChangeClient = (value) => {
    setClient(value);
    onChangeClient({
      clientName: value,
      contractName,
    });
  };

  return (
    <S.CardContainer data-testid="card-test">
      <S.FlexContainer>
        <Input
          label="Contrato"
          data-testid="contract-test"
          placeholder="Pesquisar por contrato"
          width="400px"
          required
          type="input"
          value={contractName}
          onChange={handleChangeContract}
        />

        <Input
          label="Cliente"
          data-testid="client-test"
          placeholder="Pesquisar por cliente"
          width="400px"
          required
          type="input"
          value={clientName}
          onChange={handleChangeClient}
        />
      </S.FlexContainer>
    </S.CardContainer>
  );
};

Search.propTypes = {
  onChangeContract: PropTypes.func.isRequired,
  onChangeClient: PropTypes.func.isRequired,
};

export default Search;

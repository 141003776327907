import PropTypes from 'prop-types';
import React from 'react';

import { capitalizeString, formatMoney } from 'lib/core/utility';

const TooltipInfo = ({ data }) => {
  if (!data || data?.values.length === 0) {
    return null;
  }

  return (
    <div>
      <b>Abertura por serviço </b>
      {data?.values?.map((v) => (
        <p key={`service-${v.key}`} style={{ fontWeight: 400 }}>
          {capitalizeString(v.key)}: {formatMoney(v.value)}
        </p>
      ))}
    </div>
  );
};

TooltipInfo.defaultProps = {
  data: null,
};

TooltipInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default TooltipInfo;

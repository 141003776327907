import React from 'react';
import PropTypes from 'prop-types';

import * as S from './InfoItemStyles';

const InfoItem = ({ title, content }) => {
  return (
    <S.InfoContainer data-testid="info-item-test">
      <S.Title>{title}</S.Title>
      <S.Content>{content}</S.Content>
    </S.InfoContainer>
  );
};

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

export default InfoItem;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, EmptyState } from '@vli/locomotive-ds/dist';
import { UserActions } from 'store/user/user.ducks';
import PropTypes from 'prop-types';
import Table from 'components/core/Table/Table';
import { isFinished } from 'lib/core/request';
import Columns from 'components/contexts/user/userList/TableUsers/Columns/Columns';
import { getFilterParams } from 'lib/contexts/user';
import ModalConfirm from 'components/core/ModalConfirm';
import usePagination from 'hooks/usePagination';

const TableUsers = ({ columns }) => {
  const dispatch = useDispatch();
  const { paginationSize, setValuePage } = usePagination();

  const {
    data: users,
    filter,
    userDelete,
    openDelete,
    usersRequests,
  } = useSelector(({ user }) => user);
  const onPageChange = (PageIndex) => {
    const { pageSize } = users;
    const data = getFilterParams({ ...filter, pageIndex: PageIndex, pageSize });
    dispatch(UserActions.fetchUsers(data, filter));
  };
  const onPerPageChange = (PageSize) => {
    const { pageIndex } = users;
    const data = getFilterParams({ ...filter, pageIndex, pageSize: PageSize });
    dispatch(UserActions.fetchUsers(data, filter));
    setValuePage(PageSize);
  };

  const closeModal = () => {
    dispatch(UserActions.setDeleteUser({ user: null, modalOpen: false }));
  };
  const callback = () => {
    const { currentPage, pageSize } = users;
    dispatch(
      UserActions.fetchUsers(
        getFilterParams({ pageIndex: currentPage, pageSize, ...filter }),
      ),
    );
  };
  const confirmDelete = () => {
    dispatch(UserActions.deleteUser(userDelete.id, callback));
    closeModal();
  };

  return (
    <>
      {users?.items?.length > 0 ? (
        <Table
          width="100%"
          sortBy={[]}
          customList={paginationSize}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          pageIndex={users.currentPage}
          pageCount={users.totalPages}
          totalItems={users.totalCount}
          data={users.items}
          columns={Columns(columns)}
          footer
        />
      ) : (
        isFinished(usersRequests.FETCH_USERS) && (
          <Card>
            <EmptyState condensed={false} title="Nenhum usuário encontrado." />
          </Card>
        )
      )}
      {openDelete && (
        <ModalConfirm
          closeModal={closeModal}
          confirmAction={confirmDelete}
          title="Desativar usuário"
          message={`Tem certeza que deseja desativar o usuário ${userDelete.userName}`}
        />
      )}
    </>
  );
};

TableUsers.propTypes = {
  columns: PropTypes.array,
};

TableUsers.defaultProps = {
  columns: [
    'active',
    'userName',
    'userEmail',
    'profileName',
    'segments',
    'acoes',
  ],
};

export default TableUsers;

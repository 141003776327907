import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { GET } from 'lib/core/localStorage';
import { ROLES } from 'lib/contexts/permissions';
import { FormalizationSteps } from 'lib/contexts/formalizationRequest';

import FormalizationAction from '../FormalizationAction';
import TransferServiceModal from '../TransferServiceModal';

import * as S from './FormalizationServiceStyles';

const FormalizationService = () => {
  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('TRANSFER');

  const { stepId } = formalizationData?.formalization ?? {
    stepId: 1,
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalAction('TRANSFER');
  };

  const handleOpenModal = (action) => {
    setIsModalOpen(true);
    setModalAction(action);
  };

  const formalizationId = formalizationData?.formalization?.id;
  const userService = formalizationData?.formalization?.userService;

  const { name: userName } = GET('name');
  const isItTheSameUser = userName === userService;

  return (
    <>
      {userService && (
        <S.Service data-testid="formalization-service-test">
          <span>
            Atendimento: <strong>{userService}</strong>
          </span>

          {isItTheSameUser &&
            stepId !== FormalizationSteps.SentToFormalization && (
              <FormalizationAction
                variant="tertiary"
                name="Transferir atendimento"
                type="button"
                key="button-transfer-service"
                onClick={() => handleOpenModal('TRANSFER')}
                role={ROLES.ATTEND_FORMALIZATION}
              />
            )}

          {!isItTheSameUser &&
            stepId !== FormalizationSteps.SentToFormalization && (
              <FormalizationAction
                variant="tertiary"
                name="Obter atendimento"
                type="button"
                key="button-get-service"
                onClick={() => handleOpenModal('GET')}
                role={ROLES.ATTEND_FORMALIZATION}
              />
            )}
        </S.Service>
      )}

      {formalizationId && (
        <TransferServiceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          formalizationId={formalizationId}
          action={modalAction}
        />
      )}
    </>
  );
};

export default FormalizationService;

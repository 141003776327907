import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, EmptyState } from '@vli/locomotive-ds';

import FormalizationItem from 'components/contexts/formalizationRequest/FormalizationItem/FormalizationItem';

import { handleIdPattern } from 'lib/core/idPattern';

import ExternalLink from './ExternalLink/ExternalLink';
import TakeOrPayModal from '../TakeOrPayModal/index';

import { getPVProposalLink, getNetlexDocLink } from './utils';

import * as S from './FormalizationCardStyles';

const FormalizationCard = () => {
  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { proposalCode, contractors, segments, debtors, description, modal } =
    formalizationData?.proposal || {};

  const { statusOrStep, managerName } = formalizationData?.formalization ?? {
    statusOrStep: '',
    managerName: '',
  };

  const formalizationId = formalizationData.formalization?.id;

  const proposalIdMasked = handleIdPattern(proposalCode, 'P');
  const proposalLink = getPVProposalLink(proposalIdMasked);

  const idNetlex = formalizationData.formalization?.idDocNetlex;
  const docNumberNetlex = formalizationData.formalization?.numberDocNetlex;
  const netlexUrl = getNetlexDocLink(idNetlex);

  const contractedCompanies = useMemo(() => {
    if (formalizationData?.contractedCompaniesItems) {
      const companies = formalizationData.contractedCompaniesItems.map(
        (company) => {
          return company.contractedCompanieName;
        },
      );

      return companies.join(', ');
    }

    return null;
  }, [formalizationData]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Card>
      {formalizationData?.formalization?.hasCommitment && (
        <TakeOrPayModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          formalizationId={formalizationId}
        />
      )}

      <S.ContainerFlex>
        <S.Step>Etapa: {statusOrStep}</S.Step>
      </S.ContainerFlex>

      {formalizationData?.proposal ? (
        <S.ContainerFlex>
          <FormalizationItem
            title="Proposta"
            content={
              <ExternalLink url={proposalLink} label={proposalIdMasked} />
            }
          />
          <FormalizationItem title="Gerente de contas" content={managerName} />
          <FormalizationItem
            title="Cliente (Contratante)"
            content={contractors}
          />
          <FormalizationItem title="Segmentos" content={segments} />
          <FormalizationItem title="Devedores Solidários" content={debtors} />
          <FormalizationItem title="Descrição" content={description} />
          <FormalizationItem title="Modais" content={modal} />

          {contractedCompanies && (
            <FormalizationItem
              title="Empresas Contratadas"
              content={contractedCompanies}
            />
          )}

          {formalizationData?.formalization?.hasCommitment && (
            <FormalizationItem
              title="ToP"
              content={
                <S.ModalButton type="button" onClick={handleOpenModal}>
                  Ver informações
                </S.ModalButton>
              }
            />
          )}
        </S.ContainerFlex>
      ) : (
        <Card>
          <EmptyState
            condensed={false}
            title="A proposta não foi encontrada."
          />
        </Card>
      )}

      {idNetlex && docNumberNetlex && (
        <S.ContainerFlex>
          <FormalizationItem
            title="Netlex"
            content={<ExternalLink url={netlexUrl} label={docNumberNetlex} />}
          />
        </S.ContainerFlex>
      )}

      {!idNetlex &&
        !docNumberNetlex &&
        formalizationData?.formalization?.stepId === 3 && (
          <S.ContainerFlex>
            <FormalizationItem title="Netlex" content="Aguardando retorno" />
          </S.ContainerFlex>
        )}
    </Card>
  );
};

export default FormalizationCard;

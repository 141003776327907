import React from 'react';

import * as utility from 'lib/core/utility';

import CellTable from 'components/core/Table/CellTable';

import CellWithActions from '../CellWithActions';

const Columns = [
  {
    Header: 'Código do contrato',
    accessor: 'contractCode',
    align: 'start',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Item ferroviário',
    accessor: 'railwayItemCode',
    align: 'start',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Data de aplicação',
    accessor: 'applicationDate',
    align: 'start',
    width: 50,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatDate} />
    ),
  },
  {
    Header: 'Justificativa',
    accessor: 'justification',
    align: 'start',
    width: 300,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'end',
    width: 50,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

export default Columns;

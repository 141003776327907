import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  position: fixed;

  top: 24px;
  right: 48px;

  z-index: 1;
`;

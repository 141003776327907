import { put, takeLatest } from 'redux-saga/effects';

import api from 'services/api';

import { ATTENDANT } from 'constants/endpoints';
import { GET } from 'constants/verbs';

import { AttendantActions, AttendantTypes } from './attendant.ducks';

export function* listAttendant() {
  try {
    const response = yield api({
      method: GET,
      url: ATTENDANT,
    });

    yield put(AttendantActions.listAttendantSuccess(response.data.data));
  } catch (error) {
    yield put(AttendantActions.listAttendantFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(AttendantTypes.LIST_ATTENDANT, listAttendant);
}

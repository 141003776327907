import React from 'react';
import PropTypes from 'prop-types';
import Headlight from 'components/core/headlight';
import { Tokens } from '@vli/locomotive-ds/dist';
import Tooltip from 'components/core/Tooltip';

const StatusUser = ({ cell: { value } }) => (
  <Tooltip title={value ? 'Ativado' : 'Desativado'}>
    <Headlight
      data-testid="cell-table"
      color={value ? Tokens.colors.green300 : Tokens.colors.red300}
      style={{ cursor: 'pointer' }}
    />
  </Tooltip>
);

StatusUser.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

StatusUser.defaultProps = {
  cell: {
    value: '',
  },
};

export default StatusUser;

import React from 'react';

import * as utility from 'lib/core/utility';

import CellTable from 'components/core/Table/CellTable';

import CellEditAutocomplete from '../CellEditAutocomplete/CellEditAutocomplete';
import CellWithActions from '../AdditionCellWithActions';

const AdditionsColumns = [
  {
    Header: 'Inicial',
    accessor: 'contractStartPeriod',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatDate} />
    ),
  },
  {
    Header: 'Final',
    accessor: 'contractEndPeriod',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatDate} />
    ),
  },
  {
    Header: 'Gerente',
    accessor: 'contractAccountManager',
    align: 'start',
    width: 250,
    Cell: (props) => <CellEditAutocomplete {...props} />,
  },
  {
    Header: 'Cliente',
    accessor: 'contractClient',
    align: 'start',
    minWidth: 100,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.capitalizeString} />
    ),
  },
  {
    Header: 'Produtos',
    accessor: 'contractProducts',
    align: 'start',
    minWidth: 50,
    Cell: (props) => (
      <CellTable transformedData={utility.joinComma} {...props} />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'start',
    width: 20,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

export default AdditionsColumns;

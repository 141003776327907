import styled from 'styled-components';

const TableContainer = styled.div`
  td {
    align-items: center;
  }
  tr {
    position: unset;
  }
  &.p10 {
    td {
      padding: 10px !important;
    }
  }
  padding-bottom: 40px;
`;

export default TableContainer;

import React, { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addMonths, format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Card,
  Heading,
  Row,
  Col,
  Tokens,
  Input,
  Text,
  InputDate,
  Flex,
  Button,
} from '@vli/locomotive-ds/dist';

import handleInputMask from 'lib/core/handleInputMask';
import { maxDateNextMonth } from 'lib/core/date';
import { SpacingTop } from 'components/core/Spacing';
import InputSelect from 'components/form/InputSelect';
import { DieselActions } from 'store/diesel/diesel.ducks';
import { IndicesActions } from 'store/indices/indices.ducks';

import * as S from './DieselForm.styles';

const DIESEL_PETROBRASS500_KEY = 'petrobrasS500';

const DieselFormSupplierPetrobras = ({ supplier }) => {
  const dispatch = useDispatch();

  const [lastMonthDateValue, setLastMonthDateValue] = useState(undefined);
  const [currentMonthDateValue, setCurrentMonthDateValue] = useState(undefined);

  const { errors, control, handleSubmit } = useForm({
    defaultValues: {
      localId: '',
      dieselDate: null,
      lastMonth: null,
      currentMonth: null,
    },
  });

  const localOptions = useMemo(() => {
    return supplier.types[0].locals
      .map((local) => {
        return {
          label: local.name,
          value: local.id,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [supplier.types]);

  const onSubmit = (formValues) => {
    const reload = () => dispatch(DieselActions.clearSupplierList());

    const data = {
      supplierId: supplier.id,
      startPeriod: new Date(
        lastMonthDateValue.getFullYear(),
        lastMonthDateValue.getMonth(),
        15,
      ),
      endPeriod: new Date(
        currentMonthDateValue.getFullYear(),
        currentMonthDateValue.getMonth(),
        15,
      ),
      diesel: {
        localId: formValues.localId,
        lastMonthValue: formValues.lastMonth,
        currentMonthValue: formValues.currentMonth,
      },
    };

    dispatch(
      IndicesActions.dieselReadjustmentStart(
        data,
        false,
        DIESEL_PETROBRASS500_KEY,
        reload,
      ),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
      <Card style={{ height: 'auto' }}>
        <S.CardHeader>
          <Heading tag="h5" color={Tokens.colors.gray300} variant={['bold']}>
            {supplier.types[0].name}
          </Heading>
        </S.CardHeader>

        <S.ContainerForm>
          <Row>
            <Col desktop="12">
              <Flex>
                <S.Group>
                  <div>
                    <div>
                      <Controller
                        name="localId"
                        control={control}
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                          <InputSelect
                            width="255px"
                            value={value}
                            options={localOptions}
                            onChange={onChange}
                            placeholder="Selecione base de diesel"
                            label="Selecione base de diesel"
                          />
                        )}
                      />
                    </div>

                    {errors?.localId && (
                      <S.MessageErrors data-testid="error-message-test">
                        <Text color={Tokens.colors.red400}>
                          Campo requerido
                        </Text>
                      </S.MessageErrors>
                    )}
                  </div>
                </S.Group>

                <S.Group>
                  <div>
                    <div>
                      <S.Label>Data de aplicação</S.Label>
                    </div>
                    <div>
                      <Controller
                        data-testid="dieselDate"
                        name="dieselDate"
                        rules={{ required: true }}
                        control={control}
                        render={({ onChange, value }) => (
                          <S.ContainerDate>
                            <InputDate
                              format="MM/y"
                              selected={value}
                              value={value}
                              width="255px"
                              maxDate={maxDateNextMonth()}
                              locale="pt-BR"
                              maxDetail="year"
                              onChange={(e) => {
                                onChange(e);
                                setLastMonthDateValue(addMonths(e, -2));
                                setCurrentMonthDateValue(addMonths(e, -1));
                              }}
                            />
                          </S.ContainerDate>
                        )}
                      />

                      {errors.dieselDate && (
                        <S.MessageErrors>
                          <Text color={Tokens.colors.red400}>
                            Campo requerido
                          </Text>
                        </S.MessageErrors>
                      )}
                    </div>
                  </div>
                </S.Group>
              </Flex>
            </Col>

            <Col desktop="12">
              <Flex>
                <S.Group>
                  <div>
                    <Controller
                      name="lastMonth"
                      rules={{ required: true }}
                      control={control}
                      render={({ onChange, value, name }) => (
                        <Input
                          name={name}
                          label={
                            lastMonthDateValue
                              ? format(lastMonthDateValue, '15/MM/yy')
                              : 'Índice mês anterior'
                          }
                          required
                          placeholder="$"
                          value={value}
                          width="255px"
                          onInput={(event) =>
                            handleInputMask(event, 'currency')
                          }
                          maxlength={250}
                          marginTop={10}
                          onChange={onChange}
                        />
                      )}
                    />

                    {errors.lastMonth && (
                      <S.MessageErrors>
                        <Text color={Tokens.colors.red400}>
                          Campo requerido
                        </Text>
                      </S.MessageErrors>
                    )}
                  </div>
                </S.Group>
                <S.Group>
                  <div>
                    <Controller
                      name="currentMonth"
                      rules={{ required: true }}
                      control={control}
                      render={({ onChange, value, name }) => (
                        <Input
                          name={name}
                          label={
                            currentMonthDateValue
                              ? format(currentMonthDateValue, '15/MM/yy')
                              : 'Índice mês vigente'
                          }
                          required
                          placeholder="$"
                          value={value}
                          width="255px"
                          onInput={(event) =>
                            handleInputMask(event, 'currency')
                          }
                          maxlength={250}
                          marginTop={10}
                          onChange={onChange}
                        />
                      )}
                    />

                    {errors.currentMonth && (
                      <S.MessageErrors>
                        <Text color={Tokens.colors.red400}>
                          Campo requerido
                        </Text>
                      </S.MessageErrors>
                    )}
                  </div>
                </S.Group>
              </Flex>
            </Col>

            <SpacingTop md>
              <Button name="SALVAR" type="submit" data-testid="test-button" />
            </SpacingTop>
          </Row>
        </S.ContainerForm>
      </Card>
    </form>
  );
};

DieselFormSupplierPetrobras.propTypes = {
  supplier: PropTypes.object.isRequired,
};

export default DieselFormSupplierPetrobras;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: FormalizationRequestTypes,
  Creators: FormalizationRequestActions,
} = createActions({
  formalizationRequestFailure: ['error'],
  formalizationByIdRequest: ['formalizationId'],
  formalizationByIdRequestSuccess: ['payload'],
  formalizationEditRequest: ['payload'],
  formalizationEditRequestSuccess: [],
  formalizationSendRequest: ['payload'],
  formalizationSendRequestSuccess: ['payload'],
  formalizationReturnToPlaintiffRequest: ['formalizationId'],
  formalizationReturnToPlaintiffRequestSuccess: ['payload'],
  sendForFormalizationRequest: ['payload', 'callback'],
  sendForFormalizationRequestSuccess: ['payload'],
  reviewRequest: ['formalizationId', 'expectedDate'],
  reviewRequestSuccess: ['payload'],
  expectedDateRequest: ['formalizationId'],
  expectedDateRequestSuccess: ['payload'],
  draftToRequestedFormalizationRequest: ['formalizationId', 'callback'],
  draftToRequestedFormalizationRequestSuccess: ['payload'],
  acceptService: ['formalizationId', 'expectedDate'],
  acceptServiceSuccess: ['payload'],
  transferService: ['formalizationId', 'userId'],
  transferServiceSuccess: ['payload'],
  cancelFormalization: ['formalizationId'],
  cancelFormalizationSuccess: ['payload'],
  groupedSalesBySaleId: ['saleId'],
  groupedSalesBySaleIdSuccess: ['payload'],
  groupedSalesBySaleIdFailure: ['error'],
  approveRequest: ['formalizationId'],
  approveRequestSuccess: [],
  disapproveRequest: ['payload'],
  disapproveRequestSuccess: [],
  uploadAttachment: ['payload'],
  uploadAttachmentSuccess: [],
  uploadAttachmentFailure: ['error'],
  downloadAttachment: ['formalizationId'],
  downloadAttachmentSuccess: [],
  downloadAttachmentFailure: ['error'],
  downloadSummary: ['formalizationId', 'statusId'],
  downloadSummarySuccess: [],
  downloadSummaryFailure: ['error'],
  setAdditiveFill: ['payload'],
  servicesBySaleId: ['saleId'],
  servicesBySaleIdSuccess: ['payload'],
  servicesBySaleIdFailure: ['error'],
});

const INITIAL_STATE = {
  formalizationData: {},
  groupedSales: null,
  expectedDate: '',
  error: null,
  formalizationId: null,
  formalizationRequestRequest: {},
  salesRequest: {},
  isAdditiveFill: false,
  services: null,
};

const formalizationByIdRequest = () => ({
  ...INITIAL_STATE,
  formalizationRequestRequest: {
    ...INITIAL_STATE.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_BY_ID_REQUEST]: REQUEST_PENDING,
  },
});

const formalizationByIdRequestSuccess = (state, { payload }) => ({
  ...state,
  formalizationData: payload.data,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_BY_ID_REQUEST]: REQUEST_RESOLVED,
  },
});

const formalizationEditRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_EDIT_REQUEST]: REQUEST_PENDING,
  },
});

const formalizationEditRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_EDIT_REQUEST]: REQUEST_RESOLVED,
  },
});

const formalizationSendRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_SEND_REQUEST]: REQUEST_PENDING,
  },
});

const formalizationSendRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_SEND_REQUEST]: REQUEST_RESOLVED,
  },
});

const formalizationReturnToPlaintiffRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST]: REQUEST_PENDING,
  },
});

const formalizationReturnToPlaintiffRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST]: REQUEST_RESOLVED,
  },
});

const sendForFormalizationRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.SEND_FOR_FORMALIZATION_REQUEST]: REQUEST_PENDING,
  },
});

const sendForFormalizationRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.SEND_FOR_FORMALIZATION_REQUEST]: REQUEST_RESOLVED,
  },
});

const reviewRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.REVIEW]: REQUEST_PENDING,
  },
});

const reviewRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.REVIEW]: REQUEST_RESOLVED,
  },
});

const expectedDateRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.EXPECTED_DATE_REQUEST]: REQUEST_PENDING,
  },
});

const expectedDateRequestSuccess = (state, { payload }) => ({
  ...state,
  expectedDate: payload,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.EXPECTED_DATE_REQUEST]: REQUEST_RESOLVED,
  },
});

const draftToRequestedFormalizationRequest = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST]: REQUEST_PENDING,
  },
});

const draftToRequestedFormalizationRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST]: REQUEST_RESOLVED,
  },
});

const acceptService = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.ACCEPT_SERVICE]: REQUEST_PENDING,
  },
});

const acceptServiceSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.ACCEPT_SERVICE]: REQUEST_RESOLVED,
  },
});

const transferService = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.TRANSFER_SERVICE]: REQUEST_PENDING,
  },
});

const transferServiceSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.TRANSFER_SERVICE]: REQUEST_RESOLVED,
  },
});

const cancelFormalization = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.CANCEL_FORMALIZATION]: REQUEST_PENDING,
  },
});

const cancelFormalizationSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.CANCEL_FORMALIZATION]: REQUEST_RESOLVED,
  },
});

const groupedSalesBySaleId = (state) => ({
  ...state,
  salesRequest: {
    ...state.salesRequest,
    [FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID]: REQUEST_PENDING,
  },
});

const groupedSalesBySaleIdSuccess = (state, { payload }) => ({
  ...state,
  groupedSales: payload.data,
  salesRequest: {
    ...state.salesRequest,
    [FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID]: REQUEST_RESOLVED,
  },
});

const groupedSalesBySaleIdFailure = (state, { error: { response } }) => ({
  ...state,
  groupedSales: null,
  error: response,
  salesRequest: {
    ...state.salesRequest,
    [FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID]: REQUEST_REJECTED,
  },
});

const approveRequest = () => ({
  ...INITIAL_STATE,
  formalizationRequestRequest: {
    ...INITIAL_STATE.formalizationRequestRequest,
    [FormalizationRequestTypes.APPROVE_REQUEST]: REQUEST_PENDING,
  },
});

const approveRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.APPROVE_REQUEST]: REQUEST_RESOLVED,
  },
});

const disapproveRequest = () => ({
  ...INITIAL_STATE,
  formalizationRequestRequest: {
    ...INITIAL_STATE.formalizationRequestRequest,
    [FormalizationRequestTypes.DISAPPROVE_REQUEST]: REQUEST_PENDING,
  },
});

const disapproveRequestSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DISAPPROVE_REQUEST]: REQUEST_RESOLVED,
  },
});

const formalizationRequestFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.FORMALIZATION_EDIT_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.FORMALIZATION_BY_ID_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.FORMALIZATION_SEND_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF]: REQUEST_REJECTED,
    [FormalizationRequestTypes.SEND_FOR_FORMALIZATION]: REQUEST_REJECTED,
    [FormalizationRequestTypes.REVIEW]: REQUEST_REJECTED,
    [FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.SEND_FOR_FORMALIZATION_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.EXPECTED_DATE_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.ACCEPT_SERVICE]: REQUEST_REJECTED,
    [FormalizationRequestTypes.TRANSFER_SERVICE]: REQUEST_REJECTED,
    [FormalizationRequestTypes.CANCEL_FORMALIZATION]: REQUEST_REJECTED,
    [FormalizationRequestTypes.APPROVE_REQUEST]: REQUEST_REJECTED,
    [FormalizationRequestTypes.DISAPPROVE_REQUEST]: REQUEST_REJECTED,
  },
});

const uploadAttachment = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.UPLOAD_ATTACHMENT]: REQUEST_PENDING,
  },
});

const uploadAttachmentSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.UPLOAD_ATTACHMENT]: REQUEST_RESOLVED,
  },
});

const uploadAttachmentFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.UPLOAD_ATTACHMENT]: REQUEST_REJECTED,
  },
});

const downloadAttachment = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DOWNLOAD_ATTACHMENT]: REQUEST_PENDING,
  },
});

const downloadAttachmentSuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DOWNLOAD_ATTACHMENT]: REQUEST_RESOLVED,
  },
});

const downloadAttachmentFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DOWNLOAD_ATTACHMENT]: REQUEST_REJECTED,
  },
});

const downloadSummary = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DOWNLOAD_SUMMARY]: REQUEST_PENDING,
  },
});

const downloadSummarySuccess = (state) => ({
  ...state,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DOWNLOAD_SUMMARY]: REQUEST_RESOLVED,
  },
});

const downloadSummaryFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  formalizationRequestRequest: {
    ...state.formalizationRequestRequest,
    [FormalizationRequestTypes.DOWNLOAD_SUMMARY]: REQUEST_REJECTED,
  },
});

const setAdditiveFill = (state, { payload }) => ({
  ...state,
  isAdditiveFill: payload.isAdditiveFill,
});

const servicesBySaleId = (state) => ({
  ...state,
  salesRequest: {
    ...state.salesRequest,
    [FormalizationRequestTypes.SERVICES_BY_SALE_ID]: REQUEST_PENDING,
  },
});

const servicesBySaleIdSuccess = (state, { payload }) => ({
  ...state,
  services: payload.data,
  salesRequest: {
    ...state.salesRequest,
    [FormalizationRequestTypes.SERVICES_BY_SALE_ID]: REQUEST_RESOLVED,
  },
});

const servicesBySaleIdFailure = (state, { error: { response } }) => ({
  ...state,
  services: null,
  error: response,
  salesRequest: {
    ...state.salesRequest,
    [FormalizationRequestTypes.SERVICES_BY_SALE_ID]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [FormalizationRequestTypes.FORMALIZATION_REQUEST_FAILURE]: formalizationRequestFailure,
  [FormalizationRequestTypes.FORMALIZATION_BY_ID_REQUEST]: formalizationByIdRequest,
  [FormalizationRequestTypes.FORMALIZATION_BY_ID_REQUEST_SUCCESS]: formalizationByIdRequestSuccess,
  [FormalizationRequestTypes.FORMALIZATION_EDIT_REQUEST]: formalizationEditRequest,
  [FormalizationRequestTypes.FORMALIZATION_EDIT_REQUEST_SUCCESS]: formalizationEditRequestSuccess,
  [FormalizationRequestTypes.FORMALIZATION_SEND_REQUEST]: formalizationSendRequest,
  [FormalizationRequestTypes.FORMALIZATION_SEND_REQUEST_SUCCESS]: formalizationSendRequestSuccess,
  [FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST]: formalizationReturnToPlaintiffRequest,
  [FormalizationRequestTypes.FORMALIZATION_RETURN_TO_PLAINTIFF_REQUEST_SUCCESS]: formalizationReturnToPlaintiffRequestSuccess,
  [FormalizationRequestTypes.SEND_FOR_FORMALIZATION_REQUEST]: sendForFormalizationRequest,
  [FormalizationRequestTypes.SEND_FOR_FORMALIZATION_REQUEST_SUCCESS]: sendForFormalizationRequestSuccess,
  [FormalizationRequestTypes.REVIEW_REQUEST]: reviewRequest,
  [FormalizationRequestTypes.REVIEW_REQUEST_SUCCESS]: reviewRequestSuccess,
  [FormalizationRequestTypes.EXPECTED_DATE_REQUEST]: expectedDateRequest,
  [FormalizationRequestTypes.EXPECTED_DATE_REQUEST_SUCCESS]: expectedDateRequestSuccess,
  [FormalizationRequestTypes.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST]: draftToRequestedFormalizationRequest,
  [FormalizationRequestTypes.DRAFT_TO_REQUESTED_FORMALIZATION_REQUEST_SUCCESS]: draftToRequestedFormalizationRequestSuccess,
  [FormalizationRequestTypes.ACCEPT_SERVICE]: acceptService,
  [FormalizationRequestTypes.ACCEPT_SERVICE_SUCCESS]: acceptServiceSuccess,
  [FormalizationRequestTypes.TRANSFER_SERVICE]: transferService,
  [FormalizationRequestTypes.TRANSFER_SERVICE_SUCCESS]: transferServiceSuccess,
  [FormalizationRequestTypes.CANCEL_FORMALIZATION]: cancelFormalization,
  [FormalizationRequestTypes.CANCEL_FORMALIZATION_SUCCESS]: cancelFormalizationSuccess,
  [FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID]: groupedSalesBySaleId,
  [FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID_SUCCESS]: groupedSalesBySaleIdSuccess,
  [FormalizationRequestTypes.GROUPED_SALES_BY_SALE_ID_FAILURE]: groupedSalesBySaleIdFailure,
  [FormalizationRequestTypes.APPROVE_REQUEST]: approveRequest,
  [FormalizationRequestTypes.APPROVE_REQUEST_SUCCESS]: approveRequestSuccess,
  [FormalizationRequestTypes.DISAPPROVE_REQUEST]: disapproveRequest,
  [FormalizationRequestTypes.DISAPPROVE_REQUEST_SUCCESS]: disapproveRequestSuccess,
  [FormalizationRequestTypes.UPLOAD_ATTACHMENT]: uploadAttachment,
  [FormalizationRequestTypes.UPLOAD_ATTACHMENT_SUCCESS]: uploadAttachmentSuccess,
  [FormalizationRequestTypes.UPLOAD_ATTACHMENT_FAILURE]: uploadAttachmentFailure,
  [FormalizationRequestTypes.DOWNLOAD_ATTACHMENT]: downloadAttachment,
  [FormalizationRequestTypes.DOWNLOAD_ATTACHMENT_SUCCESS]: downloadAttachmentSuccess,
  [FormalizationRequestTypes.DOWNLOAD_ATTACHMENT_FAILURE]: downloadAttachmentFailure,
  [FormalizationRequestTypes.DOWNLOAD_SUMMARY]: downloadSummary,
  [FormalizationRequestTypes.DOWNLOAD_SUMMARY_SUCCESS]: downloadSummarySuccess,
  [FormalizationRequestTypes.DOWNLOAD_SUMMARY_FAILURE]: downloadSummaryFailure,
  [FormalizationRequestTypes.SET_ADDITIVE_FILL]: setAdditiveFill,
  [FormalizationRequestTypes.SERVICES_BY_SALE_ID]: servicesBySaleId,
  [FormalizationRequestTypes.SERVICES_BY_SALE_ID_SUCCESS]: servicesBySaleIdSuccess,
  [FormalizationRequestTypes.SERVICES_BY_SALE_ID_FAILURE]: servicesBySaleIdFailure,
});

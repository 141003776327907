import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Input,
  TrashIcon,
  Button,
  Flex,
  Header,
  Col,
} from '@vli/locomotive-ds/dist';

import * as ROUTES from 'constants/urls';
import { history } from 'constants/routes';

import { MessagesActions } from 'store/messages/messages.ducks';

import * as utility from 'lib/core/utility';

import * as S from 'components/contexts/notifications/shared/EmailForm/styles';

import RecipientsModal from '../RecipientsModal';
import { emailFormSchema } from '../../shared/formSchema';

const breadcrumb = [
  {
    text: 'Notificações',
    href: `#${ROUTES.EMAIL_NOTIFICATIONS_CLIENT_LIST}`,
  },
  {
    text: 'Gerenciar Destinatários',
    href: null,
  },
];

const EmailForm = ({ data, sendForm }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notification:
        data.recipients && data.recipients.length > 0
          ? data.recipients
          : [{ name: '', email: '' }],
    },
    resolver: yupResolver(emailFormSchema),
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'notification',
  });

  const onSubmit = (formData) => {
    const payload = {
      contractId: data.contractId,
      recipients: formData.notification,
    };

    if (!formData.notification) {
      dispatch(
        MessagesActions.open({
          title: 'Validação',
          type: 'error',
          contentText: 'Por favor adicione pelo menos um destinatário',
        }),
      );
    } else {
      const checkDuplication = utility.hasDuplicates(
        formData.notification,
        'email',
      );

      if (!checkDuplication) {
        sendForm(payload);
      } else {
        dispatch(
          MessagesActions.open({
            title: 'Validação de e-mail',
            type: 'error',
            contentText: 'Não é permitido e-mails duplicados',
          }),
        );
      }
    }
  };

  const removeComponent = (index) => {
    remove(index);
    data.recipients.splice(index, 1);
  };

  const addComponent = () => {
    append({ name: '', email: '' });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmRecipients = (recipients) => {
    const fieldsLength = fields?.length || 0;

    if (data?.recipients?.length === 0) {
      remove(0);
    }

    recipients.forEach((item, index) => {
      insert(index + fieldsLength, {
        name: item.name,
        email: item.email,
      });
    });

    handleCloseModal();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Notificações" />

          <Flex justifyContent="space-between" alignItems="center">
            <Button
              onClick={() =>
                history.push(ROUTES.EMAIL_NOTIFICATIONS_CLIENT_LIST)
              }
              name="Cancelar"
              type="button"
              variant="tertiary"
              style={{ marginRight: '5px' }}
            />

            <Button
              name="Salvar"
              type="submit"
              data-testid="button-submit-test"
            />
          </Flex>
        </Flex>

        <S.CardContainer>
          <S.FlexContainer>
            <div>
              <S.Label>Cliente</S.Label>
              <S.TextValue>{data?.customerName}</S.TextValue>
            </div>

            <div>
              <S.Label>Contrato</S.Label>
              <S.TextValue>{data?.contractCode}</S.TextValue>
            </div>

            {data?.additiveCodes?.length > 0 && (
              <div>
                <S.Label>Aditivos</S.Label>
                <S.TextValue>{data?.additiveCodes?.join(', ')}</S.TextValue>
              </div>
            )}
          </S.FlexContainer>
        </S.CardContainer>

        <S.ExpandedAccordion title="Destinatários" open>
          {fields.map((item, index) => (
            <S.RowContainer key={item.id}>
              <Col desktop={5}>
                <Controller
                  name={`notification.${index}.name`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={item.name}
                  render={({ onChange }) => (
                    <Input
                      label="Nome"
                      placeholder="Nome"
                      width="100%"
                      required
                      maxLength="50"
                      type="input"
                      data-testid={`input-name-${index}-test`}
                      value={item.name}
                      onChange={onChange}
                      status={
                        errors.notification &&
                        errors.notification[index]?.name &&
                        'error'
                      }
                      statusMessage={
                        errors.notification &&
                        errors.notification[index]?.name &&
                        'Nome inválido'
                      }
                    />
                  )}
                />
              </Col>
              <Col desktop={5}>
                <Controller
                  name={`notification.${index}.email`}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={item.email}
                  render={({ onChange }) => (
                    <Input
                      label="E-mail"
                      placeholder="E-mail"
                      width="100%"
                      required
                      maxLength="50"
                      data-testid={`input-email-${index}-test`}
                      type="text"
                      value={item.email}
                      onChange={onChange}
                      status={
                        errors.notification &&
                        errors.notification[index]?.email &&
                        'error'
                      }
                      statusMessage={
                        errors.notification &&
                        errors.notification[index]?.email &&
                        'Email inválido'
                      }
                    />
                  )}
                />
              </Col>
              <Col desktop={1}>
                <S.DeleteButton
                  data-testid={`button-delete-test-${index}`}
                  icon={TrashIcon}
                  action={() => removeComponent(index)}
                />
              </Col>
            </S.RowContainer>
          ))}
        </S.ExpandedAccordion>

        <S.Actions>
          <Button
            data-testid="button-add-test"
            name="Novo Destinatário"
            onClick={addComponent}
          />

          <Button
            name="IMPORTAR DE OUTROS CONTRATOS"
            onClick={handleOpenModal}
            variant="secondary"
            type="button"
            data-testid="button-import-test"
          />
        </S.Actions>
      </form>

      <RecipientsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        contractDetails={data}
        onConfirm={handleConfirmRecipients}
      />
    </>
  );
};

EmailForm.propTypes = {
  data: PropTypes.object,
  sendForm: PropTypes.func,
};

EmailForm.defaultProps = {
  data: {
    contractCode: '',
    recipients: [],
  },
  sendForm: () => {},
};

export default EmailForm;

import React from 'react';

import * as utility from 'lib/core/utility';

import CellWithActions from './CellWithActions';
import CellEditInput from './CellEditInput';
import StatusClient from './StatusClient';

export const Columns = [
  {
    Header: 'Status',
    accessor: 'deletedAt',
    align: 'start',
    width: 50,
    Cell: (props) => <StatusClient {...props} />,
  },
  {
    Header: 'Clientes',
    accessor: 'name',
    align: 'start',
    width: 400,
    Cell: (props) => (
      <CellEditInput
        {...props}
        transformedData={utility.capitalizeFirstString}
        align="start"
      />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'acoes',
    align: 'end',
    width: 10,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

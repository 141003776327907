import { MODALS } from 'components/contexts/formalizationRequest/constants';
import { autoListingParamsGet, formatDate } from 'lib/core/utility';
import { getFormalizationStepByStatus } from './formalizationRequest';

export const formatDataFromRequest = (response) => {
  const clients = response.data.data.items.flatMap((item) => item.clients);
  const clientCount = new Set(clients).size;

  return {
    ...response.data.data,
    clientCount,
    items: response.data.data.items.map((item) => {
      return {
        ...item,
        period: `${formatDate(item.startPeriod)} a ${formatDate(
          item.endPeriod,
        )}`,
      };
    }),
  };
};

const mapSelectData = (items, nameProp = 'name') => {
  return items?.map((item) => ({
    label: item[nameProp],
    value: {
      id: item.id,
      others: '',
    },
  }));
};

export const DataFilter = (customers, users, attendants) => {
  const filterSteps = [
    {
      label: 'Rascunho',
      value: 'Rascunho',
    },
    {
      label: 'Em análise',
      value: 'Em análise',
    },
    {
      label: 'Enviada para formalização',
      value: 'Enviada para formalização',
    },
    {
      label: 'Devolvida para demandante',
      value: 'Devolvida para demandante',
    },
    {
      label: 'Solicitado Revisão Jurídica',
      value: 'Solicitado Revisão Jurídica',
    },
    {
      label: 'Cancelado',
      value: 'Cancelado',
    },
    {
      label: 'Concluído',
      value: 'Concluído',
    },
  ];

  const customersData = customers
    ? mapSelectData(customers, 'businessName')
    : [];
  const usersData = users ? mapSelectData(users) : [];
  const attendantsData = attendants ? mapSelectData(attendants) : [];

  const dataFilter = [
    {
      label: 'Vigência inicial',
      name: 'startPeriod',
      type: 'date',
    },
    {
      label: 'Vigência final',
      name: 'endPeriod',
      type: 'date',
    },
    {
      data: filterSteps,
      label: 'Etapas',
      type: 'select',
      name: 'step',
    },
    {
      data: MODALS,
      label: 'Modal',
      type: 'multiselect',
      name: 'modals',
    },
    {
      data: usersData,
      label: 'Gerente',
      type: 'multiselect',
      name: 'users',
    },
    {
      data: customersData,
      label: 'Cliente',
      type: 'multiselect',
      name: 'clients',
    },
    {
      data: attendantsData,
      label: 'Atendente',
      type: 'multiselect',
      name: 'attendants',
    },
    {
      label: 'Data de carregamento',
      name: 'loadingDate',
      type: 'date',
    },
  ];
  return dataFilter;
};

export const getFilterItemParams = (data) => {
  const startPeriodData = data.startPeriod
    ? formatDate(data.startPeriod, 'yyyy-mm-dd')
    : null;
  const endPeriodData = data.endPeriod
    ? formatDate(data.endPeriod, 'yyyy-mm-dd')
    : null;
  const StatusOrStep = data.step ? data.step : null;
  const modalsIds = data.modals
    ? data.modals.map((modal) => modal.value.id)
    : null;
  const clientsIds = data.clients
    ? data.clients.map((client) => client.value.id)
    : null;
  const usersIds = data.users ? data.users.map((user) => user.value.id) : null;
  const attendantIds = data.attendants
    ? data.attendants.map((attendants) => attendants.value.id)
    : null;
  const loadingDate = data.loadingDate
    ? formatDate(data.loadingDate, 'yyyy-mm-dd')
    : null;

  const filterFields = {
    filterFields: {
      startPeriod: startPeriodData,
      endPeriod: endPeriodData,
      StatusOrStep,
      modalIds: modalsIds,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
      CustomerIds: clientsIds,
      UserIds: usersIds,
      AttendantIds: attendantIds,
      OrderBy: data.orderBy,
      OrderByDescending: data.orderByDescending,
      LoadingDate: loadingDate,
    },
  };

  return `?${autoListingParamsGet(filterFields)}`;
};

export const getFilterParams = (data) => {
  const filterFields = {
    filterFields: {
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
      OrderBy: data.orderBy,
      OrderByDescending: String(data.orderByDescending),
      StatusOrStep: data.step,
    },
  };
  return `?${autoListingParamsGet(filterFields)}`;
};

export const joinStepAndStatus = ({ step, status, statusId }) =>
  getFormalizationStepByStatus({ step, status, statusId });

export const joinCellValues = (value) => {
  return value?.join(', ').trim();
};

export const addMaskToNullableAttendant = (value) => value || '-';

export const colorByStatus = [
  {
    id: 1,
    color: '#ff9900',
  },
  {
    id: 2,
    color: '#E25051',
  },
  {
    id: 3,
    color: '#38A740',
  },
];

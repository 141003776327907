import React from 'react';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import {
  joinCellValues,
  addMaskToNullableAttendant,
} from 'lib/contexts/requestsList';
import { formatDate } from 'lib/core/utility';

import CellTable from 'components/core/Table/CellTable';

import CellStep from '../CellStep';
import CellModal from '../CellModal';

const clickRow = (row) => {
  history.push(`${ROUTES.FORMALIZATION_REQUEST('visualizar', row.id)}`);
};

const Columns = [
  {
    Header: 'Etapa',
    accessor: 'statusOrStep',
    align: 'start',
    width: 50,
    Cell: (props) => <CellStep {...props} onClick={clickRow} />,
  },
  {
    Header: 'Solicitação',
    accessor: 'id',
    align: 'start',
    width: 80,
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Proposta',
    accessor: 'proposalCode',
    align: 'start',
    width: 80,
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Gerente',
    accessor: 'accountManagerName',
    align: 'start',
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Atendente',
    accessor: 'attendantName',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        onClick={clickRow}
        transformedData={addMaskToNullableAttendant}
      />
    ),
  },
  {
    Header: 'Cliente',
    accessor: 'clients',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={joinCellValues}
        onClick={clickRow}
      />
    ),
  },
  {
    Header: 'Vigência',
    accessor: 'period',
    align: 'start',
    Cell: (props) => <CellTable {...props} onClick={clickRow} />,
  },
  {
    Header: 'Data de carregamento',
    accessor: 'loadingDate',
    align: 'start',
    Cell: (props) => (
      <CellTable {...props} onClick={clickRow} transformedData={formatDate} />
    ),
  },
  {
    Header: 'Enviado para revisão jurídica',
    accessor: 'legalReviewStartingDate',
    align: 'start',
    Cell: (props) => (
      <CellTable {...props} onClick={clickRow} transformedData={formatDate} />
    ),
  },
  {
    Header: 'Modais',
    accessor: 'modals',
    align: 'start',
    width: 80,
    Cell: (props) => <CellModal {...props} onClick={clickRow} />,
  },
];

export default Columns;

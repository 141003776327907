import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: AttendantTypes,
  Creators: AttendantActions,
} = createActions({
  listAttendant: ['payload'],
  listAttendantSuccess: ['payload'],
  listAttendantFailure: ['error'],
});

const INITIAL_STATE = {
  attendantList: [],
  error: null,
  attendantRequests: {
    [AttendantTypes.LIST_ATTENDANT]: REQUEST_NOT_STARTED,
  },
};

const listAttendant = () => ({
  ...INITIAL_STATE,
  attendantRequests: {
    ...INITIAL_STATE.attendantRequests,
    [AttendantTypes.LIST_ATTENDANT]: REQUEST_PENDING,
  },
});

const listAttendantSuccess = (state, { payload }) => ({
  ...state,
  attendantList: payload,
  attendantRequests: {
    ...state.attendantRequests,
    [AttendantTypes.LIST_ATTENDANT]: REQUEST_RESOLVED,
  },
});

const listAttendantFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  attendantRequests: {
    ...state.attendantRequests,
    [AttendantTypes.LIST_ATTENDANT]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [AttendantTypes.LIST_ATTENDANT]: listAttendant,
  [AttendantTypes.LIST_ATTENDANT_SUCCESS]: listAttendantSuccess,
  [AttendantTypes.LIST_ATTENDANT_FAILURE]: listAttendantFailure,
});

import styled from 'styled-components';
import { Tokens, Modal } from '@vli/locomotive-ds';

export const ModalContainer = styled(Modal)`
  width: 100%;

  #divider {
    display: none;
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  border-top: 1px solid ${Tokens.colors.gray200};
  padding-top: ${Tokens.spacing.md};
  margin-top: ${Tokens.spacing.sm};

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 16px;

  padding-top: ${Tokens.spacing.xs};
  border-top: 1px solid ${Tokens.colors.gray200};
`;

export const Info = styled.span`
  span {
    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;
    color: ${Tokens.colors.gray400};
    max-width: 680px;
  }

  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    path:first-of-type {
      fill: ${Tokens.colors.yellow300};
    }
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;

  textarea {
  }
`;

export const flattenObject = (obj) => {
  let newObject = {};

  Object.keys(obj).forEach((key) => {
    newObject = {
      ...newObject,
      ...obj[key],
    };
  });

  return newObject;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const removeArrayDuplicates = (arr) => {
  return arr.reduce((uniqueArray, item) => {
    return uniqueArray.includes(item) ? uniqueArray : [...uniqueArray, item];
  }, []);
};

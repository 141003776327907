import { Card, EmptyState } from '@vli/locomotive-ds/dist';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { FareType } from 'lib/contexts/contracts';
import { isFinished, isPending } from 'lib/core/request';

import { RailwayItemsHistoryActions } from 'store/railwayItemsHistory/railwayItemsHistory.ducks';

import Columns from './Columns';

const RailwayItemHistoryList = () => {
  const dispatch = useDispatch();
  const { railwayItems, railwayItemsHistoryRequests } = useSelector(
    ({ railwayItemsHistory }) => railwayItemsHistory,
  );
  const clickRow = (row) => {
    if (row && railwayItems.fareType === FareType.CURVED_TARIFF) {
      const { applicationDate, id, railwayItemId } = row;
      const params = {
        ApplicationDate: applicationDate,
        RailwayItemId: railwayItemId,
        HistoryRailwayItemId: id,
      };
      dispatch(RailwayItemsHistoryActions.getCurvedFare(params));
    }
  };

  const data = railwayItems.historyList.map((item) => {
    return {
      ...item,
      calculatedValue: item.incentivizedRate
        ? item.incentivizedRate
        : item.calculatedValue,
    };
  });

  return (
    <TableContainer>
      {isPending(railwayItemsHistoryRequests.LIST_RAILWAY_ITEMS_HISTORY) && (
        <Loading />
      )}
      {data.length ? (
        <Table
          data-testid="railwayItems-test"
          width="100%"
          classes="sticky"
          footer={false}
          height="300px"
          columns={Columns(clickRow)}
          data={data}
          sortBy={[]}
        />
      ) : (
        isFinished(railwayItemsHistoryRequests.LIST_RAILWAY_ITEMS_HISTORY) && (
          <Card>
            <EmptyState
              condensed={false}
              title="Nenhum histórico encontrado."
            />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default RailwayItemHistoryList;

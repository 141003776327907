import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const WrapperFilter = styled.div`
  position: relative;
  padding: 8px 6px;
`;

export const WrapperSelectFilter = styled.div`
  display: flex;
  align-items: center;
  padding: ${Tokens.spacing.sm} 0px;
`;

export const WrapperActionButton = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0px;
`;

export const SpaceSelect = styled.div`
  padding: 0px 8px;
`;

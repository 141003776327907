import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from 'constants/request';

export const {
  Types: SystemParametersTypes,
  Creators: SystemParametersActions,
} = createActions({
  systemParametersList: [],
  systemParametersListSuccess: ['payload'],
  systemParametersListFailure: ['error'],
  systemParametersUpdate: ['payload'],
  systemParametersUpdateSuccess: [],
  systemParametersUpdateFailure: ['error'],
});

const INITIAL_STATE = {
  parameters: [],
  error: null,
  systemParametersRequest: {
    [SystemParametersTypes.SYSTEM_PARAMETERS_LIST]: REQUEST_NOT_STARTED,
    [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE]: REQUEST_NOT_STARTED,
  },
};

const systemParametersList = (state) => ({
  ...state,
  systemParametersRequest: {
    ...state.systemParametersRequest,
    [SystemParametersTypes.SYSTEM_PARAMETERS_LIST]: REQUEST_PENDING,
  },
});

const systemParametersListSuccess = (state, { payload }) => ({
  ...state,
  parameters: payload,
  systemParametersRequest: {
    ...state.systemParametersRequest,
    [SystemParametersTypes.SYSTEM_PARAMETERS_LIST]: REQUEST_RESOLVED,
  },
});

const systemParametersListFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  systemParametersRequest: {
    ...state.systemParametersRequest,
    [SystemParametersTypes.SYSTEM_PARAMETERS_LIST]: REQUEST_REJECTED,
  },
});

const systemParametersUpdate = (state) => ({
  ...state,
  systemParametersRequest: {
    ...state.systemParametersRequest,
    [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE]: REQUEST_PENDING,
  },
});

const systemParametersUpdateSuccess = (state) => ({
  ...state,
  systemParametersRequest: {
    ...state.systemParametersRequest,
    [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE]: REQUEST_RESOLVED,
  },
});

const systemParametersUpdateFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  systemParametersRequest: {
    ...state.systemParametersRequest,
    [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [SystemParametersTypes.SYSTEM_PARAMETERS_LIST]: systemParametersList,
  [SystemParametersTypes.SYSTEM_PARAMETERS_LIST_SUCCESS]: systemParametersListSuccess,
  [SystemParametersTypes.SYSTEM_PARAMETERS_LIST_FAILURE]: systemParametersListFailure,
  [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE]: systemParametersUpdate,
  [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE_SUCCESS]: systemParametersUpdateSuccess,
  [SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE_FAILURE]: systemParametersUpdateFailure,
});

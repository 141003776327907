import React from 'react';
import { MessagesActions } from 'store/messages/messages.ducks';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@vli/locomotive-ds/dist';
import Modal from 'components/core/modal';

const MessageModal = () => {
  const { modal, contentModalText, titleModal, callback } = useSelector(
    ({ messages }) => messages,
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(MessagesActions.close());
    callback();
  };

  const actions = () => (
    <Button variant="primary" name="Ok" onClick={closeModal} />
  );
  return (
    modal && (
      <Modal
        open={modal}
        className="modal"
        title={titleModal}
        onClose={closeModal}
        actions={actions}
      >
        {contentModalText}
      </Modal>
    )
  );
};

export default MessageModal;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: FormulasTypes,
  Creators: FormulasActions,
} = createActions({
  formulasItemsRequest: [],
  formulasItemsSuccess: ['payload'],
  formulasItemsFailure: ['error'],
  approvedFormulasItemsRequest: [],
  approvedFormulasItemsSuccess: ['payload'],
  approvedFormulasItemsFailure: ['error'],
});

const INITIAL_STATE = {
  formulasItems: [],
  approvedFormulasItems: [],
  error: null,
  formulasRequests: {
    [FormulasTypes.FORMULAS_ITEMS_REQUEST]: REQUEST_NOT_STARTED,
    [FormulasTypes.APPROVED_FORMULAS_ITEMS_REQUEST]: REQUEST_NOT_STARTED,
  },
};

const formulasRequest = (state) => ({
  ...state,
  formulasRequests: {
    ...state.formulasRequests,
    [FormulasTypes.FORMULAS_ITEMS_REQUEST]: REQUEST_PENDING,
  },
});

const formulasSuccess = (state, { payload }) => ({
  ...state,
  formulasItems: payload.data,
  formulasRequests: {
    ...state.formulasRequests,
    [FormulasTypes.FORMULAS_ITEMS_REQUEST]: REQUEST_RESOLVED,
  },
});

const formulasFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  formulasRequests: {
    ...state.formulasRequests,
    [FormulasTypes.FORMULAS_ITEMS_REQUEST]: REQUEST_REJECTED,
  },
});

const approvedFormulasRequest = (state) => ({
  ...state,
  formulasRequests: {
    ...state.formulasRequests,
    [FormulasTypes.APPROVED_FORMULAS_ITEMS_REQUEST]: REQUEST_PENDING,
  },
});

const approvedFormulasSuccess = (state, { payload }) => ({
  ...state,
  approvedFormulasItems: payload.data,
  formulasRequests: {
    ...state.formulasRequests,
    [FormulasTypes.APPROVED_FORMULAS_ITEMS_REQUEST]: REQUEST_RESOLVED,
  },
});

const approvedFormulasFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  formulasRequests: {
    ...state.formulasRequests,
    [FormulasTypes.APPROVED_FORMULAS_ITEMS_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [FormulasTypes.FORMULAS_ITEMS_REQUEST]: formulasRequest,
  [FormulasTypes.FORMULAS_ITEMS_SUCCESS]: formulasSuccess,
  [FormulasTypes.FORMULAS_ITEMS_FAILURE]: formulasFailure,
  [FormulasTypes.APPROVED_FORMULAS_ITEMS_REQUEST]: approvedFormulasRequest,
  [FormulasTypes.APPROVED_FORMULAS_ITEMS_SUCCESS]: approvedFormulasSuccess,
  [FormulasTypes.APPROVED_FORMULAS_ITEMS_FAILURE]: approvedFormulasFailure,
});

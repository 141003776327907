import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_SEGMENT } from 'constants/endpoints';
import api from 'services/api';
import { GET, POST, PUT, PATCH } from 'constants/verbs';
import messages from 'constants/messages';
import { MessagesActions } from 'store/messages/messages.ducks';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import { SegmentTypes, SegmentActions } from './segment.ducks';

export function* listSegment() {
  try {
    const response = yield api({
      method: GET,
      url: RESOURCE_SEGMENT,
    });
    yield put(SegmentActions.listSegmentSuccess(response.data.data));
  } catch (error) {
    yield put(SegmentActions.segmentRequestFailure(error));
  }
}

export function* listSegmentActive() {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_SEGMENT}/Actives`,
    });
    yield put(SegmentActions.listSegmentActiveSuccess(response.data.data));
  } catch (error) {
    yield put(SegmentActions.segmentRequestFailure(error));
  }
}

export function* getSegment({ payload, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_SEGMENT}/${payload}`,
    });
    yield put(SegmentActions.getSegmentSuccess(response.data.data));
  } catch (error) {
    yield put(SegmentActions.segmentRequestFailure(error, type));
  }
}

export function* createSegment({ payload, type }) {
  try {
    const response = yield api({
      method: POST,
      url: RESOURCE_SEGMENT,
      data: payload,
    });

    yield put(SegmentActions.createSegmentSuccess(response.data.data));

    yield put(
      MessagesActions.open({
        title: messages.messages.segment[POST].title,
        type: 'success',
        contentText: messages.messages.segment[POST].message,
      }),
    );

    yield history.push(ROUTES.SEGMENT_LIST);
  } catch (error) {
    let message = messages.messages.segment[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.segment[POST].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(SegmentActions.segmentRequestFailure(error, type));
  }
}

export function* updateSegment({ payload, type }) {
  try {
    const response = yield api({
      method: PUT,
      url: RESOURCE_SEGMENT,
      data: payload,
    });

    yield put(SegmentActions.updateSegmentSuccess(response.data.data));

    yield put(
      MessagesActions.open({
        title: messages.messages.segment[PUT].title,
        type: 'success',
        contentText: messages.messages.segment[PUT].message,
      }),
    );

    yield history.push(ROUTES.SEGMENT_LIST);
  } catch (error) {
    let message = messages.messages.segment[PUT].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.segment[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(SegmentActions.segmentRequestFailure(error, type));
  }
}

export function* updateSegmentStatus({ payload, callback, type }) {
  try {
    const response = yield api({
      method: PUT,
      url: `${RESOURCE_SEGMENT}/${payload}`,
    });

    yield put(SegmentActions.updateSegmentStatusSuccess(response.data.data));

    yield put(
      MessagesActions.open({
        title: messages.messages.segment[PATCH].title,
        type: 'success',
        contentText: messages.messages.segment[PATCH].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message = messages.messages.segment[PATCH].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.segment[PATCH].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(SegmentActions.segmentRequestFailure(error, type));
  }
}

export function* listAllSegments() {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_SEGMENT}/all-segments`,
    });
    yield put(SegmentActions.listAllSegmentsSuccess(response.data.data));
  } catch (error) {
    yield put(SegmentActions.listAllSegmentsFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(SegmentTypes.LIST_SEGMENT, listSegment);
  yield takeLatest(SegmentTypes.LIST_SEGMENT_ACTIVE, listSegmentActive);
  yield takeLatest(SegmentTypes.GET_SEGMENT, getSegment);
  yield takeLatest(SegmentTypes.CREATE_SEGMENT, createSegment);
  yield takeLatest(SegmentTypes.UPDATE_SEGMENT, updateSegment);
  yield takeLatest(SegmentTypes.UPDATE_SEGMENT_STATUS, updateSegmentStatus);
  yield takeLatest(SegmentTypes.LIST_ALL_SEGMENTS, listAllSegments);
}

import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { RESOURCE_PROFILE, GET_PRIVILEGES_SCHEMA } from 'constants/endpoints';
import * as utility from 'lib/core/utility';
import { GET, DELETE, PUT, POST } from 'constants/verbs';
import * as ROUTES from 'constants/urls';
import { MessagesActions } from 'store/messages/messages.ducks';
import { history } from 'constants/routes';
import messages from 'constants/messages';
import { ProfileActions, ProfileTypes } from './profile.ducks';

export function* getProfiles() {
  try {
    const {
      data: { data: profiles },
    } = yield api({
      method: GET,
      url: RESOURCE_PROFILE,
    });

    yield put(ProfileActions.profilesRequestSuccess(profiles));
  } catch (err) {
    yield put(ProfileActions.profilesRequestFailure(err));
  }
}

export function* getPrivilegesSchema() {
  try {
    const {
      data: { data },
    } = yield api({
      method: GET,
      url: GET_PRIVILEGES_SCHEMA,
    });
    yield put(ProfileActions.privilegesSchemaSuccess(data));
  } catch (error) {
    yield put(ProfileActions.privilegesSchemaFailure(error));
  }
}
export function* deleteProfile({ payload, callback, type }) {
  try {
    yield api({
      method: DELETE,
      url: `${RESOURCE_PROFILE}/${payload}`,
    });
    yield put(ProfileActions.deleteProfileSuccess());
    yield put(
      MessagesActions.open({
        title: messages.messages.profiles[DELETE].title,
        type: 'success',
        contentText: messages.messages.profiles[DELETE].message,
      }),
    );
    yield callback();
  } catch (error) {
    let message = messages.messages.profiles[DELETE].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.profiles[DELETE].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(ProfileActions.profileRequestFailure(message, type));
  }
}

export function* getProfile({ payload }) {
  try {
    const {
      data: { data: profile },
    } = yield api({
      method: GET,
      url: `${RESOURCE_PROFILE}/${payload}`,
    });
    yield put(ProfileActions.getProfileSuccess(profile));
  } catch (err) {
    yield put(ProfileActions.getProfileFailure(err));
  }
}

export function* updateProfile({ payload, type }) {
  try {
    const {
      data: { data: profiles },
    } = yield api({
      method: PUT,
      url: RESOURCE_PROFILE,
      data: payload,
    });

    yield put(ProfileActions.updateProfileSuccess(profiles));
    yield put(
      MessagesActions.open({
        title: messages.messages.profiles[PUT].title,
        type: 'success',
        contentText: messages.messages.profiles[PUT].message,
      }),
    );
    yield history.push(ROUTES.PROFILE_LIST);
  } catch (error) {
    let message = messages.messages.profiles[PUT].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.profiles[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(ProfileActions.profileRequestFailure(message, type));
  }
}
export function* createProfile({ payload, type }) {
  try {
    const {
      data: { data: profiles },
    } = yield api({
      method: POST,
      url: RESOURCE_PROFILE,
      data: payload,
    });

    yield put(ProfileActions.createProfileSuccess(profiles));
    yield put(
      MessagesActions.open({
        title: messages.messages.profiles[POST].title,
        type: 'success',
        contentText: messages.messages.profiles[POST].message,
      }),
    );
    yield history.push(ROUTES.PROFILE_LIST);
  } catch (error) {
    let message = messages.messages.profiles[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.profiles[POST].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(ProfileActions.profileRequestFailure(message, type));
  }
}
export function* watchSagas() {
  yield takeLatest(ProfileTypes.FETCH_PRIVILEGES_SCHEMA, getPrivilegesSchema);
  yield takeLatest(ProfileTypes.FETCH_PROFILES, getProfiles);
  yield takeLatest(ProfileTypes.CREATE_PROFILE, createProfile);
  yield takeLatest(ProfileTypes.UPDATE_PROFILE, updateProfile);
  yield takeLatest(ProfileTypes.GET_PROFILE, getProfile);
  yield takeLatest(ProfileTypes.DELETE_PROFILE, deleteProfile);
}

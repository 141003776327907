import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Flex, Container } from '@vli/locomotive-ds/dist';
import NewItemsContent from 'components/contexts/newItems/newItemsList/NewItems';
import { NewItemsActions } from 'store/newItems/newItems.ducks';
import Content from 'components/core/content';
import { getFilterParams } from 'lib/contexts/newItems';
import useDimensions from 'hooks/useDimensions';
import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Formalizações',
    href: `#${ROUTES.CONTRACTS}`,
  },
  {
    text: 'Novos itens',
    href: null,
  },
];

const NewItemsList = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      NewItemsActions.newItemsListRequest(
        getFilterParams({ pageIndex: 1, pageSize: 25 }),
      ),
    );
  }, [dispatch]);

  return (
    <Content height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Novos Itens" />
        </Flex>
        <NewItemsContent />
      </Container>
    </Content>
  );
};

export default NewItemsList;

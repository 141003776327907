import { ROLES, ACTIONS, canExecuteAction } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';

export const canUpdateCustomer = () => {
  const { roles } = GET('roles');

  return canExecuteAction(ROLES.CLIENT, ACTIONS.UPDATE, roles);
};

export const modeEditRow = (customerList, row, editMode) => {
  return customerList?.map((customer) => {
    if (row.id === customer.id) {
      return {
        ...customer,
        editMode: true,
      };
    }

    return {
      ...customer,
      editMode: editMode ? false : null,
    };
  });
};

export const resetEditRow = (customerList) => {
  return customerList?.map((customer) => {
    return {
      ...customer,
      editMode: false,
    };
  });
};

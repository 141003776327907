import { all } from 'redux-saga/effects';
import * as authSagas from './auth/auth.sagas';
import * as contractSagas from './contract/contract.sagas';
import * as contractDetailSagas from './contractDetail/contractDetail.sagas';
import * as accountManagerSagas from './accountManager/accountManager.sagas';
import * as customerSagas from './customer/customer.sagas';
import * as railwayItems from './railwayItemsHistory/railwayItemsHistory.sagas';
import * as indicesHistory from './indicesHistory/indicesHistory.sagas';
import * as indices from './indices/indices.sagas';
import * as diesel from './diesel/diesel.sagas';
import * as status from './status/status.sagas';
import * as profileSagas from './profile/profile.sagas';
import * as segmentSagas from './segment/segment.sagas';
import * as userSagas from './user/user.sagas';
import * as localSagas from './local/local.sagas';
import * as activeContractsSagas from './activeContracts/activeContracts.sagas';
import * as newItemsSagas from './newItems/newItems.sagas';
import * as resendItemsSagas from './resendItems/resendItems.sagas';
import * as reactivateItemsSagas from './reactivateItems/reactivateItems.sagas';
import * as emailNotificationsClientSagas from './emailNotificationsClient/emailNotificationClient.sagas';
import * as emailNotificationsManagerSagas from './emailNotificationsManager/emailNotificationManager.sagas';
import * as reportContractItemsSagas from './reportContractItems/reportContractItems.sagas';
import * as contractReport from './contractReport/contractReport.sagas';
import * as supplier from './supplier/supplier.sagas';
import * as railwayItem from './railwayItem/railwayItem.sagas';
import * as model from './model/model.sagas';
import * as formulas from './formulas/formulas.sagas';
import * as formalizationRequest from './formalizationRequest/formalizationRequest.sagas';
import * as requestsList from './requestsList/requestsList.sagas';
import * as notificationSagas from './notification/notification.sagas';
import * as commentsSagas from './comments/comments.sagas';
import * as attendantSagas from './attendant/attendant.sagas';
import * as compensationSagas from './compensation/compensation.sagas';
import * as commercialNegotiations from './commercialNegotiations/commercialNegotiations.sagas';
import * as systemParameters from './systemParameters/systemParameters.sagas';
import * as contractUpload from './contractUpload/contractUpload.sagas';
import * as contractsControl from './contractsControl/contractsControl.sagas';

function* Sagas() {
  yield all([
    authSagas.watchSagas(),
    contractSagas.watchSagas(),
    accountManagerSagas.watchSagas(),
    customerSagas.watchSagas(),
    railwayItems.watchSagas(),
    contractDetailSagas.watchSagas(),
    indicesHistory.watchSagas(),
    indices.watchSagas(),
    diesel.watchSagas(),
    status.watchSagas(),
    profileSagas.watchSagas(),
    segmentSagas.watchSagas(),
    userSagas.watchSagas(),
    localSagas.watchSagas(),
    activeContractsSagas.watchSagas(),
    newItemsSagas.watchSagas(),
    resendItemsSagas.watchSagas(),
    reactivateItemsSagas.watchSagas(),
    emailNotificationsClientSagas.watchSagas(),
    emailNotificationsManagerSagas.watchSagas(),
    reportContractItemsSagas.watchSagas(),
    contractReport.watchSagas(),
    supplier.watchSagas(),
    railwayItem.watchSagas(),
    model.watchSagas(),
    formulas.watchSagas(),
    formalizationRequest.watchSagas(),
    requestsList.watchSagas(),
    notificationSagas.watchSagas(),
    commentsSagas.watchSagas(),
    attendantSagas.watchSagas(),
    compensationSagas.watchSagas(),
    commercialNegotiations.watchSagas(),
    systemParameters.watchSagas(),
    contractUpload.watchSagas(),
    contractsControl.watchSagas(),
  ]);
}

export default Sagas;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container } from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';
import Form from 'components/contexts/profile/form';
import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';

import { getDataRoles } from 'lib/contexts/permissions';
import { getFilterParams } from 'lib/contexts/user';
import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { ProfileActions } from 'store/profile/profile.ducks';
import { UserActions } from 'store/user/user.ducks';

import * as ROUTES from 'constants/urls';

const EditProfile = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const breadcrumb = [
    {
      text: 'Perfis',
      href: `#${ROUTES.PROFILE_LIST}`,
    },
    {
      text: 'Editar Perfil',
      href: `#${ROUTES.EDIT_PROFILE(id)}`,
    },
  ];

  const dispatch = useDispatch();

  const {
    profile: profileData,
    profilesRequests,
    profileUpdateData,
  } = useSelector(({ profile }) => profile);
  const { usersRequests } = useSelector(({ user }) => user);

  useEffect(() => {
    dispatch(ProfileActions.getProfile(id));

    const filterData = { profile: [{ value: { id } }] };

    dispatch(
      UserActions.fetchUsers(
        getFilterParams({ pageIndex: 1, pageSize: 25, ...filterData }),
        filterData,
      ),
    );
  }, [dispatch, id]);

  const dimensions = useDimensions();

  const onSubmit = (data) => {
    dispatch(ProfileActions.setUpdateProfile(data));
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const confirmEdit = () => {
    if (profileUpdateData) {
      setOpenModal(false);
      const payload = getDataRoles(profileUpdateData);
      dispatch(ProfileActions.updateProfile({ ...payload, profileId: id }));
    }
  };

  const loading =
    isPending(profilesRequests.GET_PROFILE) ||
    isPending(profilesRequests.UPDATE_PROFILE) ||
    isPending(usersRequests.FETCH_USERS);

  return (
    <Content data-testid="content" height={dimensions.height?.toString()}>
      <Container>
        {loading && <Loading />}

        {!loading && (
          <Form
            breadcrumb={breadcrumb}
            onSubmit={onSubmit}
            editProfile={profileData}
            method="edit"
            roles={profileData?.rolesGroup}
          />
        )}
      </Container>

      {openModal && (
        <ModalConfirm
          title="Editar perfil"
          closeModal={closeModal}
          confirmAction={confirmEdit}
          message={`Deseja alterar as permissões do perfil <b>${profileUpdateData.name}</b>?`}
        />
      )}
    </Content>
  );
};

export default EditProfile;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, Popover } from '@vli/locomotive-ds';

import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';

import { Table, Th, Tr, Td, Thead } from 'components/core/Table/Table.styles';

import {
  getLetterIds,
  getEnableOption,
  enableDownload,
  enableEdit,
} from 'lib/contexts/indices';
import * as utility from 'lib/core/utility';

import { IndicesHistoryActions } from 'store/indicesHistory/indicesHistory.ducks';

import IndicesHistoryEdit from '../IndicesHistoryEdit';
import IndicesHistoryAccumulateModal from '../IndicesHistoryAccumulateModal';
import IndicesInflationHistoryAccumulateModal from '../IndicesInflationHistoryAccumulateModal';

import Options from './IndicesHistoryTableOptions';

import * as S from './IndicesHistoryTable.styles';

const IndicesHistoryTable = () => {
  const {
    params,
    indicesHistoryList: { list },
  } = useSelector(({ indicesHistory }) => indicesHistory);

  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);
  const [openHistoryAccumulateModal, setOpenHistoryAccumulateModal] = useState(
    false,
  );
  const [
    openInflationHistoryAccumulateModal,
    setOpenInflationHistoryAccumulateModal,
  ] = useState(false);
  const [itemEdit, setItemEdit] = useState({});
  const [indiceName, setIndiceName] = useState('');

  const setEditItem = ({ item }) => {
    setItemEdit(item);
    setOpenEdit(true);
  };

  const handleDownload = (ids) => {
    dispatch(
      IndicesHistoryActions.downloadLetter({
        type: 'zip',
        filename: 'cartas.zip',
        data: {
          dieselLettersIds: ids,
        },
      }),
    );
  };

  const reloadFilters = () => {
    dispatch(IndicesHistoryActions.listIndicesHistory(params));
  };

  const confirmEdit = (actionParams) => {
    if (actionParams.action) {
      if (itemEdit.order === utility.IndiceType.INFLATION) {
        dispatch(
          IndicesHistoryActions.inflationaryReadjustmentEditStart(
            actionParams.fields,
            reloadFilters,
          ),
        );
      } else {
        dispatch(
          IndicesHistoryActions.dieselReadjustmentEditStart(
            actionParams.fields,
            reloadFilters,
          ),
        );
      }
    }
    setItemEdit({});
    setOpenEdit(false);
  };

  const handleOpenAccumulateHistoryModal = (item) => {
    setIndiceName(item.name);
    setOpenHistoryAccumulateModal(true);
  };

  const handleOpenInflationHistoryAccumulateModal = (item) => {
    setIndiceName(item.name);
    setOpenInflationHistoryAccumulateModal(true);
  };

  const handleOptionItemEditClick = (item) => {
    if (enableEdit(item)) {
      setEditItem({ item });
    }
  };

  const handleOptionItemDownloadClick = (item, letterIds) => {
    if (enableDownload(item, letterIds)) {
      handleDownload(letterIds);
    }
  };

  return (
    <S.TableContainer data-testid="indices-history-table-test">
      {openEdit && (
        <IndicesHistoryEdit item={itemEdit} callBack={confirmEdit} />
      )}

      {openHistoryAccumulateModal && (
        <IndicesHistoryAccumulateModal
          list={list}
          indiceHistoryName={indiceName}
          onClose={() => setOpenHistoryAccumulateModal(false)}
        />
      )}

      {openInflationHistoryAccumulateModal && (
        <IndicesInflationHistoryAccumulateModal
          list={list}
          indiceHistoryName={indiceName}
          onClose={() => setOpenInflationHistoryAccumulateModal(false)}
        />
      )}

      <Table>
        <Thead>
          <Tr>
            <Th>Índices</Th>
            {list.months.map((monthName) => (
              <Th key={`th-${monthName}`}>{monthName}</Th>
            ))}
            <Th>Ações</Th>
          </Tr>
        </Thead>

        <tbody>
          {list.names.map((indice, indiceIndex) => {
            const indiceID = utility.simpleId();

            return (
              <Tr key={indiceID}>
                <Td>
                  {' '}
                  <Text
                    size="medium"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {indice}
                  </Text>
                </Td>

                {list.monthValues.map((month, monthIndex) => {
                  const percentageID = utility.simpleId();
                  const item = month.indexVariations[indiceIndex];
                  const letterIds = getLetterIds(list.monthValues, item);

                  return (
                    <React.Fragment key={`item-${item.percentageId}`}>
                      <Td>
                        <span data-tip="" data-for={percentageID}>
                          <Text
                            size="medium"
                            style={{
                              fontWeight: '400',
                            }}
                          >
                            {utility.valstr(item.percentage, 'percent')}
                          </Text>
                        </span>
                      </Td>

                      {monthIndex === list.monthValues.length - 1 && (
                        <Td key={`action-${item.month}`}>
                          <S.ContentCell data-testid="cell-with-action">
                            <Popover
                              content={
                                <Options
                                  item={item}
                                  letterIds={letterIds}
                                  handleOpenAccumulateHistoryModal={
                                    handleOpenAccumulateHistoryModal
                                  }
                                  handleOpenInflationHistoryAccumulateModal={
                                    handleOpenInflationHistoryAccumulateModal
                                  }
                                  handleOptionItemDownloadClick={
                                    handleOptionItemDownloadClick
                                  }
                                  handleOptionItemEditClick={
                                    handleOptionItemEditClick
                                  }
                                />
                              }
                              position="left center"
                            >
                              <Button
                                data-testid="indices-history-table-action-button-test"
                                icon={IcOption}
                                name=""
                                disabled={!getEnableOption(item, letterIds)}
                                variant="tertiary"
                              />
                            </Popover>
                          </S.ContentCell>
                        </Td>
                      )}
                    </React.Fragment>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </S.TableContainer>
  );
};

export default IndicesHistoryTable;

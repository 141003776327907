import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@vli/locomotive-ds/dist';
import * as utility from 'lib/core/utility';
import Tooltip from 'components/core/Tooltip';

const CellExecutedValue = ({
  cell: { value },
  row,
  informative,
  transformedData,
  onClick,
}) => {
  const { original } = row;
  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };
  return (
    <Tooltip
      title={`Data de cadastro ${utility.formatDate(original.creationDate)}`}
      place="top"
    >
      <Text
        size="medium"
        onClick={handleOnClick}
        data-testid={`cell-table-${value}`}
        style={{ cursor: 'pointer', width: '100%', fontWeight: '400' }}
      >
        {value !== undefined && (transformedData(value) || '-')} {informative}
      </Text>
    </Tooltip>
  );
};

CellExecutedValue.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }),
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      creationDate: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellExecutedValue.defaultProps = {
  cell: {
    value: '',
  },
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellExecutedValue;

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import * as S from '../TakeOrPayModalStyles';

const FlowDates = ({ compensationData }) => {
  const formatDate = (date) => {
    if (date) {
      return format(new Date(date), "d 'de' MMMM", {
        locale: ptBR,
      });
    }

    return '-';
  };

  return (
    <S.DateContainer data-testid="flow-dates-test">
      <S.DateColumn>
        <S.Title>Datas para apuração e faturamento</S.Title>

        <S.DataLine justifyContent="space-between">
          <S.DataLineItem>
            <span>Data de apuração mensal</span>
            <small>
              Dia{' '}
              {compensationData?.verificationPeriodDay
                ? compensationData.verificationPeriodDay
                : '-'}
            </small>
          </S.DataLineItem>
          <S.DataLineItem>
            <span>Data de apuração final</span>
            <small>
              {formatDate(compensationData.verificationPeriodFinalDate)}
            </small>
          </S.DataLineItem>
          <S.DataLineItem>
            <span>Data de faturamento anual</span>
            <small>{formatDate(compensationData.invoicingDate)}</small>
          </S.DataLineItem>
        </S.DataLine>

        <S.Title>Datas limite para alteração de volume</S.Title>

        <S.DataLine justifyContent="space-between">
          <S.DataLineItem>
            <span>Data limite para solicitar a alteração do volume</span>
            <small>
              {formatDate(compensationData.volumeChangeConfirmationDate)}
            </small>
          </S.DataLineItem>
          <S.DataLineItem>
            <span>Variação máxima do volume (para mais ou para menos)</span>
            <small>
              {compensationData?.maxFactorVariationConfirmation
                ? `${compensationData.maxFactorVariationConfirmation}%`
                : '-'}
            </small>
          </S.DataLineItem>
        </S.DataLine>

        <S.DataLine justifyContent="space-between">
          <S.DataLineItem>
            <span>Data limite para alterar o volume</span>
            <small>{formatDate(compensationData.volumeChangeDate)}</small>
          </S.DataLineItem>
          <S.DataLineItem>
            <span>Variação máxima do volume (para mais ou para menos)</span>
            <small>
              {compensationData?.maxFactorVariation
                ? `${compensationData.maxFactorVariation}%`
                : '-'}
            </small>
          </S.DataLineItem>
        </S.DataLine>
      </S.DateColumn>
    </S.DateContainer>
  );
};

FlowDates.propTypes = {
  compensationData: PropTypes.object.isRequired,
};

export default FlowDates;

import React from 'react';
import { Header, Flex, Container } from '@vli/locomotive-ds/dist';
import Content from 'components/core/content';
import useDimensions from 'hooks/useDimensions';
import Search from 'components/contexts/notifications/manager/ManagerList/Search';
import ManagerList from 'components/contexts/notifications/manager/ManagerList/ManagerList';

const breadcrumb = [
  {
    text: 'Notificações de Gerentes',
    href: null,
  },
];

const EmailNotificationsManagerList = () => {
  const dimensions = useDimensions();

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Flex>
          <Header
            bread={breadcrumb}
            tag="header"
            title="Notificações de Gerentes"
          />
        </Flex>
        <Search />
        <ManagerList />
      </Container>
    </Content>
  );
};

export default EmailNotificationsManagerList;

import styled from 'styled-components';

export const WrapperInput = styled.div`
  margin-right: ${({ marginRight }) => `${marginRight}px` || 0};
  margin-top: ${({ marginTop }) => `${marginTop}px` || 0};
  /* padding: 30px 0px 10px 0px */
`;

export const WrapperLabel = styled.div`
  margin-bottom: 4px;
`;

import styled, { css } from 'styled-components';
import { Tokens, Modal } from '@vli/locomotive-ds';

export const ModalContainer = styled(Modal)`
  width: 100%;
`;

export const Scroll = styled.div`
  width: 100%;
  height: 490px;

  position: relative;

  margin-top: ${Tokens.spacing.sm};

  max-height: 640px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${Tokens.colors.accentPrimaryLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Table = styled.div`
  width: 100%;

  border: 1px solid ${Tokens.colors.gray200};
  border-radius: 4px;
`;

export const TableHead = styled.div`
  width: 100%;

  display: flex;

  border-bottom: 1px solid ${Tokens.colors.gray200};
`;

export const TableCell = styled.div`
  width: ${(props) => props.width}%;

  padding: ${Tokens.spacing.xs} ${Tokens.spacing.sm};

  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.gray400};
`;

export const TableBody = styled.div`
  width: 100%;
`;

export const TableItem = styled.label`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${(props) => {
    if (props.isSelected) {
      return css`
        background-color: ${Tokens.colors.accentPrimaryLight};

        span {
          color: ${Tokens.colors.accentPrimaryDark};
        }
      `;
    }

    if (props.isDisabled) {
      return css`
        background-color: ${Tokens.colors.gray100};
        cursor: unset;

        span {
          color: ${Tokens.colors.gray400};
        }
      `;
    }

    return css`
      background-color: 'transparent';

      span {
        color: ${Tokens.colors.grayBlack};
      }
    `;
  }}

  & + label {
    border-top: 1px solid ${Tokens.colors.gray200};
  }

  input {
    width: 18px;
    height: 18px;

    accent-color: ${Tokens.colors.accentPrimaryDark};
  }

  & > div {
    width: 5%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    padding: ${Tokens.spacing.xs} ${Tokens.spacing.sm};

    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;

    &:first-of-type {
      width: 40%;
    }

    &:last-of-type {
      width: 25%;
    }
  }

  small {
    width: 30%;
    padding: ${Tokens.spacing.xs} ${Tokens.spacing.sm};

    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;
  }
`;

export const Info = styled.span`
  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${Tokens.colors.gray400};
`;

export const Actions = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

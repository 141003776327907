import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { modeEditRow, resetEditRow } from 'lib/contexts/newItems';

export const {
  Types: NewItemsTypes,
  Creators: NewItemsActions,
} = createActions({
  newItemsListRequest: ['payload'],
  newItemsListSuccess: ['payload', 'filter'],
  newItemsListFailure: ['error'],
  getItemsForFilterRequest: ['payload'],
  getItemsForFilterSuccess: ['payload'],
  getItemsForFilterFailure: ['error'],
  createItemRequest: ['payload'],
  createItemSuccess: ['payload'],
  createItemFailure: ['payload'],
  setModalOpen: ['payload'],
  setInCurveRate: ['payload'],
  setEditRow: ['payload'],
  setChangeNewItemsRow: ['payload'],
  resetNewItemsRow: [],
  linkNewContractRequest: ['payload', 'callback'],
  linkNewContractSuccess: ['payload', 'callback'],
  linkNewContractFailure: ['error'],
  setEditModalOpen: ['payload'],
  getNewItemRequest: ['newItemId'],
  getNewItemSuccess: ['payload'],
  getNewItemFailure: ['error'],
  updateItemRequest: ['payload'],
  updateItemSuccess: ['payload'],
  updateItemFailure: ['error'],
  listOriginDestinyRequest: ['contractId'],
  listOriginDestinySuccess: ['payload'],
  listOriginDestinyFailure: ['error'],
  getTariffRequest: ['payload'],
  getTariffSuccess: ['payload'],
  getTariffFailure: ['error'],
  createGrainItemRequest: ['payload'],
  createGrainItemSuccess: ['payload'],
  createGrainItemFailure: ['error'],
});

const INITIAL_STATE = {
  newItemsList: [],
  location: {},
  itemTariff: [],
  draftNewItem: null,
  error: null,
  itemsForFilter: [],
  isOpen: false,
  inCurveTaxRateData: null,
  filter: {},
  newItemsRequests: {
    [NewItemsTypes.NEW_ITEMS_LIST_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.GET_ITEMS_FOR_FILTER_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.CREATE_ITEM_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.LINK_NEW_CONTRACT_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.GET_NEW_ITEM_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.UPDATE_ITEM_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.LIST_ORIGIN_DESTINY_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.GET_TARIFF_REQUEST]: REQUEST_NOT_STARTED,
    [NewItemsTypes.CREATE_GRAIN_ITEM_REQUEST]: REQUEST_NOT_STARTED,
  },
  rowEdit: null,
  editMode: false,
  changeEdit: false,
  changeEditOpen: false,
};

const newItemsListRequest = () => ({
  ...INITIAL_STATE,
  newItemsRequests: {
    ...INITIAL_STATE.newItemsRequests,
    [NewItemsTypes.NEW_ITEMS_LIST_REQUEST]: REQUEST_PENDING,
  },
});

const newItemsListSuccess = (state, { payload, filter }) => ({
  ...state,
  newItemsList: payload.data,
  filter,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.NEW_ITEMS_LIST_REQUEST]: REQUEST_RESOLVED,
  },
});

const newItemsListFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.NEW_ITEMS_LIST_REQUEST]: REQUEST_REJECTED,
  },
});

const getItemsForFilterRequest = () => ({
  ...INITIAL_STATE,
  newItemsRequests: {
    ...INITIAL_STATE.newItemsRequests,
    [NewItemsTypes.GET_ITEMS_FOR_FILTER_REQUEST]: REQUEST_PENDING,
  },
});

const getItemsForFilterSuccess = (state, { payload }) => ({
  ...state,
  itemsForFilter: payload.data,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_ITEMS_FOR_FILTER_REQUEST]: REQUEST_RESOLVED,
  },
});

const getItemsForFilterFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_ITEMS_FOR_FILTER_REQUEST]: REQUEST_REJECTED,
  },
});

const createItemRequest = (state) => ({
  ...state,
  message: null,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.CREATE_ITEM_REQUEST]: REQUEST_PENDING,
  },
});

const createItemSuccess = (state) => ({
  ...state,
  isOpen: false,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.CREATE_ITEM_REQUEST]: REQUEST_RESOLVED,
  },
});

const createItemFailure = (state) => ({
  ...state,
  isOpen: false,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.CREATE_ITEM_REQUEST]: REQUEST_REJECTED,
  },
});

const setModalOpen = (state, { payload }) => ({
  ...state,
  isOpen: payload.modalOpen,
});

const setInCurveRate = (state, { payload }) => ({
  ...state,
  inCurveTaxRateData: payload,
});

const setEditRow = (state, { payload }) => ({
  ...state,
  newItemsList: {
    ...state.newItemsList,
    items: modeEditRow(state.newItemsList.items, payload.row, payload.editMode),
  },
  rowEdit: payload.row,
  editMode: payload.editMode,
});

const setChangeNewItemsRow = (state, { payload }) => ({
  ...state,
  changeEdit: true,
  rowEdit: { ...state.rowEdit, [payload.field]: payload.value },
});

const resetNewItemsRow = (state) => ({
  ...state,
  newItemsList: {
    ...state.newItemsList,
    items: resetEditRow(state.newItemsList.items),
  },
  changeEdit: false,
  rowEdit: null,
  editMode: false,
});

const linkNewContractRequest = (state) => ({
  ...state,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.LINK_NEW_CONTRACT_REQUEST]: REQUEST_PENDING,
  },
});

const linkNewContractSuccess = (state) => ({
  ...state,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.LINK_NEW_CONTRACT_REQUEST]: REQUEST_RESOLVED,
  },
});

const linkNewContractFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.LINK_NEW_CONTRACT_REQUEST]: REQUEST_REJECTED,
  },
});

const setEditModalOpen = (state, { payload }) => ({
  ...state,
  changeEditOpen: payload.modalOpen,
});

const getNewItemRequest = () => ({
  ...INITIAL_STATE,
  newItemsRequests: {
    ...INITIAL_STATE.newItemsRequests,
    [NewItemsTypes.GET_NEW_ITEM_REQUEST]: REQUEST_PENDING,
  },
});

const getNewItemSuccess = (state, { payload }) => ({
  ...state,
  draftNewItem: payload.data,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_NEW_ITEM_REQUEST]: REQUEST_RESOLVED,
  },
});

const getNewItemFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_NEW_ITEM_REQUEST]: REQUEST_REJECTED,
  },
});

const updateItemRequest = (state) => ({
  ...state,
  message: null,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.UPDATE_ITEM_REQUEST]: REQUEST_PENDING,
  },
});

const updateItemSuccess = (state) => ({
  ...state,
  isOpen: false,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.UPDATE_ITEM_REQUEST]: REQUEST_RESOLVED,
  },
});

const updateItemFailure = (state) => ({
  ...state,
  isOpen: false,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.UPDATE_ITEM_REQUEST]: REQUEST_REJECTED,
  },
});

const listOriginDestinyRequest = (state) => ({
  ...state,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.LIST_ORIGIN_DESTINY_REQUEST]: REQUEST_PENDING,
  },
});

const listOriginDestinySuccess = (state, { payload }) => ({
  ...state,
  location: payload.data,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.LIST_ORIGIN_DESTINY_REQUEST]: REQUEST_RESOLVED,
  },
});

const listOriginDestinyFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.LIST_ORIGIN_DESTINY_REQUEST]: REQUEST_REJECTED,
  },
});

const getTariffRequest = (state) => ({
  ...state,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_TARIFF_REQUEST]: REQUEST_PENDING,
  },
});

const getTariffSuccess = (state, { payload }) => ({
  ...state,
  itemTariff: payload.data,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_TARIFF_REQUEST]: REQUEST_RESOLVED,
  },
});

const getTariffFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.GET_TARIFF_REQUEST]: REQUEST_REJECTED,
  },
});

const createGrainItemRequest = (state) => ({
  ...state,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.CREATE_GRAIN_ITEM_REQUEST]: REQUEST_PENDING,
  },
});

const createGrainItemSuccess = (state) => ({
  ...state,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.CREATE_GRAIN_ITEM_REQUEST]: REQUEST_RESOLVED,
  },
});

const createGrainItemFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  newItemsRequests: {
    ...state.newItemsRequests,
    [NewItemsTypes.CREATE_GRAIN_ITEM_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [NewItemsTypes.NEW_ITEMS_LIST_REQUEST]: newItemsListRequest,
  [NewItemsTypes.NEW_ITEMS_LIST_SUCCESS]: newItemsListSuccess,
  [NewItemsTypes.NEW_ITEMS_LIST_FAILURE]: newItemsListFailure,
  [NewItemsTypes.GET_ITEMS_FOR_FILTER_REQUEST]: getItemsForFilterRequest,
  [NewItemsTypes.GET_ITEMS_FOR_FILTER_SUCCESS]: getItemsForFilterSuccess,
  [NewItemsTypes.GET_ITEMS_FOR_FILTER_FAILURE]: getItemsForFilterFailure,
  [NewItemsTypes.CREATE_ITEM_REQUEST]: createItemRequest,
  [NewItemsTypes.CREATE_ITEM_SUCCESS]: createItemSuccess,
  [NewItemsTypes.CREATE_ITEM_FAILURE]: createItemFailure,
  [NewItemsTypes.SET_MODAL_OPEN]: setModalOpen,
  [NewItemsTypes.SET_IN_CURVE_RATE]: setInCurveRate,
  [NewItemsTypes.SET_EDIT_ROW]: setEditRow,
  [NewItemsTypes.SET_CHANGE_NEW_ITEMS_ROW]: setChangeNewItemsRow,
  [NewItemsTypes.RESET_NEW_ITEMS_ROW]: resetNewItemsRow,
  [NewItemsTypes.LINK_NEW_CONTRACT_REQUEST]: linkNewContractRequest,
  [NewItemsTypes.LINK_NEW_CONTRACT_SUCCESS]: linkNewContractSuccess,
  [NewItemsTypes.LINK_NEW_CONTRACT_FAILURE]: linkNewContractFailure,
  [NewItemsTypes.SET_EDIT_MODAL_OPEN]: setEditModalOpen,
  [NewItemsTypes.GET_NEW_ITEM_REQUEST]: getNewItemRequest,
  [NewItemsTypes.GET_NEW_ITEM_SUCCESS]: getNewItemSuccess,
  [NewItemsTypes.GET_NEW_ITEM_FAILURE]: getNewItemFailure,
  [NewItemsTypes.UPDATE_ITEM_REQUEST]: updateItemRequest,
  [NewItemsTypes.UPDATE_ITEM_SUCCESS]: updateItemSuccess,
  [NewItemsTypes.UPDATE_ITEM_FAILURE]: updateItemFailure,
  [NewItemsTypes.LIST_ORIGIN_DESTINY_REQUEST]: listOriginDestinyRequest,
  [NewItemsTypes.LIST_ORIGIN_DESTINY_SUCCESS]: listOriginDestinySuccess,
  [NewItemsTypes.LIST_ORIGIN_DESTINY_FAILURE]: listOriginDestinyFailure,
  [NewItemsTypes.GET_TARIFF_REQUEST]: getTariffRequest,
  [NewItemsTypes.GET_TARIFF_SUCCESS]: getTariffSuccess,
  [NewItemsTypes.GET_TARIFF_FAILURE]: getTariffFailure,
  [NewItemsTypes.CREATE_GRAIN_ITEM_REQUEST]: createGrainItemRequest,
  [NewItemsTypes.CREATE_GRAIN_ITEM_SUCCESS]: createGrainItemSuccess,
  [NewItemsTypes.CREATE_GRAIN_ITEM_FAILURE]: createGrainItemFailure,
});

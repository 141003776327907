import React from 'react';
import { Header, Flex, Container } from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';

import useDimensions from 'hooks/useDimensions';
import * as ROUTES from 'constants/urls';

import Spreadsheets from 'components/contexts/contractUpload/Spreadsheets';
import Register from 'components/contexts/contractUpload/Register';

import * as S from './ContractUploadStyles';

const ContractUpload = () => {
  const dimensions = useDimensions();

  const breadcrumb = [
    {
      text: 'Formalizações',
      href: `#${ROUTES.CONTRACTS}`,
    },
    {
      text: 'Upload de contrato',
      href: null,
    },
  ];

  return (
    <Content data-testid="upload-contract-page-test" height={dimensions.height}>
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Upload de contrato" />
        </Flex>

        <S.Sections>
          <Spreadsheets />

          <Register />
        </S.Sections>
      </Container>
    </Content>
  );
};

export default ContractUpload;

import React from 'react';
import * as utility from 'lib/core/utility';
import CellTable from 'components/core/Table/CellTable';
import CellWithActions from 'components/contexts/resendItems/CellWithActions/CellWithActions';

const Columns = [
  {
    Header: 'Item',
    accessor: 'railwayItemCode',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Contrato',
    accessor: 'contractCode',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Tarifa Atual',
    accessor: 'calculatedValue',
    align: 'start',
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatMoney} />
    ),
  },
  {
    Header: 'Nova Tarifa',
    accessor: 'lastTariff',
    align: 'start',
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatMoney} />
    ),
  },
  {
    Header: 'Data de Aplicação',
    accessor: 'applicationDate',
    align: 'start',
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatDate} />
    ),
  },
  {
    Header: 'Status',
    accessor: 'statusText',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'end',
    Cell: (props) => <CellWithActions {...props} />,
  },
];

export default Columns;

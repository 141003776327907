import CONSTANTS from 'constants/constants';

const msalConfig = {
  auth: {
    clientId: CONSTANTS.CLIENT_ID_AUTH,
    authority: `https://login.microsoftonline.com/${CONSTANTS.TENANT_ID}`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

const apiRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export { msalConfig, apiRequest };

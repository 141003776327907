import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAutoParams } from 'lib/contexts/user';
import { Tokens } from '@vli/locomotive-ds/dist';
import { UserActions } from 'store/user/user.ducks';
import { ContractActions } from 'store/contract/contract.ducks';

import CellEditAutocompleteWrapper from 'components/core/Table/CellEditAutocompleteWrapper/CellEditAutocompleteWrapper';
import { EditCellSchema } from 'lib/core/tableCellSchemas';

const CellEditAutocomplete = (props) => {
  const dispatch = useDispatch();
  const {
    cell: { value },
    row,
  } = props;
  const [field, setField] = useState(value);

  const { data: users, filter } = useSelector(({ user }) => user);
  const onInputChange = (newValue) => {
    if (newValue) {
      const data = getAutoParams({
        name: newValue,
        active: true,
        pageIndex: 1,
        pageSize: 25,
      });
      dispatch(UserActions.fetchUsersFilter(data, filter));
    }
  };

  const onChange = (e) => {
    setField(e);
    dispatch(
      ContractActions.setChangeContractRow({
        field: 'accountManager',
        value: e.label,
      }),
    );
    dispatch(
      ContractActions.setChangeContractRow({
        field: 'accountManagerUserId',
        value: e.value,
      }),
    );
  };

  useEffect(() => {
    if (row.original.editMode) {
      const data = getAutoParams({
        active: true,
        pageIndex: 1,
        pageSize: 25,
      });
      dispatch(UserActions.fetchUsersFilter(data, null));
    }
  }, [dispatch, row.original.editMode]);
  const selects = users?.items?.map((user) => {
    return {
      label: user.userName,
      value: user.id,
    };
  });

  return (
    <CellEditAutocompleteWrapper
      {...props}
      onChange={onChange}
      onInputChange={onInputChange}
      selects={selects}
      field={field}
    />
  );
};

CellEditAutocomplete.propTypes = EditCellSchema;

CellEditAutocomplete.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
    },
  },
  color: Tokens.colors.grayBlack,
  transformedData: (text) => text,
  align: 'start',
};

export default CellEditAutocomplete;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { Flex, Row, Col, Button, Input } from '@vli/locomotive-ds/dist';

import InputSelect from 'components/form/InputSelect';
import Loading from 'components/core/Loading';
import ModalConfirm from 'components/core/ModalConfirm';

import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { LocalActions } from 'store/local/local.ducks';
import { NewItemsActions } from 'store/newItems/newItems.ducks';

import { isPending } from 'lib/core/request';
import { getOptionsActiveContracts } from 'lib/contexts/contracts';
import {
  getNewItemDataSend,
  setBaseDieselLocalTypeNameOptions,
} from 'lib/contexts/newItems';
import * as utility from 'lib/core/utility';
import handleInputMask from 'lib/core/handleInputMask';

import TableRate from '../contractedRate/TableRate/TableRate';

import * as S from './Form.styles';

const Form = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { activeContractList, activeContractRequests } = useSelector(
    ({ activeContracts }) => activeContracts,
  );
  const { data, localRequests } = useSelector(({ local }) => local);
  const { newItemsRequests, isOpen, draftNewItem } = useSelector(
    ({ newItems }) => newItems,
  );
  const [hasTrigger, setHasTrigger] = useState(false);
  const [isFixedFare, setIsFixedFare] = useState(false);
  const [segmentId, setSegmentId] = useState('');
  const [inCurveDataFromTable, setInCurveDataFromTable] = useState([]);

  const { handleSubmit, errors, setValue, control, getValues } = useForm({
    defaultValues: {
      contract: '',
      railwayId: '',
      cargoOrigin: '',
      originCode: '',
      destination: '',
      destinationCode: '',
      segment: '',
      railwayInvolved: '',
      placeOriginCargo: '',
      trigger: 0,
      triggerPercentage: '',
      product: '',
      dataBase: '',
      diesel: '',
      correctionStartDate: '',
      correctionEndDate: '',
      fareType: '',
      fareValues: '',
    },
  });

  const handleSelectActiveContract = useCallback(
    (code) => {
      const contractFiltered = activeContractList.filter(
        (contract) => contract.code === code,
      );

      setValue('fareType', contractFiltered[0]?.fareType);
      setValue('segment', contractFiltered[0]?.segment.name);
      setIsFixedFare(contractFiltered[0]?.fareType !== 0);
      setSegmentId(contractFiltered[0]?.segment.id);
    },
    [setValue, activeContractList],
  );

  const getTableData = (tableData) => {
    return setInCurveDataFromTable(tableData);
  };

  useEffect(() => {
    dispatch(ActiveContractActions.activeContract());
    dispatch(LocalActions.localFilteredList('withDeleted=false'));

    if (id) {
      dispatch(NewItemsActions.getNewItemRequest(id));
    }
  }, [dispatch, id]);

  const loading =
    isPending(localRequests.LOCAL_FILTERED_LIST) ||
    isPending(activeContractRequests.ACTIVE_CONTRACT) ||
    isPending(newItemsRequests.CREATE_ITEM_REQUEST);

  const handleSaveDraft = (formData, status) => {
    const formDataSend = getNewItemDataSend(
      formData,
      segmentId,
      status,
      inCurveDataFromTable,
    );

    if (id) {
      dispatch(
        NewItemsActions.updateItemRequest({
          id,
          ...formDataSend,
        }),
      );
    } else {
      dispatch(NewItemsActions.createItemRequest(formDataSend));
    }
  };

  const handleUpdateFormWithDraftData = useCallback(() => {
    setValue('contract', draftNewItem.codeContract);
    setValue('railwayId', draftNewItem.codeItem);
    setValue('cargoOrigin', draftNewItem?.origin || '');
    setValue('originCode', draftNewItem?.originCode || '');
    setValue('destination', draftNewItem?.destination || '');
    setValue('destinationCode', draftNewItem?.destinationCode || '');
    setValue('segment', draftNewItem?.segment?.name || '');
    setValue('railwayInvolved', draftNewItem?.railwayInvolved || '');
    setValue('placeOriginCargo', draftNewItem?.placeOriginCargo || '');
    setValue('trigger', draftNewItem?.hasTrigger ? 1 : 0);
    setValue(
      'triggerPercentage',
      String(draftNewItem?.triggerPercentage) || '',
    );
    setValue('product', draftNewItem?.productUnicomMerchandiseTacito || '');
    setValue('dataBase', new Date(draftNewItem?.dataBase) || '');
    setValue('diesel', draftNewItem?.local?.id || '');
    setValue('correctionStartDate', draftNewItem?.correctionStartDate || '');
    setValue('correctionEndDate', draftNewItem?.correctionEndDate || '');
    setValue('fareType', draftNewItem?.fareType);
    setValue(
      'fareValues',
      draftNewItem.fareType === 0
        ? String(draftNewItem.fareValues[0].value).replace('.', ',')
        : '',
    );

    setIsFixedFare(draftNewItem?.fareType !== 0);
    setSegmentId(draftNewItem?.segment.id);
  }, [draftNewItem, setValue, setIsFixedFare, setSegmentId]);

  useEffect(() => {
    if (draftNewItem) {
      handleUpdateFormWithDraftData();
    }
  }, [draftNewItem, handleUpdateFormWithDraftData]);

  const modalConfirm = (
    <ModalConfirm
      closeModal={() =>
        dispatch(
          NewItemsActions.setModalOpen({
            modalOpen: false,
          }),
        )
      }
      confirmAction={handleSubmit((e) => {
        handleSaveDraft(e, 1);
      })}
      title={`Deseja vincular o item ferroviário ${getValues(
        'railwayId',
      )} ao contrato ${getValues('contract')}?`}
      message="Ao vincular, o item estará presente na listagem de itens ferroviários do contrato com possibilidade de aprovação e reprovação de preços futuros"
    />
  );

  return (
    <form data-testid="create-new-default-form-test">
      {isOpen && modalConfirm}

      {loading && <Loading />}

      {!loading && (
        <>
          <S.ExpandedAccordion title="Novos Itens" open>
            <Row>
              <Col desktop={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="contract"
                  render={({ onChange, value }) => (
                    <InputSelect
                      value={value}
                      options={getOptionsActiveContracts(activeContractList)}
                      onChange={(e) => {
                        onChange(e);
                        handleSelectActiveContract(e);
                      }}
                      placeholder="Selecione"
                      label="Contrato*"
                      type="text"
                      maxLength="50"
                      styles={{ marginRight: '10px' }}
                      status={errors.contract && 'error'}
                      statusMessage={errors.contract && 'Campo requerido'}
                    />
                  )}
                />
              </Col>
              <Col desktop={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="railwayId"
                  render={({ onChange, value }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Número item"
                      label="Número item ferroviário*"
                      type="input"
                      onInput={(event) => handleInputMask(event, 'digits')}
                      required
                      status={errors.railwayId && 'error'}
                      statusMessage={errors.railwayId && 'Campo requerido'}
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  control={control}
                  name="originCode"
                  render={({ onChange, value }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Estação de origem"
                      label="Código estação origem"
                      type="text"
                      maxLength="20"
                      required
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  control={control}
                  name="cargoOrigin"
                  render={({ onChange, value }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Origem da carga"
                      label="Origem"
                      type="text"
                      maxLength="50"
                      required
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  control={control}
                  name="destinationCode"
                  render={({ onChange, value }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Estação de destino"
                      label="Código estação destino"
                      type="text"
                      maxLength="20"
                      required
                      styles={{ marginRight: '10px' }}
                    />
                  )}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  control={control}
                  name="destination"
                  render={({ onChange, value }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder="Destino da carga"
                      label="Destino"
                      type="text"
                      maxLength="50"
                      required
                      styles={{ marginRight: '10px' }}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col desktop={4}>
                <Controller
                  control={control}
                  name="segment"
                  render={({ value }) => {
                    return (
                      <Input
                        value={value}
                        placeholder="Segmento"
                        label="Segmento"
                        type="text"
                        maxLength="50"
                        required
                        disabled
                      />
                    );
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="railwayInvolved"
                  render={({ onChange, value }) => (
                    <S.Spacing>
                      <Input
                        value={value}
                        onChange={onChange}
                        placeholder="Ferrovias"
                        label="Ferrovias envolvidas"
                        type="text"
                        maxLength="50"
                        required
                        status={errors.railwayInvolved && 'error'}
                        statusMessage={
                          errors.railwayInvolved && 'Campo requerido'
                        }
                      />
                    </S.Spacing>
                  )}
                />
              </Col>
              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: true }}
                  name="trigger"
                  render={({ onChange, value }) => {
                    return (
                      <S.Spacing>
                        <InputSelect
                          value={value}
                          options={utility.optionsTrigger}
                          onChange={(e) => {
                            onChange(e);
                            setHasTrigger(e !== 0);
                          }}
                          placeholder="Selecione"
                          label="Gatilho"
                          type="text"
                          maxLength="50"
                          // required
                          styles={{ marginRight: '10px' }}
                          // status={errors.profileId && 'error'}
                          // statusMessage={errors.profileId && 'Campo requerido'}
                        />
                      </S.Spacing>
                    );
                  }}
                />
              </Col>
              {hasTrigger && (
                <Col desktop={2}>
                  <Controller
                    control={control}
                    rules={{ required: !!hasTrigger }}
                    name="triggerPercentage"
                    render={({ onChange, value }) => (
                      <S.Spacing>
                        <Input
                          value={value}
                          onChange={onChange}
                          placeholder="%"
                          label="Percentual"
                          type="text"
                          maxLength="50"
                          required={!!hasTrigger}
                          status={errors.triggerPercentage && 'error'}
                          statusMessage={
                            errors.triggerPercentage && 'Campo requerido'
                          }
                        />
                      </S.Spacing>
                    )}
                  />
                </Col>
              )}

              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="placeOriginCargo"
                  render={({ onChange, value }) => (
                    <S.Spacing>
                      <Input
                        value={value}
                        onChange={onChange}
                        placeholder="Observação"
                        label="Observação"
                        type="text"
                        maxLength="50"
                        required
                        status={errors.placeOriginCargo && 'error'}
                        statusMessage={
                          errors.placeOriginCargo && 'Campo requerido'
                        }
                      />
                    </S.Spacing>
                  )}
                />
              </Col>
              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="product"
                  render={({ onChange, value }) => (
                    <S.Spacing>
                      <Input
                        value={value}
                        onChange={onChange}
                        placeholder="Produto"
                        label="Produto"
                        type="text"
                        maxLength="50"
                        required
                        status={errors.product && 'error'}
                        statusMessage={errors.product && 'Campo requerido'}
                      />
                    </S.Spacing>
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col desktop={1}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="dataBase"
                  render={({ onChange, value }) => (
                    <S.Spacing>
                      <S.InputDateItem
                        label="Data base"
                        format="dd/MM"
                        selected={value}
                        value={value}
                        locale="pt-BR"
                        onChange={onChange}
                        width="100%"
                      />
                    </S.Spacing>
                  )}
                />
              </Col>
              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="diesel"
                  render={({ onChange, value }) => {
                    return (
                      <S.Spacing>
                        <InputSelect
                          value={value}
                          options={
                            data.length &&
                            utility.getOptions(
                              setBaseDieselLocalTypeNameOptions(data),
                            )
                          }
                          onChange={onChange}
                          placeholder="Selecione"
                          label="Base diesel"
                          type="text"
                          maxLength="50"
                          required
                          styles={{ marginRight: '10px' }}
                          status={errors.diesel && 'error'}
                          statusMessage={errors.diesel && 'Campo requerido'}
                        />
                      </S.Spacing>
                    );
                  }}
                />
              </Col>

              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="correctionStartDate"
                  render={({ onChange, value }) => (
                    <S.Spacing>
                      <InputSelect
                        value={value}
                        options={utility.optionsMonths}
                        onChange={onChange}
                        placeholder="Selecione"
                        label="Início da correção"
                        type="text"
                        maxLength="50"
                        styles={{ marginRight: '10px' }}
                        status={errors.correctionStartDate && 'error'}
                        statusMessage={
                          errors.correctionStartDate && 'Campo requerido'
                        }
                      />
                    </S.Spacing>
                  )}
                />
              </Col>
              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="correctionEndDate"
                  render={({ onChange, value }) => (
                    <S.Spacing>
                      <InputSelect
                        value={value}
                        options={utility.optionsMonths}
                        onChange={onChange}
                        placeholder="Selecione"
                        label="Término da correção"
                        type="text"
                        maxLength="50"
                        styles={{ marginRight: '10px' }}
                        status={errors.correctionEndDate && 'error'}
                        statusMessage={
                          errors.correctionEndDate && 'Campo requerido'
                        }
                      />
                    </S.Spacing>
                  )}
                />
              </Col>
            </Row>
          </S.ExpandedAccordion>

          <S.ExpandedAccordion title="Tarifa contratada" open>
            <Row>
              <Col desktop={2}>
                <Controller
                  control={control}
                  // rules={{ required: isBondItem }}
                  name="fareType"
                  render={({ onChange, value }) => (
                    <InputSelect
                      value={value}
                      options={utility.optionsContractedFare}
                      onChange={onChange}
                      placeholder="Selecione"
                      label=""
                      type="text"
                      maxLength="50"
                      disabled
                      styles={{ marginRight: '10px' }}
                      status={errors.fareType && 'error'}
                      statusMessage={errors.fareType && 'Campo requerido'}
                    />
                  )}
                />
              </Col>
              <Col desktop={1}>
                <Controller
                  control={control}
                  name="fareValues"
                  render={({ onChange, value }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder={isFixedFare ? 'meses' : 'R$'}
                      label=""
                      type="input"
                      maxLength="8"
                      disabled={isFixedFare}
                      status={errors.fareValues && 'error'}
                      statusMessage={errors.fareValues && 'Campo requerido'}
                      onInput={(event) => handleInputMask(event, 'currency')}
                    />
                  )}
                />
              </Col>
            </Row>
            {isFixedFare && (
              <Row style={{ marginTop: '10px' }}>
                <TableRate sendData={getTableData} />
              </Row>
            )}
          </S.ExpandedAccordion>

          <Flex justifyContent="flex-end" alignItems="center">
            <Button
              name="Salvar rascunho"
              variant="tertiary"
              style={{ marginRight: '5px' }}
              action={handleSubmit((e) => handleSaveDraft(e, 0))}
            />
            <Button
              name="Vincular"
              type="submit"
              action={(e) => {
                e.preventDefault();
                dispatch(
                  NewItemsActions.setModalOpen({
                    modalOpen: true,
                  }),
                );
              }}
            />
          </Flex>
        </>
      )}
    </form>
  );
};

export default Form;

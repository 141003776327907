import { put, takeLatest } from 'redux-saga/effects';

import { GET, PATCH } from 'constants/verbs';
import { notificationApi } from 'services/api';

import { NotificationTypes, NotificationActions } from './notification.ducks';

const CONTRACTS = '/api/v1/Notificacao';
const GET_UNREAD = '/api/v1/Notificacao/nao-lidas';

export function* listNotificationRequest({ params }) {
  try {
    const response = yield notificationApi({
      method: GET,
      url: `${CONTRACTS}/${params}`,
    });

    yield put(
      NotificationActions.listNotificationRequestSuccess(response.data),
    );
  } catch (error) {
    yield put(NotificationActions.listNotificationRequestFailure(error));
  }
}

export function* getUnreadNotificationRequest({ params }) {
  try {
    const response = yield notificationApi({
      method: GET,
      url: `${GET_UNREAD}/${params}`,
    });

    yield put(
      NotificationActions.getUnreadNotificationRequestSuccess(response.data),
    );
  } catch (error) {
    yield put(NotificationActions.getUnreadNotificationRequestFailure(error));
  }
}

export function* updateNotificationToReadRequest({ params }) {
  try {
    const response = yield notificationApi({
      method: PATCH,
      url: `${CONTRACTS}/${params}`,
    });

    yield put(
      NotificationActions.updateNotificationToReadRequestSuccess(response.data),
    );
  } catch (error) {
    yield put(
      NotificationActions.updateNotificationToReadRequestFailure(error),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    NotificationTypes.LIST_NOTIFICATION_REQUEST,
    listNotificationRequest,
  );
  yield takeLatest(
    NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST,
    getUnreadNotificationRequest,
  );
  yield takeLatest(
    NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST,
    updateNotificationToReadRequest,
  );
}

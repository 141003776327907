import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/accordion/chevronDown.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/accordion/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/accordion/success.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/accordion/incrementMinus.svg';

import * as S from './AccordionStyles';

const ICONS = {
  error: <ErrorIcon data-testid="accordion-error-icon-test" />,
  success: <SuccessIcon data-testid="accordion-success-icon-test" />,
  default: <MinusIcon data-testid="accordion-default-icon-test" />,
};

const Accordion = ({ title, variant, isDefaultOpen, children }) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const handleToggleAccordion = () => {
    setIsOpen((oldState) => !oldState);
  };

  return (
    <S.AccordionContainer
      variant={variant}
      data-testid={`accordion-${isOpen ? 'opened' : 'closed'}-test`}
    >
      <S.AccordionHeader
        onClick={handleToggleAccordion}
        isOpen={isOpen}
        data-testid="accordion-header-test"
      >
        <div>
          {variant !== 'none' && ICONS[variant]}

          {typeof title === 'string' ? (
            <span data-testid="accordion-title-test">{title}</span>
          ) : (
            <>{title}</>
          )}
        </div>

        <ChevronDownIcon />
      </S.AccordionHeader>

      <S.AccordionContent
        isOpen={isOpen}
        data-testid={`accordion-content-${isOpen ? 'open' : 'close'}-test`}
      >
        {children}
      </S.AccordionContent>
    </S.AccordionContainer>
  );
};

Accordion.propTypes = {
  title: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'success', 'error', 'none']),
  isDefaultOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  isDefaultOpen: false,
  variant: 'default',
};

export default Accordion;

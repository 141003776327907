import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { FormulasActions } from 'store/formulas/formulas.ducks';

import * as S from './SectionsStyled';

const EXTRA_FORMULA_INITIAL_STATE = {
  isVisible: false,
  formula: null,
};

const InflationReadjustment = ({ disableFields, field, isFieldRequired }) => {
  const dispatch = useDispatch();
  const { control, watch, setValue, errors } = useFormContext();

  const { approvedFormulasItems } = useSelector(({ formulas }) => formulas);
  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [extraFormula, setExtraFormula] = useState(EXTRA_FORMULA_INITIAL_STATE);
  const [showPricingMessage, setShowPricingMessage] = useState(false);

  const inputName = `formulas.${field}`;

  useEffect(() => {
    setShowPricingMessage(false);
    setExtraFormula(EXTRA_FORMULA_INITIAL_STATE);
  }, []);

  useEffect(() => {
    const dataFormulas = formalizationData?.formalization?.formulas;

    if (dataFormulas) {
      setValue(inputName, dataFormulas[field]);
    }
  }, [formalizationData, setValue, field, inputName]);

  useEffect(() => {
    if (showPricingMessage) {
      dispatch(FormulasActions.formulasItemsRequest());
    }
  }, [dispatch, showPricingMessage]);

  const inflationReadjustmentValue = watch(inputName);

  const inflationFormulas = approvedFormulasItems?.filter(
    (formula) => formula.formulaTypeId === 2,
  );

  return (
    <S.FormColumn data-testid={`form-section-${field}-test`}>
      <S.Table>
        <S.TableHead>
          <S.TableCell width={5} />
          <S.TableCell width={20}>Fórmula</S.TableCell>
          <S.TableCell width={75}>Racional</S.TableCell>
        </S.TableHead>

        <S.TableBody>
          {inflationFormulas?.map((formula) => (
            <S.TableItem
              key={formula.id}
              htmlFor={`${formula.id}-${field}`}
              isDisabled={disableFields}
              isSelected={+inflationReadjustmentValue === formula.id}
            >
              <div>
                <Controller
                  name={inputName}
                  control={control}
                  rules={{ required: isFieldRequired }}
                  render={({ ...params }) => (
                    <input
                      {...params}
                      type="radio"
                      id={`${formula.id}-${field}`}
                      value={formula.id}
                      disabled={disableFields}
                      checked={+inflationReadjustmentValue === formula.id}
                    />
                  )}
                />
              </div>
              <span>{formula.name}</span>
              <span>{formula.translatedFormula}</span>
            </S.TableItem>
          ))}
        </S.TableBody>
      </S.Table>

      {extraFormula.isVisible && extraFormula.formula && (
        <S.Table>
          <S.TableBody>
            <S.TableItem
              key={extraFormula.formula.id}
              htmlFor={`${extraFormula.formula.id}-${field}`}
              isDisabled={disableFields}
              isSelected={
                +inflationReadjustmentValue === extraFormula.formula.id
              }
            >
              <div>
                <Controller
                  name={inputName}
                  control={control}
                  rules={{ required: isFieldRequired }}
                  render={({ ...params }) => (
                    <input
                      {...params}
                      type="radio"
                      id={`${extraFormula.formula.id}-${field}`}
                      value={extraFormula.formula.id}
                      disabled={disableFields}
                      checked={
                        +inflationReadjustmentValue === extraFormula.formula.id
                      }
                    />
                  )}
                />
              </div>
              <span>{extraFormula.formula.name}</span>
              <span>{extraFormula.formula.translatedFormula}</span>
            </S.TableItem>
          </S.TableBody>
        </S.Table>
      )}

      {errors?.formulas?.[field] && (
        <S.ErrorMessage>{errors.formulas[field].message}</S.ErrorMessage>
      )}
    </S.FormColumn>
  );
};

InflationReadjustment.propTypes = {
  disableFields: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  field: PropTypes.oneOf(['inflationFormulaRailway', 'inflationFormulaHarbor'])
    .isRequired,
  isFieldRequired: PropTypes.bool.isRequired,
};

export default InflationReadjustment;

import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';

import * as utility from 'lib/core/utility';

import {
  CONTRACTS_CONTROL_REPORT,
  CONTRACTS_CONTROL_REPORT_DOWNLOAD,
} from 'constants/endpoints';
import { GET } from 'constants/verbs';

import { MessagesActions } from 'store/messages/messages.ducks';
import {
  ContractsControlActions,
  ContractsControlTypes,
} from './contractsControl.ducks';

export function* getContractsControlReport({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${CONTRACTS_CONTROL_REPORT}/${params}`,
    });

    yield put(
      ContractsControlActions.getContractsControlReportSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(ContractsControlActions.getContractsControlReportFailure(error));
  }
}

export function* downloadContractsControlReport({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${CONTRACTS_CONTROL_REPORT_DOWNLOAD}/${params}`,
      responseType: 'arraybuffer',
    });

    yield put(ContractsControlActions.downloadContractsControlReportSuccess());

    utility.downloadFile(response.data, 'ControleContratos.zip');

    yield put(
      MessagesActions.open({
        title: 'Sucesso ao realizar download',
        type: 'success',
        contentText:
          'Download do relatório de controle de contratos foi realizado',
      }),
    );
  } catch (error) {
    yield put(
      ContractsControlActions.downloadContractsControlReportFailure(error),
    );

    yield put(
      MessagesActions.open({
        title: 'Erro ao realizar download',
        type: 'error',
        contentText:
          'Não foi possível realizar download do relatório de controle de contratos',
      }),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    ContractsControlTypes.GET_CONTRACTS_CONTROL_REPORT,
    getContractsControlReport,
  );
  yield takeLatest(
    ContractsControlTypes.DOWNLOAD_CONTRACTS_CONTROL_REPORT,
    downloadContractsControlReport,
  );
}

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';
const Content = styled.div`
  width: 100%;
  padding: 0px ${Tokens.spacing.sm};
  ${({ height }) => height && `height: ${height}px; overflow: auto;`}
  header {
    padding: 16px 0px;
  }
`;

export default Content;

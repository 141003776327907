import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from '@vli/locomotive-ds/dist';
import * as S from './InputSelect.styles';

const InputSelect = ({
  options,
  label,
  informative,
  placeholder,
  width,
  marginRight,
  onChange,
  value,
  disabled,
  status,
  name,
  statusMessage,
  required,
  autocomplete,
}) => {
  return (
    <S.WrapperInput marginRight={marginRight}>
      {label && (
        <S.WrapperLabel>
          <Label data-testid={`label-${label}`} informative={informative}>
            {label}
          </Label>
        </S.WrapperLabel>
      )}
      <Input
        data-testid={`input-select-${label || name}`}
        type="select"
        value={value}
        name={name}
        options={options}
        placeholder={placeholder}
        required={required}
        status={status}
        disabled={disabled}
        statusMessage={statusMessage}
        width={width}
        onChange={(option) => onChange(option)}
        autocomplete={autocomplete}
      />
    </S.WrapperInput>
  );
};

InputSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  ).isRequired,
  label: PropTypes.string.isRequired,
  informative: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  marginRight: PropTypes.number,
  onChange: PropTypes.func,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  autocomplete: PropTypes.bool,
};

InputSelect.defaultProps = {
  informative: '',
  placeholder: '',
  width: '',
  marginRight: 0,
  onChange: () => {},
  status: '',
  name: '',
  statusMessage: '',
  required: false,
  disabled: false,
  value: '',
  autocomplete: false,
};

export default InputSelect;

import styled, { css } from 'styled-components';
import { Tokens, Text } from '@vli/locomotive-ds/dist';

export const Container = styled.div`
  display: block;
`;

export const Title = styled(Text)`
  font-size: ${Tokens.spacing.sm};
  ${({ isActive }) =>
    isActive
      ? css`
          color: ${Tokens.colors.accentPrimaryDark};
        `
      : css`
          color: ${Tokens.colors.gray300};
        `}
`;

export const PointTimeLineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  width: fit-content;
`;

export const Point = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 20px;
  margin-right: 16px;
  ${({ isActive }) =>
    isActive
      ? css`
          background: ${Tokens.colors.accentPrimaryDark};
        `
      : css`
          background: ${Tokens.colors.gray300};
        `}
`;

export const HorizontalLine = styled.div`
  width: 115px;
  height: 4px;
  border-radius: 4px;
  ${({ isActive }) =>
    isActive
      ? css`
          background: ${Tokens.colors.accentPrimaryDark};
        `
      : css`
          background: ${Tokens.colors.gray300};
        `}
`;

export const TextInfo = styled(Text)`
  font-weight: 400;
  ${({ isActive }) =>
    isActive
      ? css`
          color: ${Tokens.colors.accentPrimaryDark};
        `
      : css`
          color: ${Tokens.colors.gray300};
        `}
`;

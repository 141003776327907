/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Menu,
  Avatar,
  ItensProfile,
  MenuList,
  MenuItem,
  InfoIcon,
} from '@vli/locomotive-ds';

import { AuthActions } from 'store/auth/auth.ducks';
import { NotificationActions } from 'store/notification/notification.ducks';

import Profile from 'components/structure/profile/profile';

import { GET } from 'lib/core/localStorage';
import { getEnvironment, capitalizeString } from 'lib/core/utility';
import { makeMenu } from 'constants/menu';
import { history } from 'constants/routes';
import useCollapseResize from 'hooks/useCollapseResize';

import { SidebarContent, TutorialLink } from './Sidebar.styles';

const Sidebar = ({ roles }) => {
  const dispatch = useDispatch();
  const { profile: role } = GET('profile');
  const { name } = GET('name');
  const menu = makeMenu(roles);
  const { environment, color, title } = getEnvironment();
  useCollapseResize();

  const handleClick = (clickedIndex) => {
    if (!menu[clickedIndex].subItem) {
      history.push(menu[clickedIndex].route);
    }
  };

  const handleOpenNotificationSidebar = () => {
    dispatch(
      NotificationActions.setNotificationSidebarOpen({ sidebarOpen: true }),
    );
  };

  const tutorialsLink = 'https://vlisa.sharepoint.com/sites/SmartSales';

  return (
    <SidebarContent enviroment={environment} color={color}>
      <Menu
        collapse={false}
        brandTitle={`Jetsons Preços \n${title}`}
        data-testid="sidebar-test"
      >
        <Profile
          avatar={
            <Avatar
              className=""
              color=""
              src=""
              status="online"
              userName={name}
            />
          }
          className="profile"
          open={false}
          profile={capitalizeString(role)}
          userName={name}
        >
          <ItensProfile
            active={false}
            danger
            onClick={() => dispatch(AuthActions.signOut())}
          >
            Sair
          </ItensProfile>
        </Profile>

        <MenuList>
          {menu.map((item, index) => (
            <MenuItem
              key={item.name}
              icon={item.active ? item.iconActive : item.icon}
              active={
                item.subItem
                  ? false
                  : history.location?.pathname.includes(item.route)
              }
              onClick={
                item.name === 'Notificações'
                  ? handleOpenNotificationSidebar
                  : () => handleClick(index)
              }
              subItem={item.subItem}
              title={item.subItem ? item.name : ''}
            >
              {item.subItem ? (
                <MenuList subList>
                  {item.subItems.map((subItem) => (
                    <MenuItem
                      key={subItem.name}
                      icon={subItem.icon}
                      onClick={() => {
                        history.push(subItem.route);
                      }}
                    >
                      {subItem.name}
                    </MenuItem>
                  ))}
                </MenuList>
              ) : (
                item.name
              )}
            </MenuItem>
          ))}
        </MenuList>

        <TutorialLink
          href={tutorialsLink}
          target="_blank"
          rel="noopener noreferrer"
          environment={environment}
        >
          <InfoIcon />
          <span>Tutoriais</span>
        </TutorialLink>
      </Menu>
    </SidebarContent>
  );
};
Sidebar.propTypes = {
  roles: PropTypes.array,
};
Sidebar.defaultProps = {
  roles: [],
};
export default Sidebar;

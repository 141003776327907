import React from 'react';
import { Button, Text, Tokens } from '@vli/locomotive-ds/dist';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Modal from 'components/core/modal';
import { ContractActions } from 'store/contract/contract.ducks';
import TextArea from 'components/form/TextArea';
import InputFile from 'components/form/InputFile/InputFile';
import * as S from './CloseContractModal.styles';

const CloseContractModal = () => {
  const { rowJustify } = useSelector(({ contract }) => contract);

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    justification: Yup.string()
      .min(15, 'A justificativa deve ter minímo de 15 caracteres')
      .max(500, 'A justificativa deve ter maxima de 500 caracteres')
      .required('A justificativa é um campo requerido'),
    justificationAttachment: Yup.mixed().required('Campo requerido'),
  });

  const { handleSubmit, errors, control, setError } = useForm({
    defaultValues: {
      justification: '',
      justificationAttachment: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const closeModal = () => {
    dispatch(
      ContractActions.setJustifyModalOpen({
        modalOpen: false,
      }),
    );
  };

  const callback = () => {
    dispatch(ContractActions.listContract(''));
  };

  const submit = (data) => {
    const formData = new FormData();
    const { justification, justificationAttachment } = data;

    if (!justificationAttachment) {
      setError('justificationAttachment', 'Campo requerido');

      return;
    }

    formData.append('id', rowJustify.id);
    formData.append('justification', justification);
    formData.append('file', justificationAttachment);

    dispatch(ContractActions.closeContract(formData, callback));

    closeModal();
  };

  const actions = () => (
    <>
      <Button
        data-testid="cancel-button-test"
        variant="secondary"
        name="CANCELAR"
        onClick={closeModal}
      />

      <Button
        data-testid="submit-button-test"
        variant="primary"
        name="CONFIRMAR"
        onClick={handleSubmit(submit)}
      />
    </>
  );

  return (
    <Modal
      className="modal"
      title={`Encerrar Contrato ${rowJustify.code}`}
      onClose={closeModal}
      actions={actions}
    >
      <form>
        <Controller
          name="justification"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ onChange, value }) => (
            <TextArea
              label={
                <span>
                  Justificativa <strong>(obrigatório)</strong>
                </span>
              }
              value={value}
              marginTop={10}
              marginBottom={10}
              width={368}
              heigth={126}
              maxlength={500}
              onChange={onChange}
              statusMessage={errors.justification?.message}
              status={errors.justification && 'error'}
            />
          )}
        />
        <S.InputWrapper>
          <Controller
            name="justificationAttachment"
            defaultValue=""
            rules={{ required: true }}
            control={control}
            render={({ onChange, value }) => (
              <InputFile
                type="file"
                value={value}
                name="justificationAttachment"
                accept=".jpg, .png, .eml, .msg, .html, .pdf"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
              />
            )}
          />
          {errors.justificationAttachment && (
            <S.ErrorWrapper>
              <Text color={Tokens.colors.red400}>Campo requerido</Text>
            </S.ErrorWrapper>
          )}
        </S.InputWrapper>
      </form>
    </Modal>
  );
};

CloseContractModal.defaultProps = {};

CloseContractModal.propTypes = {};

export default CloseContractModal;

import {
  FormalizationSteps,
  FormalizationStatus,
} from 'lib/contexts/formalizationRequest';
import { ROLES } from 'lib/contexts/permissions';

export const getEditButtonsDataWithRoles = (
  formalizationData,
  handleUndoChanges,
) => {
  switch (formalizationData?.formalization?.stepId) {
    case FormalizationSteps.Draft:
      return [
        {
          variant: 'secondary',
          name: 'desfazer',
          type: 'button',
          key: 'button-undo-save-as-draft',
          onClick: handleUndoChanges,
          role: ROLES.REQUEST_FORMALIZATION,
        },
        {
          variant: 'primary',
          name: 'Salvar',
          type: 'submit',
          key: 'button-save-as-draft',
          onClick: null,
          role: ROLES.REQUEST_FORMALIZATION,
        },
      ];

    case FormalizationSteps.ReturnedToPlaintiff:
      return [
        {
          variant: 'secondary',
          name: 'desfazer',
          type: 'button',
          key: 'button-undo-returned-to-plaintiff',
          onClick: handleUndoChanges,
          role: ROLES.REQUEST_FORMALIZATION,
        },
        {
          variant: 'primary',
          name: 'Salvar',
          type: 'submit',
          key: 'button-submit-save-returned-to-plaintiff',
          onClick: null,
          role: ROLES.REQUEST_FORMALIZATION,
        },
      ];

    default:
      return [];
  }
};

export const getViewButtonsDataWithRoles = (formalizationData, handlers) => {
  const {
    handleReturnToEdit,
    handleOpenFlowDataModal,
    handleReturnToPlaintiffFormalization,
    handleSendForFormalization,
    handleDraftToRequestedFormalization,
    handleOpenCancelModal,
    handleOpenDisapproveModal,
    handleApproveRequest,
    handleOpenNewDocModal,
  } = handlers;

  const underReviewActions = [
    {
      variant: 'tertiary',
      name: 'cancelar solicitação',
      type: 'button',
      key: 'button-cancel-in-review"',
      onClick: handleOpenCancelModal,
      role: ROLES.ATTEND_FORMALIZATION,
    },
    {
      variant: 'primary',
      name: 'Devolver para Demandante',
      type: 'button',
      key: 'button-return-to-plaintiff',
      onClick: handleReturnToPlaintiffFormalization,
      role: ROLES.ATTEND_FORMALIZATION,
    },
  ];

  if (formalizationData.formalization?.numberDocNetlex) {
    underReviewActions.push({
      variant: 'primary',
      name: 'Gerar novo documento',
      type: 'button',
      key: 'button-generate-new-document',
      onClick: handleOpenNewDocModal,
      role: ROLES.ATTEND_FORMALIZATION,
    });
  } else {
    underReviewActions.push({
      variant: 'primary',
      name: 'Formalizar',
      type: 'button',
      key: 'button-formalize',
      onClick: handleSendForFormalization,
      role: ROLES.ATTEND_FORMALIZATION,
    });
  }

  switch (formalizationData?.formalization?.stepId) {
    case FormalizationSteps.Draft:
      return [
        {
          variant: 'tertiary',
          name: 'cancelar solicitação',
          type: 'button',
          key: 'button-cancel-draft',
          onClick: handleOpenCancelModal,
          role: ROLES.REQUEST_FORMALIZATION,
        },
        {
          variant: 'secondary',
          name: 'Editar',
          type: 'button',
          key: 'button-edit-draft',
          onClick: handleReturnToEdit,
          role: ROLES.REQUEST_FORMALIZATION,
        },
        {
          variant: 'primary',
          name: 'solicitar revisão',
          type: 'button',
          key: 'button-request-review',
          onClick: handleDraftToRequestedFormalization,
          role: ROLES.REQUEST_FORMALIZATION,
        },
      ];

    case FormalizationSteps.LegalRequested:
      return [
        {
          variant: 'primary',
          name: 'Assumir atendimento',
          type: 'button',
          key: 'button-accept-service',
          onClick: () => handleOpenFlowDataModal('accept'),
          role: ROLES.ATTEND_FORMALIZATION,
        },
      ];

    case FormalizationSteps.PricingRequested:
      return [
        {
          variant: 'secondary',
          name: 'Devolver para demandante',
          type: 'button',
          key: 'button-pricing-return-to-plaintiff',
          onClick: handleOpenDisapproveModal,
          role: ROLES.PRICING_REVIEW,
        },
        {
          variant: 'primary',
          name: ' Solicitar revisão jurídica',
          type: 'button',
          key: 'button-pricing-request-legal-review',
          onClick: handleApproveRequest,
          role: ROLES.PRICING_REVIEW,
        },
      ];

    case FormalizationSteps.UnderReview:
      return underReviewActions;

    case FormalizationSteps.ReturnedToPlaintiff:
      return [
        {
          variant: 'tertiary',
          name: 'cancelar solicitação',
          type: 'button',
          key: 'button-cancel-returned-plaintiff',
          onClick: handleOpenCancelModal,
          role: ROLES.REQUEST_FORMALIZATION,
        },
        {
          variant: 'secondary',
          name: 'Editar',
          type: 'button',
          key: 'button-edit-return-to-plaintiff',
          onClick: handleReturnToEdit,
          role: ROLES.REQUEST_FORMALIZATION,
        },
        {
          variant: 'primary',
          name: 'solicitar revisão',
          type: 'button',
          key: 'button-request-review',
          onClick: handleDraftToRequestedFormalization,
          role: ROLES.REQUEST_FORMALIZATION,
        },
      ];

    default:
      return [];
  }
};

export const getNetlexButtonsDataWithRoles = (formalizationData, handlers) => {
  const { handleReturnToPlaintiffFormalization } = handlers;

  if (
    formalizationData?.formalization?.statusId ===
    FormalizationStatus.InProgress
  )
    return [
      {
        variant: 'primary',
        name: 'Devolver para Demandante',
        type: 'button',
        key: 'button-return-to-plaintiff',
        onClick: handleReturnToPlaintiffFormalization,
        role: ROLES.ATTEND_FORMALIZATION,
      },
    ];

  return [];
};

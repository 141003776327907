import styled from 'styled-components';
import { Flex, Card, Tokens, Button } from '@vli/locomotive-ds/dist';

export const CardContainer = styled(Card)`
  padding: ${Tokens.spacing.sm} ${Tokens.spacing.sm} ${Tokens.spacing.md};
  margin-bottom: ${Tokens.spacing.md};
`;

export const SearchButton = styled(Button)`
  height: ${Tokens.spacing.lg};
  margin-top: 18px;
`;

export const FlexContainer = styled(Flex)`
  align-items: center;
  > div {
    margin-right: ${Tokens.spacing.sm};
  }
`;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from 'constants/request';

export const { Types: ProfileTypes, Creators: ProfileActions } = createActions({
  profilesRequestSuccess: ['payload'],
  profilesRequestFailure: ['error'],
  createProfile: ['payload'],
  createProfileSuccess: ['payload'],
  updateProfile: ['payload'],
  updateProfileSuccess: ['payload'],
  fetchProfiles: null,
  setDeleteProfile: ['payload'],
  fetchPrivilegesSchema: null,
  privilegesSchemaSuccess: ['payload'],
  privilegesSchemaFailure: ['error'],
  getProfile: ['payload'],
  getProfileSuccess: ['payload'],
  getProfileFailure: ['error'],
  deleteProfile: ['payload', 'callback'],
  deleteProfileSuccess: [],
  profileRequestFailure: ['error', 'originalType'],
  setUpdateProfile: ['payload'],
});

const INITIAL_STATE = {
  data: [],
  profile: null,
  profileDelete: null,
  profileUpdateData: null,
  openDelete: false,
  privilegesSchema: [],
  error: null,
  profilesRequests: {
    [ProfileTypes.FETCH_PROFILES]: REQUEST_NOT_STARTED,
    [ProfileTypes.FETCH_PRIVILEGES_SCHEMA]: REQUEST_NOT_STARTED,
    [ProfileTypes.CREATE_PROFILE]: REQUEST_NOT_STARTED,
    [ProfileTypes.UPDATE_PROFILE]: REQUEST_NOT_STARTED,
  },
};

const fetchProfiles = (state) => ({
  ...state,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.FETCH_PROFILES]: REQUEST_PENDING,
  },
});

const profilesRequestSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  profile: null,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.FETCH_PROFILES]: REQUEST_RESOLVED,
  },
});

const profilesRequestFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.FETCH_PROFILES]: REQUEST_REJECTED,
  },
});

const fetchPrivilegesSchema = (state) => ({
  ...state,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.FETCH_PRIVILEGES_SCHEMA]: REQUEST_PENDING,
  },
});

const privilegesSchemaSuccess = (state, { payload }) => ({
  ...state,
  privilegesSchema: payload,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.FETCH_PRIVILEGES_SCHEMA]: REQUEST_RESOLVED,
  },
});

const privilegesSchemaFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.FETCH_PRIVILEGES_SCHEMA]: REQUEST_REJECTED,
  },
});

const createProfile = (state) => ({
  ...state,
  message: null,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.CREATE_PROFILE]: REQUEST_PENDING,
  },
});

const createProfileSuccess = (state) => ({
  ...state,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.CREATE_PROFILE]: REQUEST_RESOLVED,
  },
});

const updateProfile = (state) => ({
  ...state,
  message: null,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.UPDATE_PROFILE]: REQUEST_PENDING,
  },
});

const updateProfileSuccess = (state) => ({
  ...state,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.UPDATE_PROFILE]: REQUEST_RESOLVED,
  },
});

const getProfile = (state) => ({
  ...state,
  profile: null,
  message: null,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.GET_PROFILE]: REQUEST_PENDING,
  },
});

const getProfileSuccess = (state, { payload }) => ({
  ...state,
  profile: payload,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.GET_PROFILE]: REQUEST_RESOLVED,
  },
});

const getProfileFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.GET_PROFILE]: REQUEST_REJECTED,
  },
});

const setDeleteProfile = (state, { payload }) => ({
  ...state,
  profileDelete: payload.profile,
  openDelete: payload.modalOpen,
});

const deleteProfile = (state) => ({
  ...state,
  message: null,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.DELETE_PROFILE]: REQUEST_PENDING,
  },
});

const deleteProfileSuccess = (state) => ({
  ...state,
  profilesRequests: {
    ...state.profilesRequests,
    [ProfileTypes.DELETE_PROFILE]: REQUEST_RESOLVED,
  },
});

const profileRequestFailure = (
  state,
  { error: { response }, originalType },
) => ({
  ...state,
  error: response,
  profilesRequests: {
    ...state.profilesRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const setUpdateProfile = (state, { payload }) => ({
  ...state,
  profileUpdateData: payload,
});

export default createReducer(INITIAL_STATE, {
  [ProfileTypes.PROFILES_REQUEST_SUCCESS]: profilesRequestSuccess,
  [ProfileTypes.PROFILES_REQUEST_FAILURE]: profilesRequestFailure,
  [ProfileTypes.CREATE_PROFILE]: createProfile,
  [ProfileTypes.CREATE_PROFILE_SUCCESS]: createProfileSuccess,
  [ProfileTypes.UPDATE_PROFILE]: updateProfile,
  [ProfileTypes.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [ProfileTypes.FETCH_PROFILES]: fetchProfiles,
  [ProfileTypes.FETCH_PRIVILEGES_SCHEMA]: fetchPrivilegesSchema,
  [ProfileTypes.PRIVILEGES_SCHEMA_SUCCESS]: privilegesSchemaSuccess,
  [ProfileTypes.PRIVILEGES_SCHEMA_FAILURE]: privilegesSchemaFailure,
  [ProfileTypes.GET_PROFILE]: getProfile,
  [ProfileTypes.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [ProfileTypes.GET_PROFILE_FAILURE]: getProfileFailure,
  [ProfileTypes.SET_DELETE_PROFILE]: setDeleteProfile,
  [ProfileTypes.DELETE_PROFILE]: deleteProfile,
  [ProfileTypes.DELETE_PROFILE_SUCCESS]: deleteProfileSuccess,
  [ProfileTypes.PROFILE_REQUEST_FAILURE]: profileRequestFailure,
  [ProfileTypes.SET_UPDATE_PROFILE]: setUpdateProfile,
});

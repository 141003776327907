import { Text } from '@vli/locomotive-ds/dist';
import Tooltip from 'components/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const CellStatus = ({
  cell: { value },
  row,
  informative,
  transformedData,
  onClick,
  color,
}) => {
  const { original } = row;
  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };
  return (
    <Tooltip title={original.statusTooltip} place="top">
      <Text
        size="medium"
        color={color}
        onClick={handleOnClick}
        data-testid={`cell-table-${value}`}
        style={{
          cursor: 'pointer',
          width: '100%',
          textAlign: 'left',
          fontWeight: '400',
        }}
      >
        {value !== undefined && (transformedData(value) || '-')} {informative}
      </Text>
    </Tooltip>
  );
};

CellStatus.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }),
  color: PropTypes.string,
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      statusTooltip: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellStatus.defaultProps = {
  cell: {
    value: '',
  },
  color: '#000',
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellStatus;

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';
import bgImage from 'assets/images/bg-login.jpg';

export const LoginBackground = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background 0.4s linear;
  -webkit-transition: background 0.4s linear;
`;
export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 40%;
  text-align: center;
  background: #336eae;
  box-shadow: 0 0 20px 0 rgba(0, 55, 120, 0.29);
`;
export const LoginLogo = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;

  svg {
    width: 40%;
    height: auto;
    margin-left: 10px;
  }
`;

export const LoginMessage = styled.div`
  margin-bottom: 2rem;
  color: ${Tokens.colors.grayWhite};
  font-size: 16px;
  font-weight: 300;
`;
export const LoginButtons = styled.div`
  margin-bottom: 2rem;
  display: flex;
  button {
    font-size: 16px;
    margin: 10px 50px;
    padding: 0px 60px;
    color: #ffffff;
    background-color: #f37a19;
  }
`;

import styled from 'styled-components';
import { Timeline, ScrollBar } from '@vli/locomotive-ds';

export const TimelineContainer = styled(Timeline)`
  .vertical-line {
    display: none;
  }
`;

export const ScrollBarContainer = styled(ScrollBar)`
  padding-bottom: 30px;
`;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CONSTANTS from 'constants/constants';
import { AccordionContainer } from './DieselForm.styles';
import DieselOther from './DieselOther';
import DieselFormSupplierPetrobras from './DieselFormSupplierPetrobras';

const DieselSupplierPetrobras = () => {
  const { suppliersList } = useSelector(({ diesel }) => diesel);

  const petrobrasOthersData = useMemo(() => {
    return [
      {
        ...suppliersList[0],
        types: suppliersList[0].types.filter(
          (x) => x.id !== CONSTANTS.PETROBRASS500_ID,
        ),
      },
    ];
  }, [suppliersList]);

  const petrobrasS500Data = useMemo(() => {
    return {
      ...suppliersList[0],
      types: suppliersList[0].types.filter(
        (x) => x.id === CONSTANTS.PETROBRASS500_ID,
      ),
    };
  }, [suppliersList]);

  return (
    <>
      <AccordionContainer
        title={petrobrasOthersData[0].types[0].name}
        data-testid="diesel-other-accordion"
      >
        <DieselOther suppliersList={petrobrasOthersData} />
      </AccordionContainer>
      <AccordionContainer
        title={petrobrasS500Data.types[0].name}
        data-testid="diesel-s500-accordion"
      >
        <DieselFormSupplierPetrobras supplier={petrobrasS500Data} />
      </AccordionContainer>
    </>
  );
};

export default DieselSupplierPetrobras;

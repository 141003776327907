import React, { useMemo, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

import { Button, InfoIcon, Input, Text, Tokens } from '@vli/locomotive-ds/dist';

import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';

import handleInputMask from 'lib/core/handleInputMask';
import { simpleId } from 'lib/core/utility';
import { negociationFormData } from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';

import Modal from 'components/core/modal';
import TextArea from 'components/form/TextArea';
import InputFile from 'components/form/InputFile/InputFile';
import InputSelect from 'components/form/InputSelect';

import {
  Field,
  Title,
  Flex,
  Label,
  ContainerInput,
  ContainerGrid,
  MessageErrors,
  WarningNotice,
  ErrorNotice,
} from './Negociation.styles';

const NEGOTIATION_TYPES = {
  COMERCIAL_NEGOCIATION: '1',
  WEIGHTED_FARE: '2',
  INCENTIVIZED_RATE: '3',
};

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  '.xlsx',
  '.xls',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const validationSchema = Yup.object().shape({
  negociationType: Yup.string(),
  justification: Yup.string()
    .min(15, 'A justificativa deve ter minímo de 15 caracteres')
    .max(240, 'A justificativa deve ter maxima de 240 caracteres')
    .required('A justificativa é um campo requerido'),
  file: Yup.mixed()
    .required('O anexo é um campo requerido')
    .test(
      'size',
      'Tamanho não permitido',
      (value) => !value || (value && value.size <= 2048 * 1024),
    )
    .test(
      'format',
      'Formato não permitido',
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type)),
    ),

  historys: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      newCalculatedValue: Yup.string('Por favor digite apenas número')
        .test('onlyNumbers', 'Por favor digite um número válido', (value) =>
          /^(\d{1,3}(\.\d{3})*|\d+)(,\d{2})?$/.test(value.toString()),
        )
        .required('Novo preço requerido'),
    }),
  ),
});

const Negotiation = ({ id }) => {
  const form = useRef(null);

  const dispatch = useDispatch();

  const [keyFile, setKeyFile] = useState(simpleId());

  const { handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    contractDetails,
    priceActions: { item, items: priceActionsItems },
  } = useSelector(({ contractDetail }) => contractDetail);

  const getRailwayItems = useCallback(() => {
    switch (item) {
      case 'grouped':
        return priceActionsItems.childrens;
      case 'grouped-all':
        return priceActionsItems.map((priceItem) => priceItem.childrens).flat();
      default:
        return priceActionsItems;
    }
  }, [item, priceActionsItems]);

  const items = useMemo(() => {
    const railwayItems = getRailwayItems();

    return item === 'all' || item === 'grouped' || item === 'grouped-all'
      ? utility.actionsAttributesAll(railwayItems)
      : utility.actionsAttributes([item]);
  }, [item, getRailwayItems]);

  const canIFix = useMemo(() => {
    const railwayItems = getRailwayItems();

    if (item === 'all' || item === 'grouped' || item === 'grouped-all') {
      const foundPrices = railwayItems.filter(
        (price) =>
          price?.history?.status === utility.enumStatus.Approved &&
          price?.history?.canFixPrice,
      );

      return !!foundPrices.length;
    }

    return (
      item?.history?.status === utility.enumStatus.Approved &&
      item?.history?.canFixPrice
    );
  }, [item, getRailwayItems]);

  const handleCloseModal = () => {
    dispatch(
      ContractDetailActions.setPriceAction({
        action: null,
        modal: false,
        id: null,
        item: {},
      }),
    );
  };

  const callback = () => {
    const viewScenario = contractDetails.filter.viewScenario || 'CurrentPrice';

    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario,
      }),
    );
  };

  const onSubmit = (fields) => {
    setValue('file', null);
    setKeyFile(simpleId());

    const data = negociationFormData(fields);

    if (canIFix) {
      const formData = new FormData();

      if (fields.file) {
        formData.append('File', fields.file);
      }

      formData.append('Justification', fields.justification);

      fields.historys.forEach((history, index) => {
        formData.append(
          `HistoryCorrectionPriceViewModels[${index}].Id`,
          history.id,
        );
        formData.append(
          `HistoryCorrectionPriceViewModels[${index}].CorrectedPrice`,
          history.newCalculatedValue.replaceAll('.', ''),
        );
      });

      dispatch(ContractDetailActions.correctionPrice(formData, callback));
    } else {
      dispatch(ContractDetailActions.renegotiatePrice(data, callback));
    }

    handleCloseModal();
  };

  const actions = () => (
    <>
      <Button
        variant="secondary"
        name="CANCELAR"
        onClick={() => {
          handleCloseModal();
        }}
      />

      <Button
        variant="primary"
        name="CONFIRMAR"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );

  const negotiationTypeOptions = [
    {
      label: 'Negociação comercial',
      value: NEGOTIATION_TYPES.COMERCIAL_NEGOCIATION,
    },
    {
      label: 'Tarifa ponderada',
      value: NEGOTIATION_TYPES.WEIGHTED_FARE,
    },
    {
      label: 'Tarifa incentivada',
      value: NEGOTIATION_TYPES.INCENTIVIZED_RATE,
    },
  ];

  return (
    <Modal
      className="modal"
      title="Reajuste de preço"
      onClose={handleCloseModal}
      actions={actions}
    >
      <form ref={form}>
        {canIFix && (
          <WarningNotice>
            <InfoIcon />
            <span>
              {typeof item !== 'string'
                ? `Atenção! O preço ${utility.formatMoney(
                    item?.calculatedValue,
                  )} já
              está aprovado.`
                : 'Atenção! Alguns preços já foram aprovados.'}
            </span>
          </WarningNotice>
        )}

        <div>
          Tem certeza que deseja informar novas tarifas acordadas? Estas
          informação serão disponibilizadas para as alçadas competentes.
          {items.length > 1 && (
            <p>Para ações em massa não é possível desfazer a ação.</p>
          )}
        </div>

        {!canIFix && (
          <div>
            <Field>
              <Label>Tipo de negociação</Label>
            </Field>

            <Controller
              name="negociationType"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <InputSelect
                  name="negociationType"
                  options={negotiationTypeOptions}
                  value={value}
                  width={445}
                  onChange={onChange}
                  statusMessage={errors.negociationType?.message}
                  status={errors.negociationType && 'error'}
                />
              )}
            />
          </div>
        )}

        <Controller
          name="justification"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ onChange, value }) => (
            <TextArea
              label="Justificativa"
              value={value}
              maxlength={250}
              marginTop={20}
              onChange={onChange}
              statusMessage={errors.justification?.message}
              status={errors.justification && 'error'}
            />
          )}
        />

        <div>
          <Field>
            <Label>Anexar</Label>
          </Field>

          <Controller
            key={keyFile}
            name="file"
            rules={{ required: true }}
            defaultValue={null}
            control={control}
            render={({ onChange, value }) => (
              <InputFile
                key={keyFile}
                type="file"
                width="230px"
                value={value}
                name="file"
                accept=".jpg, .png, .jpeg, .pdf, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
              />
            )}
          />

          {errors.file && (
            <MessageErrors>
              <Text color={Tokens.colors.red400}>{errors.file.message}</Text>
            </MessageErrors>
          )}
        </div>

        <ContainerInput>
          <ContainerGrid>
            {items.map((i, index) => (
              <div key={i.railwayItemId}>
                <Field>
                  <Title>Item {i.railwayItemCode}</Title>
                </Field>

                <Flex>
                  <div>
                    <Field>
                      <Controller
                        name={`historys.${index}.id`}
                        defaultValue={i.history?.id}
                        rules={{ required: true }}
                        control={control}
                        render={() => null}
                      />

                      <Controller
                        name={`historys.${index}.newCalculatedValue`}
                        defaultValue=""
                        rules={{ required: true }}
                        control={control}
                        render={({ onChange, value, name }) => (
                          <Input
                            name={name}
                            label="Nova tarifa"
                            required
                            placeholder="$"
                            value={value}
                            width="92px"
                            onInput={(event) =>
                              handleInputMask(event, 'currency')
                            }
                            maxlength={250}
                            marginTop={10}
                            onChange={onChange}
                            statusMessage={
                              errors.historys &&
                              errors.historys[index] &&
                              errors.historys[index].newCalculatedValue?.message
                            }
                            status={
                              errors.historys &&
                              errors.historys[index] &&
                              'error'
                            }
                          />
                        )}
                      />
                    </Field>
                  </div>
                </Flex>
              </div>
            ))}
          </ContainerGrid>
        </ContainerInput>

        {canIFix && (
          <ErrorNotice>
            <InfoIcon />
            <span>Você não poderá alterar esse valor novamente.</span>
          </ErrorNotice>
        )}
      </form>
    </Modal>
  );
};

Negotiation.defaultProps = {
  id: '',
};

Negotiation.propTypes = {
  id: PropTypes.string,
};

export default Negotiation;

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Header,
  Flex,
  Container,
  Button,
  Card,
  EmptyState,
} from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';
import Table from 'components/core/Table/Table';
import Loading from 'components/core/Loading';
import Columns from 'components/contexts/user/userList/TableUsers/Columns/Columns';
import FilterUsers from 'components/contexts/user/userList/FilterUsers';
import ModalConfirm from 'components/core/ModalConfirm';

import { ProfileActions } from 'store/profile/profile.ducks';
import { UserActions } from 'store/user/user.ducks';

import { isPending, isFinished } from 'lib/core/request';
import { getFilterParams } from 'lib/contexts/user';

import { history } from 'constants/routes';

import useDimensions from 'hooks/useDimensions';
import usePagination from 'hooks/usePagination';

import * as ROUTES from 'constants/urls';

import * as S from './UserList.styles';

const breadcrumb = [
  {
    text: 'Usuários',
    href: `#${ROUTES.PROFILE_LIST}`,
  },
];

const UserList = () => {
  const dimensions = useDimensions();
  const { paginationSize, setValuePage } = usePagination();

  const dispatch = useDispatch();
  const {
    data: users,
    filter,
    userDelete,
    openDelete,
    usersRequests,
  } = useSelector(({ user }) => user);

  const getUsersList = useCallback(
    (filterParams, prevFilter = {}) => {
      const data = getFilterParams({ ...prevFilter, ...filterParams });

      dispatch(UserActions.fetchUsers(data, prevFilter));
    },
    [dispatch],
  );

  const onPageChange = (PageIndex) => {
    const { pageSize } = users;

    getUsersList({ pageIndex: PageIndex, pageSize }, filter);
  };

  const onPerPageChange = (PageSize) => {
    const { pageIndex } = users;

    getUsersList({ pageIndex, pageSize: PageSize }, filter);
    setValuePage(PageSize);
  };

  const closeModal = () => {
    dispatch(UserActions.setDeleteUser({ user: null, modalOpen: false }));
  };

  const callback = () => {
    const { currentPage, pageSize } = users;

    getUsersList({ pageIndex: currentPage, pageSize, ...filter });
  };

  const confirmDelete = () => {
    dispatch(UserActions.deleteUser(userDelete.id, callback));
    closeModal();
  };

  useEffect(() => {
    getUsersList({ pageIndex: 1, pageSize: 25 });

    dispatch(ProfileActions.fetchProfiles());
  }, [dispatch, getUsersList]);

  const loading =
    isPending(usersRequests.FETCH_USERS) ||
    isPending(usersRequests.DELETE_USER);

  return (
    <Content data-testid="content" height={dimensions.height}>
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Usuário" />

          <Flex justifyContent="space-between" alignItems="center">
            <FilterUsers />
            <Button
              onClick={() => history.push(ROUTES.NEW_USER)}
              name="Novo usuário"
              type="button"
            />
          </Flex>
        </Flex>
        <S.TableContainer>
          {loading && <Loading />}
          {users?.items?.length > 0 && !loading ? (
            <Table
              width="100%"
              sortBy={[]}
              customList={paginationSize}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              pageIndex={users.currentPage}
              pageCount={users.totalPages}
              totalItems={users.totalCount}
              data={users.items}
              columns={Columns()}
              footer
            />
          ) : (
            isFinished(usersRequests.FETCH_USERS) &&
            !loading && (
              <Card>
                <EmptyState
                  condensed={false}
                  title="Nenhum usuário encontrado."
                />
              </Card>
            )
          )}
        </S.TableContainer>

        {openDelete && (
          <ModalConfirm
            closeModal={closeModal}
            confirmAction={confirmDelete}
            title="Inativar/Reativar usuário"
            message={`Tem certeza que deseja Inativar/Reativar o usuário <b>${userDelete.userName}</b>?`}
          />
        )}
      </Container>
    </Content>
  );
};

export default UserList;

import styled from 'styled-components';

export const LoadingContainer = styled.div`
  width: 100%;
  height: 80vh;

  display: flex;
  align-items: center;

  & > div {
    background-color: transparent;
    position: static;
    z-index: 1;
  }
`;

import React from 'react';

import { formatVolume } from 'lib/core/formatting';
import { displayLocation } from 'lib/contexts/formalizationRequest';

import InfoItem from '../InfoItem';

import * as S from '../PriceAndVolumeModalStyles';

const VolumeBox = ({ item }) => {
  return (
    <S.Box>
      {item.state && item.county ? (
        <small>
          {item.state}
          {displayLocation(item.county)}
        </small>
      ) : (
        <small>
          {item.state}
          {displayLocation(item.mesoregion)}
          {displayLocation(item.microregion)}
        </small>
      )}

      <InfoItem title="Volume" content={formatVolume(item.volumeValue)} />
    </S.Box>
  );
};

export default VolumeBox;

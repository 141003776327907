import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { FormulasActions } from 'store/formulas/formulas.ducks';

import * as S from './SectionsStyled';

const EXTRA_FORMULA_INITIAL_STATE = {
  isVisible: false,
  formula: null,
};

const DieselReadjustment = ({ disableFields, isFieldRequired }) => {
  const dispatch = useDispatch();
  const { control, watch, setValue, errors } = useFormContext();

  const { approvedFormulasItems } = useSelector(({ formulas }) => formulas);
  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [extraFormula, setExtraFormula] = useState(EXTRA_FORMULA_INITIAL_STATE);
  const [showPricingMessage, setShowPricingMessage] = useState(false);

  const dieselReadjustmentValue = watch('formulas.dieselFormulaRailway');

  useEffect(() => {
    setShowPricingMessage(false);
    setExtraFormula(EXTRA_FORMULA_INITIAL_STATE);
  }, []);

  useEffect(() => {
    const dataFormulas = formalizationData?.formalization?.formulas;

    if (dataFormulas) {
      setValue(
        'formulas.dieselFormulaRailway',
        dataFormulas.dieselFormulaRailway,
      );
    }
  }, [formalizationData, setValue]);

  useEffect(() => {
    if (showPricingMessage) {
      dispatch(FormulasActions.formulasItemsRequest());
    }
  }, [dispatch, showPricingMessage]);

  const dieselFormulas = approvedFormulasItems?.filter(
    (formula) => formula.formulaTypeId === 1,
  );

  return (
    <S.FormColumn data-testid="form-section-readjustment-diesel-test">
      <S.Table>
        <S.TableHead>
          <S.TableCell width={5} />
          <S.TableCell width={20}>Fórmula</S.TableCell>
          <S.TableCell width={75}>Racional</S.TableCell>
        </S.TableHead>

        <S.TableBody>
          {dieselFormulas?.map((formula) => (
            <S.TableItem
              key={formula.id}
              htmlFor={formula.id}
              isDisabled={disableFields}
              isSelected={+dieselReadjustmentValue === formula.id}
            >
              <div>
                <Controller
                  name="formulas.dieselFormulaRailway"
                  control={control}
                  rules={{ required: isFieldRequired }}
                  render={({ ...field }) => (
                    <input
                      {...field}
                      type="radio"
                      id={formula.id}
                      value={formula.id}
                      disabled={disableFields}
                      checked={+dieselReadjustmentValue === formula.id}
                    />
                  )}
                />
              </div>
              <span>{formula.name}</span>
              <span>{formula.translatedFormula}</span>
            </S.TableItem>
          ))}
        </S.TableBody>
      </S.Table>

      {extraFormula.isVisible && extraFormula.formula && (
        <S.Table>
          <S.TableBody>
            <S.TableItem
              key={extraFormula.formula.id}
              htmlFor={extraFormula.formula.id}
              isDisabled={disableFields}
              isSelected={+dieselReadjustmentValue === extraFormula.formula.id}
            >
              <div>
                <Controller
                  name="formulas.dieselFormulaRailway"
                  control={control}
                  rules={{ required: isFieldRequired }}
                  render={({ ...field }) => (
                    <input
                      {...field}
                      type="radio"
                      id={extraFormula.formula.id}
                      value={extraFormula.formula.id}
                      disabled={disableFields}
                      checked={
                        +dieselReadjustmentValue === extraFormula.formula.id
                      }
                    />
                  )}
                />
              </div>
              <span>{extraFormula.formula.name}</span>
              <span>{extraFormula.formula.translatedFormula}</span>
            </S.TableItem>
          </S.TableBody>
        </S.Table>
      )}

      {errors?.formulas?.dieselFormulaRailway && (
        <S.ErrorMessage>
          {errors.formulas.dieselFormulaRailway.message}
        </S.ErrorMessage>
      )}
    </S.FormColumn>
  );
};

DieselReadjustment.propTypes = {
  disableFields: PropTypes.bool.isRequired,
  sectionErrors: PropTypes.objectOf(PropTypes.any),
  formulas: PropTypes.array.isRequired,
  isFieldRequired: PropTypes.bool.isRequired,
};

DieselReadjustment.defaultProps = {
  sectionErrors: null,
};

export default DieselReadjustment;

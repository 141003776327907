import React from 'react';
import { Tabs } from '@vli/locomotive-ds/dist';
import RefineryList from './refineryList/Table';
import StationList from './stationList/Table';
import { AccordionContainer } from './DieselForm.styles';

const DieselAccordionBaseList = () => {
  return (
    <AccordionContainer title="Vínculos atuais">
      <Tabs position="start">
        <li label="Posto" style={{ marginRight: '16px' }}>
          <StationList />
        </li>

        <li label="Refinaria">
          <RefineryList />
        </li>
      </Tabs>
    </AccordionContainer>
  );
};

export default DieselAccordionBaseList;

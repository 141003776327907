import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const TableContainer = styled.table`
  background: ${Tokens.colors.grayWhite};
  thead {
    height: 32px;
    th {
      border-bottom: 0.5px solid #d1d1d1;
      color: ${Tokens.colors.gray400};
    }
  }

  tbody {
    td {
      padding-top: 3px;
    }

    td:not(:first-child) {
      padding-left: 4px;
    }
    td:not(:last-child) {
      padding-right: 4px;
    }
  }
`;

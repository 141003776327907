import React from 'react';

import { formatMoney } from 'lib/core/utility';
import { displayLocation } from 'lib/contexts/formalizationRequest';

import InfoItem from '../InfoItem';

import * as S from '../PriceAndVolumeModalStyles';

const PriceBox = ({ item }) => {
  const decideRegionsToDisplay = () => {
    if (item?.allMicroregions) {
      if (item?.state) {
        return `${item.state} - Todas as Praças`;
      }
      return 'Todas as Praças';
    }
    if (item?.state && item?.county) {
      return `${item.state}${displayLocation(item.county)}`;
    }
    return `${item?.state ?? ''}${displayLocation(
      item.mesoregion,
    )}${displayLocation(item.microregion)}`;
  };
  return (
    <S.Box>
      <small>{decideRegionsToDisplay()}</small>

      <InfoItem title="Tarifa cliente" content={formatMoney(item.priceValue)} />
    </S.Box>
  );
};

export default PriceBox;

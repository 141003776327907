import styled from 'styled-components';
import { Text, Tokens } from '@vli/locomotive-ds';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled(FlexColumn)`
  gap: ${Tokens.spacing.lg};
`;

export const FormLine = styled.div`
  display: flex;
  gap: ${Tokens.spacing.lg};
`;

export const FormSpanItem = styled.span`
  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${Tokens.colors.gray500};
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

// Sale

export const Sales = styled(FlexColumn)`
  gap: ${Tokens.spacing.md};

  & > h3 {
    font: ${Tokens.fonts.headingRegularH6};
    font-weight: 500;
    color: ${Tokens.colors.gray500};
  }
`;

export const SalesList = styled(FlexColumn)`
  gap: ${Tokens.spacing.sm};
`;

export const SaleItem = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${Tokens.spacing.sm};

  & + div {
    padding-top: ${Tokens.spacing.lg};
    border-top: 1px solid ${Tokens.colors.gray200};
  }
`;

export const SaleItemColumn = styled(FlexColumn)`
  gap: ${Tokens.spacing.sm};
`;

export const SaleTitleColumn = styled.div`
  gap: ${Tokens.spacing.xss};
`;

export const SaleItemColumnInput = styled(FlexColumn)`
  & > span {
    color: ${Tokens.colors.red300};
  }
`;

export const SaleItemValue = styled(Text)`
  font: ${Tokens.fonts.textRegularMedium};
  font-weight: 400;
  color: ${Tokens.colors.gray500};
`;

export const SaleContainer = styled(FlexColumn)`
  gap: ${Tokens.spacing.xs};
`;

// Price

export const Prices = styled(FlexColumn)`
  gap: ${Tokens.spacing.sm};

  border: 1px solid ${Tokens.colors.gray200};
  border-radius: 4px;
  padding: ${Tokens.spacing.sm};

  & > h4 {
    font: ${Tokens.fonts.headingBoldH5};
    font-weight: 500;
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const PriceGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${Tokens.spacing.sm};
`;

export const PriceItem = styled.div`
  display: flex;
  gap: ${Tokens.spacing.md};
`;

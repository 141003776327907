import { put, takeLatest } from 'redux-saga/effects';
import {
  TEMPLATES,
  TEMPLATES_UPLOAD,
  TEMPLATES_DOWNLOAD,
} from 'constants/endpoints';
import * as utility from 'lib/core/utility';
import api from 'services/api';
import { GET, POST } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import { ModelsActions, ModelsTypes } from './model.ducks';

export function* getTemplates() {
  try {
    const response = yield api({
      method: GET,
      url: TEMPLATES,
    });
    yield put(ModelsActions.templatesSuccess(response.data));
  } catch (error) {
    yield put(ModelsActions.templatesFailure(error));
  }
}

export function* uploadTemplate({ payload, callback }) {
  try {
    const response = yield api({
      method: POST,
      url: TEMPLATES_UPLOAD,
      data: payload,
    });
    yield put(
      MessagesActions.open({
        title: 'Planilha carregada',
        type: 'success',
        contentText: 'Upload realizado com sucesso!',
      }),
    );
    yield put(ModelsActions.templateUploadSuccess(response.data));
    callback();
  } catch (error) {
    yield put(
      MessagesActions.open({
        title: 'Arquivo não suportado',
        type: 'error',
        contentText:
          'O arquivo selecionado não é compatível, faça novamente o envio da planilha. Formatos suportados: .XLSX ou .XLS.',
      }),
    );
    yield put(ModelsActions.templateUploadFailure(error));
  }
}

export function* downloadTemplate({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: `${TEMPLATES_DOWNLOAD}/${payload.templateType}`,
      responseType: 'arraybuffer',
    });
    yield put(
      MessagesActions.open({
        title: 'Planilha baixada',
        type: 'success',
        contentText: 'Download realizado com sucesso!',
      }),
    );
    yield put(ModelsActions.templateDownloadSuccess(response.data));
    utility.downloadFile(response.data, `${payload.fileName}.xlsx`);
  } catch (error) {
    yield put(
      MessagesActions.open({
        title: 'Planilha baixada',
        type: 'error',
        contentText: 'Erro ao fazer download da planilha',
      }),
    );
    yield put(ModelsActions.templateDownloadFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(ModelsTypes.TEMPLATES_REQUEST, getTemplates);
  yield takeLatest(ModelsTypes.TEMPLATE_UPLOAD_REQUEST, uploadTemplate);
  yield takeLatest(ModelsTypes.TEMPLATE_DOWNLOAD_REQUEST, downloadTemplate);
}

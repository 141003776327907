import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, Card } from '@vli/locomotive-ds/dist';

import Table from 'components/core/Table/Table';
import Loading from 'components/core/Loading';
import TableContainer from 'components/core/TableContainer';

import { isPending, isFinished } from 'lib/core/request';
import { getLocalsParams } from 'lib/contexts/local';

import { LocalActions } from 'store/local/local.ducks';

import CONSTANTS from 'constants/constants';

import { Columns } from '../../Columns/Columns';

const RefineryList = () => {
  const dispatch = useDispatch();
  const { data: station, editMode, localRequests } = useSelector(
    ({ local }) => local,
  );

  useEffect(() => {
    const params = getLocalsParams(1, 20, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
  }, [dispatch]);

  const onPageChange = (PageIndex) => {
    const { pageSize } = station;
    const params = getLocalsParams(PageIndex, pageSize, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
  };

  const onPerPageChange = (PageSize) => {
    const { pageIndex } = station;
    const params = getLocalsParams(pageIndex, PageSize, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
  };

  const loading = isPending(localRequests.LIST_LOCAL);

  return (
    <TableContainer data-testid="table-test">
      {loading && <Loading />}
      {station?.items?.length > 0 ? (
        <Table
          width="100%"
          tableType="responsive"
          sortBy={['name']}
          isEditMode={editMode}
          data={station.items}
          columns={Columns()}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          pageIndex={station.currentPage}
          pageCount={station.totalPages}
          totalItems={station.totalCount}
          footer
        />
      ) : (
        isFinished(localRequests.LIST_LOCAL) && (
          <Card>
            <EmptyState
              condensed={false}
              title="Nenhuma refinaria encontrada."
            />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default RefineryList;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { FormalizationSteps } from 'lib/contexts/formalizationRequest';

import Accordion from 'components/core/Accordion';

import PriceParametrics from './Sections/PriceParametrics';
import Indices from './Sections/Indices';
import DieselReadjustment from './Sections/DieselReadjustment';
import InflationReadjustment from './Sections/InflationReadjustment';

import * as S from './FormalizationFormStyles';

const FormalizationForm = ({ resetForm, formErrors }) => {
  const { setValue, errors } = useFormContext();
  const { action } = useParams();

  const { formalizationData } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [showDiesel, setShowDiesel] = useState(true);
  const [showInflationSections, setShowInflationSections] = useState(false);
  const [showHarborSection, setShowHarborSection] = useState(false);
  const [isOnlyHarbor, setIsOnlyHarbor] = useState(false);
  const [hasModal, setHasModal] = useState(false);
  const [isLessThanOnMonth, setIsLessThanOneMonth] = useState(false);

  const setDateValue = (date) => {
    if (date) {
      return new Date(date);
    }

    return null;
  };

  const setInitialFormValues = useCallback(
    (data) => {
      const valuesToUpdate = {
        'parametrics.startDate': setDateValue(data.startDate),
        'parametrics.endDate': setDateValue(data.endDate),
        'parametrics.typeFormalization':
          data.instrumentType || (hasModal ? 0 : 2),
        'parametrics.instrumentReference': data.instrumentReference || '',
        'parametrics.loadingDate': setDateValue(data.loadingDate),
        'parametrics.baseDate': setDateValue(data.priceParametric.database),
        'parametrics.applicationDateDay':
          data.priceParametric.applicationDateDay || 0,
        'parametrics.firstReadjustment': data.priceParametric.firstReadjustment
          ? format(
              new Date(data.priceParametric.firstReadjustment),
              'yyyy-MM-dd',
            )
          : '',
        'indices.diesel': data.indexes.diesel || 30,
        'indices.railwayIGPM': data.indexes.railwayIGPM,
        'indices.railwayIPCA': data.indexes.railwayIPCA,
        'indices.harborIGPM': data.indexes.harborIGPM,
        'indices.harborIPCA': data.indexes.harborIPCA,
        'formulas.dieselFormulaRailway': data.formulas.dieselFormulaRailway,
        'formulas.inflationFormulaRailway':
          data.formulas.inflationFormulaRailway,
        'formulas.inflationFormulaHarbor': data.formulas.inflationFormulaHarbor,
      };

      Object.entries(valuesToUpdate).forEach(([field, value]) => {
        setValue(field, value);
      });
    },
    [setValue, hasModal],
  );

  useEffect(() => {
    if (formalizationData.formalization) {
      setInitialFormValues(formalizationData.formalization);
    }
  }, [formalizationData, setInitialFormValues]);

  useEffect(() => {
    if (resetForm && formalizationData.formalization) {
      setInitialFormValues(formalizationData.formalization);
    }
  }, [resetForm, formalizationData, setInitialFormValues]);

  useEffect(() => {
    const modals = formalizationData?.formalization?.modals;
    const harborId = 2;

    if (modals?.length === 1 && modals?.[0] === harborId) {
      setIsOnlyHarbor(true);
    }

    if (modals?.length < 1) {
      setHasModal(false);
    } else {
      setHasModal(true);
    }

    setShowDiesel(modals?.includes(1));
    setShowHarborSection(modals?.includes(2));
  }, [formalizationData]);

  const checkIfHasError = (condition) => (condition ? 'error' : 'default');

  const hasParametricsError = checkIfHasError(errors?.parametrics);
  const hasIndicesError = checkIfHasError(
    formErrors?.indices || errors?.indices,
  );
  const hasFormulasDieselError = checkIfHasError(
    errors?.formulas?.dieselFormulaRailway,
  );
  const hasFormulasInflationRailwayError = checkIfHasError(
    errors?.formulas?.inflationFormulaRailway,
  );
  const hasFormulasInflationHarborError = checkIfHasError(
    errors?.formulas?.inflationFormulaHarbor,
  );

  const harborTitleNumber =
    showDiesel &&
    showHarborSection &&
    showInflationSections &&
    !isLessThanOnMonth
      ? '3.3'
      : '3.1';

  const { stepId } = formalizationData?.formalization ?? {
    stepId: 1,
  };

  const isFieldRequired = stepId !== FormalizationSteps.Draft;

  const checksWhetherToDisplayInflationSections = (isOneYear) => {
    if (isOneYear) {
      setShowInflationSections(true);
    } else {
      setShowInflationSections(false);
    }

    if (formalizationData.formalization && action === 'visualizar') {
      setValue(
        'parametrics.baseDate',
        setDateValue(formalizationData.formalization.priceParametric.database),
      );
    }
  };

  const defineVariant = (fallback) => (isFieldRequired ? 'success' : fallback);

  return (
    <S.Container data-testid="formalization-form-test">
      <Accordion
        isDefaultOpen
        title="1. Paramétricas de preço"
        variant={defineVariant(hasParametricsError)}
      >
        <PriceParametrics
          disableFields={action === 'visualizar'}
          isFieldRequired={isFieldRequired}
          instrumentReference={
            formalizationData?.formalization?.instrumentReference
          }
          checkIsOneYear={(isOneYear) =>
            checksWhetherToDisplayInflationSections(isOneYear)
          }
          checkIsLessThanMonth={(isLess) => setIsLessThanOneMonth(isLess)}
          isOnlyHarbor={isOnlyHarbor}
          hasModal={hasModal}
        />
      </Accordion>

      {(showDiesel || (isOnlyHarbor && showInflationSections)) &&
        !isLessThanOnMonth && (
          <Accordion
            title="2. Índices"
            variant={defineVariant(hasIndicesError)}
          >
            <Indices
              disableFields={action === 'visualizar'}
              sectionErrors={formErrors?.indices || null}
              showDieselField={showDiesel}
              isFieldRequired={isFieldRequired}
            />
          </Accordion>
        )}

      {showDiesel && !isLessThanOnMonth && (
        <Accordion
          title="3.1 Reajustes de diesel - Ferrovia"
          variant={defineVariant(hasFormulasDieselError)}
        >
          <DieselReadjustment
            disableFields={action === 'visualizar'}
            isFieldRequired={isFieldRequired}
          />
        </Accordion>
      )}

      {showDiesel && showInflationSections && (
        <Accordion
          title="3.2 Reajustes de inflação - Ferrovia"
          variant={defineVariant(hasFormulasInflationRailwayError)}
        >
          <InflationReadjustment
            disableFields={action === 'visualizar'}
            field="inflationFormulaRailway"
            isFieldRequired={isFieldRequired}
            title="3.2 Reajustes de inflação - Ferrovia"
          />
        </Accordion>
      )}

      {showHarborSection && showInflationSections && (
        <Accordion
          title={`${harborTitleNumber} Reajustes de inflação - Porto`}
          variant={defineVariant(hasFormulasInflationHarborError)}
        >
          <InflationReadjustment
            disableFields={action === 'visualizar'}
            field="inflationFormulaHarbor"
            isFieldRequired={isFieldRequired}
            title={`${harborTitleNumber} Reajustes de inflação - Porto`}
          />
        </Accordion>
      )}
    </S.Container>
  );
};

FormalizationForm.propTypes = {
  resetForm: PropTypes.bool.isRequired,
  formErrors: PropTypes.objectOf(PropTypes.any),
};

FormalizationForm.defaultProps = {
  formErrors: null,
};

export default FormalizationForm;

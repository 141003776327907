import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { capitalizeFirstString, simpleId } from 'lib/core/utility';

import PriceBox from '../PriceBox';

import * as S from '../PriceAndVolumeModalStyles';

const Price = ({ priceData, period }) => {
  const formatDate = (date, pattern = 'MMMM yyyy') => {
    if (date) {
      return capitalizeFirstString(
        format(new Date(date), pattern, { locale: ptBR }),
      );
    }

    return '-';
  };

  const separatedDataByPriceTerms = priceData?.reduce((result, item) => {
    const term = item.openingsPriceTerms;
    if (!result[term]) {
      result[term] = [];
    }
    result[term].push(item);
    return result;
  }, {});

  return (
    <S.Section data-testid="price-section-test">
      <S.Title>Preço</S.Title>

      {separatedDataByPriceTerms?.Mensal && (
        <S.LineWrapper>
          <span>Preço informado Mensal</span>
          <S.ColumnWrapper>
            {separatedDataByPriceTerms.Mensal.map((price) => (
              <S.PriceContainer key={`price-mensal-${simpleId()}`}>
                <small>{formatDate(price.beginningValidity)}</small>

                {price.priceViewModel.map((item) => (
                  <PriceBox
                    key={`price-mensal-destination-${simpleId()}`}
                    item={item}
                  />
                ))}
              </S.PriceContainer>
            ))}
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}

      {separatedDataByPriceTerms?.Semestral && (
        <S.LineWrapper>
          <span>Preço informado Semestral</span>
          <S.ColumnWrapper>
            {separatedDataByPriceTerms.Semestral.map((price) => (
              <S.PriceContainer key={`price-semestral-${simpleId()}`}>
                <small>
                  {formatDate(price.beginningValidity)} -{' '}
                  {formatDate(price.endValidity)}
                </small>

                {price.priceViewModel.map((item) => (
                  <PriceBox
                    key={`price-semestral-destination-${simpleId()}`}
                    item={item}
                  />
                ))}
              </S.PriceContainer>
            ))}
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}

      {separatedDataByPriceTerms?.['Período Total'] && (
        <S.LineWrapper>
          <span>Preço informado Período Total</span>
          <S.ColumnWrapper>
            <S.PriceContainer>
              <small>{period}</small>

              {separatedDataByPriceTerms['Período Total'][0].priceViewModel.map(
                (item) => (
                  <PriceBox
                    key={`price-total-destination-${simpleId()}`}
                    item={item}
                  />
                ),
              )}
            </S.PriceContainer>
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}

      {separatedDataByPriceTerms?.Anual && (
        <S.LineWrapper>
          <span>Preço informado Anual</span>
          <S.ColumnWrapper>
            {separatedDataByPriceTerms.Anual.map((price) => (
              <S.PriceContainer key={`price-anual-${simpleId()}`}>
                <small>{formatDate(price.beginningValidity, 'yyyy')}</small>

                {price.priceViewModel.map((item) => (
                  <PriceBox
                    key={`price-anual-destination-${simpleId()}`}
                    item={item}
                  />
                ))}
              </S.PriceContainer>
            ))}
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}

      {separatedDataByPriceTerms?.Trimestral && (
        <S.LineWrapper>
          <span>Preço informado Trimestral</span>
          <S.ColumnWrapper>
            {separatedDataByPriceTerms.Trimestral.map((price) => (
              <S.PriceContainer key={`price-trimestral-${simpleId()}`}>
                <small>
                  {formatDate(price.beginningValidity)} -{' '}
                  {formatDate(price.endValidity)}
                </small>

                {price.priceViewModel.map((item) => (
                  <PriceBox
                    key={`price-trimestral-destination-${simpleId()}`}
                    item={item}
                  />
                ))}
              </S.PriceContainer>
            ))}
          </S.ColumnWrapper>
        </S.LineWrapper>
      )}
    </S.Section>
  );
};

Price.propTypes = {
  priceData: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired,
};

export default Price;

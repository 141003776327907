import * as utility from 'lib/core/utility';

export const getFilterParams = (data) => {
  const filterFields = {
    filterFields: {
      CustomerName: data.clientName,
      ContractCode: data.contractName,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, EmptyState } from '@vli/locomotive-ds/dist';

import Table from 'components/core/Table/Table';
import Loading from 'components/core/Loading';

import { isPending, isFinished } from 'lib/core/request';

import { SupplierActions } from 'store/supplier/supplier.ducks';

import { Columns } from './Columns/Columns';

import * as S from './SuppliersTable.styles';

const SuppliersTable = () => {
  const { supplierList, supplierRequests } = useSelector(
    ({ supplier }) => supplier,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SupplierActions.listVliSuppliers());
  }, [dispatch]);

  const isLoading =
    isPending(supplierRequests.LIST_VLI_SUPPLIERS) ||
    isPending(supplierRequests.UPDATE_SUPPLIER_STATUS);

  return (
    <>
      {isLoading && <Loading />}

      <S.TableContainer>
        {supplierList.length > 0 ? (
          <Table
            width="100%"
            tableType="responsive"
            footer={false}
            data={supplierList}
            columns={Columns}
          />
        ) : (
          isFinished(supplierRequests.LIST_VLI_SUPPLIER) && (
            <Card>
              <EmptyState
                condensed={false}
                title="Nenhum fornecedor encontrado."
              />
            </Card>
          )
        )}
      </S.TableContainer>
    </>
  );
};

export default SuppliersTable;

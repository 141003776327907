import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/core/Tooltip';
import Headlight from 'components/core/headlight';

import { colorByStatus } from 'lib/contexts/requestsList';

const CellStep = ({ cell: { value }, row }) => {
  const stepData = colorByStatus.find(
    (item) => item.id === row.original.statusId,
  );

  return (
    <Tooltip title={value}>
      <Headlight
        data-testid="cell-step-request-list-test"
        color={stepData?.color}
        style={{ cursor: 'pointer' }}
      />
    </Tooltip>
  );
};

CellStep.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellStep;

import React from 'react';
import PropTypes from 'prop-types';

import { TrainIcon, ShipIcon, Text } from '@vli/locomotive-ds';

const CellModal = ({ cell: { value } }) => {
  return (
    <Text>
      {value.includes('Ferrovia') && <TrainIcon />}
      {value.includes('Porto') && <ShipIcon />}
    </Text>
  );
};

CellModal.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]).isRequired,
  }).isRequired,
};

export default CellModal;

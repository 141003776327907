import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const TableContainer = styled.div`
  width: 100%;
  padding: ${Tokens.spacing.md} 0px;
  table {
    width: 100%;
    th {
      text-align: left;
    }
    th:last-child {
      text-align: right;
    }
  }
`;

export const ContentCell = styled.div`
  /* position: relative; */
  width: 100%;
  overflow: visible;
`;

export const OptionsContainer = styled.div`
  display: block;
`;
export const OptionItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 8px 4px;

  :hover {
    p {
      ${({ disabled }) =>
        disabled
          ? `color: ${Tokens.colors.gray300};`
          : `color: ${Tokens.colors.accentPrimaryDark};`}
    }
    svg {
      path {
        fill: ${Tokens.colors.accentPrimaryDark};
      }
    }
  }
  ${({ disabled }) => disabled && `color: ${Tokens.colors.gray300};`}
`;
export const IconContent = styled.div`
  margin-right: 6px;
`;

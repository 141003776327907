import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text, Popover } from '@vli/locomotive-ds';
import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';
import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ handleSettings }) => {
  const Options = (
    <S.OptionsContainer>
      <S.OptionItem data-testid="test-option-settings" onClick={handleSettings}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Gerenciar
        </Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  handleSettings: PropTypes.func,
};
CellWithActions.defaultProps = {
  handleSettings: () => {},
};

export default CellWithActions;

import React from 'react';
import ReactDOM from 'react-dom';
import * as msal from '@azure/msal-browser';

import { msalConfig } from 'lib/core/msalConfig';
import App from './App';

export const msalInstance = new msal.PublicClientApplication(msalConfig);

ReactDOM.render(<App pca={msalInstance} />, document.getElementById('root'));

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container } from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';
import Loading from 'components/core/Loading';
import EmailForm from 'components/contexts/notifications/manager/EmailForm';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { EmailNotificationManagerActions } from 'store/emailNotificationsManager/emailNotificationManager.ducks';

const EmailNotificationManagerCreate = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { details, emailNotificationManagerRequests } = useSelector(
    ({ emailNotificationManager }) => emailNotificationManager,
  );

  const sendForm = (data) => {
    dispatch(
      EmailNotificationManagerActions.createEmailNotificationManager(data),
    );
  };

  useEffect(() => {
    dispatch(EmailNotificationManagerActions.getEmailNotificationManager(id));
  }, [dispatch, id]);
  const loading =
    isPending(
      emailNotificationManagerRequests.GET_EMAIL_NOTIFICATION_MANAGER,
    ) ||
    isPending(
      emailNotificationManagerRequests.CREATE_EMAIL_NOTIFICATION_MANAGER,
    );
  return (
    <Content height={dimensions.height} data-testid="content">
      {loading && <Loading />}
      <Container>
        {details && !loading && (
          <EmailForm sendForm={sendForm} data={details} />
        )}
      </Container>
    </Content>
  );
};

export default EmailNotificationManagerCreate;

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds/dist';

export const DashboardContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CardContent = styled.div`
  display: inline-block;
  padding: 0;
  padding-right: ${Tokens.spacing.xs};
  margin: 1rem 0 0;
  width: 100%;
`;

export const CardSticky = styled.div`
  position: fixed;
  top: -999px;
  z-index: 10;
  width: 100%;
  background: ${Tokens.colors.gray100};
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  height: 0px;

  &.openActive {
    top: 0px;
    padding-bottom: calc(${Tokens.spacing.lg} + ${Tokens.spacing.lg} + 5px);
  }
`;

export const CardStickyContent = styled.div`
  display: flex;
  height: ${Tokens.spacing.xxl};
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  background-color: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  padding: 0;
  padding-right: ${Tokens.spacing.lg};

  p,
  span {
    margin: 0;
    display: inline-block;
    line-height: 24px;
  }

  a {
    svg {
      margin-left: ${Tokens.spacing.sm};
    }
  }
`;

export const CardStickyFlex = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const OriginContainer = styled.div`
  justify-content: center;
  display: flex;
`;

export const MODALS = [
  {
    label: 'Ferrovia',
    value: {
      id: 1,
    },
  },
  {
    label: 'Porto',
    value: {
      id: 2,
    },
  },
  {
    label: 'Rodovia',
    value: {
      id: 3,
    },
  },
];

export const TYPE_FORMALIZATION = {
  CONTRACT: 1,
  ADDITIVE: 2,
  SIMPLIFIED_AGREEMENT: 3,
};

export const TYPE_FORMALIZATION_OPTIONS = [
  {
    label: 'Contrato',
    value: TYPE_FORMALIZATION.CONTRACT,
  },
  {
    label: 'Aditivo',
    value: TYPE_FORMALIZATION.ADDITIVE,
  },
  {
    label: 'Acordo Simplificado',
    value: TYPE_FORMALIZATION.SIMPLIFIED_AGREEMENT,
  },
];

export const APPLICATION_DATE_OPTIONS = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
];

export const RAILWAY_FIELDS = [
  {
    name: 'indices.railwayIGPM',
    label: 'Aplicação IGPM - %',
  },
  {
    name: 'indices.railwayIPCA',
    label: 'Aplicação IPCA - %',
  },
];

export const HARBOR_FIELDS = [
  {
    name: 'indices.harborIGPM',
    label: 'Aplicação IGPM - %',
  },
  {
    name: 'indices.harborIPCA',
    label: 'Aplicação IPCA - %',
  },
];

/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@vli/locomotive-ds/dist';

import Modal from 'components/core/modal';

const ModalConfirm = ({
  closeModal,
  confirmAction,
  titleCancel,
  titleConfirm,
  title,
  message,
}) => {
  const actions = () => (
    <>
      <Button
        data-testid="btn-close"
        variant="secondary"
        name={titleCancel}
        onClick={() => {
          closeModal();
        }}
      />

      <Button
        variant="primary"
        data-testid="btn-confirm"
        name={titleConfirm}
        onClick={confirmAction}
      />
    </>
  );
  return (
    <Modal
      data-testid="modal-test"
      title={title}
      onClose={closeModal}
      actions={actions}
    >
      <div
        style={{ fontWeight: 400 }}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Modal>
  );
};
ModalConfirm.defaultProps = {
  title: '',
  titleCancel: 'CANCELAR',
  titleConfirm: 'CONFIRMAR',
  message: '',
  closeModal: () => {},
  confirmAction: () => {},
};
ModalConfirm.propTypes = {
  title: PropTypes.string,
  titleCancel: PropTypes.string,
  titleConfirm: PropTypes.string,
  message: PropTypes.string,
  closeModal: PropTypes.func,
  confirmAction: PropTypes.func,
};
export default ModalConfirm;

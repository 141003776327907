import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, EmptyState } from '@vli/locomotive-ds/dist';

import Table from 'components/core/Table/Table';
import Loading from 'components/core/Loading';

import { isPending, isFinished } from 'lib/core/request';

import { SegmentActions } from 'store/segment/segment.ducks';

import { Columns } from './Columns/Columns';

import * as S from './SegmentsTable.styles';

const SegmentsTable = () => {
  const { segmentList, segmentRequests } = useSelector(
    ({ segment }) => segment,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SegmentActions.listSegment());
  }, [dispatch]);

  const isLoading =
    isPending(segmentRequests.LIST_SEGMENT) ||
    isPending(segmentRequests.UPDATE_SEGMENT_STATUS);

  return (
    <S.TableContainer>
      {isLoading && <Loading />}
      {!isLoading && segmentList.length > 0 ? (
        <Table
          width="100%"
          tableType="responsive"
          footer={false}
          data={segmentList}
          columns={Columns}
        />
      ) : (
        isFinished(segmentRequests.LIST_SEGMENT) &&
        !isLoading && (
          <Card>
            <EmptyState condensed={false} title="Nenhum segmento encontrado." />
          </Card>
        )
      )}
    </S.TableContainer>
  );
};

export default SegmentsTable;

import { put, takeLatest } from 'redux-saga/effects';
import { COMMITMENT } from 'constants/endpoints';
import { GET } from 'constants/verbs';

import api from 'services/api';

import { CompensationActions, CompensationTypes } from './compensation.ducks';

export function* compensationById({ formalizationId }) {
  try {
    const response = yield api({
      method: GET,
      url: `${COMMITMENT}/${formalizationId}`,
    });

    yield put(
      CompensationActions.compensationByIdSuccess({
        data: response.data.data,
      }),
    );
  } catch (error) {
    yield put(CompensationActions.compensationByIdFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(CompensationTypes.COMPENSATION_BY_ID, compensationById);
}

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Text, Popover } from '@vli/locomotive-ds/dist';
import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';
import { GET } from 'lib/core/localStorage';
import { ContractReportActions } from 'store/contractReport/contractReport.ducks';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();
  const report = row.original;

  const handleDownloadReport = () => {
    dispatch(
      ContractReportActions.downloadContractReport({
        id: report.id,
        fileName: report.name,
      }),
    );
  };

  const handleDeleteReport = () => {
    const { userId } = GET('userId');

    const callback = () =>
      dispatch(ContractReportActions.listContractReports(userId));

    dispatch(ContractReportActions.deleteContractReport(report.id, callback));
  };

  const Options = (
    <S.OptionsContainer>
      <S.OptionItem data-testid="test-download" onClick={handleDownloadReport}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Download
        </Text>
      </S.OptionItem>

      <S.OptionItem data-testid="test-delete" onClick={handleDeleteReport}>
        <Text size="medium" style={{ fontWeight: '400' }}>
          Excluir
        </Text>
      </S.OptionItem>
    </S.OptionsContainer>
  );

  return (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;

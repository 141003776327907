import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: EmailNotificationManagerTypes,
  Creators: EmailNotificationManagerActions,
} = createActions({
  getEmailNotificationManager: ['payload'],
  getEmailNotificationManagerSuccess: ['payload'],
  createEmailNotificationManager: ['payload', 'callback'],
  createEmailNotificationManagerSuccess: ['payload'],
  listEmailNotificationManager: ['params'],
  listEmailNotificationManagerFilter: ['params', 'rawFilter'],
  listEmailNotificationManagerSuccess: ['payload'],
  emailNotificationManagerRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  emailNotificationManagerList: [],
  params: null,
  filter: null,
  details: null,
  error: null,
  emailNotificationManagerRequests: {
    [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER]: REQUEST_NOT_STARTED,
  },
};

const listEmailNotificationManager = () => ({
  ...INITIAL_STATE,
  emailNotificationManagerRequests: {
    ...INITIAL_STATE.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER]: REQUEST_PENDING,
  },
});

const listEmailNotificationManagerFilter = (state, { params, rawFilter }) => ({
  ...state,
  ...INITIAL_STATE,
  params,
  filter: rawFilter,
  emailNotificationManagerRequests: {
    ...INITIAL_STATE.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER]: REQUEST_RESOLVED,
  },
});
const listEmailNotificationManagerSuccess = (state, { payload }) => ({
  ...state,
  emailNotificationManagerList: payload,
  emailNotificationManagerRequests: {
    ...state.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER]: REQUEST_RESOLVED,
  },
});
const getEmailNotificationManager = () => ({
  ...INITIAL_STATE,
  emailNotificationManagerRequests: {
    ...INITIAL_STATE.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.GET_EMAIL_NOTIFICATION_MANAGER]: REQUEST_PENDING,
  },
});
const getEmailNotificationManagerSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  emailNotificationManagerRequests: {
    ...state.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.GET_EMAIL_NOTIFICATION_MANAGER]: REQUEST_RESOLVED,
  },
});
const createEmailNotificationManager = (state) => ({
  ...state,
  emailNotificationManagerRequests: {
    ...INITIAL_STATE.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.CREATE_EMAIL_NOTIFICATION_MANAGER]: REQUEST_PENDING,
  },
});
const createEmailNotificationManagerSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  emailNotificationManagerRequests: {
    ...state.emailNotificationManagerRequests,
    [EmailNotificationManagerTypes.CREATE_EMAIL_NOTIFICATION_MANAGER]: REQUEST_RESOLVED,
  },
});
const emailNotificationManagerRequestFailure = (
  state,
  { error: { response }, originalType },
) => ({
  ...state,
  error: response,
  emailNotificationManagerRequests: {
    ...state.emailNotificationManagerRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER]: listEmailNotificationManager,
  [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER_FILTER]: listEmailNotificationManagerFilter,
  [EmailNotificationManagerTypes.LIST_EMAIL_NOTIFICATION_MANAGER_SUCCESS]: listEmailNotificationManagerSuccess,
  [EmailNotificationManagerTypes.GET_EMAIL_NOTIFICATION_MANAGER]: getEmailNotificationManager,
  [EmailNotificationManagerTypes.GET_EMAIL_NOTIFICATION_MANAGER_SUCCESS]: getEmailNotificationManagerSuccess,
  [EmailNotificationManagerTypes.CREATE_EMAIL_NOTIFICATION_MANAGER]: createEmailNotificationManager,
  [EmailNotificationManagerTypes.CREATE_EMAIL_NOTIFICATION_MANAGER_SUCCESS]: createEmailNotificationManagerSuccess,
  [EmailNotificationManagerTypes.EMAIL_NOTIFICATION_MANAGER_REQUEST_FAILURE]: emailNotificationManagerRequestFailure,
});

import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const Wrapper = styled.form`
  width: 100%;

  margin-top: ${Tokens.spacing.sm};

  display: flex;
  align-items: flex-end;
  gap: ${Tokens.spacing.sm};

  & > div {
    min-width: 200px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { CalendarIcon } from '@vli/locomotive-ds';
import { CalendarStyle, Label } from './InputDateStyle';

const InputDate = ({
  isOpen,
  className,
  locale,
  width,
  value,
  range,
  onChange,
  label,
  ...attrs
}) => (
  <CalendarStyle
    className={className}
    width={width}
    spanStyle={!!value}
    variant={range && 'range'}
  >
    {label && <Label>{label}</Label>}
    {!range ? (
      <DatePicker
        isOpen={isOpen}
        value={value}
        locale={locale}
        onChange={onChange}
        calendarIcon={<CalendarIcon />}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="AAAA"
        {...attrs}
      />
    ) : (
      <DateRangePicker
        isOpen={isOpen}
        value={value}
        locale={locale}
        onChange={onChange}
        calendarIcon={<CalendarIcon />}
        {...attrs}
      />
    )}
  </CalendarStyle>
);

InputDate.propTypes = {
  isOpen: PropTypes.bool,
  locale: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  range: PropTypes.bool,
  width: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

InputDate.defaultProps = {
  isOpen: false,
  locale: 'pt-BR',
  value: '',
  className: '',
  width: '100%',
  range: false,
  onChange: () => {},
  label: '',
};

export default InputDate;

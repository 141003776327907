import { put, takeLatest } from 'redux-saga/effects';
import { SIGN_IN } from 'constants/endpoints';
import api, { apiSensedia } from 'services/api';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import { GET, POST } from 'constants/verbs';
import { SET, REMOVE, GET as GetLocalStorage } from 'lib/core/localStorage';
import CONSTANTS from 'constants/constants';
import { REDIRECT_ROLE } from 'lib/contexts/permissions';
import { msalInstance } from '../..';

import { AuthTypes, AuthActions } from './auth.ducks';

export function* signIn({ shouldReloadWindow = false, isFirstLogin = false }) {
  try {
    const profile = msalInstance.getAllAccounts()[0];

    const responseSensedia = yield apiSensedia({
      method: POST,
      data: {
        grant_type: 'client_credentials',
        ClientId: CONSTANTS.CLIENT_ID_SENSIDIA,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${CONSTANTS.AUTHORIZATION}`,
      },
    });

    yield SET('sensediaToken', { token: responseSensedia.data.access_token });

    const response = yield api({
      method: GET,
      url: SIGN_IN,
      headers: {
        Authorization: `Bearer ${GetLocalStorage('access-token')}`,
      },
    });

    yield SET('name', { name: response.data.name });
    yield SET('profile', { profile: response.data.profile });
    yield SET('roles', { roles: response.data.roles });
    yield SET('segments', { segments: response.data.segments });
    yield SET('userId', { userId: response.data.id });
    yield SET('email', { email: profile.idTokenClaims.preferred_username });

    yield put(AuthActions.signInSuccess(response.data));

    if (shouldReloadWindow) yield window.location.reload();
    else if (isFirstLogin)
      yield history.push(REDIRECT_ROLE(response.data.roles));
  } catch (error) {
    REMOVE('access-token');

    if (error.response.status !== 403) history.push(ROUTES.LOGIN);

    yield put(AuthActions.authRequestFailure(error));
  }
}
export function* signOut() {
  yield localStorage.clear();
  yield msalInstance.logoutPopup();
}

export function* watchSagas() {
  yield takeLatest(AuthTypes.SIGN_IN, signIn);
  yield takeLatest(AuthTypes.SIGN_OUT, signOut);
}

import { useState } from 'react';

const usePagination = () => {
  const [paginationSize, setPaginationSize] = useState([
    {
      selected: true,
      value: 25,
      label: '25 itens',
    },
    {
      value: 50,
      label: '50 itens',
    },
    {
      value: 100,
      label: '100 itens',
    },
    {
      value: 200,
      label: '200 itens',
    },
  ]);

  const setValuePage = (PageSize) => {
    const newList = paginationSize.map((item) => {
      if (item.value === PageSize) {
        return {
          ...item,
          selected: true,
        };
      }
      return {
        ...item,
        selected: false,
      };
    });
    setPaginationSize(newList);
  };

  return {
    paginationSize,
    setValuePage,
  };
};

export default usePagination;

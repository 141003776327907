import CONSTANTS from './constants';

const service = 'api';

const API = `${CONSTANTS.API}/${service}/`;
const URL_SENSEDIA = CONSTANTS.URL_SENSIDIA;
const { ENDPOINTSNOTIFICATION } = CONSTANTS;
const config = {
  API,
  URL_SENSEDIA,
  ENDPOINTSNOTIFICATION,
};

export { config };

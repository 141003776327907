import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from '@vli/locomotive-ds/dist';
import * as S from './TextArea.styles';

const TextArea = ({
  label,
  placeholder,
  width,
  marginRight,
  onChange,
  value,
  status,
  statusMessage,
  maxlength,
  marginTop,
  required,
  hideCount = false,
  height = 80,
  disabled,
}) => {
  const [countText, setCountText] = useState(0);
  const onChangeText = (e) => {
    setCountText(e.length);
    onChange(e);
  };
  return (
    <S.WrapperInput marginRight={marginRight} marginTop={marginTop}>
      <S.WrapperLabel>
        <Label data-testid={`label-${label}`}>
          {label}{' '}
          {!hideCount && (
            <>
              <b>
                {countText}/{maxlength}
              </b>{' '}
              caracteres
            </>
          )}
        </Label>
      </S.WrapperLabel>
      <Input
        data-testid={`input-select-${label}`}
        type="textarea"
        name="mensagem"
        // label={label}
        value={value}
        placeholder={placeholder}
        required={required}
        status={status}
        statusMessage={statusMessage}
        width={width}
        height={height}
        onChange={(e) => onChangeText(e)}
        disabled={disabled}
      />
    </S.WrapperInput>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  onChange: PropTypes.func,
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  maxlength: PropTypes.number,
  hideCount: PropTypes.bool,
  height: PropTypes.number,
  disabled: PropTypes.bool,
};

TextArea.defaultProps = {
  placeholder: '',
  width: '',
  marginRight: 0,
  marginTop: 0,
  onChange: () => {},
  status: '',
  statusMessage: '',
  required: false,
  value: '',
  maxlength: 9999,
  hideCount: false,
  height: 80,
  disabled: false,
};

export default TextArea;

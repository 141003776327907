import { put, takeLatest } from 'redux-saga/effects';

import { COMMERCIAL_NEGOTIATION } from 'constants/endpoints';
import { GET, POST } from 'constants/verbs';

import * as utility from 'lib/core/utility';

import api from 'services/api';

import { MessagesActions } from 'store/messages/messages.ducks';
import {
  CommercialNegotiationsActions,
  CommercialNegotiationsTypes,
} from './commercialNegotiations.ducks';

export function* commercialNegotiationsList({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${COMMERCIAL_NEGOTIATION}/${params}`,
    });

    yield put(
      CommercialNegotiationsActions.commercialNegotiationsListSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      CommercialNegotiationsActions.commercialNegotiationsListFailure(error),
    );
  }
}

export function* commercialNegotiationsRailwayItem() {
  try {
    const response = yield api({
      method: GET,
      url: `${COMMERCIAL_NEGOTIATION}/railway-item`,
    });

    yield put(
      CommercialNegotiationsActions.commercialNegotiationsRailwayItemSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      CommercialNegotiationsActions.commercialNegotiationsRailwayItemFailure(
        error,
      ),
    );
  }
}

export function* commercialNegotiationsDownload({ payload }) {
  try {
    const response = yield api({
      method: POST,
      url: `${COMMERCIAL_NEGOTIATION}/download`,
      data: payload,
      responseType: 'arraybuffer',
    });

    yield put(
      CommercialNegotiationsActions.commercialNegotiationsDownloadSuccess(
        response.data,
      ),
    );

    utility.downloadFile(response.data, 'NegociacoesComerciais.zip');
  } catch (error) {
    yield put(
      CommercialNegotiationsActions.commercialNegotiationsDownloadFailure(
        error,
      ),
    );

    yield put(
      MessagesActions.open({
        title: 'Erro ao realizar download',
        type: 'error',
        contentText:
          'Não foi possível realizar download para esse conjunto de negociações.',
      }),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_LIST,
    commercialNegotiationsList,
  );
  yield takeLatest(
    CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM,
    commercialNegotiationsRailwayItem,
  );
  yield takeLatest(
    CommercialNegotiationsTypes.COMMERCIAL_NEGOTIATIONS_DOWNLOAD,
    commercialNegotiationsDownload,
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { NotificationActions } from 'store/notification/notification.ducks';

import { GET } from 'lib/core/localStorage';
import useSignalR from 'hooks/useSignalR';

import NotifySidebar from './NotifySidebar';

import * as S from './NotificationStyle';

export const Notification = () => {
  const dispatch = useDispatch();
  const { connection } = useSignalR();
  const history = useHistory();

  const { email } = GET('email');
  const systemId = 'c4b39477-1258-4dce-a7e1-40e0cfe73f30';

  const defaultParams = `?Email=${email}&SistemaId=${systemId}`;
  const listParams = `${defaultParams}&Skip=0&Take=100`;

  const {
    notificationSidebarOpen,
    unreadNotifications,
    notifications,
    updatedNotification,
  } = useSelector(({ notification }) => notification);

  const handleSidebarClose = () => {
    dispatch(
      NotificationActions.setNotificationSidebarOpen({ sidebarOpen: false }),
    );
  };

  const handleMarkNotificationAsRead = (notificationId) => {
    dispatch(
      NotificationActions.updateNotificationToReadRequest(
        `?id=${notificationId}`,
      ),
    );
  };

  const handleRedirectNotification = (notification) => {
    const { id, caminhoRelativo } = notification;

    handleMarkNotificationAsRead(id);

    history.push(`/${caminhoRelativo}`);
    handleSidebarClose();
  };

  useEffect(() => {
    dispatch(NotificationActions.getUnreadNotificationRequest(defaultParams));
  }, [connection, notificationSidebarOpen, updatedNotification]);

  useEffect(() => {
    if (notificationSidebarOpen) {
      dispatch(NotificationActions.listNotificationRequest(listParams));
    }
  }, [notificationSidebarOpen, updatedNotification]);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        connection.invoke('JoinGroup', email, 'Jetsons#Web');

        connection.on('ReceiveMessage', () => {
          dispatch(
            NotificationActions.getUnreadNotificationRequest(defaultParams),
          );
        });
      });
    }
  }, [connection]);

  return (
    <S.NotificationWrapper data-testid="notification-wrapper-test">
      <NotifySidebar
        isOpen={notificationSidebarOpen}
        notifications={notifications}
        onClose={handleSidebarClose}
        onRedirectNotification={handleRedirectNotification}
        onMarkNotificationAsRead={handleMarkNotificationAsRead}
        unreadNotifications={unreadNotifications}
      />
    </S.NotificationWrapper>
  );
};

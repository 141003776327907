import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: NotificationTypes,
  Creators: NotificationActions,
} = createActions({
  setNotificationSidebarOpen: ['payload'],
  listNotificationRequest: ['params'],
  listNotificationRequestSuccess: ['payload'],
  listNotificationRequestFailure: ['error'],
  getUnreadNotificationRequest: ['params'],
  getUnreadNotificationRequestSuccess: ['payload'],
  getUnreadNotificationRequestFailure: ['error'],
  updateNotificationToReadRequest: ['params'],
  updateNotificationToReadRequestSuccess: ['payload'],
  updateNotificationToReadRequestFailure: ['error'],
});

const INITIAL_STATE = {
  notificationSidebarOpen: false,
  updatedNotification: {},
  notifications: [],
  unreadNotifications: 0,
  notificationRequests: {
    [NotificationTypes.LIST_NOTIFICATION_REQUEST]: REQUEST_NOT_STARTED,
    [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST]: REQUEST_NOT_STARTED,
    [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST]: REQUEST_NOT_STARTED,
  },
  error: null,
};

const setNotificationSidebarOpen = (state, { payload }) => ({
  ...state,
  notificationSidebarOpen: payload.sidebarOpen,
});

const listNotificationRequest = (state) => ({
  ...state,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.LIST_NOTIFICATION_REQUEST]: REQUEST_PENDING,
  },
});

const listNotificationRequestSuccess = (state, { payload }) => ({
  ...state,
  notifications: payload.data,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.LIST_NOTIFICATION_REQUEST]: REQUEST_RESOLVED,
  },
});

const listNotificationRequestFailure = (state, { error }) => ({
  ...state,
  error,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.LIST_NOTIFICATION_REQUEST]: REQUEST_REJECTED,
  },
});

const getUnreadNotificationRequest = (state) => ({
  ...state,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST]: REQUEST_PENDING,
  },
});

const getUnreadNotificationRequestSuccess = (state, { payload }) => ({
  ...state,
  unreadNotifications: payload.notificacoes,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST]: REQUEST_RESOLVED,
  },
});

const getUnreadNotificationRequestFailure = (state, { error }) => ({
  ...state,
  error,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST]: REQUEST_REJECTED,
  },
});

const updateNotificationToReadRequest = (state) => ({
  ...state,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST]: REQUEST_PENDING,
  },
});

const updateNotificationToReadRequestSuccess = (state, { payload }) => ({
  ...state,
  updatedNotification: payload,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST]: REQUEST_RESOLVED,
  },
});

const updateNotificationToReadRequestFailure = (state, { error }) => ({
  ...state,
  updatedNotification: {},
  error,
  notificationRequests: {
    ...state.notificationRequests,
    [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [NotificationTypes.SET_NOTIFICATION_SIDEBAR_OPEN]: setNotificationSidebarOpen,
  [NotificationTypes.LIST_NOTIFICATION_REQUEST]: listNotificationRequest,
  [NotificationTypes.LIST_NOTIFICATION_REQUEST_SUCCESS]: listNotificationRequestSuccess,
  [NotificationTypes.LIST_NOTIFICATION_REQUEST_FAILURE]: listNotificationRequestFailure,
  [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST]: getUnreadNotificationRequest,
  [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST_SUCCESS]: getUnreadNotificationRequestSuccess,
  [NotificationTypes.GET_UNREAD_NOTIFICATION_REQUEST_FAILURE]: getUnreadNotificationRequestFailure,
  [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST]: updateNotificationToReadRequest,
  [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST_SUCCESS]: updateNotificationToReadRequestSuccess,
  [NotificationTypes.UPDATE_NOTIFICATION_TO_READ_REQUEST_FAILURE]: updateNotificationToReadRequestFailure,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@vli/locomotive-ds/dist';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/core/modal';
import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';
import {
  FareType,
  getGroupedRailwayItemsId,
  getRailwayItems,
} from 'lib/contexts/contracts';
import { validateImmediateSend } from 'lib/core/utility';

const Send = ({ id }) => {
  const dispatch = useDispatch();
  const { contractDetails, priceActions } = useSelector(
    ({ contractDetail }) => contractDetail,
  );

  const closeModal = () => {
    dispatch(
      ContractDetailActions.setPriceAction({
        action: null,
        modal: false,
        id: null,
        item: {},
      }),
    );
  };

  const callback = () => {
    const viewScenario = contractDetails.filter.viewScenario || 'CurrentPrice';
    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario,
      }),
    );
  };

  const submit = () => {
    let data = [];
    let railwayItems = [];
    let ids = [];

    switch (priceActions.item) {
      case 'all':
        if (contractDetails.details.fareType === FareType.FIXED_TARIFF) {
          railwayItems = contractDetails.details.railwayItems;
        } else {
          railwayItems = getRailwayItems(contractDetails.details.railwayItems);
        }

        ids = railwayItems
          .filter((i) => validateImmediateSend(i))
          .map((railwayItem) => railwayItem.history.id);
        data = {
          historyRailwayItems: ids,
        };

        break;

      case 'grouped':
        ids = getGroupedRailwayItemsId(priceActions.items);

        data = {
          historyRailwayItems: ids,
        };

        break;

      case 'grouped-all':
        ids = priceActions.items
          .filter((i) => validateImmediateSend(i))
          .map((item) => getGroupedRailwayItemsId(item))
          .flat();

        data = {
          historyRailwayItems: ids,
        };

        break;

      default:
        data = {
          historyRailwayItems: [priceActions.item?.id],
        };
        break;
    }

    dispatch(ContractDetailActions.sendImmediately(data, callback));
    closeModal();
  };

  const actions = () => (
    <>
      <Button
        variant="secondary"
        name="NÃO"
        onClick={() => {
          closeModal();
        }}
      />

      <Button variant="primary" name="SIM" onClick={submit} />
    </>
  );

  return (
    <Modal
      className="modal"
      title="Envio imediato"
      onClose={closeModal}
      actions={actions}
    >
      <div>Tem certeza que deseja realizar ação de envio imediato?</div>

      {(priceActions.item === 'all' || priceActions.item === 'grouped-all') && (
        <p>Para ações em massa não é possível desfazer a ação.</p>
      )}
    </Modal>
  );
};

Send.defaultProps = {
  id: '',
};

Send.propTypes = {
  id: PropTypes.string,
};

export default Send;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ReactivateItemsTypes,
  Creators: ReactivateItemsActions,
} = createActions({
  openReactivateItemModal: ['isModalOpen'],
  reactivateItemsListRequest: ['filter', 'filterData'],
  reactivateItemsListSuccess: ['payload', 'filter'],
  reactivateItemsListFailure: ['error'],
  reactivateItemRequest: ['payload', 'callback'],
  reactivateItemSuccess: [],
  reactivateItemFailure: ['error'],
});

const INITIAL_STATE = {
  reactivateItemsList: [],
  error: null,
  itemsForFilter: [],
  filter: {},
  isModalOpen: false,
  reactivateItemsRequests: {
    [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_REQUEST]: REQUEST_NOT_STARTED,
  },
};

const openReactivateItemModal = (state, { isModalOpen }) => ({
  ...state,
  isModalOpen,
});

const reactivateItemsListRequest = () => ({
  ...INITIAL_STATE,
  reactivateItemsRequests: {
    ...INITIAL_STATE.reactivateItemsRequests,
    [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_REQUEST]: REQUEST_PENDING,
  },
});

const reactivateItemsListSuccess = (state, { payload, filter }) => ({
  ...state,
  reactivateItemsList: payload.data,
  filter,
  reactivateItemsRequests: {
    ...state.reactivateItemsRequests,
    [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_REQUEST]: REQUEST_RESOLVED,
  },
});

const reactivateItemsListFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  reactivateItemsRequests: {
    ...state.reactivateItemsRequests,
    [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_REQUEST]: REQUEST_REJECTED,
  },
});

const reactivateItemRequest = (state) => ({
  ...state,
  reactivateItemRequests: {
    ...state.reactivateItemsRequests,
    [ReactivateItemsTypes.REACTIVATE_ITEM_REQUEST]: REQUEST_PENDING,
  },
});

const reactivateItemSuccess = (state) => ({
  ...state,
  reactivateItemRequests: {
    ...state.reactivateItemsRequests,
    [ReactivateItemsTypes.REACTIVATE_ITEM_REQUEST]: REQUEST_RESOLVED,
  },
});

const reactivateItemFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  reactivateItemRequests: {
    ...state.reactivateItemsRequests,
    [ReactivateItemsTypes.REACTIVATE_ITEM_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ReactivateItemsTypes.OPEN_REACTIVATE_ITEM_MODAL]: openReactivateItemModal,
  [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_REQUEST]: reactivateItemsListRequest,
  [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_SUCCESS]: reactivateItemsListSuccess,
  [ReactivateItemsTypes.REACTIVATE_ITEMS_LIST_FAILURE]: reactivateItemsListFailure,
  [ReactivateItemsTypes.REACTIVATE_ITEM_REQUEST]: reactivateItemRequest,
  [ReactivateItemsTypes.REACTIVATE_ITEM_SUCCESS]: reactivateItemSuccess,
  [ReactivateItemsTypes.REACTIVATE_ITEM_FAILURE]: reactivateItemFailure,
});

import styled from 'styled-components';
import { Button, Text } from '@vli/locomotive-ds/dist';

export const LoginButton = styled(Button)`
  color: #2d2d2d;
  background: #f7f7f7;
  margin-top: 18px;
  padding: 5px 70px 5px 70px;
  text-transform: none;
  font-size: 16px;
`;

export const ExitButton = styled(Button)`
  left: 280px;
  margin-top: 30px;
`;

export const TextModal = styled(Text)`
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Input } from '@vli/locomotive-ds/dist';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import * as S from './FormalizationAttachmentStyles';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
  '.xlsx',
  '.xls',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const FormalizationAttachment = ({ formalizationId }) => {
  const dispatch = useDispatch();

  const [inputFile, setInputFile] = useState({
    value: null,
    error: null,
  });

  const handleAttachment = () => {
    if (!inputFile.value) {
      setInputFile((oldState) => ({
        ...oldState,
        error: 'Campo não pode estar vazio',
      }));

      return;
    }

    if (inputFile.value.size >= 3072 * 1024) {
      setInputFile((oldState) => ({
        ...oldState,
        error: 'Não é possível anexar arquivo deste tamanho',
      }));

      return;
    }

    if (!SUPPORTED_FORMATS.includes(inputFile.value.type)) {
      setInputFile((oldState) => ({
        ...oldState,
        error: 'Não é possível anexar arquivo neste formato',
      }));

      return;
    }

    setInputFile((oldState) => ({
      ...oldState,
      error: null,
    }));

    const formData = new FormData();

    formData.append('FormalizationId', formalizationId);
    formData.append('File', inputFile.value);

    dispatch(FormalizationRequestActions.uploadAttachment(formData));

    setInputFile({
      value: null,
      error: null,
    });
  };

  const handleInputChange = (value) => {
    setInputFile({
      value,
      error: null,
    });
  };

  return (
    <S.Wrapper data-testid="formalization-attachment-test">
      <Input
        name="file"
        type="file"
        label="Adicionar anexo"
        placeholder="Selecione o arquivo"
        accept="image/jpg, image/jpeg, image/png, application/pdf"
        required
        status={inputFile?.error && 'error'}
        statusMessage={inputFile?.error}
        value={inputFile.value}
        onChange={handleInputChange}
      />

      <Button
        variant="primary"
        name="Anexar"
        type="button"
        onClick={handleAttachment}
      />
    </S.Wrapper>
  );
};

FormalizationAttachment.propTypes = {
  formalizationId: PropTypes.number.isRequired,
};

export default FormalizationAttachment;

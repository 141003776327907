import { RESOURCE_REPORT_CONTRACT_ITEMS } from 'constants/endpoints';
import messages from 'constants/messages';
import { GET, PATCH, PUT } from 'constants/verbs';
import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { MessagesActions } from 'store/messages/messages.ducks';
import {
  ReportContractItemsActions,
  ReportContractItemsTypes,
} from './reportContractItems.ducks';

export function* listReportContractItems({ params, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_REPORT_CONTRACT_ITEMS}/paged/${params}`,
    });
    yield put(
      ReportContractItemsActions.listReportContractItemsSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      ReportContractItemsActions.reportContractItemsRequestFailure(error, type),
    );
  }
}

export function* getReportContractItems({ payload, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_REPORT_CONTRACT_ITEMS}/${payload}`,
    });
    yield put(
      ReportContractItemsActions.getReportContractItemsSuccess(
        response.data.data,
      ),
    );
  } catch (error) {
    yield put(
      ReportContractItemsActions.reportContractItemsRequestFailure(error, type),
    );
  }
}

export function* updateReportContractItems({ payload, callback, type }) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_REPORT_CONTRACT_ITEMS}`,
      data: payload,
    });

    yield put(ReportContractItemsActions.updateReportContractItemsSuccess());

    yield put(
      MessagesActions.open({
        title: messages.messages.reportContractItems[PUT].title,
        type: 'success',
        contentText: messages.messages.reportContractItems[PUT].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message = messages.messages.reportContractItems[PUT].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.reportContractItems[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );
    yield put(
      ReportContractItemsActions.reportContractItemsRequestFailure(error, type),
    );
  }
}

export function* updateReportServices({ payload, callback, type }) {
  try {
    const response = yield api({
      method: PUT,
      url: `${RESOURCE_REPORT_CONTRACT_ITEMS}/${payload}`,
    });

    yield put(
      ReportContractItemsActions.updateReportServicesSuccess(
        response.data.data,
      ),
    );

    yield put(
      MessagesActions.open({
        title: messages.messages.reportContractItems[PATCH].title,
        type: 'success',
        contentText: messages.messages.reportContractItems[PATCH].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message = messages.messages.reportContractItems[PATCH].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.reportContractItems[PATCH].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(
      ReportContractItemsActions.reportContractItemsRequestFailure(error, type),
    );
  }
}

export function* updateReportPiscofins({ payload, callback, type }) {
  try {
    const response = yield api({
      method: PUT,
      url: `${RESOURCE_REPORT_CONTRACT_ITEMS}/${payload}/piscofins`,
    });

    yield put(
      ReportContractItemsActions.updateReportPiscofinsSuccess(
        response.data.data,
      ),
    );

    yield put(
      MessagesActions.open({
        title: messages.messages.reportContractItemsPiscofins[PATCH].title,
        type: 'success',
        contentText:
          messages.messages.reportContractItemsPiscofins[PATCH].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message =
      messages.messages.reportContractItemsPiscofins[PATCH].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal:
          messages.messages.reportContractItemsPiscofins[PATCH].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(
      ReportContractItemsActions.reportContractItemsRequestFailure(error, type),
    );
  }
}

export function* updateReportHistoric({ contractId, callback, type }) {
  try {
    yield api({
      method: PUT,
      url: `${RESOURCE_REPORT_CONTRACT_ITEMS}/${contractId}/historic`,
    });

    yield put(ReportContractItemsActions.updateReportHistoricSuccess());

    yield put(
      MessagesActions.open({
        title: 'Dados atualizados com sucesso',
        type: 'success',
        contentText: 'Exibição de histórico atualizado com sucesso!',
      }),
    );

    yield callback();
  } catch (error) {
    let message =
      'Não foi possível atualizar os dados de exibição de histórico!';
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.open({
        title: 'Erro ao atualizar dados',
        type: 'success',
        contentText: message,
      }),
    );

    yield put(
      ReportContractItemsActions.reportContractItemsRequestFailure(error, type),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS,
    listReportContractItems,
  );
  yield takeLatest(
    ReportContractItemsTypes.LIST_REPORT_CONTRACT_ITEMS_FILTER,
    listReportContractItems,
  );
  yield takeLatest(
    ReportContractItemsTypes.GET_REPORT_CONTRACT_ITEMS,
    getReportContractItems,
  );
  yield takeLatest(
    ReportContractItemsTypes.UPDATE_REPORT_CONTRACT_ITEMS,
    updateReportContractItems,
  );
  yield takeLatest(
    ReportContractItemsTypes.UPDATE_REPORT_SERVICES,
    updateReportServices,
  );
  yield takeLatest(
    ReportContractItemsTypes.UPDATE_REPORT_PISCOFINS,
    updateReportPiscofins,
  );
  yield takeLatest(
    ReportContractItemsTypes.UPDATE_REPORT_HISTORIC,
    updateReportHistoric,
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, Card } from '@vli/locomotive-ds/dist';

import Table from 'components/core/Table/Table';
import Loading from 'components/core/Loading';
import TableContainer from 'components/core/TableContainer';
import { Columns } from 'components/contexts/local/refinery/refineryList/Columns/Columns';
import ModalConfirm from 'components/core/ModalConfirm';

import { isPending, isFinished } from 'lib/core/request';
import { getLocalsParams, optionsMultiVli } from 'lib/contexts/local';

import { LocalActions } from 'store/local/local.ducks';
import { DieselActions } from 'store/diesel/diesel.ducks';

import CONSTANTS from 'constants/constants';
import usePagination from 'hooks/usePagination';

const RefineryList = () => {
  const dispatch = useDispatch();

  const {
    data: station,
    editMode,
    rowChange,
    rowEdit,
    changeStatusOpen,
    editModalOpen,
    localRequests,
  } = useSelector(({ local }) => local);
  const { macrobaseList } = useSelector(({ diesel }) => diesel);
  const { paginationSize, setValuePage } = usePagination();

  const load = () => {
    const params = getLocalsParams(1, 20, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
  };

  useEffect(() => {
    const params = getLocalsParams(1, 20, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
    dispatch(DieselActions.listMacrobase());
  }, [dispatch]);

  const onPageChange = (PageIndex) => {
    const { pageSize } = station;
    const params = getLocalsParams(PageIndex, pageSize, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
  };

  const onPerPageChange = (PageSize) => {
    const { pageIndex } = station;
    const params = getLocalsParams(pageIndex, PageSize, CONSTANTS.REFINARY_ID);
    dispatch(LocalActions.listLocal(params));
    setValuePage(PageSize);
  };

  const options = optionsMultiVli(macrobaseList, rowChange, rowEdit);

  const onChangeSupplier = (data) => {
    const supplierObject = data.map((c) => ({ id: c.value.id, name: c.label }));
    const editRow = {
      ...rowEdit,
      suppliers: supplierObject,
      editMode: true,
      changeEdit: true,
    };
    dispatch(LocalActions.setChangeRow(editRow));
  };

  const closeModal = () => {
    dispatch(
      LocalActions.setEditModalOpen({
        modalOpen: false,
      }),
    );
    dispatch(LocalActions.resetRows());
    dispatch(LocalActions.setEditRow({ row: {}, editMode: false }));
  };

  const confirmAction = () => {
    closeModal();
    const payload = {
      id: rowChange.id,
      localSupplierIds: rowChange.suppliers.map((e) => e.id),
    };
    dispatch(LocalActions.updateLocal(payload, load));
  };

  const closeStatusModal = () => {
    dispatch(
      LocalActions.setChangeStatusModalOpen({
        changeStatusOpen: false,
        row: {},
      }),
    );
  };

  const confirmChange = () => {
    dispatch(LocalActions.changeStatusLocal(rowEdit.id, load));
    closeStatusModal();
  };

  const loading =
    isPending(localRequests.LIST_LOCAL) ||
    isPending(localRequests.UPDATE_LOCAL) ||
    isPending(localRequests.CHANGE_STATUS_LOCAL);

  return (
    <TableContainer data-testid="table-test">
      {loading && <Loading />}
      {!loading && station?.items?.length > 0 ? (
        <Table
          width="100%"
          tableType="responsive"
          sortBy={['name']}
          customList={paginationSize}
          isEditMode={editMode}
          data={station.items}
          columns={Columns({
            options,
            onChangeSupplier,
          })}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          pageIndex={station.currentPage}
          pageCount={station.totalPages}
          totalItems={station.totalCount}
          footer
        />
      ) : (
        isFinished(localRequests.LIST_LOCAL) &&
        !loading && (
          <Card>
            <EmptyState
              condensed={false}
              title="Nenhum refinaria encontrado."
            />
          </Card>
        )
      )}

      {editModalOpen && (
        <ModalConfirm
          closeModal={closeModal}
          confirmAction={confirmAction}
          title="Editar Base de Diesel"
          message={`Tem certeza que deseja editar a base de diesel selecionado?
          <br>
          <br>
          Base de diesel: <b>${rowChange?.suppliers
            .map((e) => e.name)
            .join(', ')}</b>`}
        />
      )}

      {changeStatusOpen && (
        <ModalConfirm
          closeModal={closeStatusModal}
          confirmAction={confirmChange}
          title="Alterar status da base de diesel"
          message={`Tem certeza que deseja alterar o status da base <b>${rowEdit.name}</b>?`}
        />
      )}
    </TableContainer>
  );
};

export default RefineryList;

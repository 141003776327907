import React from 'react';
import PropTypes from 'prop-types';
import { Text, Tokens } from '@vli/locomotive-ds/dist';
import { ReactComponent as IcMoney } from 'assets/icons/ic-money.svg';
import { ReactComponent as IcDiesel } from 'assets/icons/ic-diesel.svg';
import Tooltip from 'components/core/Tooltip';
import ReactDOMServer from 'react-dom/server';
import * as utility from 'lib/core/utility';
import TooltipInfo from './TooltipInfo';
import * as S from './CellCalculatedValue.styles';

const CellCalculatedValue = ({
  cell: { value },
  informative,
  transformedData,
  row,
  onClick,
}) => {
  const { original } = row;

  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };

  return (
    <Tooltip
      title={ReactDOMServer.renderToString(
        <TooltipInfo data={original.tooltip} />,
      )}
      id={row.id}
      place="top"
    >
      <S.Content>
        <Text
          size="medium"
          data-testid={`cell-table-${value}`}
          style={{ cursor: 'pointer' }}
          onClick={handleOnClick}
          color={original.flagFarol}
        >
          {value !== undefined && (transformedData(value) || '-')} {informative}
        </Text>

        {utility.IndiceType.INFLATION === original.historyOrigin.toString() ? (
          <IcMoney className="ic-info" />
        ) : (
          <IcDiesel className="ic-info" />
        )}
      </S.Content>
    </Tooltip>
  );
};

CellCalculatedValue.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      historyOrigin: PropTypes.number,
      tooltip: PropTypes.arrayOf(PropTypes.object),
      flagFarol: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

CellCalculatedValue.defaultProps = {
  cell: {
    value: '',
  },
  row: {
    id: 0,
    original: {
      id: 1,
      flagFarol: Tokens.colors.grayBlack,
    },
  },
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellCalculatedValue;

import { put, takeLatest } from 'redux-saga/effects';
import { LOCAL, UPDATE_LOCAL } from 'constants/endpoints';
import api from 'services/api';
import { GET, PUT, PATCH } from 'constants/verbs';
import messages from 'constants/messages';
import * as utility from 'lib/core/utility';
import { MessagesActions } from 'store/messages/messages.ducks';
import { LocalTypes, LocalActions } from './local.ducks';

export function* listLocal({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: `${LOCAL}?${payload}`,
    });
    yield put(LocalActions.listLocalSuccess(response.data.data));
  } catch (error) {
    yield put(LocalActions.localRequestFailure(error));
  }
}
export function* updateLocal({ payload, callback, type }) {
  try {
    const {
      data: { data: local },
    } = yield api({
      method: PUT,
      url: UPDATE_LOCAL,
      data: payload,
    });
    yield put(LocalActions.updateLocalSuccess(local));
    yield put(
      MessagesActions.open({
        title: messages.messages.local[PUT].title,
        type: 'success',
        contentText: messages.messages.local[PUT].message,
      }),
    );
    yield callback();
  } catch (error) {
    let message = messages.messages.local[PUT].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.local[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );

    yield put(LocalActions.localRequestFailure(message, type));
  }
}
export function* changeStatusLocal({ payload, callback, type }) {
  try {
    const {
      data: { data: local },
    } = yield api({
      method: PUT,
      url: `${UPDATE_LOCAL}/${payload}`,
    });
    yield put(LocalActions.changeStatusLocalSuccess(local));
    yield put(
      MessagesActions.open({
        title: messages.messages.local[PATCH].title,
        type: 'success',
        contentText: messages.messages.local[PATCH].message,
      }),
    );
    yield callback();
  } catch (error) {
    let message = messages.messages.local[PATCH].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.local[PATCH].errorTitle,
        typeModal: 'error',
        contentModalText: utility.formatMessages(message),
      }),
    );
    yield put(LocalActions.localRequestFailure(message, type));
  }
}

export function* localFilteredList({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: `${UPDATE_LOCAL}/filter?${payload}`,
    });
    yield put(LocalActions.localFilteredListSuccess(response.data.data));
  } catch (error) {
    yield put(LocalActions.localRequestFailure(error));
  }
}

export function* getLocal({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: `${UPDATE_LOCAL}/${payload}/history`,
    });
    yield put(LocalActions.getLocalSuccess(response.data.data));
  } catch (error) {
    yield put(LocalActions.localRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(LocalTypes.LIST_LOCAL, listLocal);
  yield takeLatest(LocalTypes.UPDATE_LOCAL, updateLocal);
  yield takeLatest(LocalTypes.CHANGE_STATUS_LOCAL, changeStatusLocal);
  yield takeLatest(LocalTypes.LOCAL_FILTERED_LIST, localFilteredList);
  yield takeLatest(LocalTypes.GET_LOCAL_REQUEST, getLocal);
}

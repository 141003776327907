import React from 'react';
import PropTypes from 'prop-types';
import { ChevronUpIcon, ChevronDownIcon } from '@vli/locomotive-ds/dist';

import * as S from './ExpanderCell.styles';

const ExpanderCell = ({ row }) => {
  const { items } = row.original;
  return (
    <S.Cell {...row.getToggleRowExpandedProps()} data-testid="expander-cell">
      {items &&
        items.length > 0 &&
        (row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />)}
    </S.Cell>
  );
};

ExpanderCell.propTypes = {
  row: PropTypes.shape({
    isExpanded: PropTypes.bool,
    original: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
      id: PropTypes.string.isRequired,
    }).isRequired,
    getToggleRowExpandedProps: PropTypes.func.isRequired,
  }),
};

ExpanderCell.defaultProps = {
  row: PropTypes.shape({
    isExpanded: false,
  }),
};

export default ExpanderCell;

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const { Types: StatusTypes, Creators: StatusActions } = createActions({
  listStatus: ['payload'],
  listStatusSuccess: ['payload'],
  statusRequestFailure: ['error'],
});

const INITIAL_STATE = {
  statusList: [],
  statusApplication: 'Healthy',
  error: null,
  statusRequests: {
    [StatusTypes.LIST_STATUS]: REQUEST_NOT_STARTED,
  },
};

const listStatus = () => ({
  ...INITIAL_STATE,
  statusRequests: {
    ...INITIAL_STATE.statusRequests,
    [StatusTypes.LIST_STATUS]: REQUEST_PENDING,
  },
});

const listStatusSuccess = (state, { payload }) => ({
  ...state,
  statusList: payload.monitors,
  status: payload.status,
  statusRequests: {
    ...state.statusRequests,
    [StatusTypes.LIST_STATUS]: REQUEST_RESOLVED,
  },
});
const statusFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  statusRequests: {
    ...state.statusRequests,
    [StatusTypes.LIST_STATUS]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [StatusTypes.LIST_STATUS]: listStatus,
  [StatusTypes.LIST_STATUS_SUCCESS]: listStatusSuccess,
  [StatusTypes.STATUS_REQUEST_FAILURE]: statusFailure,
});

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

import { groupMacrobase } from 'lib/contexts/indices';

export const { Types: DieselTypes, Creators: DieselActions } = createActions({
  listMacrobase: ['payload'],
  listMacrobaseSuccess: ['payload'],
  getSuppliers: ['params', 'isSupplierVli'],
  clearSupplierList: [],
  getSuppliersSuccess: ['payload', 'isSupplierVli'],
  dieselRequestFailure: ['error'],
});

const INITIAL_STATE = {
  macrobaseList: [],
  suppliersList: [],
  groupMacrobaseList: [],
  isSupplierVli: false,
  error: null,
  dieselRequests: {
    [DieselTypes.LIST_MACROBASE]: REQUEST_NOT_STARTED,
  },
};

const listMacrobase = () => ({
  ...INITIAL_STATE,
  dieselRequests: {
    ...INITIAL_STATE.dieselRequests,
    [DieselTypes.LIST_MACROBASE]: REQUEST_PENDING,
  },
});
const clearSupplierList = (state) => ({
  ...state,
  suppliersList: [],
});

const listMacrobaseSuccess = (state, { payload }) => ({
  ...state,
  macrobaseList: payload,
  groupMacrobaseList: groupMacrobase(payload),
  dieselRequests: {
    ...state.dieselRequests,
    [DieselTypes.LIST_MACROBASE]: REQUEST_RESOLVED,
  },
});
const getSuppliers = (state) => ({
  ...state,
  suppliersList: [],
  dieselRequests: {
    ...INITIAL_STATE.dieselRequests,
    [DieselTypes.GET_SUPPLIERS]: REQUEST_PENDING,
  },
});

const getSuppliersSuccess = (state, { payload, isSupplierVli }) => ({
  ...state,
  suppliersList: payload,
  isSupplierVli,
  dieselRequests: {
    ...state.dieselRequests,
    [DieselTypes.GET_SUPPLIERS]: REQUEST_RESOLVED,
  },
});
const dieselFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  dieselRequests: {
    ...state.dieselRequests,
    [DieselTypes.LIST_MACROBASE]: REQUEST_REJECTED,
    [DieselTypes.GET_SUPPLIERS]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [DieselTypes.LIST_MACROBASE]: listMacrobase,
  [DieselTypes.LIST_MACROBASE_SUCCESS]: listMacrobaseSuccess,
  [DieselTypes.GET_SUPPLIERS]: getSuppliers,
  [DieselTypes.GET_SUPPLIERS_SUCCESS]: getSuppliersSuccess,
  [DieselTypes.DIESEL_REQUEST_FAILURE]: dieselFailure,
  [DieselTypes.CLEAR_SUPPLIER_LIST]: clearSupplierList,
});

import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-top: 10px;
`;

export const ErrorWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
`;

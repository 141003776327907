import React from 'react';
import CellTable from 'components/core/Table/CellTable';
import CellStatus from '../CellStatus/CellStatus';

const Columns = [
  {
    Header: 'Serviço',
    accessor: 'tags',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Status',
    accessor: 'value',
    align: 'start',
    width: 100,
    Cell: (props) => <CellStatus {...props} />,
  },
  {
    Header: 'Mensagem',
    accessor: 'error',
    align: 'start',
    width: 122,
    Cell: (props) => <CellTable {...props} />,
  },
];

export default Columns;

export const handleIdPattern = (id, maskStart) => {
  const stringifyId = String(id);

  if (stringifyId.length < 5) {
    const finalId = stringifyId.padStart(5, '0');

    return maskStart + finalId;
  }

  return maskStart + stringifyId;
};

export const removeIdMask = (id) => {
  const idWithoutMaskStart = id.substring(1);

  return Number(idWithoutMaskStart);
};

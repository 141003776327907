import React from 'react';
import { Header, Flex, Container, Button } from '@vli/locomotive-ds/dist';
import Content from 'components/core/content';
import useDimensions from 'hooks/useDimensions';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import SuppliersTable from 'components/contexts/suppliers/suppliersList/SuppliersTable';

const breadcrumb = [
  {
    text: 'Fornecedores',
    href: null,
  },
];

const SuppliersList = () => {
  const dimensions = useDimensions();

  const handleNavigateToCreateNewSupplier = () => {
    history.push(ROUTES.NEW_SUPPLIER);
  };

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Fornecedor" />

          <Button
            onClick={handleNavigateToCreateNewSupplier}
            name="Novo fornecedor"
            type="button"
            data-testid="new-button"
          />
        </Flex>

        <SuppliersTable />
      </Container>
    </Content>
  );
};

export default SuppliersList;

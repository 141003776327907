import React from "react";
import PropTypes from "prop-types";
import { BellIcon, CloseIcon, ScrollBar } from "@vli/locomotive-ds";

import NotifyItem from "./Item";

import * as S from "./NotifySidebarStyle";

const NotifySidebar = ({ notifications, isOpen, onClose, onRedirectNotification, onMarkNotificationAsRead, unreadNotifications,...attrs }) => {
  const hasNotifications = notifications?.length > 0 ? true : false

  return (
    <S.NotifySidebarWrapper isOpen={isOpen} data-testid="notify-sidebar-test" {...attrs}>
      <S.NotifySidebarHeader>
        <div>
          <button onClick={onClose} data-testid="notify-sidebar-button-test">
            <CloseIcon />
          </button>

          <h3>Notificações</h3>
        </div>

        <div>
          <span>Não lidas</span>

          <S.NotifySidebarUnread>
            <BellIcon />
            {unreadNotifications > 0 && <div data-testid="notify-sidebar-unread-test">{unreadNotifications}</div>}
          </S.NotifySidebarUnread>
        </div>
      </S.NotifySidebarHeader>

      <S.NotifySidebarContent data-testid="notify-sidebar-content-test">
        {hasNotifications ? (
          <ScrollBar
            height="90vh"
            overflow={false}
            overflowX={false}
            overflowY
            width="100%"
          >
            {notifications?.map(notification => (
              <NotifyItem 
                key={notification.id} 
                data={notification}
                onRedirectNotification={onRedirectNotification}
                onMarkNotificationAsRead={onMarkNotificationAsRead}
              /> 
            ))}
          </ScrollBar>
        ) : (
          <S.NotifySidebarEmpty data-testid="notify-sidebar-empty-test">
            <span>Você não tem nenhuma notificação</span>
          </S.NotifySidebarEmpty>
        )}
      </S.NotifySidebarContent>
    </S.NotifySidebarWrapper>
  );
}

NotifySidebar.propTypes = {
  notifications: PropTypes.array,
  unreadNotifications: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRedirectNotification: PropTypes.func,
  onMarkNotificationAsRead: PropTypes.func,
}

NotifySidebar.default = {
  notifications: [],
  unreadNotifications: 0,
  isOpen: true,
  onClose: () => {},
  onClickNotification: () => {},
}

export default NotifySidebar;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Flex, Text, ChevronUpIcon } from '@vli/locomotive-ds';

import { ContainerProfile, BodyNav } from './profile.style';

const Profile = ({
  userName,
  avatar,
  profile,
  segment,
  children,
  open,
  ...attrs
}) => {
  const [displayClass, setDisplayClass] = useState('hide');
  const dropdownRef = useRef();
  useEffect(() => {
    const selectNode = dropdownRef.current;
    if (selectNode) {
      document.addEventListener('click', (e) => {
        if (e.target !== selectNode && !selectNode.contains(e.target)) {
          setDisplayClass('hide');
        }
      });
    }
  }, []);

  const hasAvatar = !!avatar;

  return (
    <ContainerProfile
      {...attrs}
      ref={dropdownRef}
      id="profile"
      data-testid="profile"
    >
      <nav>
        <BodyNav
          onClick={() => setDisplayClass(displayClass === 'hide' ? '' : 'hide')}
        >
          <Flex alignItems="center" justifyContent="flex-start" width="100%">
            {hasAvatar && avatar}
            <div>
              {displayClass !== 'hide' && (
                <Text
                  ml={avatar && 2}
                  style={{
                    fontSize: '10px',
                    maxWidth: '22ch',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {segment.join(' ')}
                </Text>
              )}
              <Text
                title={userName}
                ml={avatar && 2}
                variant="bold"
                className="username"
              >
                {userName}
              </Text>

              <Text
                ml={avatar && 2}
                style={{ fontSize: '9px' }}
                className="username"
              >
                {profile}
              </Text>
            </div>

            <Icon
              src={ChevronUpIcon}
              className={`toggle ${displayClass}`}
              viewBox="0 0 16 16"
            />
          </Flex>
        </BodyNav>
        <ul className={displayClass}>{children}</ul>
      </nav>
    </ContainerProfile>
  );
};

Profile.propTypes = {
  avatar: PropTypes.node,
  userName: PropTypes.string,
  segment: PropTypes.string,
  profile: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
};

Profile.defaultProps = {
  avatar: '',
  userName: 'User Name',
  segment: [],
  profile: '',
  children: '',
  open: false,
};

export default Profile;

import css from '@styled-system/css';
import { Tokens as theme } from '@vli/locomotive-ds/dist';
import styled from 'styled-components';
import { compose, flexbox, layout, space, variant } from 'styled-system';

export const FilterStyle = styled('div')(
  css({
    display: 'flex',
  }),
  compose(space, flexbox, layout),
);
export const ButtonContent = styled('div')(
  css({
    display: 'flex',
    alignItems: 'center',
  }),
);

export const ButtonFilter = styled('button')(
  css({
    width: 'auto',
    height: '32px',

    display: 'block',
    backgroundColor: 'transparent',
    border: 'none',
    ':hover': {
      background: 'transparent',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  }),
  ({ btnMtop }) =>
    btnMtop &&
    css({
      marginTop: btnMtop,
    }),
  ({ btnMright }) =>
    btnMright &&
    css({
      marginRight: btnMright,
    }),
  variant({
    variants: {
      focus: {
        background: 'transparent',
        borderRadius: '4px',
      },
    },
  }),
);

export const ItemCounter = styled('div')(
  css({
    font: theme.fonts.textRegularSmall,
    position: 'absolute',
    margin: '-30px 12px 0px',
    width: '16px',
    height: '16px',
    color: 'white',
    background: '#BB581C',
    borderRadius: '100%',
  }),
);

export const OptionsList = styled('ul')(
  css({
    position: 'fixed',
    display: 'inline-table',
    zIndex: 1000,
    padding: '20px 0px',
    userSelect: 'none',
    bg: theme.colors.grayWhite,
    marginTop: '10px',
    borderRadius: '4px',
    boxShadow:
      '0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12)',
    overflow: 'auto',
    right: '32px',
    '::-webkit-scrollbar': {
      width: '4px',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
      '&:hover': {
        width: '10px',
        cursor: 'pointer',
        bg: theme.colors.accentPrimaryLight,
      },
    },
    '::-webkit-scrollbar-thumb': {
      bg: theme.colors.accentPrimaryDark,
    },
  }),
);

export const GroupItem = styled('li')(
  css({
    position: 'relative',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 11px',
    cursor: 'pointer',
    '&:hover': {
      bg: `${theme.colors.accentPrimaryLight}50`,
      input: { borderColor: theme.colors.accentPrimaryDark },
      label: { color: theme.colors.accentPrimaryDark },
    },
    label: {
      cursor: 'pointer',
    },
  }),
  ({ selected }) =>
    selected &&
    css({
      label: { color: theme.colors.accentPrimaryDark },
    }),
  ({ focused }) =>
    focused && css({ bg: `${theme.colors.accentPrimaryLight}50` }),
);

export const GroupTitle = styled('span')(
  css({
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    font: theme.fonts.textRegularSmall,
    color: '#6D6D6D',
    height: '32px',
    paddingLeft: 16,
  }),
);

export const Group = styled('ul')(
  css({
    position: 'relative',
    userSelect: 'none',
    listStyle: 'none inside none',
    margin: '0 0 16px 0',
    padding: 0,
    display: 'block',
    paddingLeft: 2,
    paddingRight: 2,
  }),
);

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 34px 22px 0px 4px;
  > div:first-child {
    display: flex;
    width: 400px;
    justify-content: space-between;
  }
`;

export const Status = styled.div`
  background-color: ${({ color }) => color};
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin-right: 14px;
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: 10px;
`;
export const ContainerCheckBox = styled.div`
  label:first-child {
    margin-right: 10px;
    margin-left: 10px;
  }
`;
export const ContainerSwitchBox = styled.div`
  label:first-child {
    margin-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
  }
`;
export const ContanerInputDate = styled.div`
  ${({ error }) =>
    error &&
    `div > div.react-date-picker__wrapper {
  border: 1px solid ${theme.colors.red400};
}`}
`;

export const MessageError = styled.div`
  position: absolute;
  bottom: -35px;
`;

export const BlurBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 1000;
  right: 0;
  top: 0;
  opacity: 0.5;
`;

import React from 'react';
import { Header, Flex, Container } from '@vli/locomotive-ds/dist';
import Content from 'components/core/content';
import useDimensions from 'hooks/useDimensions';
import * as ROUTES from 'constants/urls';
import FormulasList from './FormulasList';

const Formulas = () => {
  const dimensions = useDimensions();
  const breadcrumb = [
    {
      text: 'Fórmulas',
      href: `#${ROUTES.FORMULAS}`,
    },
  ];

  return (
    <Content height={dimensions.height} data-testid="formulas-content-test">
      <Container>
        <Flex>
          <Header bread={breadcrumb} tag="header" title="Fórmulas" />
        </Flex>
        <FormulasList />
      </Container>
    </Content>
  );
};

export default Formulas;

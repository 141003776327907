import { Card, EmptyState } from '@vli/locomotive-ds/dist';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/core/Loading';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';

import { getFilterParams } from 'lib/contexts/reportContractItems';
import { isFinished, isPending } from 'lib/core/request';

import { ReportContractItemsActions } from 'store/reportContractItems/reportContractItems.ducks';

import usePagination from 'hooks/usePagination';

import Columns from './Columns';

const ReportContractsList = () => {
  const dispatch = useDispatch();
  const {
    reportContractItemsList,
    filter,
    reportContractItemsRequests,
  } = useSelector(({ reportContractItems }) => reportContractItems);
  const { paginationSize, setValuePage } = usePagination();

  const onPageChange = (PageIndex) => {
    const { pageSize } = reportContractItemsList;
    const params = getFilterParams({
      pageIndex: PageIndex,
      pageSize,
      ...filter,
    });
    dispatch(ReportContractItemsActions.listReportContractItems(params));
  };

  const onPerPageChange = (PageSize) => {
    const { pageIndex } = reportContractItemsList;
    const params = getFilterParams({
      pageIndex,
      pageSize: PageSize,
      ...filter,
    });
    dispatch(ReportContractItemsActions.listReportContractItems(params));
    setValuePage(PageSize);
  };

  useEffect(() => {
    dispatch(ReportContractItemsActions.listReportContractItems(''));
  }, [dispatch]);

  const pending =
    isPending(reportContractItemsRequests.LIST_REPORT_CONTRACT_ITEMS) ||
    isPending(reportContractItemsRequests.UPDATE_REPORT_SERVICES);

  return (
    <TableContainer data-testid="report-contract-test">
      {pending && <Loading />}
      {reportContractItemsList.items?.length ? (
        <Table
          data-testid="report-contract-table-test"
          width="100%"
          columns={Columns}
          data={reportContractItemsList.items}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          footer
          customList={paginationSize}
          pageIndex={reportContractItemsList.currentPage}
          pageCount={reportContractItemsList.totalPages}
          totalItems={reportContractItemsList.totalCount}
        />
      ) : (
        isFinished(reportContractItemsRequests.LIST_REPORT_CONTRACT_ITEMS) &&
        reportContractItemsList.items?.length === 0 && (
          <Card>
            <EmptyState condensed={false} title="Nenhum contrato encontrado." />
          </Card>
        )
      )}
    </TableContainer>
  );
};

export default ReportContractsList;

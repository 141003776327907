import styled from 'styled-components';

export const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;

  transform: rotate(${({ isInverted }) => (isInverted ? '180deg' : '0deg')});
`;

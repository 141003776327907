import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { modeEditRow, modeChangeRow } from 'lib/contexts/local';

export const { Types: LocalTypes, Creators: LocalActions } = createActions({
  listLocal: ['payload', 'filter'],
  listLocalSuccess: ['payload', 'filter'],
  localRequestFailure: ['error', 'originalType'],
  setEditRow: ['payload'],
  setChangeRow: ['payload'],
  resetRows: ['payload'],
  setEditModalOpen: ['payload'],
  setChangeStatusModalOpen: ['payload'],
  updateLocal: ['payload', 'callback'],
  updateLocalSuccess: ['payload'],
  changeStatusLocal: ['payload', 'callback'],
  changeStatusLocalSuccess: ['payload'],
  localFilteredList: ['payload'],
  localFilteredListSuccess: ['payload'],
  getLocalRequest: ['payload'],
  getLocalSuccess: ['payload'],
  setHistoryModalOpen: ['payload'],
});

const INITIAL_STATE = {
  data: {},
  filter: {},
  editMode: false,
  editModalOpen: false,
  changeStatusOpen: false,
  localData: [],
  historyModalOpen: false,
  error: null,
  localRequests: {
    [LocalTypes.LIST_LOCAL]: REQUEST_NOT_STARTED,
    [LocalTypes.GET_LOCAL_REQUEST]: REQUEST_NOT_STARTED,
    [LocalTypes.UPDATE_LOCAL]: REQUEST_NOT_STARTED,
    [LocalTypes.CHANGE_STATUS_LOCAL]: REQUEST_NOT_STARTED,
  },
};

const listLocal = () => ({
  ...INITIAL_STATE,
  localRequests: {
    ...INITIAL_STATE.localRequests,
    [LocalTypes.LIST_LOCAL]: REQUEST_PENDING,
  },
});

const listLocalSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.LIST_LOCAL]: REQUEST_RESOLVED,
  },
});
const updateLocal = (state) => ({
  ...state,
  message: null,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.UPDATE_LOCAL]: REQUEST_PENDING,
  },
});
const updateLocalSuccess = (state) => ({
  ...state,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.UPDATE_LOCAL]: REQUEST_RESOLVED,
  },
});
const changeStatusLocal = (state) => ({
  ...state,
  message: null,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.CHANGE_STATUS_LOCAL]: REQUEST_PENDING,
  },
});
const changeStatusLocalSuccess = (state) => ({
  ...state,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.CHANGE_STATUS_LOCAL]: REQUEST_RESOLVED,
  },
});
const localRequestFailure = (state, { error: { response }, originalType }) => ({
  ...state,
  error: response,
  localRequests: {
    ...state.localRequests,
    [originalType]: REQUEST_REJECTED,
  },
});
const setEditRow = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    items: modeEditRow(state.data.items, payload.row, payload.editMode),
  },
  rowEdit: payload.row,
  editMode: payload.editMode,
});
const setChangeRow = (state, { payload }) => ({
  ...state,
  rowChange: payload,
  data: {
    ...state.data,
    items: modeChangeRow(state.data.items, state.rowEdit, payload),
  },
});
const resetRows = (state) => ({
  ...state,
  rowChange: {},
  data: {
    ...state.data,
    items: modeChangeRow(state.data.items, state.rowEdit, state.rowEdit),
  },
});
const setEditModalOpen = (state, { payload }) => ({
  ...state,
  editModalOpen: payload.modalOpen,
});
const setChangeStatusModalOpen = (state, { payload }) => ({
  ...state,
  changeStatusOpen: payload.changeStatusOpen,
  rowEdit: payload.row,
});

const localFilteredList = () => ({
  ...INITIAL_STATE,
  localRequests: {
    ...INITIAL_STATE.localRequests,
    [LocalTypes.LOCAL_FILTERED_LIST]: REQUEST_PENDING,
  },
});
const localFilteredListSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.LOCAL_FILTERED_LIST]: REQUEST_RESOLVED,
  },
});

const setHistoryModalOpen = (state, { payload }) => ({
  ...state,
  historyModalOpen: payload.modalOpen,
});

const getLocalRequest = (state) => ({
  ...state,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.GET_LOCAL_REQUEST]: REQUEST_PENDING,
  },
});
const getLocalSuccess = (state, { payload }) => ({
  ...state,
  localData: payload,
  localRequests: {
    ...state.localRequests,
    [LocalTypes.GET_LOCAL_REQUEST]: REQUEST_RESOLVED,
  },
});

export default createReducer(INITIAL_STATE, {
  [LocalTypes.LIST_LOCAL]: listLocal,
  [LocalTypes.LIST_LOCAL_SUCCESS]: listLocalSuccess,
  [LocalTypes.LOCAL_REQUEST_FAILURE]: localRequestFailure,
  [LocalTypes.SET_EDIT_ROW]: setEditRow,
  [LocalTypes.SET_CHANGE_ROW]: setChangeRow,
  [LocalTypes.RESET_ROWS]: resetRows,
  [LocalTypes.SET_EDIT_MODAL_OPEN]: setEditModalOpen,
  [LocalTypes.SET_HISTORY_MODAL_OPEN]: setHistoryModalOpen,
  [LocalTypes.UPDATE_LOCAL]: updateLocal,
  [LocalTypes.UPDATE_LOCAL_SUCCESS]: updateLocalSuccess,
  [LocalTypes.CHANGE_STATUS_LOCAL]: changeStatusLocal,
  [LocalTypes.CHANGE_STATUS_LOCAL_SUCCESS]: changeStatusLocalSuccess,
  [LocalTypes.SET_CHANGE_STATUS_MODAL_OPEN]: setChangeStatusModalOpen,
  [LocalTypes.LOCAL_FILTERED_LIST]: localFilteredList,
  [LocalTypes.LOCAL_FILTERED_LIST_SUCCESS]: localFilteredListSuccess,
  [LocalTypes.GET_LOCAL_REQUEST]: getLocalRequest,
  [LocalTypes.GET_LOCAL_SUCCESS]: getLocalSuccess,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@vli/locomotive-ds/dist';
import Tooltip from 'components/core/Tooltip';

const CellStatusTable = ({
  cell: { value },
  informative,
  transformedData,
  row,
  onClick,
}) => {
  const { original } = row;
  const handleOnClick = () => {
    if (original) {
      onClick(original);
    }
    return null;
  };

  return (
    <Tooltip title={original.statusTooltip?.toString()} place="top">
      <Text
        size="medium"
        data-testid={`cell-table-${value}`}
        style={{ cursor: 'pointer', fontWeight: '400' }}
        onClick={handleOnClick}
      >
        {transformedData(value) || '-'} {informative}
      </Text>
    </Tooltip>
  );
};

CellStatusTable.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  informative: PropTypes.string,
  transformedData: PropTypes.func,
  onClick: PropTypes.func,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      statusTooltip: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CellStatusTable.defaultProps = {
  cell: {
    value: '',
  },
  informative: '',
  transformedData: (text) => text,
  onClick: () => {},
};

export default CellStatusTable;

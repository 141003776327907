import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@vli/locomotive-ds';

import { ReactComponent as OutsideLinkIcon } from 'assets/icons/outside-link.svg';

import * as S from "./ExternalLink.styles"

const ExternalLink = ({ url, label }) => {
  return (
    <Flex alignItems="center">
      {label}
      <S.Link href={url} target="_blank" rel="noopener noreferrer">
        <OutsideLinkIcon />
      </S.Link>
    </Flex>
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
};

export default ExternalLink;

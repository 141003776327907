import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from 'constants/request';

export const {
  Types: SupplierTypes,
  Creators: SupplierActions,
} = createActions({
  listVliSuppliers: [''],
  listVliSuppliersSuccess: ['payload'],
  getSupplier: ['params'],
  getSupplierSuccess: ['payload'],
  createSupplier: ['payload'],
  createSupplierSuccess: ['payload'],
  updateSupplier: ['payload'],
  updateSupplierSuccess: ['payload'],
  updateSupplierStatus: ['params', 'callback'],
  updateSupplierStatusSuccess: ['payload'],
  supplierRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  supplierList: [],
  error: null,
  details: null,
  supplierRequests: {
    [SupplierTypes.LIST_VLI_SUPPLIERS]: REQUEST_NOT_STARTED,
  },
};

const listVliSuppliers = () => ({
  ...INITIAL_STATE,
  supplierRequests: {
    ...INITIAL_STATE.supplierRequests,
    [SupplierTypes.LIST_VLI_SUPPLIERS]: REQUEST_PENDING,
  },
});

const listVliSuppliersSuccess = (state, { payload }) => ({
  ...state,
  supplierList: payload,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.LIST_VLI_SUPPLIERS]: REQUEST_RESOLVED,
  },
});

const getSupplier = (state) => ({
  ...state,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.GET_SUPPLIER]: REQUEST_PENDING,
  },
});

const getSupplierSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.GET_SUPPLIER]: REQUEST_RESOLVED,
  },
});

const createSupplier = (state) => ({
  ...state,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.CREATE_SUPPLIER]: REQUEST_PENDING,
  },
});

const createSupplierSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.CREATE_SUPPLIER]: REQUEST_RESOLVED,
  },
});

const updateSupplier = (state) => ({
  ...state,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.UPDATE_SUPPLIER]: REQUEST_PENDING,
  },
});

const updateSupplierSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.UPDATE_SUPPLIER]: REQUEST_RESOLVED,
  },
});

const updateSupplierStatus = (state) => ({
  ...state,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.UPDATE_SUPPLIER_STATUS]: REQUEST_PENDING,
  },
});

const updateSupplierStatusSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.UPDATE_SUPPLIER_STATUS]: REQUEST_RESOLVED,
  },
});

const supplierRequestFailure = (
  state,
  { error: { response }, originalType },
) => ({
  ...state,
  error: response,
  supplierRequests: {
    ...state.supplierRequests,
    [SupplierTypes.LIST_VLI_SUPPLIERS]: REQUEST_REJECTED,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [SupplierTypes.LIST_VLI_SUPPLIERS]: listVliSuppliers,
  [SupplierTypes.LIST_VLI_SUPPLIERS_SUCCESS]: listVliSuppliersSuccess,
  [SupplierTypes.GET_SUPPLIER]: getSupplier,
  [SupplierTypes.GET_SUPPLIER_SUCCESS]: getSupplierSuccess,
  [SupplierTypes.CREATE_SUPPLIER]: createSupplier,
  [SupplierTypes.CREATE_SUPPLIER_SUCCESS]: createSupplierSuccess,
  [SupplierTypes.UPDATE_SUPPLIER]: updateSupplier,
  [SupplierTypes.UPDATE_SUPPLIER_SUCCESS]: updateSupplierSuccess,
  [SupplierTypes.UPDATE_SUPPLIER_STATUS]: updateSupplierStatus,
  [SupplierTypes.UPDATE_SUPPLIER_STATUS_SUCCESS]: updateSupplierStatusSuccess,
  [SupplierTypes.SUPPLIER_REQUEST_FAILURE]: supplierRequestFailure,
});

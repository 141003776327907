import { Tokens } from '@vli/locomotive-ds';
import styled from 'styled-components';

export const ItemLine = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  gap: 16px;

  & + div {
    padding-top: ${Tokens.spacing.lg};
    border-top: 1px solid ${Tokens.colors.gray200};
  }
`;

export const Title = styled.span`
  font: ${Tokens.fonts.textRegularMedium};
  color: ${Tokens.colors.gray500};
  font-weight: 400;
`;

export const Content = styled.span`
  font: ${Tokens.fonts.textRegularMedium};
  color: ${Tokens.colors.gray300};
  font-weight: 400;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Action = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;

  display: flex;
  align-items: center;
  gap: 10px;

  transition: all 0.3s;
  cursor: pointer;

  span {
    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;
    color: ${Tokens.colors.accentPrimaryDark};
  }

  svg path {
    fill: #3777bc;
  }

  &:hover {
    opacity: 0.8;
  }
`;

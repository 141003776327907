import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, Card } from '@vli/locomotive-ds/dist';

import Loading from 'components/core/Loading';
import TableContainer from 'components/core/TableContainer';
import Table from 'components/core/Table';
import ReactivationModal from 'components/contexts/reactivateItems/ReactivationModal/ReactivationModal';

import { isPending, isFinished } from 'lib/core/request';
import { getFilterParams } from 'lib/contexts/reactivateItems';

import usePagination from 'hooks/usePagination';

import { ReactivateItemsActions } from 'store/reactivateItems/reactivateItems.ducks';

import Columns from '../Columns';

const ReactivateItemsList = () => {
  const [selectedItems, setSelectedItems] = useState({});
  const {
    reactivateItemsRequests,
    reactivateItemsList,
    isModalOpen,
  } = useSelector(({ reactivateItems }) => reactivateItems);
  const dispatch = useDispatch();
  const { paginationSize, setValuePage } = usePagination();

  const loading =
    isPending(reactivateItemsRequests.REACTIVATE_ITEMS_LIST_REQUEST) ||
    isPending(reactivateItemsRequests.REACTIVATE_ITEM_REQUEST);

  const clearSelections = () => setSelectedItems({});

  const onPageChange = (PageIndex) => {
    const { pageSize } = reactivateItemsList;
    const params = getFilterParams({
      pageIndex: PageIndex,
      pageSize,
    });

    setSelectedItems({});
    dispatch(ReactivateItemsActions.reactivateItemsListRequest(params));
  };

  const onPerPageChange = (PageSize) => {
    const params = getFilterParams({
      pageSize: PageSize,
    });

    setSelectedItems({});
    setValuePage(PageSize);
    dispatch(ReactivateItemsActions.reactivateItemsListRequest(params));
  };

  const handleCheck = useCallback(({ id, code, isChecked }) => {
    setSelectedItems((items) => {
      if (isChecked) {
        return {
          ...items,
          [id]: code,
        };
      }

      const otherItems = items;
      delete otherItems?.[id];
      return { ...otherItems };
    });
  }, []);

  return (
    <TableContainer>
      {loading && <Loading />}
      {reactivateItemsList?.items?.length > 0 ? (
        <Table
          data-testid="reactivateItemlist-test"
          width="100%"
          columns={Columns(selectedItems, handleCheck)}
          data={reactivateItemsList?.items}
          sortBy={['createdAt']}
          footer
          customList={paginationSize}
          pageIndex={reactivateItemsList.currentPage}
          pageCount={reactivateItemsList.totalPages}
          totalItems={reactivateItemsList.totalCount}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
      ) : (
        isFinished(reactivateItemsRequests.REACTIVATE_ITEMS_LIST_REQUEST) && (
          <Card>
            <EmptyState condensed={false} title="Nenhum item encontrado." />
          </Card>
        )
      )}
      {isModalOpen && (
        <ReactivationModal
          codes={Object.values(selectedItems)}
          ids={Object.keys(selectedItems)}
          onSubmit={clearSelections}
        />
      )}
    </TableContainer>
  );
};

export default ReactivateItemsList;

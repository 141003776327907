import React from 'react';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { FareType } from 'lib/contexts/contracts';
import { EmptyState } from '@vli/locomotive-ds';
import PriceAction from './Modals/PriceAction';
import { DefaultTable, GrainSegmentTable } from './Tables';

const ContractDetail = () => {
  const { id } = useParams();
  const { contractDetails, priceActions } = useSelector(
    ({ contractDetail }) => contractDetail,
  );

  const renderTable = () => {
    if (!contractDetails?.details?.id)
      return (
        <EmptyState
          condensed={false}
          title="Não foi possível carregar o contrato."
        />
      );

    if (contractDetails.details.fareType === FareType.FIXED_TARIFF) {
      return <DefaultTable />;
    }
    return <GrainSegmentTable />;
  };

  return (
    <>
      {renderTable()}
      {priceActions.modal && (
        <PriceAction id={id} priceActions={priceActions} />
      )}
    </>
  );
};

export default ContractDetail;

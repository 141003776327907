import styled from 'styled-components';
import { Tokens, Modal } from '@vli/locomotive-ds';

export const CommentModal = styled(Modal)`
  gap: ${Tokens.spacing.md};

  #divider {
    display: none;
  }
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Tokens.spacing.sm};

  margin-bottom: ${Tokens.spacing.md};

  max-height: 280px;
  overflow-y: auto;
  scroll-behavior: smooth;

  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${Tokens.colors.accentPrimaryLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${Tokens.spacing.xs};
`;

export const InputField = styled.div`
  width: 576px;

  display: flex;
  justify-content: space-between;
  gap: ${Tokens.spacing.xs};

  border: 1px solid ${Tokens.colors.accentPrimaryDark};
  padding-left: ${Tokens.spacing.sm};
  border-radius: ${Tokens.spacing.xs};

  textarea {
    appearance: none;
    border: none;
    resize: none;
    padding: ${Tokens.spacing.xs} 0;

    width: 100%;
    max-height: 48px;

    font: ${Tokens.fonts.textRegularMedium};
    font-weight: 400;
    color: ${Tokens.colors.gray500};

    &::placeholder {
      color: ${Tokens.colors.gray300};
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: ${Tokens.colors.accentPrimaryLight};
    }

    &::-webkit-scrollbar-thumb {
      background: ${Tokens.colors.accentPrimaryDark};
    }
  }

  button {
    display: flex;
    align-items: center;

    outline: none;
    cursor: pointer;

    width: fit-content;

    border-radius: 4px;
    border: 0;
    padding: ${Tokens.spacing.xs};
    box-shadow: none;

    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    font: ${Tokens.fonts.textRegularLarge};

    background-color: transparent;
    color: ${Tokens.colors.accentPrimaryDark};
  }
`;

export const Characters = styled.span`
  font: ${Tokens.fonts.textRegularSmall};
  font-weight: 400;
  font-size: 10px;
  color: ${Tokens.colors.gray200};
  padding: 0 ${Tokens.spacing.xs};
`;

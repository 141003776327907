import { put, takeLatest } from 'redux-saga/effects';
import {
  RAILWAY_ITEMS_HISTORY,
  GET_CURVED_FARE,
  CLOSE_RAILWAY_ITEM,
} from 'constants/endpoints';
import api from 'services/api';
import { GET, PUT } from 'constants/verbs';

import { MessagesActions } from 'store/messages/messages.ducks';
import {
  RailwayItemsHistoryTypes,
  RailwayItemsHistoryActions,
} from './railwayItemsHistory.ducks';

export function* listRailwayItemsHistory({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: RAILWAY_ITEMS_HISTORY,
      params,
    });
    const getData = response.data.data;
    const format = response.data.data.historyList.map((item) => ({
      subRows: item.items.map((sub) => {
        delete sub.endDate;
        delete sub.applicationDate;
        return {
          ...sub,
        };
      }),
      ...item,
    }));
    getData.historyList = format;
    yield put(
      RailwayItemsHistoryActions.listRailwayItemsHistorySuccess(getData),
    );
  } catch (error) {
    yield put(
      RailwayItemsHistoryActions.railwayItemsHistoryRequestFailure(error),
    );
  }
}

export function* getCurvedFare({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: GET_CURVED_FARE,
      params,
    });
    if (response.data.data.curvedFares.length > 0) {
      yield put(
        RailwayItemsHistoryActions.getCurvedFareSuccess(response.data.data),
      );
    } else {
      yield put(RailwayItemsHistoryActions.getCurvedFareEmpty());
    }
  } catch (error) {
    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha',
        typeModal: 'error',
        contentModalText: 'Não foi possível encontrar o dado',
      }),
    );
    yield put(
      RailwayItemsHistoryActions.railwayItemsHistoryRequestFailure(error),
    );
  }
}

export function* closeRailwayItem({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: CLOSE_RAILWAY_ITEM,
      data: payload,
    });

    yield put(RailwayItemsHistoryActions.closeRailwayItemSuccess());

    yield callback();
  } catch (error) {
    const message =
      error?.response?.data?.errors || 'Não foi possível encerrar esse item';

    yield put(
      MessagesActions.openModal({
        titleModal: 'Falha ao encerrar item',
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(RailwayItemsHistoryActions.closeRailwayItemFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(
    RailwayItemsHistoryTypes.LIST_RAILWAY_ITEMS_HISTORY,
    listRailwayItemsHistory,
  );
  yield takeLatest(RailwayItemsHistoryTypes.GET_CURVED_FARE, getCurvedFare);
  yield takeLatest(
    RailwayItemsHistoryTypes.CLOSE_RAILWAY_ITEM,
    closeRailwayItem,
  );
}

import React from 'react';

import { Card, Heading, Tokens } from '@vli/locomotive-ds/dist';

import StatusList from './Table';

import * as S from './Status.styles';

const StatusContent = () => (
  <Card
    style={{
      height: '100%',
    }}
  >
    <S.CardHeader>
      <Heading tag="h5" color={Tokens.colors.gray300} variant={['bold']}>
        Health Check
      </Heading>
    </S.CardHeader>

    <S.Container>
      <StatusList />
    </S.Container>
  </Card>
);

export default StatusContent;

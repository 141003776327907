import React from 'react';
import PropTypes from 'prop-types';
import Approve from './Approve';
import Negociation from './Negociation';
import Send from './Send';

const PriceAction = ({ id, priceActions }) => {
  switch (priceActions.action) {
    case 'approve':
      return <Approve id={id} />;
    case 'negociation':
      return <Negociation id={id} />;
    case 'send':
      return <Send id={id} />;

    default:
      return null;
  }
};
PriceAction.defaultProps = {
  id: '',
  priceActions: {
    action: null,
  },
};
PriceAction.propTypes = {
  id: PropTypes.string,
  priceActions: PropTypes.shape({
    action: PropTypes.string.isRequired,
  }),
};
export default PriceAction;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {
  addDays,
  subDays,
  getDate,
  getMonth,
  getYear,
  format,
  differenceInYears,
  differenceInDays,
} from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import InputSelect from 'components/form/InputSelect';

import { FormalizationRequestActions } from 'store/formalizationRequest/formalizationRequest.ducks';

import {
  TYPE_FORMALIZATION,
  TYPE_FORMALIZATION_OPTIONS,
  APPLICATION_DATE_OPTIONS,
} from '../../constants';

import * as S from './SectionsStyled';

const PriceParametrics = ({
  disableFields,
  isFieldRequired,
  instrumentReference,
  checkIsOneYear,
  checkIsLessThanMonth,
  isOnlyHarbor,
  hasModal,
}) => {
  const dispatch = useDispatch();
  const { control, watch, errors, setValue } = useFormContext();

  const { isAdditiveFill } = useSelector(
    ({ formalizationRequest }) => formalizationRequest,
  );

  const [firstReadjustmentOptions, setFirstReadjustmentOptions] = useState([]);

  const typeFormalization = watch('parametrics.typeFormalization');
  const startDate = watch('parametrics.startDate');
  const endDate = watch('parametrics.endDate');
  const applicationDateDay = watch('parametrics.applicationDateDay');

  const minDate = startDate ? addDays(new Date(startDate), 1) : null;
  const maxDate = endDate ? subDays(new Date(endDate), 1) : null;

  const isAdditiveSelected = typeFormalization === TYPE_FORMALIZATION.ADDITIVE;

  const isLaterThanStartDate = (value) => {
    if (!value && !isFieldRequired) {
      return true;
    }

    return new Date(value).getTime() > new Date(startDate).getTime();
  };

  const createFirstReadjustmentDateOptions = useCallback(() => {
    if (!applicationDateDay) {
      return [];
    }

    const startDateDay = getDate(new Date(startDate));
    const startDateMonth = getMonth(new Date(startDate));
    const startDateYear = getYear(new Date(startDate));
    const firstReadjustmentDateMonths = [];

    if (applicationDateDay <= startDateDay) {
      firstReadjustmentDateMonths.push(startDateMonth + 1);
    } else if (applicationDateDay > startDateDay) {
      firstReadjustmentDateMonths.push(startDateMonth);
      firstReadjustmentDateMonths.push(startDateMonth + 1);
    }

    const options = firstReadjustmentDateMonths.map((month) => {
      return {
        label: format(
          new Date(startDateYear, month, applicationDateDay),
          'dd/MM',
        ),
        value: format(
          new Date(startDateYear, month, applicationDateDay),
          'yyyy-MM-dd',
        ),
      };
    });

    return options;
  }, [applicationDateDay, startDate]);

  useEffect(() => {
    const options = createFirstReadjustmentDateOptions();

    setFirstReadjustmentOptions(options);
  }, [createFirstReadjustmentDateOptions]);

  useEffect(() => {
    if (isAdditiveSelected) {
      setValue('parametrics.instrumentReference', instrumentReference || '');
    }
  }, [isAdditiveSelected, instrumentReference, setValue]);

  useEffect(() => {
    if (typeFormalization === TYPE_FORMALIZATION.ADDITIVE && !hasModal) {
      dispatch(
        FormalizationRequestActions.setAdditiveFill({ isAdditiveFill: true }),
      );
    }
  }, [typeFormalization, hasModal, dispatch]);

  const isWeekend = (data) => {
    if (data.view === 'month') {
      const dayOfWeek = data.date.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6;
    }

    return false;
  };

  const isGreaterThanOrEqualToOneYear =
    differenceInYears(new Date(endDate), new Date(startDate)) >= 1;
  const isLessThanOneMonth =
    differenceInDays(new Date(endDate), new Date(startDate)) <= 31;

  useEffect(() => {
    checkIsOneYear(isGreaterThanOrEqualToOneYear);
  }, [isGreaterThanOrEqualToOneYear, checkIsOneYear]);

  useEffect(() => {
    checkIsLessThanMonth(isLessThanOneMonth);
  }, [isLessThanOneMonth, checkIsLessThanMonth]);

  return (
    <S.FormGrid data-testid="form-section-parametrics-test">
      <S.InputContainer>
        <Controller
          name="parametrics.startDate"
          control={control}
          rules={{ required: isFieldRequired }}
          render={({ ...field }) => (
            <S.DateInput
              {...field}
              label="Início da Vigência do Instrumento"
              format="dd/MM/yyyy"
              maxDate={maxDate}
              disabled={disableFields}
            />
          )}
        />

        {errors?.parametrics?.startDate && (
          <S.ErrorMessage>
            {errors.parametrics.startDate.message}
          </S.ErrorMessage>
        )}
      </S.InputContainer>

      <S.InputContainer>
        <Controller
          name="parametrics.endDate"
          control={control}
          rules={{
            required: isFieldRequired,
            validate: {
              isLaterThanStartDate,
            },
          }}
          render={({ ...field }) => (
            <S.DateInput
              {...field}
              label="Fim da Vigência do Instrumento"
              format="dd/MM/yyyy"
              minDate={minDate}
              disabled={disableFields}
              tileDisabled={(data) => isWeekend(data)}
            />
          )}
        />

        {errors?.parametrics?.endDate && (
          <S.ErrorMessage>{errors.parametrics.endDate.message}</S.ErrorMessage>
        )}
      </S.InputContainer>

      <S.InputContainer>
        <Controller
          name="parametrics.typeFormalization"
          control={control}
          rules={{ required: isFieldRequired }}
          render={({ ...field }) => (
            <InputSelect
              {...field}
              width="100%"
              label="Tipo de Formalização"
              placeholder="Selecione"
              options={TYPE_FORMALIZATION_OPTIONS}
              required={isFieldRequired}
              disabled={disableFields || !hasModal}
            />
          )}
        />

        {errors?.parametrics?.typeFormalization && (
          <S.ErrorMessage>
            {errors.parametrics.typeFormalization.message}
          </S.ErrorMessage>
        )}
      </S.InputContainer>

      <S.InputContainer>
        <Controller
          name="parametrics.loadingDate"
          control={control}
          rules={{ required: isFieldRequired }}
          render={({ ...field }) => (
            <S.DateInput
              {...field}
              label="Data de carregamento"
              format="dd/MM/yyyy"
              disabled={disableFields}
            />
          )}
        />

        {errors?.parametrics?.loadingDate && (
          <S.ErrorMessage>
            {errors.parametrics.loadingDate.message}
          </S.ErrorMessage>
        )}
      </S.InputContainer>

      {isAdditiveSelected && (
        <S.InputContainer>
          <Controller
            name="parametrics.instrumentReference"
            control={control}
            rules={{ required: isAdditiveSelected && isFieldRequired }}
            render={({ ...field }) => (
              <S.TextArea
                {...field}
                type="textarea"
                label="Instrumento Referência"
                disabled={disableFields}
                maxlength={50}
                height={38}
                required
              />
            )}
          />

          {errors?.parametrics?.instrumentReference && (
            <S.ErrorMessage>
              {errors.parametrics.instrumentReference.message}
            </S.ErrorMessage>
          )}
        </S.InputContainer>
      )}

      {isGreaterThanOrEqualToOneYear && !isAdditiveFill && (
        <S.InputContainer>
          <Controller
            name="parametrics.baseDate"
            control={control}
            rules={{ required: isFieldRequired }}
            render={({ ...field }) => (
              <S.DateInput
                {...field}
                label="Data base"
                format="dd/MM"
                disabled={disableFields}
              />
            )}
          />

          {errors?.parametrics?.baseDate && (
            <S.ErrorMessage>
              {errors.parametrics.baseDate.message}
            </S.ErrorMessage>
          )}
        </S.InputContainer>
      )}

      {!isOnlyHarbor && !isAdditiveFill && !isLessThanOneMonth && (
        <>
          <S.InputContainer>
            <Controller
              name="parametrics.applicationDateDay"
              control={control}
              rules={{ required: isFieldRequired }}
              render={({ ...field }) => (
                <InputSelect
                  {...field}
                  width="100%"
                  label="Data de aplicação"
                  placeholder="Selecione"
                  options={APPLICATION_DATE_OPTIONS}
                  required={isFieldRequired}
                  disabled={disableFields}
                />
              )}
            />

            {errors?.parametrics?.applicationDateDay && (
              <S.ErrorMessage>
                {errors.parametrics.applicationDateDay.message}
              </S.ErrorMessage>
            )}
          </S.InputContainer>

          <S.InputContainer>
            <Controller
              name="parametrics.firstReadjustment"
              control={control}
              rules={{ required: isFieldRequired }}
              render={({ ...field }) => (
                <InputSelect
                  {...field}
                  width="100%"
                  label="Primeiro reajuste do diesel"
                  placeholder="Selecione"
                  options={firstReadjustmentOptions}
                  required={isFieldRequired}
                  disabled={disableFields || applicationDateDay === 0}
                />
              )}
            />

            {errors?.parametrics?.firstReadjustment && (
              <S.ErrorMessage>
                {errors.parametrics.firstReadjustment.message}
              </S.ErrorMessage>
            )}
          </S.InputContainer>
        </>
      )}
    </S.FormGrid>
  );
};

PriceParametrics.propTypes = {
  disableFields: PropTypes.bool.isRequired,
  isFieldRequired: PropTypes.bool.isRequired,
  instrumentReference: PropTypes.string,
  checkIsOneYear: PropTypes.func.isRequired,
  checkIsLessThanMonth: PropTypes.func.isRequired,
  isOnlyHarbor: PropTypes.bool.isRequired,
  hasModal: PropTypes.bool.isRequired,
};

PriceParametrics.defaultProps = {
  instrumentReference: '',
};

export default PriceParametrics;

import React from 'react';
import { MessagesActions } from 'store/messages/messages.ducks';
import { useSelector, useDispatch } from 'react-redux';

import {
  Tokens as theme,
  Toast,
  Text,
  ToastContainer,
} from '@vli/locomotive-ds/dist';

const MessageToast = () => {
  const { open, type, autoClose, contentText, title } = useSelector(
    ({ messages }) => messages,
  );
  const dispatch = useDispatch();
  const setClose = () => {
    dispatch(MessagesActions.close());
  };

  return (
    <ToastContainer>
      <Toast
        error={type === 'error'}
        warning={type === 'warning'}
        success={type === 'success'}
        title={title}
        isOpen={open}
        autoClose={autoClose}
        delay={8000}
        onClose={() => setClose()}
      >
        <Text color={theme.colors.gray400}>{contentText}</Text>
      </Toast>
    </ToastContainer>
  );
};

export default MessageToast;

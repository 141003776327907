import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_RESENDITEMS, CALCULATED_PRICE } from 'constants/endpoints';
import api from 'services/api';
import { GET, POST } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import { ResendItemsTypes, ResendItemsActions } from './resendItems.ducks';

export function* resendItems({ filter, filterData }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_RESENDITEMS}?${filter}`,
    });

    yield put(
      ResendItemsActions.resendItemsListSuccess(response.data, filterData),
    );
  } catch (error) {
    yield put(ResendItemsActions.resendItemsListFailure(error));
  }
}

export function* resendItemRequest({ payload }) {
  try {
    yield api({
      method: POST,
      url: `${CALCULATED_PRICE}/resend`,
      data: payload,
    });

    yield put(ResendItemsActions.resendItemSuccess());

    yield put(
      MessagesActions.open({
        title: 'Reenviado com sucesso',
        type: 'success',
        contentText: `O item foi reenviado com sucesso!`,
      }),
    );
  } catch (error) {
    let messages = 'Erro ao reenviar item!';
    if (error.response.data?.errors) {
      messages = error.response.data.errors;
    }
    yield put(
      MessagesActions.open({
        title: 'Erro',
        type: 'error',
        contentText: messages,
      }),
    );
    yield put(ResendItemsActions.resendItemFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(ResendItemsTypes.RESEND_ITEMS_LIST_REQUEST, resendItems);
  yield takeLatest(ResendItemsTypes.RESEND_ITEM_REQUEST, resendItemRequest);
}

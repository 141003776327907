import * as utility from 'lib/core/utility';

export const DataFilter = (contractList = []) => {
  const contractData = contractList.map((contract) => ({
    label: contract.code,
    value: {
      id: contract.id,
      others: '',
    },
  }));

  const statusData = {
    Approved: '2',
    Error: '5',
    Excluded: '6',
  };

  const statusFilter = [
    {
      label: 'Aprovado',
      value: {
        id: statusData.Approved,
      },
    },
    {
      label: 'Erro na atualização',
      value: {
        id: statusData.Error,
      },
    },
    {
      label: 'Excluído',
      value: {
        id: statusData.Excluded,
      },
    },
  ];

  const dataFilter = [
    {
      data: contractData,
      label: 'Contrato',
      name: 'contract',
      type: 'multiselect',
    },
    {
      label: 'Data de aplicação',
      name: 'aplicationDate',
      type: 'dateYearMonth',
    },
    {
      data: statusFilter,
      label: 'Status',
      name: 'status',
      type: 'multiselect',
    },
    {
      label: 'Item',
      name: 'item',
      type: 'input',
    },
  ];
  return dataFilter;
};

export const getFilterParams = (data) => {
  let ContractIds = [];
  let Status = [];
  if (data?.contract) {
    ContractIds = data.contract.map((item) => item.value.id);
  }
  if (data?.status) {
    Status = data.status.map((item) => item.value.id);
  }
  const filterFields = {
    filterFields: {
      ContractIds,
      ApplicationDate: data?.applicationDate,
      Status,
      PageIndex: data?.pageIndex,
      PageSize: data?.pageSize,
      ItemCode: data?.item,
    },
  };

  return `${utility.autoListingParamsGet(filterFields)}`;
};

import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';

export const {
  Types: ResendItemsTypes,
  Creators: ResendItemsActions,
} = createActions({
  resendItemsListRequest: ['filter', 'filterData'],
  resendItemsListSuccess: ['payload', 'filter'],
  resendItemsListFailure: ['error'],
  resendItemRequest: ['payload'],
  resendItemSuccess: [],
  resendItemFailure: ['error'],
});

const INITIAL_STATE = {
  resendItemsList: [],
  error: null,
  itemsForFilter: [],
  isOpen: false,
  inCurveTaxRateData: null,
  filter: {},
  resendItemsRequests: {
    [ResendItemsTypes.RESEND_ITEMS_LIST_REQUEST]: REQUEST_NOT_STARTED,
  },
};

const resendItemsListRequest = () => ({
  ...INITIAL_STATE,
  resendItemsRequests: {
    ...INITIAL_STATE.resendItemsRequests,
    [ResendItemsTypes.RESEND_ITEMS_LIST_REQUEST]: REQUEST_PENDING,
  },
});

const resendItemsListSuccess = (state, { payload, filter }) => ({
  ...state,
  resendItemsList: payload.data,
  filter,
  resendItemsRequests: {
    ...state.resendItemsRequests,
    [ResendItemsTypes.RESEND_ITEMS_LIST_REQUEST]: REQUEST_RESOLVED,
  },
});

const resendItemsListFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  resendItemsRequests: {
    ...state.resendItemsRequests,
    [ResendItemsTypes.RESEND_ITEMS_LIST_REQUEST]: REQUEST_REJECTED,
  },
});

const resendItemRequest = (state) => ({
  ...state,
  resendItemsRequests: {
    ...state.resendItemsRequests,
    [ResendItemsTypes.RESEND_ITEM_REQUEST]: REQUEST_PENDING,
  },
});

const resendItemSuccess = (state) => ({
  ...state,
  resendItemsRequests: {
    ...state.resendItemsRequests,
    [ResendItemsTypes.RESEND_ITEM_REQUEST]: REQUEST_RESOLVED,
  },
});

const resendItemFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  resendItemsRequests: {
    ...state.resendItemsRequests,
    [ResendItemsTypes.RESEND_ITEM_REQUEST]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ResendItemsTypes.RESEND_ITEMS_LIST_REQUEST]: resendItemsListRequest,
  [ResendItemsTypes.RESEND_ITEMS_LIST_SUCCESS]: resendItemsListSuccess,
  [ResendItemsTypes.RESEND_ITEMS_LIST_FAILURE]: resendItemsListFailure,
  [ResendItemsTypes.RESEND_ITEM_REQUEST]: resendItemRequest,
  [ResendItemsTypes.RESEND_ITEM_SUCCESS]: resendItemSuccess,
  [ResendItemsTypes.RESEND_ITEM_FAILURE]: resendItemFailure,
});

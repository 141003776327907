import React from 'react';
import * as utility from 'lib/core/utility';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import CellTable from 'components/core/Table/CellTable';
import CellWithActions from '../CellWithActions/CellWithActions';
import CellEditAutocomplete from '../CellEditAutocomplete/CellEditAutocomplete';
import StatusNewItem from '../StatusNewItem';

export const onClickRow = (row) => {
  if (row.status === 0) {
    history.push(`${ROUTES.NEW_ITEM_DRAFT(row.id)}`);
  }
};

const Columns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 50,
    Cell: (props) => <StatusNewItem {...props} />,
  },
  {
    Header: 'Código do Contrato',
    accessor: 'codeContract',
    align: 'start',
    Cell: (props) => <CellEditAutocomplete {...props} onClick={onClickRow} />,
  },
  {
    Header: 'N° Item ferroviário',
    accessor: 'codeItem',
    align: 'start',
    Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
  },
  {
    Header: 'Produto',
    accessor: 'productUnicomMerchandiseTacito',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeString}
        onClick={onClickRow}
      />
    ),
  },
  {
    Header: 'Origem',
    accessor: 'origin',
    align: 'start',
    Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
  },
  {
    Header: 'Destino',
    accessor: 'destination',
    align: 'start',
    Cell: (props) => <CellTable {...props} onClick={onClickRow} />,
  },
  {
    Header: 'Base Diesel',
    accessor: 'local.name',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeString}
        onClick={onClickRow}
      />
    ),
  },
  {
    Header: 'Retirada',
    accessor: 'placeOriginCargo',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeString}
        onClick={onClickRow}
      />
    ),
  },
  {
    Header: 'Segmento',
    accessor: 'segment.name',
    align: 'start',
    width: 200,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeString}
        onClick={onClickRow}
      />
    ),
  },
  {
    Header: 'Data',
    accessor: 'updateAt',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatDate}
        onClick={onClickRow}
      />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'start',
    width: 20,
    Cell: (props) => <CellWithActions {...props} onClick={onClickRow} />,
  },
];

export default Columns;

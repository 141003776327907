import { ReactComponent as IcClip } from 'assets/icons/clip.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as S from './FileInput.styles';

const FileInput = ({ onChange, id, fileName, isLoading }) => {
  const [filename, setFilename] = useState(
    fileName ? `${fileName}.xlsx` : null,
  );

  const handleOnChange = (event) => {
    const [file] = event.target.files;
    setFilename(file?.name);
    onChange(event);
  };

  return (
    <label htmlFor={id}>
      <S.FileWrapper>
        <IcClip />
        <S.FileButton>Adicionar arquivo</S.FileButton>
        <S.FileName>{filename || 'Nenhum arquivo carregado'}</S.FileName>
        {isLoading && <S.Loading data-testid="models-loading" />}
      </S.FileWrapper>
      <input
        type="file"
        name={id}
        id={id}
        accept=".xlsx"
        style={{ display: 'none' }}
        onChange={handleOnChange}
      />
    </label>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  isLoading: PropTypes.bool,
};

FileInput.defaultProps = {
  fileName: '',
  isLoading: false,
};

export default FileInput;

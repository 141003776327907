import React from 'react';

import * as utility from 'lib/core/utility';

import CellTable from 'components/core/Table/CellTable';

const Columns = [
  {
    Header: 'Código',
    accessor: 'code',
    align: 'start',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Gerente',
    accessor: 'manager',
    align: 'start',
    width: 200,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Cliente',
    accessor: 'customer',
    align: 'start',
    width: 200,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Data de inclusão',
    accessor: 'date',
    align: 'start',
    width: 50,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatDate} />
    ),
  },
];

export default Columns;

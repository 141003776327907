import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Input,
  TrashIcon,
  Button,
  Flex,
  Header,
  Col,
} from '@vli/locomotive-ds/dist';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import * as ROUTES from 'constants/urls';
import { history } from 'constants/routes';

import { MessagesActions } from 'store/messages/messages.ducks';

import * as utility from 'lib/core/utility';

import * as S from '../../shared/EmailForm/styles';

import { emailFormSchema } from '../../shared/formSchema';

const breadcrumb = [
  {
    text: 'Notificações',
    href: `#${ROUTES.EMAIL_NOTIFICATIONS_MANAGER_LIST}`,
  },
  {
    text: 'Gerenciar Destinatários',
    href: null,
  },
];

const EmailForm = ({ data, sendForm }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notification:
        data.recipients && data.recipients.length > 0
          ? data.recipients
          : [{ name: '', email: '' }],
    },
    resolver: yupResolver(emailFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notification',
  });

  const onSubmit = (formData) => {
    const payload = {
      localId: data.localId,
      recipients: formData.notification,
    };

    if (!formData.notification) {
      dispatch(
        MessagesActions.open({
          title: 'Validação',
          type: 'error',
          contentText: 'Por favor adicione pelo menos um destinatário',
        }),
      );
    } else {
      const checkDuplication = utility.hasDuplicates(
        formData.notification,
        'email',
      );

      if (!checkDuplication) {
        sendForm(payload);
      } else {
        dispatch(
          MessagesActions.open({
            title: 'Validação de e-mail',
            type: 'error',
            contentText: 'Não é permitido e-mails duplicados',
          }),
        );
      }
    }
  };

  const removeComponent = (index) => {
    remove(index);
    data.recipients.splice(index, 1);
  };

  const addComponent = () => {
    if (fields.length < 10) {
      append({ name: '', email: '' });
    } else {
      dispatch(
        MessagesActions.open({
          title: 'Limite atingido',
          type: 'error',
          contentText: 'Você pode adicionar apenas 10 emails',
        }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form-test">
      <Flex justifyContent="space-between" alignItems="center">
        <Header bread={breadcrumb} tag="header" title="Notificações" />
        <Flex justifyContent="space-between" alignItems="center">
          <Button
            onClick={() =>
              history.push(ROUTES.EMAIL_NOTIFICATIONS_MANAGER_LIST)
            }
            name="Cancelar"
            type="button"
            variant="tertiary"
            style={{ marginRight: '5px' }}
          />
          <Button name="Salvar" type="submit" data-testid="buttonSubmit-test" />
        </Flex>
      </Flex>
      <S.CardContainer>
        <S.FlexContainer>
          <div>
            <S.Label>Base</S.Label>
            <S.TextValue>{data?.name}</S.TextValue>
          </div>
          <div>
            <S.Label>Posto/Refinaria</S.Label>
            <S.TextValue>{data?.localName || 'Posto'}</S.TextValue>
          </div>
        </S.FlexContainer>
      </S.CardContainer>
      <S.ExpandedAccordion title="Destinatários" open>
        {fields.map((item, index) => (
          <S.RowContainer key={item.id}>
            <Col desktop={5}>
              <Controller
                name={`notification.${index}.name`}
                control={control}
                rules={{ required: true }}
                render={({ onChange }) => (
                  <Input
                    label="Nome"
                    placeholder="Nome"
                    width="100%"
                    required
                    maxLength="50"
                    type="input"
                    data-testid="name-test"
                    value={item.name}
                    onChange={onChange}
                    status={
                      errors.notification &&
                      errors.notification[index]?.name &&
                      'error'
                    }
                    statusMessage={
                      errors.notification &&
                      errors.notification[index]?.name &&
                      'Nome inválido'
                    }
                  />
                )}
              />
            </Col>
            <Col desktop={5}>
              <Controller
                name={`notification.${index}.email`}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ onChange }) => (
                  <Input
                    label="E-mail"
                    placeholder="E-mail"
                    width="100%"
                    required
                    maxLength="250"
                    data-testid="email-test"
                    type="text"
                    value={item.email}
                    onChange={onChange}
                    status={
                      errors.notification &&
                      errors.notification[index]?.email &&
                      'error'
                    }
                    statusMessage={
                      errors.notification &&
                      errors.notification[index]?.email &&
                      'Email inválido'
                    }
                  />
                )}
              />
            </Col>
            <Col desktop={1}>
              <S.DeleteButton
                data-testid={`buttonDelete-test-${index}`}
                icon={TrashIcon}
                action={() => removeComponent(index)}
              />
            </Col>
          </S.RowContainer>
        ))}
      </S.ExpandedAccordion>
      <S.AddButton
        data-testid="buttonAdd-test"
        name="Adicionar destinatário"
        isDisabled={fields.length >= 10}
        icon={PlusIcon}
        action={addComponent}
      />
    </form>
  );
};

EmailForm.propTypes = {
  data: PropTypes.object,
  sendForm: PropTypes.func,
};

EmailForm.defaultProps = {
  data: {
    contractCode: '',
    recipients: [],
  },
  sendForm: () => {},
};

export default EmailForm;

import styled from 'styled-components';
import css from '@styled-system/css';
import { Tokens } from '@vli/locomotive-ds';

export const ToastContainerStyle = styled('ul')(
  css({
    listStyle: 'none',
    width: '100%',
    top: '37px',
    right: '10px',
    display: 'grid',
    height: '0',
  }),
);

export const ToastContentStyle = styled('div')(
  css({
    background: Tokens.colors.grayWhite,
    borderLeft: `8px solid ${Tokens.colors.accentPrimaryDark}`,
    borderRadius: '4px',
    boxShadow: `3px 3px 7px 0px ${Tokens.colors.gray200}`,
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '8px',
    paddingLeft: '24px',
    marginBottom: '20px',
    width: '100%',
    '&.error': {
      paddingLeft: '16px',
      borderLeft: `8px solid ${Tokens.colors.red300}`,
    },
    '&.warning': {
      paddingLeft: '16px',
      borderLeft: `8px solid ${Tokens.colors.yellow300}`,
    },
    '&.success': {
      paddingLeft: '16px',
      borderLeft: `8px solid ${Tokens.colors.green300}`,
    },
  }),
);

export const ToastItemStyle = styled('div')(
  css({
    transition: '0.4s',
    position: 'relative',
    right: '0px',
    opacity: '0',
    minWidth: '100%',
    '&.open': {
      right: '0px',
      opacity: '1',
      transition: '0.4s',
    },
  }),
);

export const ToastHeader = styled('div')(
  css({
    display: 'flex',
    width: '100%',
    minHeight: '24px',
    padding: '0px',
    paddingRight: '8px',
    marginBottom: '8px',
    '& > h1': {
      font: Tokens.fonts.textBoldLarge,
      maxWidth: '227px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export const ToastChildren = styled('div')(
  css({
    color: Tokens.colors.gray400,
    width: '100%',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    '> *': {
      textAlign: 'left',
    },
  }),
);

import React from 'react';
import PropTypes from 'prop-types';
import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';
import { Checkbox } from '@vli/locomotive-ds';

const CellWithCheckbox = ({ line, checked, handleChangeRowSelection }) => {
  return (
    <S.ContentCell data-testid="cell-with-checkbox">
      <S.FlexCenter>
        <Checkbox
          id={`checkbox_selection_${line}`}
          data-testid="checkbox"
          label=""
          group="checkbox-selected"
          checked={checked}
          onChange={(e) => {
            handleChangeRowSelection(line, e.target.checked);
          }}
        />
      </S.FlexCenter>
    </S.ContentCell>
  );
};

CellWithCheckbox.propTypes = {
  line: PropTypes.string,
  checked: PropTypes.bool,
  handleChangeRowSelection: PropTypes.func,
};
CellWithCheckbox.defaultProps = {
  line: '',
  checked: false,
  handleChangeRowSelection: () => {},
};

export default CellWithCheckbox;

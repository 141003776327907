import { createActions, createReducer } from 'reduxsauce';
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { modeEditRow, resetEditRow } from 'lib/contexts/customer';

export const {
  Types: CustomerTypes,
  Creators: CustomerActions,
} = createActions({
  listCustomer: ['params'],
  listCustomerSuccess: ['payload'],
  updateCustomer: ['payload', 'callback'],
  updateCustomerSuccess: ['payload'],
  customerRequestFailure: ['error'],
  setEditRow: ['payload'],
  setChangeCustomerRow: ['payload'],
  resetCustomerRow: [],
  listProposalsCustomer: ['payload'],
  listProposalsCustomerSuccess: ['payload'],
});

const INITIAL_STATE = {
  customerList: [],
  proposalsCustomerList: [],
  error: null,
  customerRequests: {
    [CustomerTypes.LIST_CUSTOMER]: REQUEST_NOT_STARTED,
    [CustomerTypes.UPDATE_CUSTOMER]: REQUEST_NOT_STARTED,
    [CustomerTypes.LIST_PROPOSALS_CUSTOMER]: REQUEST_NOT_STARTED,
  },
  rowEdit: null,
  editMode: false,
  changeEdit: false,
  changeEditOpen: false,
};

const listCustomer = () => ({
  ...INITIAL_STATE,
  customerRequests: {
    ...INITIAL_STATE.customerRequests,
    [CustomerTypes.LIST_CUSTOMER]: REQUEST_PENDING,
  },
});

const listCustomerSuccess = (state, { payload }) => ({
  ...state,
  customerList: payload,
  customerRequests: {
    ...state.customerRequests,
    [CustomerTypes.LIST_CUSTOMER]: REQUEST_RESOLVED,
  },
});

const updateCustomer = () => ({
  ...INITIAL_STATE,
  customerRequests: {
    ...INITIAL_STATE.customerRequests,
    [CustomerTypes.UPDATE_CUSTOMER]: REQUEST_PENDING,
  },
});

const updateCustomerSuccess = (state) => ({
  ...state,
  customerRequests: {
    ...state.customerRequests,
    [CustomerTypes.UPDATE_CUSTOMER]: REQUEST_RESOLVED,
  },
});

const setEditRow = (state, { payload }) => ({
  ...state,
  customerList: modeEditRow(state.customerList, payload.row, payload.editMode),
  rowEdit: payload.row,
  editMode: payload.editMode,
});

const setChangeCustomerRow = (state, { payload }) => ({
  ...state,
  changeEdit: true,
  rowEdit: { ...state.rowEdit, [payload.field]: payload.value },
});

const resetCustomerRow = (state) => ({
  ...state,
  customerList: resetEditRow(state.customerList),
  changeEdit: false,
  rowEdit: null,
  editMode: false,
});

const listProposalsCustomer = () => ({
  ...INITIAL_STATE,
  customerRequests: {
    ...INITIAL_STATE.customerRequests,
    [CustomerTypes.LIST_PROPOSALS_CUSTOMER]: REQUEST_PENDING,
  },
});

const listProposalsCustomerSuccess = (state, { payload }) => ({
  ...state,
  proposalsCustomerList: payload,
  customerRequests: {
    ...state.customerRequests,
    [CustomerTypes.LIST_PROPOSALS_CUSTOMER]: REQUEST_RESOLVED,
  },
});

const customerFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  customerRequests: {
    ...state.customerRequests,
    [CustomerTypes.LIST_CUSTOMER]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [CustomerTypes.LIST_CUSTOMER]: listCustomer,
  [CustomerTypes.LIST_CUSTOMER_SUCCESS]: listCustomerSuccess,
  [CustomerTypes.UPDATE_CUSTOMER]: updateCustomer,
  [CustomerTypes.UPDATE_CUSTOMER_SUCCESS]: updateCustomerSuccess,
  [CustomerTypes.CUSTOMER_REQUEST_FAILURE]: customerFailure,
  [CustomerTypes.SET_EDIT_ROW]: setEditRow,
  [CustomerTypes.SET_CHANGE_CUSTOMER_ROW]: setChangeCustomerRow,
  [CustomerTypes.RESET_CUSTOMER_ROW]: resetCustomerRow,
  [CustomerTypes.LIST_PROPOSALS_CUSTOMER]: listProposalsCustomer,
  [CustomerTypes.LIST_PROPOSALS_CUSTOMER_SUCCESS]: listProposalsCustomerSuccess,
});

import { put, takeLatest } from 'redux-saga/effects';
import { ACCOUNT_MANAGER } from 'constants/endpoints';
import api from 'services/api';
import { GET } from 'constants/verbs';

import {
  AccountManagerTypes,
  AccountManagerActions,
} from './accountManager.ducks';

export function* listAccountManager({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: params
        ? `${ACCOUNT_MANAGER}/?ViewScenario=${params}`
        : ACCOUNT_MANAGER,
    });
    yield put(
      AccountManagerActions.listAccountManagerSuccess(response.data.data),
    );
  } catch (error) {
    yield put(AccountManagerActions.accountManagerRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(
    AccountManagerTypes.LIST_ACCOUNT_MANAGER,
    listAccountManager,
  );
}

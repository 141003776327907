import CellTable from 'components/core/Table/CellTable';
import * as utility from 'lib/core/utility';
import React from 'react';

import CellWithActions from 'components/contexts/notifications/shared/CellWithActions';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

export const handleSettings = ({ row }) => {
  const { original } = row;
  history.push(ROUTES.EMAIL_NOTIFICATIONS_MANAGER_CREATE(original.id));
};

const Columns = [
  {
    Header: 'Base',
    align: 'start',
    accessor: 'name',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Posto/Refinaria',
    accessor: 'typeLocal',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.capitalizeString} />
    ),
  },
  {
    Header: 'Destinatários vinculados',
    accessor: 'totalRecipients',
    align: 'start',
    width: 100,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'end',
    width: 20,
    Cell: (props) => (
      <CellWithActions handleSettings={() => handleSettings({ ...props })} />
    ),
  },
];

export default Columns;

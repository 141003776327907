import React, { useState } from 'react';
import { Input } from '@vli/locomotive-ds/dist';
import InputSelect from 'components/form/InputSelect';
import { useDispatch } from 'react-redux';
import {
  getFilterManagerParams,
  optionsTypeLocal,
} from 'lib/contexts/notification';
import { EmailNotificationManagerActions } from 'store/emailNotificationsManager/emailNotificationManager.ducks';
import * as S from './styles';

const Search = () => {
  const dispatch = useDispatch();

  const [typeLocal, setTypeLocal] = useState('');
  const [localName, setLocalName] = useState('');

  const handleChangeName = (value) => {
    setLocalName(value);
    const params = getFilterManagerParams({ typeLocal, localName: value });

    dispatch(
      EmailNotificationManagerActions.listEmailNotificationManagerFilter(
        params,
        { typeLocal, localName: value },
      ),
    );
  };
  const handleChangeClient = (value) => {
    setTypeLocal(value);
    const params = getFilterManagerParams({ typeLocal: value, localName });
    dispatch(
      EmailNotificationManagerActions.listEmailNotificationManagerFilter(
        params,
        { typeLocal: value, localName },
      ),
    );
  };
  return (
    <S.CardContainer data-testid="card-test">
      <S.FlexContainer>
        <Input
          label="Nome"
          data-testid="name-test"
          placeholder="Pesquisar por nome da base"
          width="400px"
          required
          type="input"
          value={localName}
          onChange={handleChangeName}
        />

        <InputSelect
          label="Posto/Refinaria"
          data-testid="typelocal-test"
          placeholder="Pesquisar por Posto/Refinaria"
          width="400px"
          required
          options={optionsTypeLocal}
          type="input"
          value={typeLocal}
          onChange={handleChangeClient}
        />
      </S.FlexContainer>
    </S.CardContainer>
  );
};

export default Search;

import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_RAILWAYITEM } from 'constants/endpoints';
import api from 'services/api';
import { PUT } from 'constants/verbs';
import { MessagesActions } from 'store/messages/messages.ducks';
import messages from 'constants/messages';
import { RailwayItemTypes, RailwayItemActions } from './railwayItem.ducks';

export function* update({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: RESOURCE_RAILWAYITEM,
      data: payload,
    });

    yield put(RailwayItemActions.updateSuccess());

    yield put(
      MessagesActions.open({
        title: messages.messages.railwayItem[PUT].title,
        type: 'success',
        contentText: messages.messages.railwayItem[PUT].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message = messages.messages.railwayItem[PUT].errorMessage;

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.railwayItem[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(RailwayItemActions.railwayItemRequestFailure(message));
  }
}

export function* watchSagas() {
  yield takeLatest(RailwayItemTypes.UPDATE, update);
}

import * as utility from 'lib/core/utility';

export const getLocalsParams = (PageIndex, PageSize, TypeLocalId) => {
  const filterFields = {
    filterFields: {
      PageIndex,
      PageSize,
      TypeLocalId,
    },
  };
  return `${utility.autoListingParamsGet(filterFields)}`;
};

export const modeEditRow = (locals, row, editMode) => {
  return locals?.map((local) => {
    if (row.id === local.id) {
      return {
        ...local,
        editMode: true,
      };
    }
    return {
      ...local,
      editMode: editMode ? false : null,
    };
  });
};

export const modeChangeRow = (locals, row, item) => {
  return locals?.map((local) => {
    if (row.id === local.id) {
      return item;
    }
    return {
      ...local,
    };
  });
};

export const optionsMultiVli = (macrobaseList, rowChange, rowEdit) =>
  macrobaseList
    .filter((f) => f.isVliSupplier)
    .map((l) => {
      if (rowChange) {
        return {
          key: utility.simpleId(),
          label: l.name,
          selected: rowChange?.suppliers
            ? utility.existObject(rowChange.suppliers, 'id', l.id)
            : false,
          value: { id: l.id },
        };
      }
      return {
        key: utility.simpleId(),
        label: l.name,
        selected: rowEdit?.suppliers
          ? utility.existObject(rowEdit.suppliers, 'id', l.id)
          : false,
        value: { id: l.id },
      };
    });

export const optionsVli = (macrobaseList) =>
  macrobaseList
    .filter((f) => f.isVliSupplier)
    .map((l) => ({ label: l.name, value: l.id }));

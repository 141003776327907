/* eslint-disable react/prop-types */
import React from 'react';
import * as utility from 'lib/core/utility';
import CellTable from 'components/core/Table/CellTable';
import CellWithCheckbox from 'components/contexts/reportContractItems/ReportContractItemsForm/CellWithCheckbox/CellWithCheckbox';

const Columns = (selectedItems, handleChangeRowSelection) => [
  {
    Header: '',
    accessor: 'id',
    align: 'center',
    width: 50,
    Cell: ({ row }) => {
      const { id, code } = row.original;
      const isChecked = selectedItems[id];

      return (
        <CellWithCheckbox
          line={code}
          checked={isChecked}
          handleChangeRowSelection={(_, checked) =>
            handleChangeRowSelection({
              id,
              code,
              isChecked: checked,
            })
          }
        />
      );
    },
  },
  {
    Header: 'Número do item',
    accessor: 'code',
    align: 'start',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Data da inativação',
    accessor: 'inactivatedDate',
    align: 'start',
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.formatDate} />
    ),
  },
];

export default Columns;

import React from 'react';

import Autocomplete from 'components/form/Autocomplete';
import CellTable from 'components/core/Table/CellTable';

const CellEditAutocompleteWrapper = ({
  selects,
  onChange,
  onInputChange,
  field,
  ...props
}) => {
  const {
    cell: { value },
    row,
    align,
    color,
  } = props;

  return row.original.editMode ? (
    <Autocomplete
      size="medium"
      autocomplete
      defaultOptions={selects}
      data-testid={`cell-table-${value}`}
      style={{ cursor: 'pointer', textAlign: align }}
      color={color}
      onInputChange={onInputChange}
      onChange={onChange}
      placeholder={value}
      value={field}
    />
  ) : (
    <CellTable {...props} />
  );
};

export default CellEditAutocompleteWrapper;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Text, Popover } from '@vli/locomotive-ds/dist';

import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';

import { CustomerActions } from 'store/customer/customer.ducks';
import { canUpdateCustomer } from 'lib/contexts/customer';

import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';

const CellWithActions = ({ row }) => {
  const dispatch = useDispatch();
  const { rowEdit, changeEdit } = useSelector(({ customer }) => customer);

  const client = row.original;

  const reloadCustomerList = () => {
    dispatch(CustomerActions.listCustomer());
  };

  const handleEditClient = () => {
    if (client && canUpdateCustomer) {
      dispatch(CustomerActions.setEditRow({ row: client, editMode: true }));
    }
  };

  const handleConfirmEdit = () => {
    if (client) {
      if (changeEdit) {
        dispatch(
          CustomerActions.updateCustomer(
            {
              customerId: rowEdit.id,
              customerName: rowEdit.name,
            },
            reloadCustomerList,
          ),
        );
      } else {
        dispatch(CustomerActions.setEditRow({ row: {}, editMode: false }));
      }
    }
  };

  const Options = (
    <S.OptionsContainer>
      {canUpdateCustomer && (
        <S.OptionItem data-testid="test-edit" onClick={handleEditClient}>
          <Text size="medium" style={{ fontWeight: '400' }}>
            Editar cliente
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return !row.original.editMode ? (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="test-button"
          icon={IcOption}
          name=""
          variant="tertiary"
          isDisabled={!canUpdateCustomer}
        />
      </Popover>
    </S.ContentCell>
  ) : (
    <S.Flex direction="">
      <Button
        onClick={handleConfirmEdit}
        data-testid="test-button"
        name="Ok"
        variant="primary"
      />
    </S.Flex>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CellWithActions;

import React from 'react';
import CellTable from 'components/core/Table/CellTable';
import * as utility from 'lib/core/utility';
import CellWithActions from '../CellWithAction/CellWithActions';
import StatusUser from '../StatusUser';

const Columns = (customColum) => {
  const defaultColumns = customColum || [
    'active',
    'userName',
    'userEmail',
    'profileName',
    'segments',
    'acoes',
  ];

  const columns = [];

  if (defaultColumns.includes('active'))
    columns.push({
      Header: 'Status',
      accessor: 'active',
      align: 'start',
      width: 50,
      Cell: (props) => <StatusUser {...props} />,
    });
  if (defaultColumns.includes('userName'))
    columns.push({
      Header: 'Nome',
      accessor: 'userName',
      align: 'start',
      Cell: (props) => (
        <CellTable
          {...props}
          transformedData={utility.capitalizeString}
          align="start"
        />
      ),
    });
  if (defaultColumns.includes('userEmail'))
    columns.push({
      Header: 'Matricula',
      accessor: 'userEmail',
      align: 'start',
      Cell: (props) => <CellTable {...props} align="start" />,
    });
  if (defaultColumns.includes('profileName'))
    columns.push({
      Header: 'Perfil',
      accessor: 'profileName',
      align: 'start',
      Cell: (props) => (
        <CellTable
          {...props}
          transformedData={utility.capitalizeFirstString}
          align="start"
        />
      ),
    });
  if (defaultColumns.includes('segments'))
    columns.push({
      Header: 'Segmento',
      accessor: 'segments',
      align: 'start',

      Cell: (props) => (
        <CellTable
          {...props}
          transformedData={utility.formatArray}
          align="start"
        />
      ),
    });
  if (defaultColumns.includes('acoes'))
    columns.push({
      Header: 'Ações',
      accessor: 'acoes',
      align: 'end',
      width: 10,
      Cell: (props) => <CellWithActions {...props} />,
    });
  return columns;
};

export default Columns;

import React from 'react';
import { Route, Switch } from 'react-router';

import * as ROUTES from 'constants/urls';

import Login from 'pages/login';
import Forbidden from 'pages/forbidden';

const Public = () => (
  <Switch>
    <Route path={ROUTES.FORBIDDEN} component={Forbidden} />

    <Route path="*" component={Login} />
  </Switch>
);

export default Public;

import { takeLatest, put, call } from 'redux-saga/effects';
import api from 'services/api';
import { CONTRACT_EXTEND } from 'constants/endpoints';
import { GET, POST, PUT } from 'constants/verbs';
import { getFilterDetailsParams } from 'lib/contexts/contracts';
import { MessagesActions } from 'store/messages/messages.ducks';
import {
  ContractDetailTypes,
  ContractDetailActions,
} from './contractDetail.ducks';

export function* getContract({ payload }) {
  try {
    const response = yield api({
      method: GET,
      url: `/1/railwayitem/contract/details${getFilterDetailsParams(payload)}`,
    });

    yield put(
      ContractDetailActions.getContractDetailSuccess(response.data.data),
    );
  } catch (error) {
    yield put(
      MessagesActions.open({
        title: 'Falha',
        type: 'error',
        contentText: 'Erro ao carregar os items',
      }),
    );

    yield put(ContractDetailActions.getContractDetailFailure(error));
  }
}

export function* sendApprovePrice({ data, callback }) {
  try {
    yield api({
      method: POST,
      url: `/1/CalculatedValueApproval`,
      data,
    });

    yield put(
      MessagesActions.open({
        title: 'Aprovar preço',
        type: 'success',
        contentText: 'Preço aprovado com sucesso',
      }),
    );

    yield put(ContractDetailActions.approvePriceSuccess());

    yield callback();
  } catch (error) {
    const newError = error?.response
      ? error.response.data.errors
      : ['Tente novamente!'];

    yield put(
      MessagesActions.open({
        title: 'Falha ao aprovar preço',
        type: 'error',
        contentText: newError,
      }),
    );

    yield put(ContractDetailActions.approvePriceFailure(newError));
  }
}

export function* sendRenegotiatePrice({ data, callback }) {
  try {
    yield api({
      method: POST,
      url: `/1/CalculatedValueApproval/negociation`,
      data,
    });

    yield put(
      MessagesActions.open({
        title: 'Negociação de preço',
        type: 'success',
        contentText: 'Preço negociado com sucesso',
      }),
    );

    yield put(ContractDetailActions.renegotiatePriceSuccess());

    yield callback();
  } catch (error) {
    const newError = error?.response
      ? error.response.data.errors
      : ['Falha ao negociar preço'];

    yield put(
      MessagesActions.open({
        title: 'Negociar preço',
        type: 'error',
        contentText: newError,
      }),
    );

    yield put(ContractDetailActions.renegotiatePriceFailure(newError));
  }
}

export function* extendContract({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: CONTRACT_EXTEND,
      data: payload,
    });

    yield put(
      MessagesActions.open({
        title: 'Prorrogação de contrato',
        type: 'success',
        contentText: 'Contrato prorrogado com sucesso',
      }),
    );

    yield put(ContractDetailActions.extendContractSuccess());

    yield callback();
  } catch (error) {
    const message = error.response.data.errors;

    yield put(
      MessagesActions.open({
        title: 'Prorrogação de contrato',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(ContractDetailActions.extendContractFailure(error));
  }
}

export function* sendCorrectionPrice({ data, callback }) {
  try {
    yield api({
      method: POST,
      url: `/1/CalculatedValueApproval/correction`,
      data,
    });

    yield put(
      MessagesActions.open({
        title: 'Negociação de preço',
        type: 'success',
        contentText: 'Preço negociado com sucesso',
      }),
    );

    yield put(ContractDetailActions.correctionPriceSuccess());

    yield callback();
  } catch (error) {
    const newError = error?.response
      ? error.response.data.errors
      : ['Falha ao negociar preço'];

    yield put(
      MessagesActions.open({
        title: 'Negociar preço',
        type: 'error',
        contentText: newError,
      }),
    );

    yield put(ContractDetailActions.correctionPriceFailure(newError));
  }
}

export function* sendImmediately({ data, callback }) {
  try {
    yield api({
      method: POST,
      url: `/1/CalculatedValueApproval/send-immediately`,
      data,
    });

    // Delay para integração com UNICOM
    yield call(
      (time) => new Promise((resolve) => setTimeout(resolve, time)),
      2000,
    );

    yield put(
      MessagesActions.open({
        title: 'Envio imediato',
        type: 'success',
        contentText: 'Envio imediato realizado com sucesso',
      }),
    );

    yield put(ContractDetailActions.sendImmediatelySuccess());

    yield callback();
  } catch (error) {
    const newError = error?.response
      ? error.response.data.errors
      : ['Falha ao realizar envio imediato'];

    yield put(
      MessagesActions.open({
        title: 'Envio imediato',
        type: 'error',
        contentText: newError,
      }),
    );

    yield put(ContractDetailActions.sendImmediatelyFailure(newError));
  }
}

export function* getPreviousMonth({ data, callback }) {
  try {
    yield api({
      method: PUT,
      url: `/1/CalculatedValueApproval/previous-month-tariff`,
      data,
    });

    yield put(
      MessagesActions.open({
        title: 'Nova tarifa',
        type: 'success',
        contentText: 'Tarifa do mês anterior enviada!',
      }),
    );

    yield put(ContractDetailActions.getPreviousMonthSuccess());

    yield callback();
  } catch (error) {
    const newError = error?.response
      ? error.response.data.errors
      : ['Falha ao utilizar valor do mês anterior'];

    yield put(
      MessagesActions.open({
        title: 'Tente novamente!',
        type: 'error',
        contentText: newError,
      }),
    );

    yield put(ContractDetailActions.getPreviousMonthFailure(newError));
  }
}

export function* sendManuallyValue({ data, callback }) {
  try {
    yield api({
      method: PUT,
      url: `/1/CalculatedValueApproval/tariff-without-value`,
      data,
    });

    yield put(
      MessagesActions.open({
        title: 'Nova tarifa',
        type: 'success',
        contentText: 'Tarifa inserida foi enviada com sucesso!',
      }),
    );

    yield put(ContractDetailActions.sendManuallyValueSuccess());

    yield callback();
  } catch (error) {
    const newError = error?.response
      ? error.response.data.errors
      : ['Falha ao inserir nova tarifa.'];

    yield put(
      MessagesActions.open({
        title: 'Tente novamente!',
        type: 'error',
        contentText: newError,
      }),
    );

    yield put(ContractDetailActions.sendManuallyValueFailure(newError));
  }
}

export function* watchSagas() {
  yield takeLatest(ContractDetailTypes.GET_CONTRACT_DETAIL, getContract);
  yield takeLatest(ContractDetailTypes.APPROVE_PRICE, sendApprovePrice);
  yield takeLatest(ContractDetailTypes.RENEGOTIATE_PRICE, sendRenegotiatePrice);
  yield takeLatest(ContractDetailTypes.EXTEND_CONTRACT, extendContract);
  yield takeLatest(ContractDetailTypes.CORRECTION_PRICE, sendCorrectionPrice);
  yield takeLatest(ContractDetailTypes.SEND_IMMEDIATELY, sendImmediately);
  yield takeLatest(ContractDetailTypes.GET_PREVIOUS_MONTH, getPreviousMonth);
  yield takeLatest(ContractDetailTypes.SEND_MANUALLY_VALUE, sendManuallyValue);
}

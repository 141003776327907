import styled from 'styled-components';
import { Text, Tokens } from '@vli/locomotive-ds/dist';

export const Title = styled(Text)`
  font: ${Tokens.fonts.textRegularSmall};
  color: ${Tokens.colors.gray400};
`;

export const Content = styled.div`
  font: ${Tokens.fonts.textRegularMedium};
  color: ${Tokens.colors.gray500};
`;

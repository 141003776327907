import React from 'react';
import { Header, Flex, Container, Button } from '@vli/locomotive-ds/dist';
import Content from 'components/core/content';
import SegmentsTable from 'components/contexts/segments/segmentsList/SegmentsTable';
import useDimensions from 'hooks/useDimensions';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Segmentos',
    href: null,
  },
];

const SegmentsList = () => {
  const dimensions = useDimensions();

  const handleNavigateToCreateNewSegment = () => {
    history.push(ROUTES.NEW_SEGMENT);
  };

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <Header bread={breadcrumb} tag="header" title="Segmento" />

          <Button
            onClick={handleNavigateToCreateNewSegment}
            name="Novo segmento"
            type="button"
            data-testid="new-button"
          />
        </Flex>

        <SegmentsTable />
      </Container>
    </Content>
  );
};

export default SegmentsList;

/* eslint-disable react/prop-types */
import CellTable from 'components/core/Table/CellTable';
import * as utility from 'lib/core/utility';
import React from 'react';
import CellWithActions from '../CellWithActions/CellWithActions';

const handleTransformData = (data, props) => {
  if (props.row.original.subSegmentName === 'GRÃOS') {
    return '-';
  }

  return utility.formatBooleanToText(data);
};

const Columns = [
  {
    Header: 'Contratos',
    align: 'start',
    accessor: 'contractCode',
    width: 50,
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Clientes',
    accessor: 'customerName',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable {...props} transformedData={utility.capitalizeString} />
    ),
  },
  {
    Header: 'Exibir quebra de serviço',
    accessor: 'enabledServices',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={(data) => handleTransformData(data, props)}
      />
    ),
  },
  {
    Header: 'Exibir tarifa Piscofins',
    accessor: 'enabledPiscofins',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={(data) => handleTransformData(data, props)}
      />
    ),
  },
  {
    Header: 'Histórico',
    accessor: 'enabledHistoric',
    align: 'start',
    width: 100,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={(data) => handleTransformData(data, props)}
      />
    ),
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'end',
    width: 20,
    Cell: (props) => <CellWithActions {...props} />,
  },
];

export default Columns;

import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';
import Loading from 'components/core/Loading';
import ReportContractItemsForm from 'components/contexts/reportContractItems/ReportContractItemsForm/ReportContractItemsForm';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { ReportContractItemsActions } from 'store/reportContractItems/reportContractItems.ducks';

const ReportContractItemsEdit = () => {
  const dimensions = useDimensions();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { details, reportContractItemsRequests } = useSelector(
    ({ reportContractItems }) => reportContractItems,
  );

  const loadItems = useCallback(() => {
    dispatch(ReportContractItemsActions.getReportContractItems(id));
  }, [dispatch, id]);

  const submit = (data) => {
    dispatch(
      ReportContractItemsActions.updateReportContractItems(data, loadItems),
    );
  };

  useEffect(() => {
    loadItems();
  }, [dispatch, id, loadItems]);

  const loading =
    isPending(reportContractItemsRequests.GET_REPORT_CONTRACT_ITEMS) ||
    isPending(reportContractItemsRequests.UPDATE_REPORT_CONTRACT_ITEMS);

  return (
    <Content height={dimensions.height} data-testid="content">
      {loading && <Loading />}
      <Container>
        {details && <ReportContractItemsForm data={details} submit={submit} />}
      </Container>
    </Content>
  );
};

export default ReportContractItemsEdit;

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Checkbox, Input } from '@vli/locomotive-ds/dist';

import InputSelect from 'components/form/InputSelect';
import Loading from 'components/core/Loading';

import { ContractUploadActions } from 'store/contractUpload/contractUpload.ducks';
import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';

import { isPending } from 'lib/core/request';
import { getOptionsActiveContracts } from 'lib/contexts/contracts';

import * as S from './SpreadsheetsStyles';

const SpreadSheets = () => {
  const dispatch = useDispatch();

  const [enableContract, setEnableContract] = useState(false);

  const { handleSubmit, control, errors, reset, register, watch } = useForm({
    defaultValues: {
      file: '',
      contractCode: '',
    },
  });

  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );
  const { contractUploadRequests } = useSelector(
    ({ contractUpload }) => contractUpload,
  );

  useEffect(() => {
    dispatch(ActiveContractActions.activeContract('?additives=false'));
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('UploadedData', data.file);

    if (enableContract) {
      const selectedContract = activeContractList.find(
        (contract) => contract.code === data.contractCode,
      );

      if (selectedContract?.id) {
        formData.append('ContractFatherId', selectedContract.id);
      }
    }

    dispatch(ContractUploadActions.importSpreadsheet(formData));

    reset();
  };

  const fileInput = watch('file');
  const contractOptions = getOptionsActiveContracts(activeContractList);

  const isLoading = isPending(contractUploadRequests.IMPORT_SPREADSHEET);

  return (
    <Accordion
      title="Via planilha manual"
      key="contract-by-spreadsheet"
      width="100%"
      open
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? (
          <Loading />
        ) : (
          <S.FormContainer>
            <S.FormFields>
              <S.InputContainer>
                <Controller
                  rules={{ required: true }}
                  name="file"
                  control={control}
                  render={({ value, onChange }) => (
                    <Input
                      data-testid="input-select-file"
                      type="file"
                      label="Arquivo"
                      name="file"
                      accept=".xlsx"
                      required
                      ref={register({ required: true })}
                      value={value}
                      onChange={onChange}
                      width="250px"
                    />
                  )}
                />

                {errors.file && <S.ErrorText>Campo requerido</S.ErrorText>}
              </S.InputContainer>

              <S.InputContainer>
                <Checkbox
                  label="Aditivo"
                  checked={enableContract}
                  onChange={() => setEnableContract(!enableContract)}
                />
              </S.InputContainer>

              {enableContract && (
                <Controller
                  control={control}
                  name="contractCode"
                  render={({ onChange, value }) => (
                    <InputSelect
                      name="contractCode"
                      value={value}
                      options={contractOptions}
                      onChange={onChange}
                      placeholder="Selecione"
                      label="Contrato"
                      type="text"
                      disabled={!enableContract}
                      width="250px"
                      autocomplete
                    />
                  )}
                />
              )}
            </S.FormFields>

            <S.ButtonContainer>
              <Button
                name="Enviar planilha"
                type="submit"
                disabled={!fileInput}
              />
            </S.ButtonContainer>
          </S.FormContainer>
        )}
      </form>
    </Accordion>
  );
};

SpreadSheets.propTypes = {};

SpreadSheets.defaultProps = {};

export default SpreadSheets;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { read, utils } from 'xlsx';
import { useSelector } from 'react-redux';

import { ScrollBar, Button } from '@vli/locomotive-ds';

import Table from 'components/core/Table/Table';
import { Columns } from './Columns';
import PDFViewer from './PDFViewer';

import * as S from './ModalDoubleCheckStyles';

const CHECK_STEPS = {
  SUPPLIER_1_REFINERY: 1,
  SUPPLIER_1_STATION: 2,
  SUPPLIER_2_REFINERY: 3,
  SUPPLIER_2_STATION: 4,
  INDICES: 5,
};

const ModalDoubleCheck = ({ isOpen, onClose, data, onConfirm }) => {
  const totalSteps = 5;

  const { suppliersList } = useSelector(({ diesel }) => diesel);

  const [dieselJSON, setDieselJSON] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const formatAsPercent = (num) => {
    return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits: 2,
    }).format(num / 100);
  };

  const transformSheetToJSON = async (dieselExcel) => {
    const f = await dieselExcel.arrayBuffer();
    const wb = read(f);
    const ws = wb.Sheets[wb.SheetNames[0]];
    const dataJson = utils.sheet_to_json(ws);

    setDieselJSON(dataJson);
  };

  useEffect(() => {
    if (data?.DieselExcel) {
      transformSheetToJSON(data.DieselExcel);
    }
  }, [data]);

  const dieselData = dieselJSON?.map((item) => ({
    id: item.ID,
    name: item.Localidade,
    percent: formatAsPercent(item['%']),
  }));

  const getSupplierName = (supplierId) => {
    const foundSupplier = suppliersList.find(
      (supplier) => supplier.id === supplierId,
    );

    return foundSupplier.name;
  };

  const getFileValues = (file) => {
    const fileFields = Object.entries(file);
    const values = [];

    fileFields.forEach((item) => {
      values.push({
        supplierName: getSupplierName(item[0]),
        refinery: item[1].refinary,
        station: item[1].station,
      });
    });

    return values;
  };

  const goNextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((oldState) => oldState + 1);
    }
  };

  const goBackStep = () => {
    if (currentStep > 1) {
      setCurrentStep((oldState) => oldState - 1);
    }
  };

  const getStepContent = () => {
    const supplierContent = getFileValues(data.file);

    switch (currentStep) {
      case CHECK_STEPS.SUPPLIER_1_REFINERY:
        return {
          label: 'Carta de diesel refinaria',
          supplierName: supplierContent[0].supplierName,
          data: supplierContent[0].refinery,
        };
      case CHECK_STEPS.SUPPLIER_1_STATION:
        return {
          label: 'Carta de diesel posto',
          supplierName: supplierContent[0].supplierName,
          data: supplierContent[0].station,
        };
      case CHECK_STEPS.SUPPLIER_2_REFINERY:
        return {
          label: 'Carta de diesel refinaria',
          supplierName: supplierContent[1].supplierName,
          data: supplierContent[1].refinery,
        };
      case CHECK_STEPS.SUPPLIER_2_STATION:
        return {
          label: 'Carta de diesel posto',
          supplierName: supplierContent[1].supplierName,
          data: supplierContent[1].station,
        };
      default:
        return null;
    }
  };

  const stepContent = getStepContent();

  return (
    <S.ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title="Revisar itens da atualização de índices"
      width="690px"
    >
      <S.Header>
        <span>
          {currentStep} de {totalSteps}
        </span>

        {stepContent && (
          <>
            <span>{stepContent.label}</span>
            <small>{stepContent.supplierName}</small>
            <small>{stepContent.data.name}</small>
          </>
        )}

        {currentStep === CHECK_STEPS.INDICES && (
          <small>Variações de Diesel e suas respectivas bases</small>
        )}
      </S.Header>

      <S.Box>
        {currentStep === CHECK_STEPS.INDICES ? (
          <ScrollBar overflowY height="300px">
            <Table
              width="100%"
              tableType="responsive"
              data={dieselData}
              columns={Columns()}
              footer={false}
            />
          </ScrollBar>
        ) : (
          <ScrollBar overflowY overflowX height="400px" width="650px">
            <PDFViewer file={stepContent.data} />
          </ScrollBar>
        )}
      </S.Box>

      <S.Footer>
        <Button
          name="Anterior"
          type="button"
          variant="tertiary"
          data-testid="back-diesel-modal-test"
          onClick={goBackStep}
          isDisabled={currentStep === 1}
        />

        {currentStep === totalSteps ? (
          <Button
            name="Atualizar Índices"
            type="button"
            data-testid="send-diesel-modal-test"
            onClick={onConfirm}
          />
        ) : (
          <Button
            name="Próximo"
            type="button"
            data-testid="next-diesel-modal-test"
            onClick={goNextStep}
          />
        )}
      </S.Footer>
    </S.ModalContainer>
  );
};

ModalDoubleCheck.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ModalDoubleCheck;

export const normalizeNumber = (n) => {
  if (Number.isInteger(n)) {
    return n;
  }
  if (n.toFixed) {
    return n.toFixed(2);
  }
  return '';
};

export const formatVolume = (value, isUN) => {
  const decimalFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  });

  const volumeToBi = (volume) =>
    `${decimalFormatter.format(normalizeNumber(volume / 1000000000))} ${
      isUN ? 'BiUN' : 'BiTU'
    }`;
  const volumeToM = (volume) =>
    `${decimalFormatter.format(normalizeNumber(volume / 1000000))} ${
      isUN ? 'MUN' : 'MTU'
    } `;
  const volumeToK = (volume) =>
    `${decimalFormatter.format(normalizeNumber(volume / 1000))} ${
      isUN ? 'KUN' : 'KTU'
    } `;
  const normalizeVolume = (volume) =>
    `${decimalFormatter.format(normalizeNumber(volume))} ${
      isUN ? 'UN' : 'TU'
    } `;

  const volumeFormat = () => {
    const intPart = String(value).split('.')[0];
    const intPartWithoutSymbols = intPart.replace('-', '');

    if (intPartWithoutSymbols.length >= 10) {
      return volumeToBi(value);
    }
    if (
      intPartWithoutSymbols.length >= 7 &&
      intPartWithoutSymbols.length <= 9
    ) {
      return volumeToM(value);
    }
    if (
      intPartWithoutSymbols.length >= 4 &&
      intPartWithoutSymbols.length <= 6
    ) {
      return volumeToK(value);
    }
    return normalizeVolume(value);
  };

  return volumeFormat(value);
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from '@vli/locomotive-ds/dist';

import { CompensationActions } from 'store/compensation/compensation.ducks';
import { isPending } from 'lib/core/request';

import Flow from './Sections/Flow';
import FlowDates from './Sections/FlowDates';
import Compensation from './Sections/Compensation';

import * as S from './TakeOrPayModalStyles';

const TakeOrPayModal = ({ isOpen, onClose, formalizationId }) => {
  const dispatch = useDispatch();

  const { compensationData, compensationRequest } = useSelector(
    ({ compensation }) => compensation,
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(CompensationActions.compensationById(formalizationId));
    }
  }, [formalizationId, dispatch, isOpen]);

  const isLoading = isPending(compensationRequest.COMPENSATION_BY_ID);

  const checkIfHasCompensation = () => {
    const compensationIsNullable = compensationData.salesCommitments.every(
      (commitment) => commitment.compensationTypeId === null,
    );

    return !compensationIsNullable;
  };

  return (
    <S.ModalContainer
      title="Informações de Take or Pay"
      isOpen={isOpen}
      onClose={onClose}
      width="1150px"
    >
      <S.Scroll>
        {isLoading && (
          <Loading fitParent message="Por favor, espere alguns segundos..." />
        )}

        {compensationData?.salesCommitments && (
          <S.Wrapper data-testid="compensation-data-test">
            {compensationData.salesCommitments.length > 0 && (
              <Flow salesCommitments={compensationData.salesCommitments} />
            )}

            <FlowDates compensationData={compensationData} />

            {checkIfHasCompensation() && (
              <Compensation
                salesCommitments={compensationData.salesCommitments}
              />
            )}
          </S.Wrapper>
        )}
      </S.Scroll>
    </S.ModalContainer>
  );
};

TakeOrPayModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  formalizationId: PropTypes.number.isRequired,
};

export default TakeOrPayModal;

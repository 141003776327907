import styled from 'styled-components';
import { Tokens } from '@vli/locomotive-ds';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  margin-top: ${Tokens.spacing.sm};

  span {
    font: ${Tokens.fonts.textRegularSmall};
    color: ${Tokens.colors.gray400};
    margin-bottom: ${Tokens.spacing.xss};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Tokens.spacing.xs};

  margin-top: ${Tokens.spacing.md};
`;

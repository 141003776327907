import { put, takeLatest } from 'redux-saga/effects';
import { RESOURCE_SUPPLIER } from 'constants/endpoints';
import api from 'services/api';
import { GET, POST, PUT, PATCH } from 'constants/verbs';
import messages from 'constants/messages';
import { MessagesActions } from 'store/messages/messages.ducks';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import { SupplierActions, SupplierTypes } from './supplier.ducks';

export function* listVliSuppliers() {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_SUPPLIER}/vlisuppliers`,
    });

    yield put(SupplierActions.listVliSuppliersSuccess(response.data.data));
  } catch (error) {
    yield put(SupplierActions.supplierRequestFailure(error));
  }
}

export function* getSupplier({ params, type }) {
  try {
    const response = yield api({
      method: GET,
      url: `${RESOURCE_SUPPLIER}/${params}`,
    });

    yield put(SupplierActions.getSupplierSuccess(response.data.data));
  } catch (error) {
    yield put(SupplierActions.supplierRequestFailure(error, type));
  }
}

export function* createSupplier({ payload, type }) {
  try {
    const response = yield api({
      method: POST,
      url: RESOURCE_SUPPLIER,
      data: payload,
    });

    yield put(SupplierActions.createSupplierSuccess(response.data.data));

    yield put(
      MessagesActions.open({
        title: messages.messages.supplier[POST].title,
        type: 'success',
        contentText: messages.messages.supplier[POST].message,
      }),
    );

    yield history.push(ROUTES.SUPPLIER_LIST);
  } catch (error) {
    let message = messages.messages.supplier[POST].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.supplier[POST].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(SupplierActions.supplierRequestFailure(error, type));
  }
}

export function* updateSupplier({ payload, type }) {
  try {
    const response = yield api({
      method: PUT,
      url: RESOURCE_SUPPLIER,
      data: payload,
    });

    yield put(SupplierActions.updateSupplierSuccess(response.data.data));

    yield put(
      MessagesActions.open({
        title: messages.messages.supplier[PUT].title,
        type: 'success',
        contentText: messages.messages.supplier[PUT].message,
      }),
    );

    yield history.push(ROUTES.SUPPLIER_LIST);
  } catch (error) {
    let message = messages.messages.supplier[PUT].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.supplier[PUT].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(SupplierActions.supplierRequestFailure(error, type));
  }
}

export function* updateSupplierStatus({ params, callback, type }) {
  try {
    const response = yield api({
      method: PUT,
      url: `${RESOURCE_SUPPLIER}/${params}`,
    });

    yield put(SupplierActions.updateSupplierStatusSuccess(response.data.data));

    yield put(
      MessagesActions.open({
        title: messages.messages.supplier[PATCH].title,
        type: 'success',
        contentText: messages.messages.supplier[PATCH].message,
      }),
    );

    yield callback();
  } catch (error) {
    let message = messages.messages.supplier[PATCH].errorMessage;
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: messages.messages.supplier[PATCH].errorTitle,
        typeModal: 'error',
        contentModalText: message,
      }),
    );

    yield put(SupplierActions.supplierRequestFailure(error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(SupplierTypes.LIST_VLI_SUPPLIERS, listVliSuppliers);
  yield takeLatest(SupplierTypes.GET_SUPPLIER, getSupplier);
  yield takeLatest(SupplierTypes.CREATE_SUPPLIER, createSupplier);
  yield takeLatest(SupplierTypes.UPDATE_SUPPLIER, updateSupplier);
  yield takeLatest(SupplierTypes.UPDATE_SUPPLIER_STATUS, updateSupplierStatus);
}

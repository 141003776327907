const ENVIROMENT = {
  DEVELOPMENT: {
    API: 'https://jetsons-backend-dev.vli-logistica.com.br/',
    ENDPOINTSNOTIFICATION:
      'https://notificacao-pvdigital-dev-api.azurewebsites.net',
    URL_SENSIDIA: 'https://api.vli-logistica.com.br/oauth/access-token',
    PV_DIGITAL_URL: 'https://pvdigital-dev.vli-logistica.com.br/',
    NETLEX_URL: 'https://hom-vli-logistica.netlex.com.br/',
    TENANT_ID: 'eef8f41a-1fa3-486c-9dc3-2fa5f63c9231',
    CLIENT_ID_AUTH: 'b618410b-a1ae-4fac-9e31-98df4a21f126',
    CLIENT_ID_SENSIDIA: '27376840-0019-3cef-895b-04eb468f8fe4',
    AUTHORIZATION:
      'MjczNzY4NDAtMDAxOS0zY2VmLTg5NWItMDRlYjQ2OGY4ZmU0OmJmMWNlZTRmLTIzODItMzg5ZC04NTJlLTI4MWY3N2Q5MDZjNg==',
    dev: true,
  },
  LOCAL: {
    API: 'https://localhost:5001',
    URL_SENSIDIA: 'https://api.vli-logistica.com.br/oauth/access-token',
    PV_DIGITAL_URL: 'https://pvdigital-dev.vli-logistica.com.br/',
    NETLEX_URL: 'https://hom-vli-logistica.netlex.com.br/',
    TENANT_ID: 'eef8f41a-1fa3-486c-9dc3-2fa5f63c9231',
    CLIENT_ID_AUTH: 'b618410b-a1ae-4fac-9e31-98df4a21f126',
    CLIENT_ID_SENSIDIA: '27376840-0019-3cef-895b-04eb468f8fe4',
    AUTHORIZATION:
      'MjczNzY4NDAtMDAxOS0zY2VmLTg5NWItMDRlYjQ2OGY4ZmU0OmJmMWNlZTRmLTIzODItMzg5ZC04NTJlLTI4MWY3N2Q5MDZjNg==',
    dev: true,
  },
  DEPLOY: {
    API: '???API???',
    ENDPOINTSNOTIFICATION: '???ENDPOINTSNOTIFICATION???',
    URL_SENSIDIA: 'https://api.vli-logistica.com.br/oauth/access-token',
    PV_DIGITAL_URL: '???PV_DIGITAL_URL???',
    NETLEX_URL: '???NETLEX_URL???',
    TENANT_ID: '???TENANT_ID???',
    CLIENT_ID_AUTH: '???CLIENT_ID_AUTH???',
    CLIENT_ID_SENSIDIA: '27376840-0019-3cef-895b-04eb468f8fe4',
    AUTHORIZATION:
      'MjczNzY4NDAtMDAxOS0zY2VmLTg5NWItMDRlYjQ2OGY4ZmU0OmJmMWNlZTRmLTIzODItMzg5ZC04NTJlLTI4MWY3N2Q5MDZjNg==',
  },
};

const CONSTANTS = {
  ...ENVIROMENT.DEPLOY,
  START_DAY: 26,
  STATION_ID: 'b01c32d3-1bd5-4b01-89cf-1a30959e1536',
  REFINARY_ID: 'aeb2ee67-e1e4-4f4e-a5a7-49c6fed16626',
  PETROBRASS500_ID: 'c4e884cb-b7de-47da-816a-68036538ce3c',
};

export default CONSTANTS;

import usePagination from 'hooks/usePagination';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, EmptyState, Flex } from '@vli/locomotive-ds';

import TableContainer from 'components/core/TableContainer';
import Table from 'components/core/Table';
import FilterDS from 'components/core/FilterDs';
import Loading from 'components/core/Loading';

import { CommercialNegotiationsActions } from 'store/commercialNegotiations/commercialNegotiations.ducks';
import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';

import {
  getFilterParams,
  DataFilter,
} from 'lib/contexts/commercialNegotiations';
import { isPending } from 'lib/core/request';
import { removeArrayDuplicates } from 'lib/core/object';

import Columns from './Columns';

const NegotiationsList = () => {
  const dispatch = useDispatch();
  const { paginationSize, setValuePage } = usePagination();

  const {
    data,
    filter,
    commercialNegotiationsRequest,
    railwayItems,
  } = useSelector(({ commercialNegotiations }) => commercialNegotiations);
  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  const [dataFilter, setDataFilter] = useState(filter);

  useEffect(() => {
    dispatch(ActiveContractActions.activeContract());
    dispatch(CommercialNegotiationsActions.commercialNegotiationsRailwayItem());
  }, [dispatch]);

  useEffect(() => {
    const params = getFilterParams(filter);

    dispatch(CommercialNegotiationsActions.commercialNegotiationsList(params));
  }, [dispatch, filter]);

  const onPageChange = (pageIndex) => {
    const { pageSize } = data;

    const params = getFilterParams({
      ...filter,
      pageIndex,
      pageSize,
    });

    dispatch(CommercialNegotiationsActions.commercialNegotiationsList(params));
  };

  const onPerPageChange = (pageSize) => {
    const { currentPage } = data;

    const params = getFilterParams({
      ...filter,
      pageIndex: currentPage,
      pageSize,
    });

    setValuePage(pageSize);

    dispatch(CommercialNegotiationsActions.commercialNegotiationsList(params));
  };

  const handleFilterAction = (filterData) => {
    const params = getFilterParams(filterData);

    dispatch(CommercialNegotiationsActions.commercialNegotiationsList(params));
  };

  const onConfirm = (filterData) => {
    setDataFilter(filterData);
    handleFilterAction(filterData);
  };

  const onClean = () => {
    setDataFilter({});
    handleFilterAction({});
  };

  const isLoading =
    isPending(commercialNegotiationsRequest.COMMERCIAL_NEGOTIATIONS_LIST) ||
    isPending(
      commercialNegotiationsRequest.COMMERCIAL_NEGOTIATIONS_RAILWAY_ITEM,
    ) ||
    isPending(commercialNegotiationsRequest.COMMERCIAL_NEGOTIATIONS_DOWNLOAD);

  const itemList = useMemo(() => {
    if (railwayItems?.length) {
      const items = railwayItems.map((item) => item.railwayItemCode);

      return removeArrayDuplicates(items);
    }

    return [];
  }, [railwayItems]);

  return (
    <TableContainer data-testid="negotiations-list-table">
      <Flex justifyContent="flex-end" marginBottom="8px">
        <FilterDS
          defaultSelectedItems={dataFilter}
          listItems={DataFilter(activeContractList, itemList)}
          onConfirm={onConfirm}
          onClean={onClean}
        />
      </Flex>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data?.items?.length ? (
            <Table
              width="100%"
              columns={Columns}
              data={data.items}
              customList={paginationSize}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              pageIndex={data.currentPage}
              pageCount={data.totalPages}
              totalItems={data.totalCount}
              footer
            />
          ) : (
            <Card>
              <EmptyState title="Nenhuma negociação encontrada." />
            </Card>
          )}
        </>
      )}
    </TableContainer>
  );
};

export default NegotiationsList;

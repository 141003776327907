import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@vli/locomotive-ds/dist';

import Content from 'components/core/content';
import Form from 'components/contexts/segments/form/Form';
import Loading from 'components/core/Loading';

import { isPending } from 'lib/core/request';

import useDimensions from 'hooks/useDimensions';

import { SegmentActions } from 'store/segment/segment.ducks';

import * as ROUTES from 'constants/urls';

const breadcrumb = [
  {
    text: 'Segmentos',
    href: `#${ROUTES.SEGMENT_LIST}`,
  },
  {
    text: 'Novo segmento',
    href: `#${ROUTES.NEW_SEGMENT}`,
  },
];

const CreateSegment = () => {
  const dimensions = useDimensions();

  const dispatch = useDispatch();
  const { segmentRequests } = useSelector(({ segment }) => segment);

  const onSubmit = (payload) => {
    dispatch(SegmentActions.createSegment(payload));
  };

  const isLoading = isPending(segmentRequests.CREATE_SEGMENT);

  return (
    <Content height={dimensions.height} data-testid="content">
      <Container>
        {isLoading && <Loading />}

        {!isLoading && <Form breadcrumb={breadcrumb} onSubmit={onSubmit} />}
      </Container>
    </Content>
  );
};

export default CreateSegment;

import { put, takeLatest } from 'redux-saga/effects';

import { SYSTEM_PARAMETERS } from 'constants/endpoints';
import { GET, PUT } from 'constants/verbs';

import api from 'services/api';

import { MessagesActions } from 'store/messages/messages.ducks';
import {
  SystemParametersActions,
  SystemParametersTypes,
} from './systemParameters.ducks';

export function* systemParametersList() {
  try {
    const response = yield api({
      method: GET,
      url: SYSTEM_PARAMETERS,
    });

    yield put(
      SystemParametersActions.systemParametersListSuccess(response.data.data),
    );
  } catch (error) {
    yield put(SystemParametersActions.systemParametersListFailure(error));
  }
}

export function* systemParametersUpdate({ payload }) {
  try {
    yield api({
      method: PUT,
      url: SYSTEM_PARAMETERS,
      data: payload,
    });

    yield put(SystemParametersActions.systemParametersUpdateSuccess());

    yield put(
      MessagesActions.open({
        title: 'Sucesso ao atualizar dados',
        type: 'success',
        contentText: 'Os parâmetros do sistema foram atualizados',
      }),
    );

    yield put(SystemParametersActions.systemParametersList());
  } catch (error) {
    yield put(SystemParametersActions.systemParametersUpdateFailure(error));

    yield put(
      MessagesActions.open({
        title: 'Erro ao atualizar dados',
        type: 'error',
        contentText: 'Não foi possível atualizar parâmetros do sistema.',
      }),
    );
  }
}

export function* watchSagas() {
  yield takeLatest(
    SystemParametersTypes.SYSTEM_PARAMETERS_LIST,
    systemParametersList,
  );
  yield takeLatest(
    SystemParametersTypes.SYSTEM_PARAMETERS_UPDATE,
    systemParametersUpdate,
  );
}

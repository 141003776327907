import React from 'react';

import CellTable from 'components/core/Table/CellTable/CellTable';

export const Columns = () => [
  {
    Header: 'Percentual',
    accessor: 'percent',
    align: 'left',
    Cell: (props) => <CellTable {...props} />,
  },
  {
    Header: 'Base de diesel',
    accessor: 'name',
    align: 'left',
    Cell: (props) => <CellTable {...props} />,
  },
];

import { Tokens as theme } from '@vli/locomotive-ds/dist';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 160px;
  right: 375px;
  display: inline-table;
  z-index: 1000;
  padding: 20px;
  user-select: none;
  background: ${theme.colors.grayWhite};
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.12);
  overflow: auto;
  width: 550px;

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;

    &:hover {
      width: 10px;
      cursor: pointer;
      background: ${theme.colors.accentPrimaryLight};
    }
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.accentPrimaryDark};
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  align-items: center;
`;

export const Close = styled.div`
  margin: 0 0 0 auto;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 20px;
  button:first-child {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  padding: 16px 0 10px 0;
`;

import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';

import { CUSTOMER, CUSTOMER_PROPOSALS } from 'constants/endpoints';
import { GET, PUT } from 'constants/verbs';

import { CustomerTypes, CustomerActions } from './customer.ducks';

export function* listCustomer({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: params ? `${CUSTOMER}/?ViewScenario=${params}` : CUSTOMER,
    });

    yield put(CustomerActions.listCustomerSuccess(response.data.data));
  } catch (error) {
    yield put(CustomerActions.customerRequestFailure(error));
  }
}

export function* updateCustomer({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: CUSTOMER,
      data: payload,
    });

    yield put(CustomerActions.updateCustomerSuccess());

    yield callback();
  } catch (error) {
    yield put(CustomerActions.customerRequestFailure(error));
  }
}

export function* listProposalsCustomer() {
  try {
    const response = yield api({
      method: GET,
      url: CUSTOMER_PROPOSALS,
    });

    yield put(CustomerActions.listProposalsCustomerSuccess(response.data.data));
  } catch (error) {
    yield put(CustomerActions.customerRequestFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(CustomerTypes.LIST_CUSTOMER, listCustomer);
  yield takeLatest(CustomerTypes.UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(
    CustomerTypes.LIST_PROPOSALS_CUSTOMER,
    listProposalsCustomer,
  );
}

import styled from 'styled-components';

import { Tokens as theme } from '@vli/locomotive-ds/dist';

export const Title = styled.span`
  color: ${theme.colors.gray500};
  font: ${theme.fonts.textRegularMedium};
  font-weight: normal;
`;
export const Label = styled.span`
  color: ${theme.colors.gray500};
  font: ${theme.fonts.textRegularSmall};
  font-weight: normal;
`;
export const Field = styled.div`
  margin-top: ${theme.spacing.xs};
`;

export const Flex = styled.div`
  display: flex;
`;

export const ContainerInput = styled.div`
  height: 120px;
  margin-top: 15px;
  overflow: auto;
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
`;
export const MessageErrors = styled.div`
  text-align: right;
  margin-top: 4px;
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font: ${theme.fonts.textRegularMedium};
    color: ${theme.colors.gray400};
    font-weight: 400;
  }
`;

export const WarningNotice = styled(Notice)`
  margin-bottom: 12px;

  svg path:first-of-type {
    fill: ${theme.colors.yellow300};
  }
`;

export const ErrorNotice = styled(Notice)`
  svg path:first-of-type {
    fill: ${theme.colors.red300};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceStrict } from 'date-fns';
import { pt } from 'date-fns/locale';

import { ChevronRightIcon, CheckIcon } from '@vli/locomotive-ds';

import * as S from './ItemStyle';

const Item = ({
  data,
  onRedirectNotification,
  onMarkNotificationAsRead,
  ...attrs
}) => {
  const formattedTime = formatDistanceStrict(
    new Date(data.dataCriacao),
    new Date(),
    {
      locale: pt,
      addSuffix: false,
    },
  );

  return (
    <S.ItemWrapper isRead={data.visualizada} data-testid="item-test" {...attrs}>
      <div>
        <S.ItemTitle>{data.titulo}</S.ItemTitle>

        <S.ItemDescription isRead={data.visualizada}>
          {data.mensagemFinal}
        </S.ItemDescription>

        <S.ItemTime>{formattedTime}</S.ItemTime>
      </div>

      <S.ItemButtonWrapper data-testid="item-unread-test">
        {data.caminhoRelativo.length > 0 ? (
          <S.ButtonLink
            onClick={() => onRedirectNotification(data)}
            data-testid="item-button-link-test"
          >
            <ChevronRightIcon />
          </S.ButtonLink>
        ) : (
          <S.ButtonMark
            onClick={() => onMarkNotificationAsRead(data.id)}
            data-testid="item-button-mark-test"
            disabled={data.visualizada}
          >
            {data.visualizada ? (
              <>
                <CheckIcon />
                <CheckIcon />
              </>
            ) : (
              <CheckIcon />
            )}
          </S.ButtonMark>
        )}
      </S.ItemButtonWrapper>
    </S.ItemWrapper>
  );
};

Item.propTypes = {
  data: PropTypes.object,
  onRedirectNotification: PropTypes.func,
  onMarkNotificationAsRead: PropTypes.func,
};

Item.defaultProps = {
  data: {},
  onRedirectNotification: () => {},
  onMarkNotificationAsRead: () => {},
};

export default Item;
